import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import ClassicDecorator from '../decorators/QueryTagEntityDecorator';
import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';
import NEO_SEARCH_ENTITY_TYPES_ARRAY from '../consts/NeoSearchEntityTypes';

const handleEntityStrategyByType = (types: Array<NeoSearchEntityTypeEnum>) => (
  contentBlock: ContentBlock, callback, contentState: ContentState,
): void => contentBlock.findEntityRanges((character) => {
  const entityKey = character.getEntity();
  if (entityKey === null) {
    return false;
  }

  return types.includes(
      contentState.getEntity(entityKey).getType() as NeoSearchEntityTypeEnum,
  );
}, callback);

const createNeoSearchEditorDecorators = (): CompositeDecorator => new CompositeDecorator([
  {
    strategy: handleEntityStrategyByType(NEO_SEARCH_ENTITY_TYPES_ARRAY),
    component: ClassicDecorator,
  },
]);

export default createNeoSearchEditorDecorators;
