export enum DateSlugEnum {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_TWENTY_FOUR_HOURS = 'Last 24 Hours',
  YESTERDAY_AND_TODAY = 'Yesterday and Today',
  THIS_WEEK = 'This Week (Sunday to Today)',
  LAST_SEVEN_DAYS = 'Last 7 Days',
  THIS_MONTH = 'This Month',
  PREVIOUS_MONTH = 'Previous Month',
  THIS_YEAR = 'This Year',
  LAST_TWELVE_MONTHS = 'Last 12 Months',
  ALL = 'All',
}

export interface DateSlugOption {
  id: DateSlugEnum;
  title: string;
  value: string;
}
