import {
  buildFeedbackMessage,
  CreateMontageRequest,
  CreateMontageResponse,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetMontagesResponse,
  getMontageTransformationUtilities,
  MontageItem,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  createAndClickDownloadLink,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

import { HeartbeatDataChange, HeartbeatDataType, HeartbeatMonitor } from '../redux/reducers/Heartbeat';
import { beat } from '../constants/actions/creators/HeartbeatActions';

export const getMontages = (
  params?: object, cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<MontageItem>> => axios.get<GetMontagesResponse>('/montage', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_MONTAGES]}`)
      .setNotifyUser(false)
      .build()));
    return getMontageTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_MONTAGES]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const downloadMontageFile = (
  resourceUrl: string, sourceName: string,
) => (dispatch): Promise<Blob> => fetch(resourceUrl)
  .then((response) => response.blob())
  .then((response) => {
    createAndClickDownloadLink(response, `${sourceName}.mp4`);

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.DOWNLOAD_MONTAGE]}`)
      .setNotifyUser(true)
      .build()));
    return response;
  })
  .catch((error) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
      .setSystemMessage(error)
      .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.DOWNLOAD_MONTAGE]}`)
      .setNotifyUser(true)
      .build()));
    return Promise.reject(error);
  });

export const createMontage = (
  request: CreateMontageRequest, cancelToken?: CancelToken,
) => (dispatch): Promise<MontageItem> => axios.post<CreateMontageResponse>('/montage', request, {
  cancelToken,
})
  .then(({ data }) => {
    const heartbeatPayload: HeartbeatMonitor = {
      type: HeartbeatDataType.MONTAGE,
      cause: HeartbeatDataChange.CREATE,
      identification: {},
    };
    dispatch(beat(heartbeatPayload));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.CREATE_MONTAGE]}`)
      .setNotifyUser(true)
      .build()));

    return getMontageTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.CREATE_MONTAGE]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });
