import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { NotFound, useScheduler } from 'mediascouting-core-ui-common';
import { EditorState } from 'draft-js';
import NeoBarContent from './components/NeoBarContent';
import MobileDrawer from './components/MobileDrawer';
import DesktopDrawer from './components/DesktopDrawer';
import getNeoSearchRequest, { DEFAULT_NEO_SEARCH_REQUEST } from './utils/NeoSearchBuilderUtils';
import useNeoBarNavSections from './hooks/useNeoBarNavSections';
import getSearchTerms from './utils/NeoSearchEditorUtils';
import {
  NeoSearchConditionHandlers, NeoSearchEntityTypeEnum, NeoSearchRequest,
} from './types/NeoSearch';
import getConditionHandlers from './utils/NeoSearchConditionHandlers';
import { NeoBarConfiguration } from './types/NeoBarConfiguration';

interface NeoBarPropTypes {
  open: boolean;
  logo: string | NotFound;
  configuration: NeoBarConfiguration;
  onClose: VoidFunction;
}

function NeoBar(props: NeoBarPropTypes): JSX.Element {
  const {
    open, configuration, logo, onClose,
  } = props;
  const {
    getNavBarSections, handleQuerySearch, handleTagSearch, handleSharedQuerySearch,
    handleSharedTagSearch,
  } = useNeoBarNavSections();
  const { onSchedule } = useScheduler();
  const [searchRequest, setSearchRequest] = useState<NeoSearchRequest>(DEFAULT_NEO_SEARCH_REQUEST);

  const handlers: NeoSearchConditionHandlers = useMemo(() => ({
    handleQuerySearch,
    handleSharedQuerySearch,
    handleTagSearch,
    handleSharedTagSearch,
  }), [handleQuerySearch, handleSharedQuerySearch, handleSharedTagSearch, handleTagSearch]);

  const handleSearching = useCallback((text: string, types: Array<NeoSearchEntityTypeEnum>): void => {
    const request = getNeoSearchRequest(text, types);
    const conditions = getConditionHandlers(request, handlers);
    conditions.forEach((condition) => condition.if && condition.then());

    setSearchRequest(request);
  }, [handlers]);

  const handleEditorStateChanged = useCallback((editorState: EditorState) => {
    const { searchText, types } = getSearchTerms(editorState);

    if (configuration.search.neoSearch.autoSearch) {
      onSchedule(() => handleSearching(searchText, types), configuration.search.autoSearchInterval);
    }
  }, [configuration.search.autoSearchInterval, configuration.search.neoSearch.autoSearch,
    handleSearching, onSchedule]);

  const handleSearchPressed = useCallback((currentEditorState: EditorState) => {
    const { searchText, types } = getSearchTerms(currentEditorState);

    handleSearching(searchText, types);
  }, [handleSearching]);

  const getContent = (): React.ReactNode => (
      <NeoBarContent
        logo={logo}
        sections={getNavBarSections(searchRequest)}
        onSearch={handleSearchPressed}
        onSearchEditorStateChanged={handleEditorStateChanged}
        configuration={configuration}
      />
  );

  return (
      <div>
          <MobileDrawer open={open} onNavClose={onClose}>
              {getContent()}
          </MobileDrawer>
          <DesktopDrawer open={open}>
              {getContent()}
          </DesktopDrawer>
      </div>
  );
}

export default memo(NeoBar);
