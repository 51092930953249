import {
  Author,
  AuthorFilterDTO,
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetAuthorsRemoteParams,
  GetAuthorsResponse,
  getAuthorTransformationUtilities,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

export const getAuthors = (
  params?: GetAuthorsRemoteParams,
  cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<Author>> => axios
  .get<GetAuthorsResponse>('/authors', {
    params,
    cancelToken,
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_AUTHORS]}`)
      .setNotifyUser(false)
      .build()));
    return getAuthorTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_AUTHORS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getAuthor = (id: number) => (dispatch): Promise<Author> => axios
  .get<AuthorFilterDTO>(`/authors/${id}`, {
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_AUTHOR]}`)
      .setNotifyUser(false)
      .build()));
    return getAuthorTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_AUTHOR]}`)
        .setNotifyUser(true)
        .build()));
    }
    return Promise.reject(error);
  });
