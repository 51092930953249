import { EMPTY_STRING, SPACE } from 'mediascouting-core-ui-common';
import { EditorState } from 'draft-js';
import { findEntityRanges } from '../../../../utils/draft-js/EntityUtils';
import { EntityRange } from '../../../../types/layout/topBar/search/editor';
import { NeoSearchEntityTypeEnum, NeoSearchTerms } from '../types/NeoSearch';
import NEO_SEARCH_ENTITY_TYPES_ARRAY from '../components/NeoSearch/consts/NeoSearchEntityTypes';

const extractSearchingTerms = (editorState: EditorState): NeoSearchTerms => {
  const searchText = editorState.getCurrentContent().getPlainText();
  let text = EMPTY_STRING;
  let lastIndex = 0;

  const entityRanges = findEntityRanges(editorState, NEO_SEARCH_ENTITY_TYPES_ARRAY);
  const types: Array<NeoSearchEntityTypeEnum> = entityRanges.map((result: EntityRange) => {
    const type = result.entity.getType() as NeoSearchEntityTypeEnum;
    const textUntilType = searchText.substring(lastIndex, result.start);

    lastIndex = result.end;
    text = text.concat(textUntilType);
    return type;
  });

  const remainingText = searchText.substring(lastIndex);
  text = text.concat(remainingText);

  const splits = text.split(SPACE);
  const updatedSplits = splits.map((split) => split.trim());
  const updatedSearchText = updatedSplits
    .filter((split) => split !== EMPTY_STRING)
    .join(SPACE)
    .trimStart()
    .trimEnd();

  return {
    searchText: updatedSearchText,
    types,
  };
};

export default extractSearchingTerms;
