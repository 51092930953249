import { useMemo } from 'react';
import { HeartbeatDataChange, HeartbeatDataType } from '../../../../redux/reducers/Heartbeat';
import useHeartbeat from '../../../../hooks/useHeartbeat';

interface UseNeoBarHeartbeatPropTypes {
  initQueries: () => void;
  initTags: () => void;
}
const useNeoBarHeartbeat = (props: UseNeoBarHeartbeatPropTypes): {} => {
  const { initQueries, initTags } = props;

  const heartbeatMonitors = useMemo(() => [
    {
      type: HeartbeatDataType.QUERY,
      cause: HeartbeatDataChange.ANY,
      identification: {},
      act: initQueries,
    },
    {
      type: HeartbeatDataType.TAG,
      cause: HeartbeatDataChange.ANY,
      identification: {},
      act: initTags,
    },
  ], [initQueries, initTags]);

  useHeartbeat({
    monitors: heartbeatMonitors,
  });

  return {};
};

export default useNeoBarHeartbeat;
