import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetMarketsResponse,
  getMarketTransformationUtilities,
  Market,
  MarketDTO,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

const getMarkets = (
  params?: object,
  cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<Market>> => axios.get<GetMarketsResponse>('/markets', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_MARKETS]}`)
      .setNotifyUser(false)
      .build()));
    return getMarketTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_MARKETS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getMarket = (
  id: number,
  cancelToken?: CancelToken,
) => (dispatch): Promise<Market> => axios.get<MarketDTO>(`/markets/${id}`, {
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_MARKET]}`)
      .setNotifyUser(false)
      .build()));
    return getMarketTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_MARKET]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export default getMarkets;
