import { PageableProperties } from 'mediascouting-core-ui-common';
import { SelectionState, SelectionType, SelectTarget } from '../../types/query/Selector';
import { StorySnapSelectionRangeEnum } from '../../constants/StorySnapToolbarSelectionOptions';
import { INIT_SELECTION, QueryStoryResults } from '../../redux/reducers/QueryStoryResults';
import {
  SELECT_QUERY_ALL_STORIES_LIMIT,
} from '../../components/features/story/StorySnapToolbar/SelectionRangeMenuOptions';

const handleQueryRange = (target: SelectTarget, previousState: SelectionState): SelectionState => ({
  range: StorySnapSelectionRangeEnum.QUERY,
  selectedStoriesBetweenPages: [],
  latestAvailableStories: [],
  storiesVisibleInViewAtMoment: target.storiesVisibleInViewAtMoment || previousState.storiesVisibleInViewAtMoment,
});

const handlePageRange = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  if (target.type === SelectionType.SELECT) {
    return {
      range: target.range,
      selectedStoriesBetweenPages: Array.from(
        new Set([...previousState.selectedStoriesBetweenPages, ...target.storiesToSelect]),
      ),
      latestAvailableStories: target.latestAvailableStories || previousState.latestAvailableStories,
      storiesVisibleInViewAtMoment: target.storiesVisibleInViewAtMoment || previousState.storiesVisibleInViewAtMoment,
    };
  }
  if (target.type === SelectionType.DESELECT) {
    return {
      range: target.range,
      selectedStoriesBetweenPages: previousState.selectedStoriesBetweenPages
        .filter((value) => !target.storiesToSelect.includes(value)),
      latestAvailableStories: target.latestAvailableStories || previousState.latestAvailableStories,
      storiesVisibleInViewAtMoment: target.storiesVisibleInViewAtMoment || previousState.storiesVisibleInViewAtMoment,
    };
  }
  return previousState;
};

const handleInvertRange = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  let updatedSelectedIdsBetweenPages = [...previousState.selectedStoriesBetweenPages];

  previousState.latestAvailableStories.forEach((availableStory) => {
    if (previousState.selectedStoriesBetweenPages.find((story) => story.id === availableStory.id)) {
      updatedSelectedIdsBetweenPages = updatedSelectedIdsBetweenPages.filter((story) => story.id !== availableStory.id);
    } else {
      updatedSelectedIdsBetweenPages.push(availableStory);
    }
  });

  return ({
    range: target.range,
    selectedStoriesBetweenPages: updatedSelectedIdsBetweenPages,
    latestAvailableStories: target.latestAvailableStories || previousState.latestAvailableStories,
    storiesVisibleInViewAtMoment: target.storiesVisibleInViewAtMoment || previousState.storiesVisibleInViewAtMoment,
  });
};

const RANGE_MAPPER: {
    [key in StorySnapSelectionRangeEnum]: (
        target: SelectTarget,
        previousState: SelectionState
    ) => SelectionState
} = {
  [StorySnapSelectionRangeEnum.QUERY]: handleQueryRange,
  [StorySnapSelectionRangeEnum.PAGE]: handlePageRange,
  [StorySnapSelectionRangeEnum.INVERT]: handleInvertRange,
};

const handleSelect = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  const { range } = target;
  const optionalHandler = RANGE_MAPPER[range];

  if (optionalHandler) {
    return optionalHandler(target, previousState);
  }

  return previousState;
};

const handleDeselect = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  const { range } = target;
  const optionalHandler = RANGE_MAPPER[range];

  if (optionalHandler) {
    return optionalHandler(target, previousState);
  }

  return previousState;
};

const handleUpdate = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  if (previousState.range === StorySnapSelectionRangeEnum.QUERY) {
    return {
      ...previousState,
      latestAvailableStories: target.latestAvailableStories || previousState.latestAvailableStories,
    };
  }
  return {
    ...previousState,
    latestAvailableStories: target.latestAvailableStories || previousState.latestAvailableStories,
    storiesVisibleInViewAtMoment: target.storiesVisibleInViewAtMoment || previousState.storiesVisibleInViewAtMoment,
  };
};

const SELECTION_TYPE_MAPPER: {
    [key in SelectionType]: (
        target: SelectTarget,
        previousState: SelectionState
    ) => SelectionState
} = ({
  [SelectionType.SELECT]: handleSelect,
  [SelectionType.DESELECT]: handleDeselect,
  [SelectionType.UPDATE]: handleUpdate,
});

export const getUpdatedState = (target: SelectTarget, previousState: SelectionState): SelectionState => {
  const handler = SELECTION_TYPE_MAPPER[target.type];
  if (handler) {
    return handler(target, previousState);
  }

  return previousState;
};

export const getSelectionStateOnPaginationChange = (
  pageableProperties: PageableProperties, previousState: QueryStoryResults,
): SelectionState => {
  if (previousState.selection.range === StorySnapSelectionRangeEnum.QUERY) {
    if (pageableProperties.totalElements >= SELECT_QUERY_ALL_STORIES_LIMIT) {
      return INIT_SELECTION;
    }
    return {
      ...previousState.selection,
      storiesVisibleInViewAtMoment: pageableProperties.totalElements,
    };
  }
  return previousState.selection;
};
