import { DateSlugEnum, DateSlugOption } from '../types/query/results/DateSlug';

const DATE_SLUG_OPTIONS: Array<DateSlugOption> = [
  {
    id: DateSlugEnum.TODAY,
    title: 'date.slug.today',
    value: DateSlugEnum.TODAY,
  },
  {
    id: DateSlugEnum.YESTERDAY,
    title: 'date.slug.yesterday',
    value: DateSlugEnum.YESTERDAY,
  },
  {
    id: DateSlugEnum.LAST_TWENTY_FOUR_HOURS,
    title: 'date.slug.last.twenty.four.hours',
    value: DateSlugEnum.LAST_TWENTY_FOUR_HOURS,
  },
  {
    id: DateSlugEnum.YESTERDAY_AND_TODAY,
    title: 'date.slug.yesterday.and.today',
    value: DateSlugEnum.YESTERDAY_AND_TODAY,
  },
  {
    id: DateSlugEnum.THIS_WEEK,
    title: 'date.slug.this.week',
    value: DateSlugEnum.THIS_WEEK,
  },
  {
    id: DateSlugEnum.LAST_SEVEN_DAYS,
    title: 'date.slug.last.seven.days',
    value: DateSlugEnum.LAST_SEVEN_DAYS,
  },
  {
    id: DateSlugEnum.THIS_MONTH,
    title: 'date.slug.this.month',
    value: DateSlugEnum.THIS_MONTH,
  },
  {
    id: DateSlugEnum.PREVIOUS_MONTH,
    title: 'date.slug.previous.month',
    value: DateSlugEnum.PREVIOUS_MONTH,
  },
  {
    id: DateSlugEnum.THIS_YEAR,
    title: 'date.slug.this.year',
    value: DateSlugEnum.THIS_YEAR,
  },
  {
    id: DateSlugEnum.LAST_TWELVE_MONTHS,
    title: 'date.slug.last.twelve.months',
    value: DateSlugEnum.LAST_TWELVE_MONTHS,
  },
  {
    id: DateSlugEnum.ALL,
    title: 'date.slug.all',
    value: DateSlugEnum.ALL,
  },
];

export const getStoryDateSlugOptions = (): Array<DateSlugOption> => DATE_SLUG_OPTIONS;

export const getDefaultDateSlug = (): DateSlugOption => {
  const options = getStoryDateSlugOptions();
  const DEFAULT = DateSlugEnum.LAST_SEVEN_DAYS;

  const found = options.find((search) => search.id === DEFAULT);

  if (found) {
    return found;
  }

  return DATE_SLUG_OPTIONS[0];
};
