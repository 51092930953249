import React, { useCallback, useMemo, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import VerticalSplitOutlinedIcon from '@material-ui/icons/VerticalSplitOutlined';
import {
  Radio, SvgIcon, Typography, useMediaQuery,
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { DEFAULT_VIEW_TYPE } from '../../../../constants/LocalStorage';
import { ViewTypeEnum } from '../../../../types/query/results/ViewTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(0.5),
  },
  icon: {
    color: 'lightgrey',
  },
}));

interface ViewType{
  id: ViewTypeEnum;
  label: string;
  icon: typeof SvgIcon;
  disabledOnMobile?: boolean;
}

export interface QuickSettingsConfiguration {
  disabledViews: Array<ViewTypeEnum>;
}

interface ViewSettingsPropTypes{
  configuration?: QuickSettingsConfiguration;
}
export default function ViewSettings(props: ViewSettingsPropTypes): JSX.Element {
  const { configuration } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [defaultViewType, setDefaultViewType] = useState(
    localStorage.getItem(DEFAULT_VIEW_TYPE) || ViewTypeEnum.GRID_VIEW_TYPE,
  );

  const handleChange = (event): void => {
    setDefaultViewType(event.target.value);

    localStorage.setItem(DEFAULT_VIEW_TYPE, event.target.value);
  };

  const viewTypes = useMemo((): Array<ViewType> => ([
    {
      id: ViewTypeEnum.GRID_VIEW_TYPE,
      label: 'layout.topBar.search.searchBar.settings.view.brief',
      icon: ViewModuleOutlinedIcon,
    },
    {
      id: ViewTypeEnum.FULL_VIEW_TYPE,
      label: 'layout.topBar.search.searchBar.settings.view.detailed',
      icon: ViewStreamOutlinedIcon,
    },
    {
      id: ViewTypeEnum.LIST_VIEW_TYPE,
      label: 'layout.topBar.search.searchBar.settings.view.catalogue',
      icon: ReorderOutlinedIcon,
    }, {
      id: ViewTypeEnum.SPLIT_VIEW_TYPE,
      label: 'layout.topBar.search.searchBar.settings.view.vertical',
      icon: VerticalSplitOutlinedIcon,
      disabledOnMobile: true,
    }, {
      id: ViewTypeEnum.TIMELINE_VIEW_TYPE,
      label: 'layout.topBar.search.searchBar.settings.view.chronological',
      icon: TimelineOutlinedIcon,
      disabledOnMobile: true,
    },
  ]), []);

  const getAvailableViewTypes = useCallback(() => viewTypes
    .filter((viewType) => !configuration?.disabledViews
        .includes(viewType.id)), [configuration, viewTypes]);

  const renderViewTypes = useCallback(() => getAvailableViewTypes().map((viewType) => {
    const Icon = viewType.icon;
    return (
        <div
          key={viewType.id}
          className={classes.item}
        >
            <FormControlLabel
              key={viewType.id}
              checked={defaultViewType === viewType.id}
              onChange={handleChange}
              control={<Radio size="small" />}
              value={viewType.id}
              label={t(viewType.label)}
              disabled={(mobileScreen && viewType.disabledOnMobile)}
            />
            <Icon fontSize="large" className={classes.icon} />
        </div>
    );
  }), [classes.icon, classes.item, defaultViewType, mobileScreen, t, getAvailableViewTypes]);

  return (
      <FormControl className={classes.root} component="fieldset">
          <FormLabel component="legend">
              <Typography variant="overline">
                  {t('layout.topBar.search.searchBar.settings.view')}
              </Typography>
          </FormLabel>
          {renderViewTypes()}
      </FormControl>
  );
}
