import { PowerSearchFilterTypes } from 'mediascouting-core-ui-common';
import UrlParams from '../types/UrlParams';

export type UrlQueryOption = {
  name: UrlParams;
  powerSearchFilterType: PowerSearchFilterTypes;
  isAsynchronous?: boolean;
};

const MULTIVALUED_QUERY_FILTER_URL_KEYS: Array<UrlQueryOption> = [
  {
    name: UrlParams.SUB_QUERIES,
    powerSearchFilterType: PowerSearchFilterTypes.SUBQUERIES,
    isAsynchronous: true,
  },
  {
    name: UrlParams.TAGS,
    powerSearchFilterType: PowerSearchFilterTypes.TAGS,
  },
  {
    name: UrlParams.SOURCE_TYPES,
    powerSearchFilterType: PowerSearchFilterTypes.SOURCE_TYPES,
  },
  {
    name: UrlParams.LANGUAGES,
    powerSearchFilterType: PowerSearchFilterTypes.LANGUAGES,
  },
  {
    name: UrlParams.SOURCES,
    powerSearchFilterType: PowerSearchFilterTypes.SOURCES,
    isAsynchronous: true,
  },
  {
    name: UrlParams.SOURCE_GROUPS,
    powerSearchFilterType: PowerSearchFilterTypes.SOURCE_GROUPS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.GEOLOCATION_TAGS,
    powerSearchFilterType: PowerSearchFilterTypes.GEOLOCATION_TAGS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.MARKETS,
    powerSearchFilterType: PowerSearchFilterTypes.MARKETS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.MARKET_GROUPS,
    powerSearchFilterType: PowerSearchFilterTypes.MARKET_GROUPS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.AUTHORS,
    powerSearchFilterType: PowerSearchFilterTypes.AUTHORS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.SENTIMENTS,
    powerSearchFilterType: PowerSearchFilterTypes.SENTIMENTS,
  },
  {
    name: UrlParams.DAYS,
    powerSearchFilterType: PowerSearchFilterTypes.DAYS,
  },
  {
    name: UrlParams.TIME_SECTIONS,
    powerSearchFilterType: PowerSearchFilterTypes.TIME_SECTIONS,
  },
  {
    name: UrlParams.PEOPLE,
    powerSearchFilterType: PowerSearchFilterTypes.PEOPLE,
    isAsynchronous: true,
  },
  {
    name: UrlParams.ORGANIZATIONS,
    powerSearchFilterType: PowerSearchFilterTypes.ORGANIZATIONS,
    isAsynchronous: true,
  },
  {
    name: UrlParams.ORIGIN,
    powerSearchFilterType: PowerSearchFilterTypes.ORIGIN,
    isAsynchronous: false,
  },
  {
    name: UrlParams.PROGRAMS,
    powerSearchFilterType: PowerSearchFilterTypes.PROGRAMS,
    isAsynchronous: false,
  },
];

export default MULTIVALUED_QUERY_FILTER_URL_KEYS;
