import {
  COMMA,
  encodeFilters,
  GetStatisticsRequest,
  GetStoriesRequest,
  GraphIdentity,
  NOT_FOUND,
  NotFound,
  PowerSearchFilterTypes,
  QueryDTO,
} from 'mediascouting-core-ui-common';
import {
  encodeCurationFacets,
  getActiveFacets,
  getFacetsByExcludingFacetType,
  getFacetsWithDateSlug,
} from './FacetUtils';
import { QuerySearchSpaceReducer } from '../../redux/reducers/QuerySearchSpace';
import { createPowerSearchFiltersFromQueryReducer } from '../common/PowerSearchUtils';

export const getFreeQueryTextWhenDoesNotExceedGETLimit = (freeQueryText: string | NotFound): string | NotFound => {
  const GETCharLimit = 5000;

  if (freeQueryText && freeQueryText?.length > GETCharLimit) {
    return NOT_FOUND;
  }

  return freeQueryText;
};

export const constructQueryDto = (
  name: string, description: string, freeQueryText: string, selectedQueryFilters: string,
  isLiveQuery: boolean, storyDurationFilterId: number, shouldAutoPercolate: boolean,
): Partial<QueryDTO> => ({
  name,
  description,
  freeQueryText,
  selectedQueryFilters,
  isLiveQuery,
  storyDurationFilterId,
  isActive: true,
  shouldAutoPercolate,
});

export const getAggregationsRequiredInRequest = (
  previousQueryReducer: QuerySearchSpaceReducer,
  currentQueryReducer: QuerySearchSpaceReducer,
  initiated: boolean,
  aggregationsDisabled: boolean,
  previousAggregationsDisabled: boolean,
): boolean => {
  if (aggregationsDisabled) {
    return false;
  }

  if (!initiated) {
    return true;
  }

  const previousFilters = createPowerSearchFiltersFromQueryReducer(previousQueryReducer);
  const previousEncodedFilters = encodeFilters(previousFilters);

  const currentFilters = createPowerSearchFiltersFromQueryReducer(currentQueryReducer);
  const currentEncodedFilters = encodeFilters(currentFilters);

  const previousFacetsWithDateSlug = getFacetsWithDateSlug(previousQueryReducer.dateSlug, previousQueryReducer.facets);
  const previousSelectedFacets = getActiveFacets(getFacetsByExcludingFacetType(previousFacetsWithDateSlug, 'curation'));

  const currentFacetsWithDateSlug = getFacetsWithDateSlug(currentQueryReducer.dateSlug, currentQueryReducer.facets);
  const currentSelectedFacets = getActiveFacets(getFacetsByExcludingFacetType(currentFacetsWithDateSlug, 'curation'));

  const previousStoryDuration = previousFilters[PowerSearchFilterTypes.STORY_DURATION];
  const currentStoryDuration = currentFilters[PowerSearchFilterTypes.STORY_DURATION];

  const queryIdChanged = previousQueryReducer.query?.id !== currentQueryReducer.query?.id;
  const searchTextChanged = previousQueryReducer.searchText !== currentQueryReducer.searchText;
  const selectedFiltersChanged = previousEncodedFilters !== currentEncodedFilters;
  const previewEnabledChanged = previousQueryReducer.previewNewItems !== currentQueryReducer.previewNewItems;
  const facetsAppliedChanged = previousSelectedFacets !== currentSelectedFacets;
  const storyDurationFilterIdChanged = previousStoryDuration !== currentStoryDuration;
  const disableFacetsChanged = previousAggregationsDisabled !== aggregationsDisabled;

  const changedBooleanIndicationsBetweenQuerySearchSpaces = [queryIdChanged, searchTextChanged, selectedFiltersChanged,
    previewEnabledChanged, facetsAppliedChanged, storyDurationFilterIdChanged, disableFacetsChanged];

  const querySearchSpaceContainsChangesThatImpactFacets = changedBooleanIndicationsBetweenQuerySearchSpaces
    .find((bool) => bool) || false;

  return querySearchSpaceContainsChangesThatImpactFacets;
};

export const createStorySearchRequestFromQueryReducer = (
  queryReducer: QuerySearchSpaceReducer,
  aggregationsRequired: boolean,
): GetStoriesRequest => {
  const filters = createPowerSearchFiltersFromQueryReducer(queryReducer);
  const facetsWithDateSlug = getFacetsWithDateSlug(queryReducer.dateSlug, queryReducer.facets);
  const encodedFilters = encodeFilters(filters);
  const selectedFacets = getActiveFacets(getFacetsByExcludingFacetType(facetsWithDateSlug, 'curation'));
  const storyDuration = filters[PowerSearchFilterTypes.STORY_DURATION];
  const curationFacets = encodeCurationFacets(facetsWithDateSlug);

  return {
    queryId: (queryReducer.query?.id) ? queryReducer.query.id.toString() : NOT_FOUND,
    freeQueryText: (queryReducer.query?.id && !queryReducer.previewNewItems)
      ? NOT_FOUND
      : getFreeQueryTextWhenDoesNotExceedGETLimit(queryReducer.searchText),
    selectedQueryFilters: encodedFilters,
    page: queryReducer.page - 1,
    previewEnabled: queryReducer.previewNewItems,
    enableAggregations: aggregationsRequired,
    enableHighlights: true,
    size: queryReducer.pageSize,
    facetsApplied: selectedFacets,
    sortField: queryReducer.sort.sortField,
    sortOrder: queryReducer.sort.sortOrder,
    storyDurationFilterId: storyDuration ? storyDuration.id.toString() : NOT_FOUND,
    curationFacets,
  };
};

export const createStatisticsSearchRequest = (
  querySearchSpace: QuerySearchSpaceReducer,
  graphIdentities: Array<GraphIdentity>,
): GetStatisticsRequest => {
  const filters = createPowerSearchFiltersFromQueryReducer(querySearchSpace);
  const facetsWithDateSlug = getFacetsWithDateSlug(querySearchSpace.dateSlug, querySearchSpace.facets);
  const encodedFilters = encodeFilters(filters);
  const selectedFacets = getActiveFacets(getFacetsByExcludingFacetType(facetsWithDateSlug, 'curation'));

  return {
    queryId: (querySearchSpace.query?.id) ? querySearchSpace.query.id.toString() : NOT_FOUND,
    freeQueryText: getFreeQueryTextWhenDoesNotExceedGETLimit(querySearchSpace.searchText),
    selectedQueryFilters: encodedFilters,
    curationFacets: encodeCurationFacets(facetsWithDateSlug),
    facetsApplied: selectedFacets,
    graphIds: graphIdentities.join(COMMA),
    storyDurationFilterId: querySearchSpace[PowerSearchFilterTypes.STORY_DURATION]?.id.toString() || NOT_FOUND,
    previewEnabled: querySearchSpace.previewNewItems,
  };
};
