import { ContentBlock, ContentState, EditorState } from 'draft-js';
import { EMPTY_STRING, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import { EntityRange, EntityRecognition } from '../../../../../../../../types/layout/topBar/search/editor';
import { getPreviousEntity } from '../../../../../../../../utils/draft-js/EntityUtils';

type EntityEdges = {
  start: number;
  end: number;
}

const findEntityRange = (contentBlock: ContentBlock, entityKey: string): EntityEdges | NotFound => {
  let optionalStart;
  let optionalEnd;

  contentBlock.findEntityRanges((character) => {
    const retrievedEntityKey = character.getEntity();

    return retrievedEntityKey === entityKey;
  }, (start, end) => {
    optionalStart = start;
    optionalEnd = end;
  });

  if (optionalStart !== undefined && optionalEnd !== undefined) {
    return {
      start: optionalStart,
      end: optionalEnd,
    };
  }

  return NOT_FOUND;
};

const findReferencedEntityRanges = (
  contentState: ContentState, contentBlock: ContentBlock, parentReference: string,
): EntityRange | NotFound => {
  let optionalEntityRange;

  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      const { reference } = entity.getData();

      if (reference === parentReference) {
        const blockKey = contentBlock.getKey();
        const text = EMPTY_STRING;
        const start = 0;
        const end = 0;

        optionalEntityRange = {
          entityKey, blockKey, entity, text, start, end,
        };

        return true;
      }
    }

    return false;
  }, (start, end) => {
    const text = contentBlock.getText().slice(start, end);

    optionalEntityRange = {
      ...optionalEntityRange, text, start, end,
    };
  });

  return optionalEntityRange;
};

const getEntityRanges = (
  entityKey: string, contentState: ContentState, contentBlock: ContentBlock, blockKey: string,
): EntityRecognition | NotFound => {
  const entityEdges = findEntityRange(contentBlock, entityKey);

  if (entityEdges) {
    const entity = contentState.getEntity(entityKey);
    const { start, end } = entityEdges;
    const { parentReference } = entity.getData();
    const text = contentBlock.getText().slice(start, end);
    const entityRange = {
      entityKey, blockKey, entity, text, start, end,
    };

    if (parentReference) {
      const parentEntityRange = findReferencedEntityRanges(contentState, contentBlock, parentReference);

      return {
        entityRange,
        parentEntityRange,
      };
    }

    return {
      entityRange,
    };
  }

  return NOT_FOUND;
};
const recognizeEntities = (editorState: EditorState): EntityRecognition | NotFound => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockKey = selectionState.getAnchorKey();
  const contentBlock = contentState.getBlockForKey(blockKey);

  if (contentBlock) {
    const entityKey = getPreviousEntity(selectionState.getFocusOffset() - 1, contentBlock);

    if (entityKey) {
      return getEntityRanges(entityKey, contentState, contentBlock, blockKey);
    }
  }

  return NOT_FOUND;
};

const findNearbyEntity = (editorState: EditorState): EntityRecognition | NotFound => {
  try {
    return recognizeEntities(editorState);
  } catch (e) {
    return NOT_FOUND;
  }
};

export default findNearbyEntity;
