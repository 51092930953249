import React, { memo, useCallback } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  GetItemsResponse,
  hasPermission,
  INIT_PAGINATION,
  MojitoSelect,
  PowerSearchFilterTypes,
  PowerSearchSynchronousQueryFilters,
  StoryDuration,
  StyledToggleButton,
  StyledToggleButtonGroup,
  UserPermission,
} from 'mediascouting-core-ui-common';
import { FormikProps } from 'formik';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SettingFormFields } from './index';
import { ReduxState } from '../../../../../../../redux/reducers';

type AdvancedSettingsPropTypes = {
  formikProps: FormikProps<SettingFormFields>;
  synchronousQueryFilterOptions: PowerSearchSynchronousQueryFilters;
};

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  shouldPercolateCheckBox: {
    paddingTop: 10,
  },
}));

function AdvancedSettings(props: AdvancedSettingsPropTypes): JSX.Element {
  const { formikProps, synchronousQueryFilterOptions } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((reduxState: ReduxState) => reduxState.auth.user);

  const canCreateLiveQueries = (): boolean => !!(
    user && hasPermission(UserPermission.CREATE_LIVE_QUERIES)(user));

  const canCreatePercolatedQueries = useCallback((): boolean => !!(
    user && hasPermission(UserPermission.CREATE_PERCOLATED_QUERIES)(user)), [user]);

  const handleStoryDurationChange = useCallback((setFieldValue) => (
    event, values: string | StoryDuration | (string | StoryDuration)[] | null,
  ): void => {
    const valuesAsStoryDuration = (values as StoryDuration);

    if (valuesAsStoryDuration?.id) {
      setFieldValue('storyDurationId', valuesAsStoryDuration.id);

      if (valuesAsStoryDuration.id === 4 && canCreatePercolatedQueries()) {
        setFieldValue('isLiveQuery', false);
      }
    }
  }, [canCreatePercolatedQueries]);

  const handleQueryTypeChange = (setFieldValue) => (event, queryType): void => {
    if (queryType !== null) {
      if (queryType === 'Saved') {
        setFieldValue('isLiveQuery', false);
        setFieldValue('storyDurationId', 4);
      } else {
        setFieldValue('isLiveQuery', true);
        setFieldValue('storyDurationId', 1);
      }
    }
  };

  const handleShouldAutoPercolateChange = useCallback((e) => {
    formikProps.setFieldValue('shouldAutoPercolate', !formikProps.values.shouldAutoPercolate);
  }, [formikProps]);

  const getQueryTypeValue = (): string => {
    if (formikProps.values.isLiveQuery) {
      return 'Live';
    }

    return 'Saved';
  };

  const getSubheader = (title: string, description: string): JSX.Element => (
      <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                  <Typography variant="body2" color="textPrimary">
                      {title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                      {description}
                  </Typography>
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Paper elevation={0} className={classes.paper}>
                  <StyledToggleButtonGroup
                    className={classes.fullWidth}
                    size="small"
                    exclusive
                    value={getQueryTypeValue()}
                    onChange={handleQueryTypeChange(formikProps.setFieldValue)}
                  >
                      <StyledToggleButton
                        className={classes.fullWidth}
                        value="Saved"
                        disabled={!canCreatePercolatedQueries()}
                      >
                          {t('layout.topBar.search.searchBar.query.settings.dialog.advanceSettings.saved')}
                      </StyledToggleButton>
                      <StyledToggleButton
                        className={classes.fullWidth}
                        value="Live"
                        disabled={!canCreateLiveQueries()}
                      >
                          {t('layout.topBar.search.searchBar.query.settings.dialog.advanceSettings.live')}
                      </StyledToggleButton>
                  </StyledToggleButtonGroup>
              </Paper>
          </Grid>
      </Grid>
  );

  const getStoryDurationShallow = useCallback((): StoryDuration | null => {
    const found = synchronousQueryFilterOptions[PowerSearchFilterTypes.STORY_DURATION]
      .find((s) => s.id === formikProps.values.storyDurationId);

    return found || null;
  }, [formikProps.values.storyDurationId, synchronousQueryFilterOptions]);

  const getQueryTypeDescription = (): string => {
    if (formikProps.values.isLiveQuery) {
      return t('layout.topBar.search.searchBar.query.settings.dialog.advanceSettings.live.description');
    }

    return t('layout.topBar.search.searchBar.query.settings.dialog.advanceSettings.saved.description');
  };

  const getStoryDurationOptionsRestrictedByPermission = useCallback((): Array<StoryDuration> => {
    if (canCreatePercolatedQueries()) {
      return synchronousQueryFilterOptions[PowerSearchFilterTypes.STORY_DURATION];
    }

    return synchronousQueryFilterOptions[PowerSearchFilterTypes.STORY_DURATION]
      .filter((storyDuration) => storyDuration.availableForLiveQueries);
  }, [canCreatePercolatedQueries, synchronousQueryFilterOptions]);

  const getStoryDurationOptions = useCallback((): Promise<GetItemsResponse<StoryDuration>> => {
    const content = getStoryDurationOptionsRestrictedByPermission();
    const getItemsResponse: GetItemsResponse<StoryDuration> = {
      content,
      ...INIT_PAGINATION,
    };

    return Promise.resolve(getItemsResponse);
  }, [getStoryDurationOptionsRestrictedByPermission]);

  return (
      <>
          <Card variant="outlined">
              <CardHeader
                avatar={(
                    <Avatar className={classes.avatar}>
                        <AccountTreeIcon />
                    </Avatar>
                )}
                subheader={getSubheader(
                  t('layout.topBar.search.searchBar.query.settings.dialog.advanceSettings.title'),
                  getQueryTypeDescription(),
                )}
              />
              <Divider />
              <CardContent>
                  <MojitoSelect
                    id={t('layout.topBar.search.searchBar.query.settings.dialog.form.duration')}
                    value={getStoryDurationShallow()}
                    onFetchData={getStoryDurationOptions}
                    onGetOptionSelected={(option, value): boolean => option.id === value.id}
                    onGetOptionLabel={(option): string => option.name}
                    onChange={handleStoryDurationChange(formikProps.setFieldValue)}
                  />
                  {!formikProps.values.isLiveQuery && (
                      <FormControlLabel
                        className={classes.shouldPercolateCheckBox}
                        disabled={formikProps.values.isLiveQuery}
                        control={(
                            <Checkbox
                              checked={formikProps.values.shouldAutoPercolate}
                              onChange={handleShouldAutoPercolateChange}
                              name="shouldAutoPercolate"
                              color="primary"
                            />
                        )}
                        label={t('layout.topBar.search.searchBar.query.settings.dialog.form.autoPercolate')}
                      />
                  )}
              </CardContent>
          </Card>
      </>

  );
}

export default memo(AdvancedSettings);
