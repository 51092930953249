import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';
import { setDisplayErrorNotifications } from '../../../../constants/actions/creators/SettingsActions';
import { AppDispatch } from '../../../../redux/store';
import { ReduxState } from '../../../../redux/reducers';
import AntSwitch from '../../../../components/styled/AntSwitch';
import { DISPLAY_ERROR_BASED_NOTIFICATIONS } from '../../../../constants/LocalStorage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function ErrorBasedNotificationSwitch(): JSX.Element {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const displayErrorNotifications = useSelector((state: ReduxState) => state.settings.displayErrorNotifications);
  const { t } = useTranslation();
  const handleChange = (event): void => {
    if (event.target.checked) {
      dispatch(setDisplayErrorNotifications(true));
      localStorage.setItem(DISPLAY_ERROR_BASED_NOTIFICATIONS, 'true');
    } else {
      dispatch(setDisplayErrorNotifications(false));
      localStorage.setItem(DISPLAY_ERROR_BASED_NOTIFICATIONS, 'false');
    }
  };

  return (
      <FormGroup>
          <FormLabel component="legend">
              <Typography variant="overline">
                  {t('layout.topBar.search.searchBar.settings.notification')}
              </Typography>
          </FormLabel>
          <Typography className={classes.root} component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>{t('layout.topBar.search.searchBar.settings.notification.off')}</Grid>
                  <Grid item>
                      <AntSwitch
                        checked={displayErrorNotifications}
                        onChange={handleChange}
                        name="errorBasedNotifications"
                      />
                  </Grid>
                  <Grid item>{t('layout.topBar.search.searchBar.settings.notification.on')}</Grid>
              </Grid>
          </Typography>
      </FormGroup>
  );
}

export default ErrorBasedNotificationSwitch;
