interface PositionOption {
  id: string;
  name: string;
}

export const MONTAGE_POSITION_OPTIONS: Array<PositionOption> = [
  {
    id: 'center',
    name: 'Center',
  },
  {
    id: 'left',
    name: 'Left',
  },
  {
    id: 'right',
    name: 'Right',
  },
  {
    id: 'top_center',
    name: 'Top center',
  },
  {
    id: 'top_left',
    name: 'Top left',
  },
  {
    id: 'top_right',
    name: 'Top right',
  },
  {
    id: 'bottom_center',
    name: 'Bottom center',
  },
  {
    id: 'bottom_left',
    name: 'Bottom left',
  },
  {
    id: 'bottom_right',
    name: 'Bottom right',
  },
];

export const getDefaultPositionOption = (): PositionOption => MONTAGE_POSITION_OPTIONS[0];
