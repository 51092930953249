/* eslint-disable import/no-unresolved,@typescript-eslint/ban-ts-comment,import/no-webpack-loader-syntax */
// @ts-ignore
import worker from 'workerize-loader!../workers/NeoWorker.ts';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GetItemsResponse,
  GetQueryInfoRemoteParams,
  GetTagsRemoteParams,
  NOT_FOUND,
  QueryInfo,
  SPACE,
  useInfiniteScrolling,
} from 'mediascouting-core-ui-common';
import { NavigationSectionItem } from '../../../../types/layout/navBar';
import { getTags, REMOTE_USER_PARAM_TAG_TYPE } from '../../../../remote/Tag';
import { createTagNavBarItems } from '../../../../utils/common/NavBarUtils';
import { getQueryInfo } from '../../../../remote/Query';
import { AppDispatch } from '../../../../redux/store';
import { NeoSearchEntityTypeEnum } from '../types/NeoSearch';

interface ExposedUseNeoBarFetchersProperties {
  tagsLoading: boolean;
  queriesLoading: boolean;
  sharedQueriesLoading: boolean;
  hasNextQueries: boolean;
  hasNextTags: boolean;
  hasNextSharedQueries: boolean;
  sharedQueries: Array<NavigationSectionItem>;
  queries: Array<NavigationSectionItem>;
  tags: Array<NavigationSectionItem>;
  loadNextQueries: VoidFunction;
  loadNextTags: VoidFunction;
  loadNextSharedQueries: VoidFunction;
  handleQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleSharedTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleSharedQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  initQueries: (params?: object) => void;
  initTags: (params?: object) => void;
  initSharedQueries: (params?: object) => void;
}

interface InputTextSearchParams {
  name: string;
}

const getInputTextSearchParams = (
  text: string, types: Array<NeoSearchEntityTypeEnum>,
): InputTextSearchParams => {
  const shortcutTypes = types
    .filter((t) => t !== NeoSearchEntityTypeEnum.GENERAL)
    .map((t) => t.slice(0, 3).toUpperCase());
  const joinedShortcutTypes = shortcutTypes.join(SPACE);
  let nameToSearch = joinedShortcutTypes;

  if (joinedShortcutTypes.length > 0) {
    nameToSearch = nameToSearch.concat(` ${text}`);
  } else {
    nameToSearch = nameToSearch.concat(text);
  }

  return {
    name: nameToSearch,
  };
};

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 50;

const useNeoBarFetchers = (): ExposedUseNeoBarFetchersProperties => {
  const dispatch: AppDispatch = useDispatch();
  const [sharedQueryPage, setSharedQueryPage] = useState(DEFAULT_PAGE);
  const [queryPage, setQueryPage] = useState(DEFAULT_PAGE);
  const [tagPage, setTagPage] = useState(DEFAULT_PAGE);

  const getQueryPage = useCallback((): number => queryPage, [queryPage]);

  const getTagPage = useCallback((): number => tagPage, [tagPage]);

  const getSharedQueryPage = useCallback((): number => sharedQueryPage, [sharedQueryPage]);

  const fetchTags = useCallback((
    page: number, otherParams?: object,
  ): Promise<GetItemsResponse<NavigationSectionItem>> => {
    const params: GetTagsRemoteParams = {
      ...REMOTE_USER_PARAM_TAG_TYPE,
      page,
      ...otherParams,
    };

    return dispatch(getTags(params))
      .then((response) => {
        const tagNavItems = createTagNavBarItems(response.content);

        const updatedResponse: GetItemsResponse<NavigationSectionItem> = {
          ...response,
          content: tagNavItems,
        };

        return updatedResponse;
      });
  }, [dispatch]);

  const processQueries = (items: Array<QueryInfo>): Promise<Array<NavigationSectionItem>> => {
    const navWorker = worker();

    return navWorker.getNavBarItems(items)
      .finally(() => {
        navWorker.terminate();
      });
  };

  const getQueries = useCallback((
    page: number, otherParams?: object,
  ): Promise<GetItemsResponse<NavigationSectionItem>> => {
    const params: GetQueryInfoRemoteParams = {
      isAShare: false,
      size: DEFAULT_PAGE_SIZE,
      sort: 'name,asc',
      page,
      ...otherParams,
    };

    return dispatch(getQueryInfo(params))
      .then((response) => processQueries(response.content)
        .then((navBarItems) => {
          const updatedResponse: GetItemsResponse<NavigationSectionItem> = {
            ...response,
            content: navBarItems,
          };

          return updatedResponse;
        }));
  }, [dispatch]);

  const getSharedQueries = useCallback((
    page: number, otherParams?: object,
  ): Promise<GetItemsResponse<NavigationSectionItem>> => {
    const params: GetQueryInfoRemoteParams = {
      isAShare: true,
      size: DEFAULT_PAGE_SIZE,
      sort: 'name,asc',
      page,
      ...otherParams,
    };

    return dispatch(getQueryInfo(params))
      .then((response) => processQueries(response.content)
        .then((navBarItems) => {
          const updatedResponse: GetItemsResponse<NavigationSectionItem> = {
            ...response,
            content: navBarItems,
          };

          return updatedResponse;
        }));
  }, [dispatch]);

  const {
    loadNext: loadNextQueries,
    hasNext: hasNextQueries,
    initLoad: initQueries,
    loading: queriesLoading,
    items: queries,
  } = useInfiniteScrolling({
    getPage: getQueryPage,
    getItems: getQueries,
    setPage: setQueryPage,
  });

  const {
    loadNext: loadNextSharedQueries,
    hasNext: hasNextSharedQueries,
    initLoad: initSharedQueries,
    loading: sharedQueriesLoading,
    items: sharedQueries,
  } = useInfiniteScrolling({
    getPage: getSharedQueryPage,
    getItems: getSharedQueries,
    setPage: setSharedQueryPage,
  });

  const {
    loadNext: loadNextTags,
    hasNext: hasNextTags,
    initLoad: initTags,
    items: tags,
    loading: tagsLoading,
  } = useInfiniteScrolling({
    getPage: getTagPage,
    getItems: fetchTags,
    setPage: setTagPage,
  });

  const handleSharedQuerySearch = useCallback((
    input: string, types: Array<NeoSearchEntityTypeEnum>,
  ) => {
    initSharedQueries(getInputTextSearchParams(input, types));
  }, [initSharedQueries]);

  const handleTagSearch = useCallback((
    input: string, types: Array<NeoSearchEntityTypeEnum>,
  ) => {
    initTags(getInputTextSearchParams(input, types));
  }, [initTags]);

  const handleQuerySearch = useCallback((
    input: string, types: Array<NeoSearchEntityTypeEnum>,
  ) => {
    initQueries(getInputTextSearchParams(input, types));
  }, [initQueries]);

  const handleSharedTagSearch = useCallback((
    input: string, types: Array<NeoSearchEntityTypeEnum>,
  ) => NOT_FOUND, []);

  useEffect(() => {
    initQueries();
    initTags();
    initSharedQueries();
  }, [initQueries, initSharedQueries, initTags]);

  return {
    queriesLoading,
    tagsLoading,
    sharedQueriesLoading,
    queries,
    tags,
    sharedQueries,
    loadNextQueries,
    loadNextSharedQueries,
    loadNextTags,
    handleTagSearch,
    handleQuerySearch,
    handleSharedQuerySearch,
    handleSharedTagSearch,
    hasNextQueries,
    hasNextSharedQueries,
    hasNextTags,
    initQueries,
    initTags,
    initSharedQueries,
  };
};

export default useNeoBarFetchers;
