import React, { memo } from 'react';
import {
  Card, CardContent, CardHeader, Divider, List, makeStyles, Theme,
} from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { PlaylistItem } from '../../../../types/layout/topBar/Montage';
import PlaylistItemDetails from './PlaylistItemDetails';
import TranslationNameSpaceEnum from '../../../../types/translation/TranslationNameSpaceEnum';

export interface MontagePlaylistPropTypes {
  playlist: Array<PlaylistItem>;
  onRemovePlaylistItem?: (playlistItem: PlaylistItem) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function MontagePlaylist(props: MontagePlaylistPropTypes): JSX.Element {
  const { playlist, onRemovePlaylistItem } = props;
  const classes = useStyles();
  const { t } = useTranslation(TranslationNameSpaceEnum.MONTAGES);

  return (
      <Card variant="outlined">
          <CardHeader title={t('playlist.title') || EMPTY_STRING} />
          <Divider />
          <CardContent>
              <Droppable droppableId="playlist">
                  {(provided): JSX.Element => (
                      <List
                        className={classes.root}
                        classes={{ root: classes.listRoot }}
                        {...provided.droppableProps}
                        innerRef={provided.innerRef}
                      >
                          {playlist.map((playlistItem, index) => {
                            const uniqueIdentity = playlistItem.source.id + index;

                            return (
                                <React.Fragment key={uniqueIdentity}>
                                    <PlaylistItemDetails
                                      key={uniqueIdentity}
                                      index={index}
                                      playlistItem={playlistItem}
                                      onRemove={onRemovePlaylistItem}
                                    />
                                    <Divider key={`${uniqueIdentity}divider`} variant="inset" component="li" />
                                </React.Fragment>
                            );
                          })}
                          {playlist.length === 0
                            && <Typography>{t('playlist.empty')}</Typography>}
                          {provided.placeholder}
                      </List>
                  )}
              </Droppable>
          </CardContent>
      </Card>

  );
}

export default memo(MontagePlaylist);
