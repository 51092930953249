import React, { memo, MutableRefObject, useCallback } from 'react';
import { Button, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  EMPTY_STRING, GetItemsResponse, INIT_PAGINATION, PagedContent, Tag, NotFound,
} from 'mediascouting-core-ui-common';
import { Add } from '@material-ui/icons';
import { CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import LimePicker, { LimePickerFetchDataParams } from '../../../common/LimePicker';
import TranslationNameSpaceEnum from '../../../../types/translation/TranslationNameSpaceEnum';

type MainScreenPropTypes = {
    onChange: (event, value) => void;
    onCreate: () => void;
    onFetchTags: (
        params: LimePickerFetchDataParams,
        cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
    ) => Promise<PagedContent<Tag>>;
};

const useStyles = makeStyles(() => createStyles({
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonRootOverride: {
    minWidth: 0,
  },
}));

function MainScreen(props: MainScreenPropTypes): JSX.Element {
  const {
    onChange, onCreate, onFetchTags,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(TranslationNameSpaceEnum.NAV_BAR);

  const handleTagFetch = useCallback((
    params: LimePickerFetchDataParams,
    cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
  ): Promise<GetItemsResponse<Tag>> => {
    if (onFetchTags) {
      return onFetchTags(params, cancelToken);
    }

    return Promise.resolve<GetItemsResponse<Tag>>({
      content: [],
      ...INIT_PAGINATION,
    });
  }, [onFetchTags]);

  const handleCreate = (e): void => {
    e.stopPropagation();

    return onCreate();
  };

  const getLabel = (option): string => option.name;

  const getDescription = (option): string => option.description;

  const getSelected = (option, value): boolean => option.id === value.id;

  const getColor = (option): string => {
    if (option.attributes) {
      return option.attributes?.color || EMPTY_STRING;
    }

    return EMPTY_STRING;
  };

  return (
      <>
          <div className={classes.header}>
              <Typography variant="overline" color="inherit">
                  {t('categorySubHeader.tags.main.screen.edit')}
              </Typography>
              <Button
                onClick={handleCreate}
                classes={{ root: classes.buttonRootOverride }}
                startIcon={<Add />}
                size="small"
                color="primary"
              >
                  {t('categorySubHeader.tags.main.screen.add')}
              </Button>
          </div>
          <Paper>
              <LimePicker
                onGetLabel={getLabel}
                onGetDescription={getDescription}
                onGetSelected={getSelected}
                onGetColor={getColor}
                onChange={onChange}
                onFetchData={handleTagFetch}
                noOptionsText={t('categorySubHeader.tags.main.screen.notFound')}
                inputProps={{
                  placeholder: t('categorySubHeader.tags.main.screen.search'),
                }}
              />
          </Paper>
      </>
  );
}

export default memo(MainScreen);
