import { SortFieldDTO, SortOrderDTO } from 'mediascouting-core-ui-common';

export enum SortTypeEnum {
  NEWEST = 'Newest',

  OLDEST = 'Oldest',

  RELEVANCE = 'Relevance',

  MARKET = 'Market',

  MARKET_RANK = 'Market Rank',

  SOURCE = 'Source',

  TEXT_TYPE = 'Text Type',
}

export type SortOption = {
  id: SortTypeEnum;
  title: string;
  value: string;
  sortField: SortFieldDTO;
  sortOrder: SortOrderDTO;
};
