import { OptionsObject, VariantType } from 'notistack';

const getDefaultSnackbarOptions = (variant: VariantType): OptionsObject => ({
  variant,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
});

export default getDefaultSnackbarOptions;
