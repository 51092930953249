import React, {
  memo, MutableRefObject, useCallback, useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import {
  NOT_FOUND, PagedContent, Tag, useIsMountedRef, NotFound,
} from 'mediascouting-core-ui-common';
import { ClickAwayListener } from '@material-ui/core';
import { CancelTokenSource } from 'axios';
import EditingScreen from './EditingScreen';
import MainScreen from './MainScreen';

type TagEditorPropTypes = {
    anchorEl: Element | null;
    onClose: () => void;
    onDelete: (id: number) => Promise<void>;
    onUpdate: (id: number, tag: Partial<Tag>) => Promise<Tag>;
    onCreate: (tag: Partial<Tag>) => Promise<Tag>;
    onFetchTags: (
        params: object, cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
    ) => Promise<PagedContent<Tag>>;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
}));

function TagEditor(props: TagEditorPropTypes): JSX.Element {
  const classes = useStyles();
  const {
    anchorEl, onDelete, onUpdate, onCreate, onClose, onFetchTags,
  } = props;
  const isMounted = useIsMountedRef();
  const [editorScreen, setEditorScreen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag>();
  const open = Boolean(anchorEl);

  const handleFetchTags = useCallback((
    params: object, cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
  ): Promise<PagedContent<Tag>> => onFetchTags(params, cancelToken)
    .then((response) => response), [onFetchTags]);

  const reset = useCallback((): void => {
    if (isMounted.current) {
      setEditorScreen(false);
      setSelectedTag(NOT_FOUND);
    }
  }, [isMounted]);

  const handleTagDelete = useCallback((id: number): Promise<void> => onDelete(id)
    .then(() => reset())
    .catch((error) => Promise.reject(error)), [onDelete, reset]);

  const handleTagUpdate = useCallback((id: number, tag: Partial<Tag>): Promise<Tag> => onUpdate(id, tag)
    .then((updatedTag: Tag) => {
      reset();

      return updatedTag;
    })
    .catch((error) => Promise.reject(error)), [onUpdate, reset]);

  const handleTagCreate = useCallback((tag: Partial<Tag>): Promise<Tag> => onCreate(tag)
    .then((createdTag) => {
      reset();

      return createdTag;
    })
    .catch((error) => Promise.reject(error)), [onCreate, reset]);

  const handleTagChange = useCallback((event, updatedSelectedTags: Array<Tag>): void => {
    if (updatedSelectedTags.length > 0) {
      setEditorScreen(true);
      setSelectedTag(updatedSelectedTags[0]);
    }
  }, []);

  const handleCreateNewTag = useCallback((): void => {
    setEditorScreen(true);
  }, []);

  const handleCancel = useCallback((): void => {
    setEditorScreen(false);
    setSelectedTag(NOT_FOUND);
  }, []);

  const handleClose = (): void => {
    onClose();
    setSelectedTag(NOT_FOUND);
    setEditorScreen(false);
  };

  const renderScreen = (): JSX.Element => {
    if (editorScreen) {
      return (
          <EditingScreen
            tag={selectedTag}
            onDelete={handleTagDelete}
            onCreate={handleTagCreate}
            onUpdate={handleTagUpdate}
            onCancel={handleCancel}
          />
      );
    }

    return (
        <MainScreen
          onFetchTags={handleFetchTags}
          onChange={handleTagChange}
          onCreate={handleCreateNewTag}
        />
    );
  };

  return (
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.popper}>
          <ClickAwayListener onClickAway={handleClose}>
              <div>
                  {renderScreen()}
              </div>
          </ClickAwayListener>
      </Popper>
  );
}

export default memo(TagEditor);
