import { ExportItem, NOT_FOUND, NotificationTypeEnum } from 'mediascouting-core-ui-common';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import useDataUpdateProbes from './useDataUpdateProbes';
import { getDataExportTasks } from '../remote/Export';
import { getExportPending } from '../utils/story/StoryExportUtils';

import { HeartbeatDataChange, HeartbeatDataType } from '../redux/reducers/Heartbeat';
import { DEFAULT_EXPORT_ITEM } from '../components/features/export/Export/constants';

const NOTIFICATION_TITLE = 'Export Center';
const NOTIFICATION_DESCRIPTION = 'Exports have been updated';
function useExportPollingWrapper(): void {
  const history = useHistory();

  const matches = (prevExports: Array<ExportItem>, incomingExports: Array<ExportItem>): boolean => {
    if (incomingExports.length > prevExports.length) {
      return false;
    } if (incomingExports.length === prevExports.length) {
      const booleans = prevExports.map((prevExport, index) => prevExport.status === incomingExports[index].status);
      return booleans.every((matchedStatus) => matchedStatus);
    }
    return true;
  };

  const onClickNotification = (): void => {
    if (history) {
      history.push({
        pathname: '/portal/exports',
      });
    }
  };

  const generatedId = useMemo(() => new Date().getTime(), []);

  useDataUpdateProbes<ExportItem>({
    fetchData: getDataExportTasks,
    getPending: getExportPending,
    matches,
    millisecondInterval: 60_000,
    notification: {
      id: `${generatedId}`,
      title: NOTIFICATION_TITLE,
      description: NOTIFICATION_DESCRIPTION,
      type: NotificationTypeEnum.INFO,
      read: false,
      date: new Date(),
      onClick: onClickNotification,
    },
    heartbeat: {
      type: HeartbeatDataType.EXPORT,
      cause: HeartbeatDataChange.CREATE,
      identification: {
        id: generatedId,
      },
    },
    defaultObjectOnCreate: DEFAULT_EXPORT_ITEM,
  });

  return NOT_FOUND;
}

export default useExportPollingWrapper;
