import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core/styles';

type AdvancedFilterDecoratorPropTypes = {
  children?: Array<React.ReactNode>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'rgba(var(--sk_highlight_accent,29,155,209),.1)',
    color: 'rgba(var(--sk_highlight,18,100,163),1)',
    fontSize: '0.8125rem',
    textTransform: 'uppercase',
    padding: '0 2px 1px 2px',
    borderRadius: '4px',
  },
}));

function FilterOptionDecorator(props: AdvancedFilterDecoratorPropTypes): JSX.Element {
  const { children } = props;
  const classes = useStyles();

  return (
      <Typography component="span" className={clsx(classes.root)}>
          {children}
      </Typography>
  );
}

export default memo(FilterOptionDecorator);
