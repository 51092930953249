enum UrlParams {
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    START_TIME_OF_DAY = 'startTimeOfDay',
    END_TIME_OF_DAY = 'endTimeOfDay',
    TITLE = 'title',
    TAGS = 'tags',
    LANGUAGES = 'languages',
    SOURCES = 'sources',
    SOURCE_GROUPS = 'sourceGroups',
    GEOLOCATION_TAGS = 'geolocationTags',
    MARKETS = 'markets',
    MARKET_GROUPS = 'marketGroups',
    SENTIMENTS = 'sentiments',
    DAYS = 'days',
    TIME_SECTIONS = 'timeSections',
    SUB_QUERIES = 'subQueries',
    PEOPLE = 'people',
    ORGANIZATIONS = 'organizations',
    SOURCE_TYPES = 'sourceTypes',
    FACETS ='f',
    PAGE='page',
    QUERY_ID = 'qId',
    TEXT = 't',
    STORY_DURATION = 'storyDuration',
    SORT = 'sort',
    PREVIEW_NEW_ITEMS = 'previewNewItems',
    ORIGIN = 'textTypes',
    AUTHORS = 'authors',
    PROGRAMS = 'programs',
    DATE_SLUG = 'dateSlug',
}

export default UrlParams;
