import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

type AdvancedFilterDecoratorPropTypes = {
  children?: Array<React.ReactNode>;
};

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.8125rem',
    padding: '0 2px 1px 2px',
    textTransform: 'uppercase',
    borderRadius: '4px',
  },
}));

function FilterDecorator(props: AdvancedFilterDecoratorPropTypes): JSX.Element {
  const { children } = props;
  const classes = useStyles();

  return (
      <Typography component="span" className={clsx(classes.root)}>
          {children}
      </Typography>
  );
}

export default memo(FilterDecorator);
