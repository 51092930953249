import {
  Describable,
  EMPTY_STRING,
  Identifiable, NotFound,
  ShallowPowerSearchQueryFilters,
  Tag,
} from 'mediascouting-core-ui-common';
import { NavigationSectionItem, NeoBarItemType } from '../../types/layout/navBar';
import { NeoSearchEntityTypeEnum } from '../../components/common/NeoBar/types/NeoSearch';
import {
  NEO_SEARCH_ENTITY_TYPES_WITHOUT_GENERIC_QUERY_TAG_SHARED_ARRAY,
} from '../../components/common/NeoBar/components/NeoSearch/consts/NeoSearchEntityTypes';

const getTypeBasedOnInitialLetters = (text: string): NeoSearchEntityTypeEnum => {
  const foundType = NEO_SEARCH_ENTITY_TYPES_WITHOUT_GENERIC_QUERY_TAG_SHARED_ARRAY
    .find((entity) => {
      const firstThreeChars = entity.slice(0, 3)?.toUpperCase();

      return text.includes(firstThreeChars);
    });

  if (foundType) {
    return foundType;
  }

  return NeoSearchEntityTypeEnum.GENERAL;
};

export const constructQueryNavBarItemDto = (
  type: NeoBarItemType,
  id: number,
  name: string,
  text: string,
  activeFilters: ShallowPowerSearchQueryFilters,
  subQueries: Array<Subquery>,
  isShared: boolean,
  isLiveQuery: boolean,
  desiredBackgroundColor: string | NotFound,
): NavigationSectionItem => ({
  type,
  id,
  name,
  text,
  filters: activeFilters,
  subQueries: subQueries || [],
  shared: isShared || false,
  live: isLiveQuery || false,
  entityType: getTypeBasedOnInitialLetters(name),
  desiredBackgroundColor,
});

export interface Subquery extends Identifiable, Describable {
  isLiveQuery?: boolean;
}

export const constructSubquery = (
  id: number,
  name?: string,
  description?: string,
  isLiveQuery?: boolean,
): Subquery => ({
  id,
  name: name || EMPTY_STRING,
  description: description || EMPTY_STRING,
  isLiveQuery: isLiveQuery || false,
});

export const createTagNavBarItems = (tags: Array<Tag>): Array<NavigationSectionItem> => tags.map((tag) => {
  const tagIdentity: Identifiable = {
    id: tag.id,
  };

  const activeFilters: ShallowPowerSearchQueryFilters = {
    Tags: [tagIdentity],
  };

  return constructQueryNavBarItemDto(
    NeoBarItemType.TAG_TYPE,
    tag.id,
    tag.name,
    EMPTY_STRING,
    activeFilters,
    [],
    false,
    false,
    tag.attributes.color,
  );
});
