import { Identifiable, ShallowPowerSearchQueryFilters } from 'mediascouting-core-ui-common';
import { Subquery } from '../../../utils/common/NavBarUtils';
import { NeoSearchEntityTypeEnum } from '../../../components/common/NeoBar/types/NeoSearch';

export enum NeoBarItemType {
 'QUERY_TYPE' = 'Query',
 'TAG_TYPE' = 'Tag',
 'UNKNOWN' = 'Unknown',
}

export interface NavigationSectionItemBooleans {
    shared: boolean;
    live: boolean;
}

export interface NavigationSectionItem extends Identifiable, NavigationSectionItemBooleans {
    name: string;
    text: string;
    entityType: NeoSearchEntityTypeEnum;
    type: NeoBarItemType;
    filters: ShallowPowerSearchQueryFilters;
    subQueries: Array<Subquery>;
    desiredBackgroundColor?: string;
}

export interface NeoBarSectionMessages {
    noItemsAvailableI18Key: string;
}

export interface NeoBarSectionHandlers {
    onSearch?: (value: string, types: Array<NeoSearchEntityTypeEnum>) => void;
    onNextPage?: VoidFunction;
    onPreviousPage?: VoidFunction;
    onToggleCollapsed?: VoidFunction;
}

export interface NavigationBarSection {
    subheaderTextI18Key: string;
    priority: number;
    loading: boolean;
    hidden: boolean;
    collapsed: boolean;
    items: Array<NavigationSectionItem>;
    type: NeoBarItemType;
    messages: NeoBarSectionMessages;
    handlers: NeoBarSectionHandlers;
}
