import React from 'react';
import { EMPTY_STRING, parseFrontEndExactTextUnit, TILDA } from 'mediascouting-core-ui-common';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createMatcherAdditionRequest, createSuggestion } from '../SuggestionServiceUtils';
import {
  AdditionRequest,
  EntityRange,
  SubstituteRequest,
} from '../../../../../../../types/layout/topBar/search/editor';
import ExactTextMatch from '../../../QueryEditor/ExactTextMatch';
import ConditionMatcher from '../../../QueryEditor/ConditionMatcher';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';

const createConditionMatcherPreview = (text, matcher): JSX.Element => (
    <span>
        <ExactTextMatch>{text}</ExactTextMatch>
        {' '}
        <ConditionMatcher>{matcher}</ConditionMatcher>
    </span>
);

const textIncludesTilda = (text): boolean => text.includes(TILDA);

const createSimpleTextSuggestion = (
  entityRange: EntityRange, suggestions: Array<Suggestion<EntityTypesEnum>>,
): Array<Suggestion<EntityTypesEnum>> => {
  if (!textIncludesTilda(entityRange.text)) {
    const preview = entityRange.text.replace(/"/g, EMPTY_STRING);
    const request: SubstituteRequest<EntityTypesEnum> = {
      substituteText: preview,
      reference: entityRange,
      cursorPositionWithinText: preview.length,
      entityMetadata: [
        {
          type: EntityTypesEnum.SIMPLE_INPUT,
          start: 0,
          end: preview.length,
        },
      ],
    };

    const simpleTextSuggestion = createSuggestion(preview, request);

    return Array.of(...suggestions, simpleTextSuggestion);
  }

  return suggestions;
};

const createTildaSuggestion = (
  entityRange: EntityRange, suggestions: Array<Suggestion<EntityTypesEnum>>,
): Array<Suggestion<EntityTypesEnum>> => {
  if (!textIncludesTilda(entityRange.text)) {
    const formattedText = `${entityRange.text} ${TILDA}`;
    const preview = (<ExactTextMatch>{formattedText}</ExactTextMatch>);
    const request: SubstituteRequest<EntityTypesEnum> = {
      substituteText: formattedText,
      reference: entityRange,
      cursorPositionWithinText: formattedText.length,
      entityMetadata: [
        {
          type: EntityTypesEnum.EXACT_TEXT_MATCH,
          start: 0,
          end: formattedText.length,
        },
      ],
    };

    const tildaSuggestion = createSuggestion(preview, request);

    return Array.of(...suggestions, tildaSuggestion);
  }

  return suggestions;
};

const createORSuggestion = (
  entityRange: EntityRange,
  suggestions: Array<Suggestion<EntityTypesEnum>>,
): Array<Suggestion<EntityTypesEnum>> => {
  const preview = createConditionMatcherPreview(entityRange.text, EntityTypesEnum.OR_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(EntityTypesEnum.OR_CONDITION_MATCHER, entityRange);

  const orSuggestion = createSuggestion(preview, request);

  return Array.of(...suggestions, orSuggestion);
};

const createANDSuggestion = (
  entityRange: EntityRange,
  suggestions: Array<Suggestion<EntityTypesEnum>>,
): Array<Suggestion<EntityTypesEnum>> => {
  const preview = createConditionMatcherPreview(entityRange.text, EntityTypesEnum.AND_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(EntityTypesEnum.AND_CONDITION_MATCHER, entityRange);

  const andSuggestion = createSuggestion(preview, request);

  return Array.of(...suggestions, andSuggestion);
};

const createExactTextMatchSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  let suggestions: Array<Suggestion<EntityTypesEnum>> = [];
  let parsingError = false;

  const onParsingError = (): void => {
    parsingError = true;
  };

  parseFrontEndExactTextUnit(entityRange.text, onParsingError);

  if (!parsingError) {
    suggestions = createANDSuggestion(entityRange, suggestions);
    suggestions = createORSuggestion(entityRange, suggestions);
    suggestions = createTildaSuggestion(entityRange, suggestions);
    suggestions = createSimpleTextSuggestion(entityRange, suggestions);
  }

  return suggestions;
};

export default createExactTextMatchSuggestions;
