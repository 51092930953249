import {
  Box, Divider, ListItem, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface EmptySectionPropTypes {
    emptyText: string;
}

const useStyles = makeStyles(() => createStyles({
  empty: {
    display: 'block',
    paddingTop: 8,
    paddingBottom: 0,
    padding: '10px 8px',
    marginLeft: '24px',
  },
}));

function EmptySection(props: EmptySectionPropTypes): JSX.Element {
  const { emptyText } = props;
  const classes = useStyles();

  return (
      <>
          <ListItem className={clsx(classes.empty)} disableGutters dense>
              <Box px={1}>
                  <Typography variant="h5" color="textSecondary">
                      {emptyText}
                  </Typography>
              </Box>
          </ListItem>
          <Box pt={2}>
              <Divider />
          </Box>
      </>
  );
}

export default EmptySection;
