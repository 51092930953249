import {
  Author,
  CacheType,
  GetItemsResponse,
  Group,
  Market,
  NOT_FOUND,
  NotFound,
  PagedContent,
  PowerSearchFilterTypes,
  Query,
  RemoteDataRetrievers,
  RemoteSearchRetrievers,
  Source,
  SupportedCacheDataType,
  Tag,
} from 'mediascouting-core-ui-common';
import { MutableRefObject } from 'react';
import { CancelTokenSource } from 'axios';
import { getTag, getTags } from '../../remote/Tag';
import getSources, { getSource } from '../../remote/Source';
import getMarkets, { getMarket } from '../../remote/Market';
import { getQueries, getQuery } from '../../remote/Query';
import { getMarketGroup, getMarketGroups } from '../../remote/MarketGroup';
import { getSourceGroup, getSourceGroups } from '../../remote/SourceGroup';
import getGroups, { getGroup } from '../../remote/Group';
import { getAuthor, getAuthors } from '../../remote/Author';

const reviseSourceWithNameAndType = (source: Source): Source => ({
  ...source,
  name: `${source.name} | ${source?.type?.name}`,
});

export const getSourcesWrapper = (
  params: object,
  cancelToken?: MutableRefObject<CancelTokenSource | NotFound>,
) => (dispatch): Promise<PagedContent<Source>> => dispatch(getSources(params, cancelToken?.current?.token))
  .then((response: PagedContent<Source>) => {
    const revisedSourceTitleContent = response?.content?.map((source) => reviseSourceWithNameAndType(source));

    return {
      ...response,
      content: revisedSourceTitleContent,
    };
  });

const getSourceWrapper = (id: number) => (dispatch): Promise<Source> => dispatch(getSource(id))
  .then((response: Source) => reviseSourceWithNameAndType(response));

export const createDataRemoteRetrievers = (
  onCache: (type: CacheType, data: SupportedCacheDataType) => void,
) => (dispatch): RemoteDataRetrievers => ({
  [PowerSearchFilterTypes.TAGS]: (id: number): Promise<Tag | NotFound> => dispatch(getTag(id))
    .then((tag) => {
      onCache(PowerSearchFilterTypes.TAGS, tag);

      return tag;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.SOURCES]: (id: number): Promise<Source | NotFound> => dispatch(getSourceWrapper(id))
    .then((source) => {
      onCache(PowerSearchFilterTypes.SOURCES, source);

      return source;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.SOURCE_GROUPS]: (id: number): Promise<Source | NotFound> => dispatch(getSourceGroup(id))
    .then((sourceGroup) => {
      onCache(PowerSearchFilterTypes.SOURCE_GROUPS, sourceGroup);

      return sourceGroup;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.OWNER_GROUPS]: (id: number): Promise<Group | NotFound> => dispatch(getGroup(id))
    .then((sourceGroup) => {
      onCache(PowerSearchFilterTypes.OWNER_GROUPS, sourceGroup);

      return sourceGroup;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.AUTHORS]: (id: number): Promise<Author | NotFound> => dispatch(getAuthor(id))
    .then((author) => {
      onCache(PowerSearchFilterTypes.AUTHORS, author);

      return author;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.MARKETS]: (id: number): Promise<Market | NotFound> => dispatch(getMarket(id))
    .then((market) => {
      onCache(PowerSearchFilterTypes.MARKETS, market);

      return market;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.MARKET_GROUPS]: (id: number): Promise<Market | NotFound> => dispatch(getMarketGroup(id))
    .then((marketGroup) => {
      onCache(PowerSearchFilterTypes.MARKET_GROUPS, marketGroup);

      return marketGroup;
    })
    .catch(() => NOT_FOUND),
  [PowerSearchFilterTypes.SUBQUERIES]: (id: number): Promise<Query | NotFound> => dispatch(getQuery(id))
    .then((subquery) => {
      onCache(PowerSearchFilterTypes.SUBQUERIES, subquery);

      return subquery;
    })
    .catch(() => NOT_FOUND),
});

export const createSearchRemoteRetrievers = (
  cancelToken?: MutableRefObject<CancelTokenSource | NotFound>,
) => (dispatch): RemoteSearchRetrievers => ({
  [PowerSearchFilterTypes.TAGS]: (
    params?: object,
  ): Promise<GetItemsResponse<Tag>> => dispatch(getTags(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.SOURCES]: (
    params?: object,
  ): Promise<GetItemsResponse<Source>> => dispatch(getSourcesWrapper(params || {}, cancelToken)),
  [PowerSearchFilterTypes.SOURCE_GROUPS]: (
    params?: object,
  ): Promise<GetItemsResponse<Source>> => dispatch(getSourceGroups(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.OWNER_GROUPS]: (
    params?: object,
  ): Promise<GetItemsResponse<Group>> => dispatch(getGroups(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.AUTHORS]: (
    params?: object,
  ): Promise<GetItemsResponse<Author>> => dispatch(getAuthors(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.MARKETS]: (
    params?: object,
  ): Promise<GetItemsResponse<Market>> => dispatch(getMarkets(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.MARKET_GROUPS]: (
    params?: object,
  ): Promise<GetItemsResponse<Market>> => dispatch(getMarketGroups(params, cancelToken?.current?.token)),
  [PowerSearchFilterTypes.SUBQUERIES]: (
    params?: object,
  ): Promise<GetItemsResponse<Query>> => dispatch(getQueries(params, cancelToken?.current?.token)),
});
