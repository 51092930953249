export const INVALID_VALUE = 'invalid_value';
export const PARSING_ERROR = 'parsing_error';

const STYLE_MAP = {
  [INVALID_VALUE]: {
    color: 'darkgray',
  },
  [PARSING_ERROR]: {
    backgroundColor: '#e614146b',
  },
};

export default STYLE_MAP;
