import React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import RadioIcon from '@material-ui/icons/Radio';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import { StoryTypeEnum } from 'mediascouting-core-ui-common';

const createStoryTypeIconMatchers = (): object => ({
  [StoryTypeEnum.PRINT_ITEM]: <ImportContactsIcon />,
  [StoryTypeEnum.WEB_ITEM]: <PublicIcon />,
  [StoryTypeEnum.FIVE_MINUTE_BROADCAST_STORY_ITEM]: <LiveTvIcon />,
  [StoryTypeEnum.TEN_MINUTE_BROADCAST_STORY_ITEM]: <LiveTvIcon />,
  [StoryTypeEnum.TWENTY_MINUTE_BROADCAST_STORY_ITEM]: <LiveTvIcon />,
  [StoryTypeEnum.FIVE_MINUTE_RADIO_STORY_ITEM]: <RadioIcon />,
  [StoryTypeEnum.TEN_MINUTE_RADIO_STORY_ITEM]: <RadioIcon />,
  [StoryTypeEnum.TWENTY_MINUTE_RADIO_STORY_ITEM]: <RadioIcon />,
});

const getSourceTypeIcon = (itemSourceType: string): JSX.Element => {
  const sourceTypeIconMatchers = createStoryTypeIconMatchers();
  const icon = sourceTypeIconMatchers[itemSourceType];

  if (icon) {
    return icon;
  }

  return <BrokenImageOutlinedIcon />;
};

export default getSourceTypeIcon;
