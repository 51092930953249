import { HeartbeatMonitor } from '../../../redux/reducers/Heartbeat';
import { CLEAR_ACTION, NOTIFY_ACTION } from '../types/HeatbeatActionTypes';

export const beat = (payload: HeartbeatMonitor) => ({
  type: NOTIFY_ACTION,
  payload,
} as const);

export const clearAction = () => ({
  type: CLEAR_ACTION,
} as const);

export type HeartbeatActions =
    | ReturnType<typeof beat>
    | ReturnType<typeof clearAction>
