import React, { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function EmptyScreen(): JSX.Element {
  const { t } = useTranslation();
  return (
      <Box p={2}>
          <Typography variant="h6" color="textPrimary">
              {t('layout.topBar.search.searchBar.notifications.empty')}
          </Typography>
      </Box>
  );
}

export default memo(EmptyScreen);
