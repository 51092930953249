import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

type ExactTextMatchPropTypes = {
    children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  typography: {
    fontFamily: 'Helvetica',
    fontStyle: 'italic',
  },
}));

function ExactTextMatch(props: ExactTextMatchPropTypes): JSX.Element {
  const classes = useStyles();
  const { children } = props;

  return (
      <Typography className={classes.typography} component="span">
          {children}
      </Typography>
  );
}

export default memo(ExactTextMatch);
