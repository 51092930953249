export enum ViewTypeEnum {
    GRID_VIEW_TYPE = 'GRID_VIEW',
    FULL_VIEW_TYPE = 'FULL_VIEW',
    LIST_VIEW_TYPE = 'LIST_VIEW',
    SPLIT_VIEW_TYPE = 'SPLIT_VIEW',
    TIMELINE_VIEW_TYPE = 'TIMELINE_VIEW'
}

export type ViewTypeOption = {
    id: string;
    title: string;
    value: ViewTypeEnum;
}
