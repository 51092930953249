import React, { memo } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { useTranslation } from 'react-i18next';
import { SettingFormFields } from './index';

type IdentityPropTypes = {
    formikProps: FormikProps<SettingFormFields>;
};

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

function QueryIdentity(props: IdentityPropTypes): JSX.Element {
  const {
    formikProps,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getSubheader = (title: string, description: string): JSX.Element => (
      <Box>
          <div>
              <Typography variant="body2" color="textPrimary">
                  {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                  {description}
              </Typography>
          </div>
      </Box>
  );

  return (
      <Card variant="outlined">
          <CardHeader
            avatar={(
                <Avatar className={classes.avatar}>
                    <FingerprintIcon />
                </Avatar>
            )}
            subheader={getSubheader(
              t('layout.topBar.search.searchBar.query.settings.dialog.queryIdentity.title'),
              t('layout.topBar.search.searchBar.query.settings.dialog.queryIdentity.description'),
            )}
          />
          <Divider />
          <CardContent>
              <TextField
                fullWidth
                value={formikProps.values.name}
                label={t('layout.topBar.search.searchBar.query.settings.dialog.form.name')}
                placeholder={t('layout.topBar.search.searchBar.query.settings.dialog.form.name')}
                helperText={formikProps.touched.name && formikProps.errors.name}
                error={Boolean(formikProps.touched.name && formikProps.errors.name)}
                onChange={formikProps.handleChange}
                margin="normal"
                name="name"
                variant="outlined"
              />
              <TextField
                fullWidth
                value={formikProps.values.description}
                label={t('layout.topBar.search.searchBar.query.settings.dialog.form.description')}
                placeholder={t('layout.topBar.search.searchBar.query.settings.dialog.form.description')}
                helperText={formikProps.touched.description && formikProps.errors.description}
                error={Boolean(formikProps.touched.description && formikProps.errors.description)}
                onChange={formikProps.handleChange}
                margin="normal"
                name="description"
                variant="outlined"
              />
          </CardContent>
      </Card>

  );
}

export default memo(QueryIdentity);
