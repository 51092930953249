import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  getGroupTransformationUtilities,
  GetMyGroupResponse,
  getUserTransformationUtilities,
  LoginResponse,
  onFeedback,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  UpdateUserResponse,
  User,
  UserDTO,
  UserUpdateDTO,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

import {
  sessionLoginAction,
  sessionLogoutAction,
  setUserGroup,
  userDataAction,
} from '../constants/actions/creators/AuthActions';
import authService from '../services/authService';
import getPowerSearchOptions from './PowerSearch';
import getApplicationProperties from './ApplicationProperties';

export const getMyGroup = (
  cancelToken?: CancelToken,
) => (dispatch): Promise<unknown> => axios.get<GetMyGroupResponse>('auth/portal/my-group', {
  cancelToken,
})
  .then(({ data }) => {
    const group = getGroupTransformationUtilities().transform().fromDto(data?.group || {});

    dispatch(setUserGroup(group));

    return data;
  })
  .catch((error) => Promise.reject(error));

export const getUser = (
  cancelToken?: CancelToken,
) => (dispatch): Promise<User> => {
  let outterUser;
  return axios.get<UserDTO>('auth/portal/me', {
    cancelToken,
  })
    .then(({ data }) => {
      const user = getUserTransformationUtilities().transform().fromDto(data);
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
        .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
        .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_USER]}`)
        .setNotifyUser(false)
        .build()));
      outterUser = user;
      return user;
    })
    .then(() => getPowerSearchOptions()(dispatch))
    .then(() => getApplicationProperties()(dispatch))
    .then(() => getMyGroup()(dispatch))
    .then(() => {
      if (outterUser) {
        return outterUser;
      }
      return Promise.reject();
    })
    .catch((error) => {
      if (!initialAxiosInstance.isCancel(error)) {
        dispatch(onFeedback(buildFeedbackMessage()
          .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
          .setSystemMessage(error)
          .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_USER]}`)
          .setNotifyUser(true)
          .build()));
      }

      return Promise.reject(error);
    })
    .finally(() => {
      if (outterUser) {
        dispatch(userDataAction(outterUser));
      }
    });
};

export const updateUser = (
  userDataPayload: Partial<UserUpdateDTO>,
) => (dispatch): Promise<User> => axios.put<UpdateUserResponse>('auth/portal/me', userDataPayload)
  .then(({ data }) => {
    const user = getUserTransformationUtilities().transform().fromDto(data);
    dispatch(userDataAction(user));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.UPDATE_USER]}`)
      .setNotifyUser(true)
      .build()));
    return user;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.UPDATE_USER]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const login = (
  username: string,
  password: string,
) => (dispatch): Promise<void> => axios.post<LoginResponse>('auth/portal/login', { username, password })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.LOGIN]}`)
      .setNotifyUser(false)
      .build()));
    dispatch(sessionLoginAction({ token: data.access_token }));

    authService.setSession(data.access_token);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.LOGIN]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const logoutAfterExpiredAccess = () => (dispatch): void => {
  authService.logout();
  dispatch(sessionLogoutAction());
};

export const logout = () => (dispatch): Promise<void> => axios.post<void>('auth/portal/logout')
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.LOGOUT]}`)
      .setNotifyUser(false)
      .build()));
    return data;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.LOGOUT]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  })
  .finally(() => {
    authService.logout();
    dispatch(sessionLogoutAction());
  });
