import {
  AnyAction, applyMiddleware, CombinedState, compose, createStore, Store, StoreEnhancer,
} from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer, { ReduxState } from '../reducers';

const DEVELOPMENT = 'development';

const getMiddleware = (): StoreEnhancer => {
  if (process?.env?.NODE_ENV === DEVELOPMENT) {
    return composeWithDevTools(applyMiddleware(thunkMiddleware));
  }

  return compose(applyMiddleware(thunkMiddleware));
};

export default function configureStore(preloadedState = {}):
    Store<CombinedState<ReduxState>> {
  const middlewareEnhancer = getMiddleware();

  const enhancers = [middlewareEnhancer];
  const composedEnhancers: StoreEnhancer = compose(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}

export type AppDispatch = ThunkDispatch<ReduxState, StoreEnhancer, AnyAction>;
