import {
  AlertItemsDisplayMode,
  AlertItemsGrouping,
  CreateDataExportTaskRequest,
  DataExportTaskStatusEnum,
  EMPTY_STRING,
  encodeFilters,
  ExportItem,
  getOptionalBoolean,
  NOT_FOUND,
  PowerSearchFilterTypes,
  ReportResourceTypeDTO,
  ShallowResourceDefinitionDTO,
} from 'mediascouting-core-ui-common';
import { QuerySearchSpaceReducer } from '../../redux/reducers/QuerySearchSpace';
import { SelectionState } from '../../types/query/Selector';
import { createPowerSearchFiltersFromQueryReducer } from '../common/PowerSearchUtils';
import {
  encodeCurationFacets,
  getActiveFacets,
  getFacetsByExcludingFacetType,
  getFacetsWithDateSlug,
} from '../query/FacetUtils';
import { getDefaultDateSlug } from '../../constants/DateSlugOptions';
import { StorySnapSelectionRangeEnum } from '../../constants/StorySnapToolbarSelectionOptions';

export const getExportSuccess = (exportItem: ExportItem): boolean => exportItem.status === DataExportTaskStatusEnum.SENT
    || exportItem.status === DataExportTaskStatusEnum.SENT_WITH_EMAIL_FAILURE;

export const getExportPending = (
  exportItem: ExportItem,
): boolean => exportItem.status === DataExportTaskStatusEnum.EXPORTING
    || exportItem.status === DataExportTaskStatusEnum.SENDING
    || exportItem.status === DataExportTaskStatusEnum.REQUESTED;

export const getExportFailed = (
  exportItem: ExportItem,
): boolean => exportItem.status === DataExportTaskStatusEnum.SEND_FAILED
    || exportItem.status === DataExportTaskStatusEnum.EXPORT_FAILED
    || exportItem.status === DataExportTaskStatusEnum.PUBLISH_FAILED;

const getResourceDefinitions = (exportItem?: ExportItem): Array<ShallowResourceDefinitionDTO> => {
  const resourceDefinitions: Array<ShallowResourceDefinitionDTO> = [];

  if (exportItem?.emailTemplate && exportItem?.emailTemplate?.id !== -1) {
    resourceDefinitions.push({
      type: ReportResourceTypeDTO.EMAIL,
      template: {
        id: exportItem?.emailTemplate?.id,
      },
    });
  }

  if (exportItem?.exportTemplate && exportItem?.exportTemplate?.id !== -1) {
    resourceDefinitions.push({
      type: exportItem.type,
      template: {
        id: exportItem?.exportTemplate?.id,
      },
    });
  }

  if (exportItem?.type && exportItem.exportTemplate?.id === -1) {
    resourceDefinitions.push({
      type: exportItem.type,
    });
  }

  return resourceDefinitions;
};

const getSelectedFacets = (
  querySearchSpace?: QuerySearchSpaceReducer,
  selectionState?: SelectionState,
): string => {
  if (selectionState?.range === StorySnapSelectionRangeEnum.QUERY) {
    const facetsWithDateSlug = getFacetsWithDateSlug(
        querySearchSpace?.dateSlug || getDefaultDateSlug(),
        querySearchSpace?.facets || [],
    );
    return getActiveFacets(getFacetsByExcludingFacetType(facetsWithDateSlug, 'curation'));
  }
  return EMPTY_STRING;
};

const createDefaultExportRequest = (
  exportItem?: ExportItem, querySearchSpace?: QuerySearchSpaceReducer, selectionState?: SelectionState,
): CreateDataExportTaskRequest => {
  const queryId = querySearchSpace?.query?.id !== NOT_FOUND ? querySearchSpace.query.id : null;
  const convertedFilters = querySearchSpace ? createPowerSearchFiltersFromQueryReducer(querySearchSpace) : NOT_FOUND;

  const selectedFacets = getSelectedFacets(querySearchSpace, selectionState);
  const encodedFilters = convertedFilters ? encodeFilters(convertedFilters) : EMPTY_STRING;

  return {
    previewEnabled: getOptionalBoolean(querySearchSpace?.previewNewItems),
    includeEntireSearch: selectionState?.range === StorySnapSelectionRangeEnum.QUERY,
    selectedQueryFilters: encodedFilters,
    facetsApplied: selectedFacets,
    curationFacets: querySearchSpace ? encodeCurationFacets(querySearchSpace.facets) : EMPTY_STRING,
    freeQueryText: querySearchSpace?.searchText || null,
    percolatables: selectionState?.selectedStoriesBetweenPages.map((shallowStory) => ({
      id: shallowStory.id,
    })) || exportItem?.percolatables || [],
    recipientEmails: exportItem?.recipientEmails || [],
    storyDurationFilterId: querySearchSpace ? querySearchSpace[PowerSearchFilterTypes.STORY_DURATION]?.id || 1 : 1,
    download: true,
    queryId,
    resources: getResourceDefinitions(exportItem),
    emailConfiguration: exportItem?.emailConfiguration,
    emailSubject: exportItem?.emailSubject,
    reportBuildingSettings: {
      addPrintMargins: getOptionalBoolean(exportItem?.addPrintMargins),
      includeFrontPage: getOptionalBoolean(exportItem?.includeFrontPage),
      linkItemFiles: getOptionalBoolean(exportItem?.linkItemFiles),
      itemsGrouping: exportItem?.itemsGrouping || AlertItemsGrouping.QUERY,
      itemsDisplayMode: exportItem?.itemsDisplayMode || AlertItemsDisplayMode.SUMMARY,
      attachResourcesToEmail: getOptionalBoolean(exportItem?.attachResourcesToEmail),
      produceExternalLinks: getOptionalBoolean(exportItem?.produceExternalLinks),
      embedItemsToEmail: getOptionalBoolean(exportItem?.embedItemsToEmail),
      removeLiveQueryGroupIfPossible: getOptionalBoolean(exportItem?.removeLiveQueryGroup),
      renderTimezone: exportItem?.renderTimezone || null,
    },
    summary: exportItem?.summary || null,
  };
};

export default createDefaultExportRequest;
