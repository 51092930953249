import {
  Cache,
  CacheType,
  NOT_FOUND,
  NotFound,
  PowerSearchFilterTypes,
  QueryFilterOptionShallow, SupportedCacheDataType,
  SupportedRemoteDataRetrieverType,
} from 'mediascouting-core-ui-common';

const handleDefaultFinder = (optionalOptions: Array<object>, id: number): QueryFilterOptionShallow | NotFound => {
  const options = [...optionalOptions] as Array<QueryFilterOptionShallow>;

  return options.find((searchableOption) => searchableOption.id === id);
};

const createCacheRetrievers = (optionalOptions: Array<object>, id: number): object => ({
  [PowerSearchFilterTypes.SUBQUERIES]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.TAGS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.GEOLOCATION_TAGS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.PEOPLE]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.ORGANIZATIONS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.SOURCES]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.MARKETS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.DAYS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.TIME_SECTIONS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.SOURCE_TYPES]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.LANGUAGES]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.SENTIMENTS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.AUTHORS]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
  [PowerSearchFilterTypes.ORIGIN]: (): SupportedCacheDataType | NotFound => handleDefaultFinder(
    optionalOptions, id,
  ),
});

const retrieveFromCache = (type: CacheType, id: number, cache: Cache): SupportedRemoteDataRetrieverType | NotFound => {
  const optionalOptions = cache[type];

  if (optionalOptions) {
    const finders = createCacheRetrievers(optionalOptions, id);
    const matchedStorageFinder = finders[type];

    if (matchedStorageFinder) {
      return matchedStorageFinder();
    }
  }

  return NOT_FOUND;
};

export default retrieveFromCache;
