import {
  AlertItemsDisplayMode,
  AlertItemsGrouping,
  PageableBatchTransformationMappers,
  DataExportTaskDTO,
  DataExportTaskStatusEnum,
  EMPTY_STRING,
  ExportItem,
  getPagedResponseContent,
  NOT_FOUND,
  NotFound,
  PagedContent,
  PagedResponse,
  PageableTransformationMappers,
  PageableTransformationUtility,
  ReportResourceDTO,
  ReportResourceTypeDTO,
  ShallowAlertTemplate,
  getFormattedDate,
} from 'mediascouting-core-ui-common';

interface ExportTransformationUtilities extends PageableTransformationUtility
    <DataExportTaskDTO, DataExportTaskDTO, ExportItem> {

}

const getReportTemplate = (
  resourceDefinitions: Array<ReportResourceDTO>,
): ShallowAlertTemplate | NotFound => {
  const found = resourceDefinitions.find((r) => r.definition.type !== ReportResourceTypeDTO.EMAIL);

  if (found) {
    return found?.definition.template;
  }

  return NOT_FOUND;
};

const getEmailTemplate = (
  resourceDefinitions: Array<ReportResourceDTO>,
): ShallowAlertTemplate | NotFound => {
  const found = resourceDefinitions.find((r) => r.definition.type === ReportResourceTypeDTO.EMAIL);

  if (found) {
    return found?.definition.template;
  }

  return NOT_FOUND;
};

const getReportFormat = (
  resourceDefinitions: Array<ReportResourceDTO>,
): ReportResourceTypeDTO => {
  const found = resourceDefinitions.find((r) => r.definition.type !== ReportResourceTypeDTO.EMAIL);

  if (found) {
    return found.definition.type;
  }

  return ReportResourceTypeDTO.PDF;
};

const fromDto = (dto: Partial<DataExportTaskDTO>): ExportItem => ({
  id: dto?.id || -1,
  name: `${getFormattedDate(dto?.createdAt)}`,
  description: dto?.dataExportTaskStatus || DataExportTaskStatusEnum.EXPORT_FAILED,
  emailTemplate: getEmailTemplate(dto?.resources || []),
  exportTemplate: getReportTemplate(dto?.resources || []),
  itemsDisplayMode: dto?.reportBuildingSettings?.itemsDisplayMode || AlertItemsDisplayMode.SUMMARY,
  recipientEmails: dto?.recipientEmails || [],
  produceExternalLinks: dto?.reportBuildingSettings?.produceExternalLinks || false,
  embedItemsToEmail: dto?.reportBuildingSettings?.embedItemsToEmail || false,
  removeLiveQueryGroup: dto?.reportBuildingSettings?.removeLiveQueryGroupIfPossible || false,
  attachResourcesToEmail: dto?.reportBuildingSettings?.attachResourcesToEmail || false,
  includeFrontPage: dto?.reportBuildingSettings?.includeFrontPage || false,
  linkItemFiles: dto?.reportBuildingSettings?.linkItemFiles || false,
  addPrintMargins: dto?.reportBuildingSettings?.addPrintMargins || false,
  renderTimezone: dto?.reportBuildingSettings?.renderTimezone || null,
  percolatables: dto?.percolatables || [],
  itemsGrouping: dto?.reportBuildingSettings?.itemsGrouping || AlertItemsGrouping.QUERY,
  status: dto?.dataExportTaskStatus || DataExportTaskStatusEnum.EXPORT_FAILED,
  type: getReportFormat(dto?.resources || []),
  createdAt: new Date(dto?.createdAt || EMPTY_STRING),
  resources: dto?.resources || [],
  emailConfiguration: dto?.emailConfiguration,
  emailSubject: dto?.emailSubject || EMPTY_STRING,
  summary: dto?.summary || EMPTY_STRING,
});

const toDto = (_model: ExportItem): Partial<DataExportTaskDTO> => ({});

const allFromDto = (
  response: PagedResponse<DataExportTaskDTO>,
): PagedContent<ExportItem> => getPagedResponseContent<DataExportTaskDTO, ExportItem>(response, fromDto);

const allToDto = (models: Array<ExportItem>): Array<Partial<DataExportTaskDTO>> => models.map((model) => toDto(model));

const getExportTransformationUtilities = (): ExportTransformationUtilities => ({
  transform(): PageableTransformationMappers<DataExportTaskDTO, DataExportTaskDTO, ExportItem> {
    return {
      fromDto(dto: Partial<DataExportTaskDTO>): ExportItem {
        return fromDto(dto);
      },
      toDto(model: ExportItem): Partial<DataExportTaskDTO> {
        return toDto(model);
      },
      all(): PageableBatchTransformationMappers<DataExportTaskDTO, DataExportTaskDTO, ExportItem> {
        return {
          fromDto: (response: PagedResponse<DataExportTaskDTO>): PagedContent<ExportItem> => allFromDto(response),
          toDto: (models: Array<ExportItem>): Array<Partial<DataExportTaskDTO>> => allToDto(models),
        };
      },
    };
  },
});

export default getExportTransformationUtilities;
