import {
  Box, Collapse, ListSubheader, makeStyles, Theme,
} from '@material-ui/core';
import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { EditorRequest } from '../../../../../../../types/layout/topBar/search/editor';
import { NeoSearchEntityTypeEnum } from '../../../../types/NeoSearch';
import { createKey } from '../../utils/NeoSearchSuggestionServiceUtils';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';

interface NeoSearchFiltertingSuggestionsPropTypes {
  suggestions: Array<Suggestion<NeoSearchEntityTypeEnum>>;
  onEditorRequest: (request: EditorRequest<NeoSearchEntityTypeEnum>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    justifyContent: 'space-evenly',
  },
  previewWrapper: {
    textTransform: 'uppercase',
    cursor: 'hand',
    padding: theme.spacing(1),
  },
}));

function NeoSearchFilteringSuggestions(props: NeoSearchFiltertingSuggestionsPropTypes): JSX.Element {
  const {
    suggestions, onEditorRequest,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);

  const handleOnClick = (suggestion) => (): void => {
    onEditorRequest(suggestion.request);
  };

  return (
      <Collapse in={suggestions.length > 0}>
          <ListSubheader disableSticky component="div" id="filtering-suggestions">
              {t('neoBar.neoSearch.filtering.suggestions.explore')}
          </ListSubheader>
          <Box className={classes.root}>
              {suggestions.map((suggestion) => (
                  <Box
                    key={createKey(suggestion.request)}
                    className={clsx(classes.previewWrapper)}
                    onClick={handleOnClick(suggestion)}
                  >
                      {suggestion.preview}
                  </Box>
              ))}
          </Box>
      </Collapse>
  );
}

export default memo(NeoSearchFilteringSuggestions);
