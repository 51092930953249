/* eslint-disable no-restricted-syntax,no-await-in-loop,no-plusplus */
import {
  COMMA,
  EMPTY_STRING,
  extractSingleValuedFilterOption, NOT_FOUND,
  QueryFilterOptionShallow, replaceAt,
} from 'mediascouting-core-ui-common';
import { ContentState, EditorState, SelectionState } from 'draft-js';
import { ParsedSegment } from '../../../../../../../../types/layout/topBar/search/editor';
import { getContentBlockSelection } from '../../../../../../../../utils/draft-js/SelectionUtils';
import {
  DATE_FILTERS,
  EntityTypesEnum,
  FRONT_TO_BACK_FILTER_MAPPER,
  MULTIPLE_VALUE_FILTERS,
  SEARCHABLE_CONVERSION_FILTERS, TIME_OF_DAY_FILTERS,
} from '../../../../../../../../constants/search/editor/EntityTypes';
import PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER from '../../../../../../../../constants/search/editor/Mappers';
import { findEntityRanges } from '../../../../../../../../utils/draft-js/EntityUtils';

const isFilterOption = (entityKey: string, contentState: ContentState): boolean => {
  if (entityKey) {
    const entityInstance = contentState.getEntity(entityKey);

    return entityInstance.getType() === EntityTypesEnum.FILTER_OPTION;
  }

  return false;
};

export const decodeAndReplaceTimeHourFilterOptionWithMillisecondsIntoDay = (
  editorState: EditorState, entitySelection: SelectionState, filterType: EntityTypesEnum,
): string => {
  const contentState = editorState.getCurrentContent();
  const blockKey = entitySelection.getAnchorKey();
  const startOffset = entitySelection.getStartOffset();
  const endOffset = entitySelection.getEndOffset();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const entityText = contentBlock.getText().slice(startOffset, endOffset).toLocaleLowerCase();
  const extractedOption = extractSingleValuedFilterOption(
    entityText, `${filterType.toLocaleLowerCase()}:`,
  );
  let index = `${filterType}:`.length;

  const trimmedEdgesOption = extractedOption.trimStart().trimEnd();

  index = entityText.indexOf(trimmedEdgesOption, index);

  if (index !== -1) {
    const optionStartsAt = startOffset + index;
    const optionEntityKey = contentBlock.getEntityAt(optionStartsAt);

    if (optionEntityKey && isFilterOption(optionEntityKey, contentState)) {
      const entityInstance = contentState.getEntity(optionEntityKey);
      const { option } = entityInstance.getData();
      const milliseconds = option.id;

      if (option as QueryFilterOptionShallow) {
        return `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: (${milliseconds})`;
      }
    }
  }

  return `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: ()`;
};

export const decodeAndReplaceDateFilterOptionWithUnixTimestamp = (
  editorState: EditorState, entitySelection: SelectionState, filterType: EntityTypesEnum,
): string => {
  const contentState = editorState.getCurrentContent();
  const blockKey = entitySelection.getAnchorKey();
  const startOffset = entitySelection.getStartOffset();
  const endOffset = entitySelection.getEndOffset();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const entityText = contentBlock.getText().slice(startOffset, endOffset).toLocaleLowerCase();
  const extractedOption = extractSingleValuedFilterOption(
    entityText, `${filterType.toLocaleLowerCase()}:`,
  );
  let index = `${filterType}:`.length;

  const trimmedEdgesOption = extractedOption.trimStart().trimEnd();

  index = entityText.indexOf(trimmedEdgesOption, index);

  if (index !== -1) {
    const optionStartsAt = startOffset + index;
    const optionEntityKey = contentBlock.getEntityAt(optionStartsAt);

    if (optionEntityKey && isFilterOption(optionEntityKey, contentState)) {
      const entityInstance = contentState.getEntity(optionEntityKey);
      const { option } = entityInstance.getData();

      if (option as QueryFilterOptionShallow) {
        return `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: (${option.id})`;
      }
    }
  }

  return `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: ()`;
};

export const decodeAndReplaceFilterOptionsWithIds = (
  editorState: EditorState,
  entitySelection: SelectionState,
  filterType: EntityTypesEnum,
): string => {
  const contentState = editorState.getCurrentContent();
  const blockKey = entitySelection.getAnchorKey();
  const startOffset = entitySelection.getStartOffset();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const ids: Array<number> = [];
  const entityRanges = findEntityRanges(editorState, [EntityTypesEnum.FILTER_OPTION]);
  const optionEntityKey = contentBlock.getEntityAt(startOffset);

  if (optionEntityKey) {
    const entityInstance = contentState.getEntity(optionEntityKey);
    const entityRef = entityInstance.getData()?.reference;
    const foundRelated = entityRanges.filter((
      entityRange,
    ) => entityRange?.entity?.getData()?.parentReference === entityRef);

    foundRelated.forEach((found) => {
      const optionId = found.entity.getData()?.option?.id;

      if (optionId !== NOT_FOUND) {
        ids.push(optionId);
      }
    });
  }

  return `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: (${ids.join(COMMA)})`;
};

const handleFilterConversion = (
  editorState: EditorState, filterType: EntityTypesEnum, start: number, end: number, plainText: string,
): string => {
  const contentState = editorState.getCurrentContent();
  const entitySelection = getContentBlockSelection(contentState, start, end);

  if (entitySelection) {
    const startOffset = entitySelection.getStartOffset();
    const blockKey = entitySelection.getAnchorKey();
    const contentBlock = contentState.getBlockForKey(blockKey);
    const parentEntityKey = contentBlock.getEntityAt(startOffset);

    if (parentEntityKey && MULTIPLE_VALUE_FILTERS.includes(filterType)) {
      const filterAsStringWithIds = decodeAndReplaceFilterOptionsWithIds(
        editorState, entitySelection, filterType,
      );

      return replaceAt(plainText, filterAsStringWithIds, start, end);
    }

    if (parentEntityKey && DATE_FILTERS.includes(filterType)) {
      const filterAsString = decodeAndReplaceDateFilterOptionWithUnixTimestamp(
        editorState, entitySelection, filterType,
      );

      return replaceAt(plainText, filterAsString, start, end);
    }

    if (parentEntityKey && TIME_OF_DAY_FILTERS.includes(filterType)) {
      const filterAsString = decodeAndReplaceTimeHourFilterOptionWithMillisecondsIntoDay(
        editorState, entitySelection, filterType,
      );

      return replaceAt(plainText, filterAsString, start, end);
    }

    return replaceAt(plainText, `${FRONT_TO_BACK_FILTER_MAPPER[filterType]}: ()`, start, end);
  }

  return plainText;
};

const encodeFilters = (editorState: EditorState, parsedSegments: Array<ParsedSegment>): string => {
  try {
    let plainText = editorState.getCurrentContent().getPlainText();

    parsedSegments.reverse().forEach((segment) => {
      const { type, start, end } = segment;
      const mappedType = PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER[type] as EntityTypesEnum;

      if (end && SEARCHABLE_CONVERSION_FILTERS.includes(mappedType)) {
        plainText = handleFilterConversion(editorState, mappedType, start, end + 1, plainText);
      }
    });

    return plainText;
  } catch (e) {
    return EMPTY_STRING;
  }
};

export default encodeFilters;
