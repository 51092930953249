import React, {
  Suspense, useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import { NOT_FOUND, NotFound, PowerSearchSynchronousQueryFilters } from 'mediascouting-core-ui-common';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import AdvancedSettings from './AdvancedSettings';
import QueryIdentity from './QueryIdentity';
import QueryEntitySettings from './QueryTypeSettings';
import { NeoSearchEntityTypeEnum } from '../../../../../../../components/common/NeoBar/types/NeoSearch';
import {
  OrganizationTypeConfigurationFormFields,
} from '../../../../../../../components/features/queryTypes/OrganizationTypeConfiguration/FormFields';
import {
  GeolocationTypeConfigurationFormFields,
} from '../../../../../../../components/features/queryTypes/GeolocationTypeConfiguration/FormFields';
import {
  PersonTypeConfigurationFormFields,
} from '../../../../../../../components/features/queryTypes/PersonTypeConfiguration/FormFields';
import {
  TopicTypeConfigurationFormFields,
} from '../../../../../../../components/features/queryTypes/TopicTypeConfiguration/FormFields';
import PLATFORM_CONFIGURATION from '../../../../../../../configuration';
import createNeoSearchEntityTypeMetadataFormMapper from './utils/MetadataFormUtils';

export type SettingFormFields = {
    name: string;
    description: string;
    isLiveQuery: boolean;
    storyDurationId: number;
    shouldAutoPercolate: boolean;
};

type SettingsDialogPropTypes = {
    open: boolean;
    initialValues: SettingFormFields;
    synchronousQueryFilterOptions: PowerSearchSynchronousQueryFilters;
    onSubmit: (values: SettingFormFields, formikProps: FormikHelpers<SettingFormFields>) => void;
    onClose: () => void;
};

const useStyles = makeStyles(() => ({
  content: {},
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface MainQueryDetailFormikValuesAndHelpers {
  values: SettingFormFields;
  helpers: FormikHelpers<SettingFormFields>;
}

function SettingsDialog(props: SettingsDialogPropTypes): JSX.Element {
  const {
    initialValues, synchronousQueryFilterOptions, open, onClose, onSubmit,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedNeoSearchEntity, setSelectedNeoSearchEntity] = useState<NeoSearchEntityTypeEnum | NotFound>();
  const [nextScreen, setNextScreen] = useState(false);
  const mainQueryDetailFormikValuesAndHelpers = useRef<MainQueryDetailFormikValuesAndHelpers | NotFound>(NOT_FOUND);

  const initialFormValues: SettingFormFields = useMemo(() => ({
    name: initialValues.name,
    description: initialValues.description,
    isLiveQuery: initialValues.isLiveQuery,
    storyDurationId: initialValues.storyDurationId,
    shouldAutoPercolate: initialValues.shouldAutoPercolate,
  }), [initialValues]);

  const validationSchema = useMemo(() => Yup.object().shape({
    name: Yup
      .string()
      .required(t('form.fields.required',
        { field: t('layout.topBar.search.searchBar.query.settings.dialog.form.name') })),
    storyDurationId: Yup
      .number()
      .required(t('form.fields.required',
        { field: t('layout.topBar.search.searchBar.query.settings.dialog.form.duration') })),
  }), [t]);

  const handleSubmit = (values: SettingFormFields, formikHelpers: FormikHelpers<SettingFormFields>): Promise<void> => {
    mainQueryDetailFormikValuesAndHelpers.current = {
      values,
      helpers: formikHelpers,
    };

    if (selectedNeoSearchEntity && selectedNeoSearchEntity !== NeoSearchEntityTypeEnum.GENERAL) {
      setNextScreen(true);
    } else {
      onSubmit(values, formikHelpers);
    }

    return Promise.resolve(NOT_FOUND);
  };

  const handleBack = (): void => {
    setNextScreen(false);
  };

  const handleMetadataSet = (type: NeoSearchEntityTypeEnum): void => {
    setSelectedNeoSearchEntity(type);
  };

  const handleTopicMetadataSubmit = useCallback((
    topicMetadata: TopicTypeConfigurationFormFields,
  ): Promise<void> => {
    const mainDetails = mainQueryDetailFormikValuesAndHelpers.current;

    if (mainDetails) {
      onSubmit(mainDetails?.values, mainDetails?.helpers);
    }

    return Promise.resolve(NOT_FOUND);
  }, [onSubmit]);

  const handlePersonMetadataSubmit = useCallback((
    personMetadata: PersonTypeConfigurationFormFields,
  ): Promise<void> => {
    const mainDetails = mainQueryDetailFormikValuesAndHelpers.current;

    if (mainDetails) {
      onSubmit(mainDetails?.values, mainDetails?.helpers);
    }

    return Promise.resolve(NOT_FOUND);
  }, [onSubmit]);

  const handleGeolocationMetadataSubmit = useCallback((
    geolocationMetadata: GeolocationTypeConfigurationFormFields,
  ): Promise<void> => {
    const mainDetails = mainQueryDetailFormikValuesAndHelpers.current;

    if (mainDetails) {
      onSubmit(mainDetails?.values, mainDetails?.helpers);
    }

    return Promise.resolve(NOT_FOUND);
  }, [onSubmit]);

  const handleOrganizationMetadataSubmit = useCallback((
    organizationMetadata: OrganizationTypeConfigurationFormFields,
  ): Promise<void> => {
    const mainDetails = mainQueryDetailFormikValuesAndHelpers.current;

    if (mainDetails) {
      onSubmit(mainDetails?.values, mainDetails?.helpers);
    }

    return Promise.resolve(NOT_FOUND);
  }, [onSubmit]);

  const NEO_SEARCH_ENTITY_TYPE_METADATA_FORM_MAPPER = useMemo(() => createNeoSearchEntityTypeMetadataFormMapper({
    topicSubmit: handleTopicMetadataSubmit,
    personSubmit: handlePersonMetadataSubmit,
    geolocationSubmit: handleGeolocationMetadataSubmit,
    organizationSubmit: handleOrganizationMetadataSubmit,
  }, {
    onBack: handleBack,
    onClose,
  }), [handleGeolocationMetadataSubmit, handleOrganizationMetadataSubmit,
    handlePersonMetadataSubmit, handleTopicMetadataSubmit, onClose]);

  const getMetadataScreen = (): React.ReactNode => {
    const foundConfiguration = NEO_SEARCH_ENTITY_TYPE_METADATA_FORM_MAPPER[
      selectedNeoSearchEntity || NeoSearchEntityTypeEnum.GENERAL
    ];

    if (foundConfiguration) {
      return (
          <Suspense fallback={<Skeleton variant="rect" height="100%" width="100%" />}>
              {foundConfiguration}
          </Suspense>
      );
    }

    return <></>;
  };

  const getDialogButtonOk = (): string => {
    if (selectedNeoSearchEntity && selectedNeoSearchEntity !== NeoSearchEntityTypeEnum.GENERAL) {
      return t('layout.topBar.search.searchBar.query.settings.dialog.next');
    }

    return t('layout.topBar.search.searchBar.query.settings.dialog.confirm');
  };

  const getScreen = (): React.ReactNode => {
    if (!nextScreen) {
      return (
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
              {(formikProps): JSX.Element => (
                  <form onSubmit={formikProps.handleSubmit}>
                      <DialogTitle>
                          {t('layout.topBar.search.searchBar.query.settings.dialog.title')}
                      </DialogTitle>
                      <DialogContent dividers>
                          <FormGroup className={classes.content}>
                              <QueryIdentity
                                formikProps={formikProps}
                              />
                              <Box paddingTop={3}>
                                  <AdvancedSettings
                                    formikProps={formikProps}
                                    synchronousQueryFilterOptions={synchronousQueryFilterOptions}
                                  />
                              </Box>
                              {(PLATFORM_CONFIGURATION.schema.neoNavigationSideBar.savingOptions.createAllowed
                                  || PLATFORM_CONFIGURATION.schema.neoNavigationSideBar.savingOptions.updateAllowed)
                                && (
                                    <Box paddingTop={3}>
                                        <QueryEntitySettings
                                          onSetMetadata={handleMetadataSet}
                                        />
                                    </Box>
                                )}
                          </FormGroup>
                      </DialogContent>
                      <DialogActions>
                          <Button
                            color="primary"
                            autoFocus
                            onClick={onClose}
                            disabled={formikProps.isSubmitting}
                          >
                              {t('layout.topBar.search.searchBar.query.settings.dialog.cancel')}
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={formikProps.isSubmitting}
                          >
                              {getDialogButtonOk()}
                          </Button>
                      </DialogActions>
                  </form>
              )}
          </Formik>
      );
    }

    return getMetadataScreen();
  };

  return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={onMobile}
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth="sm"
        scroll="paper"
      >
          <Suspense fallback={<Skeleton variant="rect" height="100%" width="100%" />}>
              {getScreen()}
          </Suspense>
      </Dialog>
  );
}

export default SettingsDialog;
