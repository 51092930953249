import React, { memo, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar, Box, Fade, Hidden, IconButton, makeStyles, Theme, Toolbar, Tooltip,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING, NotFound } from 'mediascouting-core-ui-common';
import Account from './Account';
import Notifications from './Notifications';
import Contacts from './Contacts';
import Settings from './Settings';
import Montage from './Montage';
import Search from './Search';
import Cart from './Cart';
import Logo from '../../../components/common/Logo';
import PLATFORM_CONFIGURATION from '../../../configuration';
import { QuickSettingsConfiguration } from './Settings/ViewSettings';

type TopBarPropTypes = {
    className?: string;
    onNavOpen: () => void;
    onNavClose: () => void;
    navOpen: boolean;
    logo: string | NotFound;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100%',
  },
  white: {
    color: 'white',
  },
  hide: {
    display: 'none',
  },
  logo: {
    minWidth: '185px',
  },
}));

function TopBar(props: TopBarPropTypes): JSX.Element {
  const {
    className, onNavOpen, onNavClose, navOpen, logo,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const disabledViews = PLATFORM_CONFIGURATION.schema.stories.views.disabled;

  const getConfiguration = useCallback((): QuickSettingsConfiguration => ({
    disabledViews,
  }), [disabledViews]);

  return (
      <AppBar
        position="fixed"
        className={clsx(classes.root, className)}
      >
          <Toolbar>
              <Hidden lgUp>
                  <IconButton color="inherit" onClick={onNavOpen}>
                      <MenuRoundedIcon />
                  </IconButton>
              </Hidden>
              <Hidden mdDown>
                  <RouterLink to="/portal/search" className={classes.logo}>
                      <Logo logo={logo} />
                  </RouterLink>
              </Hidden>
              <Hidden mdDown>
                  <Box ml={5} />
              </Hidden>
              <Hidden mdDown>
                  <Fade in={navOpen} unmountOnExit mountOnEnter>
                      <Tooltip title={t('layout.topBar.collapse.menu.tooltip') || EMPTY_STRING}>
                          <IconButton
                            onClick={onNavClose}
                            className={clsx(classes.white, { [classes.hide]: !navOpen })}
                          >
                              <MenuOpenRoundedIcon />
                          </IconButton>
                      </Tooltip>
                  </Fade>
                  <Fade in={!navOpen} unmountOnExit mountOnEnter>
                      <Tooltip title={t('layout.topBar.expand.menu.tooltip') || EMPTY_STRING}>
                          <IconButton
                            onClick={onNavOpen}
                            className={clsx(classes.white, { [classes.hide]: navOpen })}
                          >
                              <MenuIcon />
                          </IconButton>
                      </Tooltip>
                  </Fade>
                  <Box width="100%">
                      <Search />
                  </Box>
              </Hidden>
              <Box ml={2} flexGrow={1} />
              <Contacts />
              <Notifications />
              <Montage />
              <Cart />
              <Settings configuration={getConfiguration()} />
              <Box ml={2}>
                  <Account />
              </Box>
          </Toolbar>
      </AppBar>
  );
}

export default memo(TopBar);
