import { useCallback, useEffect, useRef } from 'react';
import {
  addNotification,
  NOT_FOUND,
  Notification,
  PagedContent,
  useCancelPolicy,
  useInitialCall,
  usePoller,
} from 'mediascouting-core-ui-common';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import { AppDispatch } from '../redux/store';
import { ReduxState } from '../redux/reducers';

import { HeartbeatMonitor } from '../redux/reducers/Heartbeat';
import { clearAction } from '../constants/actions/creators/HeartbeatActions';
import getHeartbeatEquality from '../utils/common/HeartbeatUtils';

interface UseDataUpdateProbesPropTypes<T>{
    millisecondInterval: number;
    fetchData: (params?: object, cancelToken?: CancelToken) => (dispatch: any) => Promise<PagedContent<T>>;
    getPending: (item: T) => boolean;
    matches: (prevItems: Array<T>, incomingItems: Array<T>) => boolean;
    heartbeat?: HeartbeatMonitor;
    notification: Notification;
    defaultObjectOnCreate: T;
}

function useDataUpdateProbes<T>(props: UseDataUpdateProbesPropTypes<T>): void {
  const {
    millisecondInterval, fetchData, getPending, matches, heartbeat, notification, defaultObjectOnCreate,
  } = props;
  const dispatch: AppDispatch = useDispatch();
  const { startPolling, stopPolling, isPolling } = usePoller();
  const heartbeatReducer = useSelector((reduxState: ReduxState) => reduxState.heartbeat);
  const dataRef = useRef<Array<T>>([]);
  const { cancel, cancelToken, cancelablePromise } = useCancelPolicy<PagedContent<T>>();

  const sendNotification = useCallback((): void => {
    if (notification) {
      dispatch(addNotification(notification));
    }
  }, [dispatch, notification]);

  const hasPendingItems = useCallback((incomingExports: Array<T>): boolean => {
    const booleans = incomingExports.map((incomingExport) => getPending(incomingExport));
    return booleans.some((matchedStatus) => matchedStatus);
  }, [getPending]);

  const compareItems = useCallback((
    prevItems: Array<T>, incomingItems: Array<T>,
  ): Array<T> => {
    const matchesPrevious = matches(prevItems, incomingItems);
    const pendingExports = hasPendingItems(incomingItems);

    if (!matchesPrevious && prevItems.length > 0) {
      sendNotification();
    }

    if (!pendingExports) {
      stopPolling();
    }

    return incomingItems;
  }, [hasPendingItems, matches, sendNotification, stopPolling]);

  const getData = useCallback((): Promise<PagedContent<T>> => cancelablePromise(
    () => dispatch(fetchData(cancelToken?.current?.token)),
  )
    .then((response) => {
      dataRef.current = compareItems(dataRef.current, response.content);
      const pendingItems = response.content.filter((item) => getPending(item));
      if (pendingItems.length === 0) {
        stopPolling();
      }
      return response;
    })
    .catch((error) => {
      stopPolling();

      return Promise.reject(error);
    }), [cancelToken, cancelablePromise, compareItems, dispatch, fetchData, getPending, stopPolling]);

  const getInitialData = useCallback((): Promise<PagedContent<T>> => cancelablePromise(
    () => dispatch(fetchData(cancelToken?.current?.token)),
  )
    .then((response) => {
      dataRef.current = compareItems(dataRef.current, response.content);

      return response;
    })
    .catch((error) => {
      stopPolling();

      return Promise.reject(error);
    }), [cancelToken, cancelablePromise, compareItems, dispatch, fetchData, stopPolling]);

  const listenToHeartbeatUpdate = useCallback(() => {
    if (heartbeat) {
      if (getHeartbeatEquality(heartbeatReducer, heartbeat)) {
        dataRef.current.push(defaultObjectOnCreate);
        if (!isPolling) {
          startPolling(getData, millisecondInterval);
        }
        dispatch(clearAction());
      }
    }
  }, [defaultObjectOnCreate, dispatch, getData, heartbeat,
    heartbeatReducer, isPolling, millisecondInterval, startPolling]);

  const handleInit = useCallback((): void => {
    getInitialData()
      .then((response) => {
        const pendingItems = response.content.filter((item) => getPending(item));
        if (pendingItems.length > 0) {
          startPolling(getData, millisecondInterval);
        }
        if (pendingItems.length === 0) {
          stopPolling();
        }
      });
  }, [getData, getInitialData, getPending, millisecondInterval, startPolling, stopPolling]);

  useInitialCall({
    callback: () => {
      handleInit();
    },
  });

  useEffect(() => {
    listenToHeartbeatUpdate();
  }, [listenToHeartbeatUpdate]);

  useEffect(() => (): void => {
    cancel();
  }, [cancel]);

  return NOT_FOUND;
}

export default useDataUpdateProbes;
