import { NotFound, SchemaDisabled } from 'mediascouting-core-ui-common';
import { NeoBarDisplayFormatEnum, NeoSearchEntityTypeEnum } from './NeoSearch';
import { ENTER_KEY_CODE, TAB_KEY_CODE } from '../../../../constants/KeyPropertyValues';

export enum TypingSearchSuggestionModeEnum {
    POP_UP = 'POP_UP',
    LIST = 'LIST',
}

export enum NeoBarShortcutKeyCodeEnum {
    ENTER = ENTER_KEY_CODE,
    TAB = TAB_KEY_CODE,
}

export interface NeoBarDisplayItemCommonConfiguration {
    displaySubQueryIcon: boolean;
}

export interface NeoBarDefaultListItemConfiguration extends NeoBarDisplayItemCommonConfiguration {

}

export interface NeoBarCardConfiguration extends NeoBarDisplayItemCommonConfiguration {
    displaySummary: boolean;
    displayTypeBadge: boolean;
}

export interface NeoBarListItemConfiguration extends NeoBarDisplayItemCommonConfiguration {
    displayAvatar: boolean;
    displayType: boolean;
    useDesiredBackgroundColor: boolean;
}

export interface NeoBarSearchSuggestionsConfiguration {
    allowUserToExploreOptions: boolean;
    suggestOptionsWhileUserIsTyping: boolean;
    displayTypingSuggestionMode: TypingSearchSuggestionModeEnum;
}

export interface NeoBarSearchNeoConfiguration {
    enabled: boolean;
    showSearchProgress: boolean;
    autoSearch: boolean;
    suggestions: NeoBarSearchSuggestionsConfiguration;
    initializeWithEntityType: NeoSearchEntityTypeEnum | NotFound;
}

export interface NeoBarSearchConfiguration {
    neoSearch: NeoBarSearchNeoConfiguration;
    autoSearchInterval: number;
}

export interface NeoBarDisplaySettingsConfiguration {
    defaultList: NeoBarDefaultListItemConfiguration;
    neoList: NeoBarListItemConfiguration;
    neoCard: NeoBarCardConfiguration;
}

export interface NeoBarDisplayConfiguration extends SchemaDisabled<NeoBarDisplayFormatEnum>{
    type: NeoBarDisplayFormatEnum;
    settings: NeoBarDisplaySettingsConfiguration;
}

export interface NeoBarShortcutConfiguration {
    manualSearchTriggerKey: NeoBarShortcutKeyCodeEnum;
    selectSuggestedSuggestionKey: NeoBarShortcutKeyCodeEnum;
}

export interface NeoBarConfiguration {
    version: number;
    display: NeoBarDisplayConfiguration;
    search: NeoBarSearchConfiguration;
    types: Array<NeoSearchEntityTypeEnum>;
    shortcuts: NeoBarShortcutConfiguration;
}
