/* eslint-disable import/no-unresolved,@typescript-eslint/ban-ts-comment,import/no-webpack-loader-syntax */
import React, {
  forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {
  Box, Input, makeStyles, Paper,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ErrorBoundary } from 'react-error-boundary';
import {
  ContentState, convertFromRaw, convertToRaw, EditorState,
} from 'draft-js';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
// @ts-ignore
import worker from 'workerize-loader!./QueryWorker';
import {
  EMPTY_STRING,
  NotFound,
  parseBackEndText,
  parseFrontEndText,
  ParsingError,
  PowerSearchSynchronousQueryFilters,
  QueryFilterOptionShallow,
  QueryInfo,
  ReferencedQueryEntities,
} from 'mediascouting-core-ui-common';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SuggestionService from './SuggestionService';
import { EditorRequest, EntityRecognition, QueryWorkerOutput } from '../../../../types/layout/topBar/search/editor';
import { FocusState } from '../../../../types/layout/topBar/search/suggestion';
import { createFocusState, updateFocusStateForKey } from './utils/FocusStateUtils';
import createEditorDecorators from './utils/editor/draft-js/EditorDecoratorUtils';
import Detachment from './Detachment';
import fulfilEditorRequest from './utils/editor/draft-js/EditorRequestUtils';
import { ENTER_KEY_CODE } from '../../../../constants/KeyPropertyValues';
import QueryEditor from './QueryEditor';
import findNearbyEntity from './utils/editor/draft-js/processing/EntityRecognitionUtils';
import decodeFilters from './utils/editor/draft-js/transformation/DecodingUtils';
import encodeFilters from './utils/editor/draft-js/transformation/EncodingUtils';
import { EntityTypesEnum } from '../../../../constants/search/editor/EntityTypes';
import { IntelligentQuerySearchBarConfiguration } from '../../../../configuration/schema';
import ENTITY_MUTABILITY_MAPPER from '../../../../constants/search/editor/EntityMutability';

interface IntelligentQuerySearchBarPropTypes {
    width: number;
    anchorEl: Element | null;
    readOnly?: boolean;
    assistantOptions?: React.ReactNode;
    onSearch: () => void;
    onClickAway: () => void;
    onDataRequest: (type: EntityTypesEnum, search: string) => Promise<Array<QueryFilterOptionShallow>>;
    onError?: (error?: Error, info?: { componentStack: string }) => void;
    configuration?: IntelligentQuerySearchBarConfiguration;
}

export type IntelligentQuerySearchBarExposedMethods = {
    initializeEditor: (
        text: string,
        powerSearch: PowerSearchSynchronousQueryFilters,
        onRetrieveMissingData: (type: EntityTypesEnum, id: number) => Promise<QueryFilterOptionShallow | NotFound>,
        queryInfo?: QueryInfo
    ) => void;
    getSearchableText: () => string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  editor: {
    width: '100%',
  },
  search: {
    padding: theme.spacing(1.9),
  },
  overflow: {
    maxHeight: '54px',
    overflow: 'hidden',
    // '-webkit-mask-image': '-webkit-gradient('
    //     + 'linear, '
    //     + 'left top,'
    //     + 'left bottom,from('
    //     + 'rgb(64 92 177)), to(rgb(0 63 255 / 0%))'
    //     + ')',
  },
  detached: {
    maxHeight: '40vh',
    overflow: 'auto',
  },
  fallbackSearch: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
  },
}));

const editorDecorators = createEditorDecorators();

const IntelligentQuerySearchBar = forwardRef((props: IntelligentQuerySearchBarPropTypes, ref): JSX.Element => {
  const {
    anchorEl, onSearch, width, readOnly, assistantOptions, onDataRequest, onClickAway, onError, configuration,
  } = props;
  const classes = useStyles(width);
  const { t } = useTranslation();
  const [parsingError, setParsingError] = useState<ParsingError>();
  const [entityRecognition, setEntityRecognition] = useState<EntityRecognition>();
  const [focusState, setFocusState] = useState<FocusState>(createFocusState(-1, false, false));
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(editorDecorators));
  const [processedEditorState, setProcessedEditorState] = useState<EditorState>();
  const [loading, setLoading] = useState<boolean>(false);
  const [decoding, setDecoding] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string>(EMPTY_STRING);
  const schedulerReference = useRef<number>();

  const prevEditorStateRef = useRef<EditorState>(editorState);
  const prevFocusStateRef = useRef<FocusState>(focusState);

  const parseQuery = useCallback((latestEditorState: EditorState): void => {
    const queryWorker = worker();
    const rawContentState = convertToRaw(latestEditorState.getCurrentContent());

    queryWorker.asyncProcessing(rawContentState)
      .then((output: QueryWorkerOutput) => {
        const { updatedRawContentState, optionalParsingError } = output;
        const contentState = convertFromRaw(updatedRawContentState);
        const updatedEditorState = EditorState.push(latestEditorState, contentState, 'apply-entity');

        setProcessedEditorState(updatedEditorState);
        setParsingError(optionalParsingError);
      })
      .finally(() => {
        queryWorker.terminate();
        schedulerReference.current = undefined;
      });
  }, []);

  const searchForNearbyEntity = useCallback((currentEditorState: EditorState) => {
    const recognizedEntities = findNearbyEntity(currentEditorState);

    setEntityRecognition(recognizedEntities);
  }, []);

  const handleEditorRequest = useCallback((editorRequest: EditorRequest<EntityTypesEnum>) => {
    if (!readOnly) {
      setEditorState((prevState) => fulfilEditorRequest(
        prevState, editorRequest, ENTITY_MUTABILITY_MAPPER,
      ));
    }
  }, [readOnly]);

  const handleKeyAction = useCallback((key: number): void => {
    if (key === ENTER_KEY_CODE && !prevFocusStateRef.current.active) {
      return onSearch();
    }

    return setFocusState((prevState) => updateFocusStateForKey(key, prevState));
  }, [onSearch]);

  const handleActionKeyPress = useCallback((key: number) => {
    handleKeyAction(key);
  }, [handleKeyAction]);

  const handleProcessedEditorState = useCallback((
    latestProcessedEditorState: EditorState,
    latestEditorState: EditorState,
  ): void => {
    const processedText = latestProcessedEditorState.getCurrentContent().getPlainText();
    const latestText = latestEditorState.getCurrentContent().getPlainText();

    if (processedText === latestText) {
      const currentSelection = latestEditorState.getSelection();
      const mergedEditorState = EditorState.forceSelection(latestProcessedEditorState, currentSelection);

      setEditorState(mergedEditorState);
      setLoading(false);
      setDecoding(false);
    }

    setProcessedEditorState(undefined);
  }, []);

  const scheduleParsing = useCallback((latestEditorState: EditorState, timeout?: number) => {
    if (schedulerReference.current) {
      clearTimeout(schedulerReference.current);
    }

    setLoading(true);
    schedulerReference.current = window.setTimeout(() => parseQuery(latestEditorState), timeout || 250);
  }, [parseQuery]);

  const handleEditorStateSideEffects = useCallback((updatedEditorState: EditorState): void => {
    const contentState = updatedEditorState.getCurrentContent();
    const contentText = contentState.getPlainText();

    searchForNearbyEntity(updatedEditorState);
    setQueryText(contentText);
  }, [searchForNearbyEntity]);

  const handleFilterTransformation = useCallback((
    text: string,
    powerSearch: PowerSearchSynchronousQueryFilters,
    onRetrieveMissingData: (type: EntityTypesEnum, id: number) => Promise<QueryFilterOptionShallow | NotFound>,
    queryInfo?: QueryInfo,
  ): void => {
    setDecoding(true);

    const { parsedSegments } = parseBackEndText(text);
    const contentState = ContentState.createFromText(text);
    const nonFormattedEditorState = EditorState.createWithContent(contentState, editorDecorators);
    const references = queryInfo?.referencedQueryEntities || ({} as ReferencedQueryEntities);

    decodeFilters(nonFormattedEditorState, parsedSegments, references, powerSearch, onRetrieveMissingData)
      .then((formattedEditorState) => {
        setEditorState(formattedEditorState);
        parseQuery(formattedEditorState);
      })
      .catch(() => {
        const emptyEditorState = EditorState.createEmpty(editorDecorators);

        setEditorState(emptyEditorState);
        setDecoding(false);
      });
  }, [parseQuery]);

  const initializeEditor = useCallback((
    text: string,
    powerSearch: PowerSearchSynchronousQueryFilters,
    onRetrieveMissingData: (type: EntityTypesEnum, id: number) => Promise<QueryFilterOptionShallow | NotFound>,
    queryInfo?: QueryInfo,
  ) => {
    if (text.length > 0) {
      handleFilterTransformation(text, powerSearch, onRetrieveMissingData, queryInfo);
    } else {
      const emptyEditorState = EditorState.createEmpty(editorDecorators);

      setEditorState(emptyEditorState);
    }
  }, [handleFilterTransformation]);

  const getSearchableText = useCallback(() => {
    const plainText = editorState.getCurrentContent().getPlainText();
    const { parsedSegments } = parseFrontEndText(plainText);

    return encodeFilters(editorState, parsedSegments);
  }, [editorState]);

  useEffect(() => {
    if (processedEditorState) {
      handleProcessedEditorState(processedEditorState, editorState);
    }
  }, [editorState, processedEditorState, handleProcessedEditorState]);

  useEffect(() => {
    const previousText = prevEditorStateRef.current?.getCurrentContent().getPlainText();
    const nextText = editorState.getCurrentContent().getPlainText();

    if (previousText !== nextText) {
      scheduleParsing(editorState);
    }
  }, [editorState, scheduleParsing]);

  useEffect(() => {
    handleEditorStateSideEffects(editorState);
  }, [editorState, handleEditorStateSideEffects]);

  useEffect(() => {
    prevEditorStateRef.current = editorState;
  });

  useEffect(() => {
    prevFocusStateRef.current = focusState;
  });

  useImperativeHandle(ref, (): IntelligentQuerySearchBarExposedMethods => ({
    initializeEditor: (
      text, powerSearch, onRetrieveMissingData, queryInfo,
    ): void => initializeEditor(text, powerSearch, onRetrieveMissingData, queryInfo),
    getSearchableText: (): string => getSearchableText(),
  }), [getSearchableText, initializeEditor]);

  const handleFallbackInputChange = (event): void => {
    setQueryText(event.target.value);
  };

  const handleFallbackError = (error: Error, info: { componentStack: string }): void => {
    if (onError) {
      onError(error, info);
    }
  };

  const handleFallbackRender = (): JSX.Element => (
      <Paper className={classes.fallbackSearch}>
          <SearchIcon />
          <Box width={10} />
          <Box className={classes.editor}>
              <Input
                disableUnderline
                fullWidth
                placeholder={t('layout.topBar.search.searchBar.fallback.input')}
                disabled={readOnly}
                value={queryText}
                onChange={handleFallbackInputChange}
              />
          </Box>
      </Paper>
  );

  const loadEditor = (): JSX.Element => {
    if (decoding) {
      return (
          <>
              {_.times(2, (key) => (
                  <Skeleton key={key} />
              ))}
          </>
      );
    }

    return (
        <QueryEditor
          editorState={editorState}
          focusState={focusState}
          onChangeEditorState={setEditorState}
          onKeyAction={handleActionKeyPress}
        />
    );
  };
  return (
      <ErrorBoundary fallbackRender={handleFallbackRender} onError={handleFallbackError}>
          <Detachment anchorEl={anchorEl} width={width} onClickAway={onClickAway}>
              <Box className={classes.root}>
                  <Box
                    className={clsx(
                      classes.search,
                      { [classes.overflow]: anchorEl === null },
                      { [classes.detached]: anchorEl !== null },
                    )}
                  >
                      <Box className={classes.editor}>
                          {loadEditor()}
                      </Box>
                  </Box>
                  {(anchorEl) && (
                      <SuggestionService
                        queryText={queryText}
                        focusState={focusState}
                        loading={loading || decoding}
                        error={parsingError}
                        entityRecognition={entityRecognition}
                        onDataRequest={onDataRequest}
                        onEditorRequest={handleEditorRequest}
                        onChangeFocus={setFocusState}
                        assistantOptions={assistantOptions}
                        configuration={configuration}
                      />
                  )}
              </Box>
          </Detachment>
      </ErrorBoundary>
  );
});

export default memo(IntelligentQuerySearchBar);
