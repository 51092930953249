import {
  ContentBlock, EditorState, EntityInstance, Modifier, SelectionState,
} from 'draft-js';
import { EntityRange } from '../../types/layout/topBar/search/editor';
import EntityMutability from '../../constants/draft-js/EntityMutability';

type FoundEntity = {
  entityKey: string;
  entityInstance: EntityInstance;
};

type CreatedEntity = {
  updatedEditorState: EditorState;
  entityKey: string;
};

export const getEntityAtSelection = (
  editorState: EditorState, entitySelection: SelectionState,
): FoundEntity | undefined => {
  const contentState = editorState.getCurrentContent();
  const anchorKey = entitySelection.getAnchorKey();
  const contentBlock = contentState.getBlockForKey(anchorKey);

  if (contentBlock) {
    const optionalEntityKey = contentBlock.getEntityAt(entitySelection.getStartOffset());

    if (optionalEntityKey) {
      const entityInstance = contentState.getEntity(optionalEntityKey);

      return {
        entityKey: optionalEntityKey,
        entityInstance,
      };
    }
  }

  return undefined;
};

export const getNextEntity = (currentSelection, remainingCharacters, block: ContentBlock): string | null => {
  let initialSelectionOffset = currentSelection.getStartOffset();

  for (let i = 0; i < remainingCharacters; i += 1) {
    initialSelectionOffset += i;
    const nextEntityKey = block.getEntityAt(initialSelectionOffset);

    if (nextEntityKey !== null) {
      return nextEntityKey;
    }
  }

  return null;
};

export const getPreviousEntity = (blockPlainTextLength: number, block: ContentBlock): string | null => {
  for (let j = blockPlainTextLength; j > 0; j -= 1) {
    const entityKey = block.getEntityAt(j);
    if (entityKey !== null) {
      return entityKey;
    }
  }

  return null;
};

export const createEntity = (
  editorState: EditorState, selectionState: SelectionState, mutability: EntityMutability, type: string, data?: object,
): CreatedEntity => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(type, mutability, data);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const updatedContentState = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey);
  const updatedEditorState = EditorState.push(editorState, updatedContentState, 'apply-entity');

  return {
    updatedEditorState,
    entityKey,
  };
};

export const updateEntity = (editorState: EditorState, entityKey: string, data: object): EditorState => {
  const contentState = editorState.getCurrentContent();
  const updatedEntityContentState = contentState.mergeEntityData(entityKey, data);

  return EditorState.push(editorState, updatedEntityContentState, 'apply-entity');
};

export const findEntityRanges = (editorState: EditorState, types: Array<string>): Array<EntityRange> => {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();
  const entityRanges: Array<EntityRange> = [];

  blocks.forEach((block) => {
    let currentEntityRange: EntityRange;

    block.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey !== null) {
        const entity = contentState.getEntity(entityKey);
        const type = entity.getType();
        if (types.includes(type)) {
          const blockKey = block.getKey();

          currentEntityRange = {
            entityKey, blockKey, entity, start: 0, end: 0, text: '',
          };

          return true;
        }
      }

      return false;
    }, (start, end) => {
      entityRanges.push({ ...currentEntityRange, start, end });
    });
  });

  return entityRanges;
};
