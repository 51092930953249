import { useRouteMatch } from 'react-router-dom';

type StatisticsMatch = {
  tab?: string;
}

type AlertsMatch = {

}

type SharesMatch = {

}

interface ExportedUseNeoBarRouterProperties {
  getPath: () => string;
}

const useNeoBarRouter = (): ExportedUseNeoBarRouterProperties => {
  const statisticsMatch = useRouteMatch<StatisticsMatch>({ path: '/portal/statistics/:tab?' });
  const alertsMatch = useRouteMatch<AlertsMatch>({ path: '/portal/alerts' });
  const shareMatch = useRouteMatch<SharesMatch>({ path: '/portal/shares' });

  const getPath = (): string => {
    if (statisticsMatch) {
      if (statisticsMatch.params.tab) {
        return `/portal/statistics/${statisticsMatch.params.tab}`;
      }

      return '/portal/statistics';
    }

    if (alertsMatch) {
      return '/portal/alerts';
    }

    if (shareMatch) {
      return '/portal/shares';
    }

    return '/portal/search';
  };

  return {
    getPath,
  };
};

export default useNeoBarRouter;
