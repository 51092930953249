import { SchemaDisabled } from 'mediascouting-core-ui-common';

export interface FacetConfiguration extends SchemaDisabled<FacetType>{

}

export enum FacetType {
    DATE = 'date',
    TAGS = 'tags',
    CURATION = 'curation',
    LANGUAGE = 'language',
    SUB_QUERIES = 'sub-queries',
    SENTIMENT = 'sentiment',
    AUTHORS = 'authors',
    DAYS = 'days',
    TIME_SECTIONS = 'time_sections',
    SOURCE_TYPE = 'source_type',
    SOURCES = 'sources',
    MARKET = 'market',
    UNKNOWN = '',
}

export interface Facet {
    name: string;
    count: number;
    value: string;
}

export interface FacetGroup {
    type: FacetType;
    name: string;
    count: number;
    selectionLimit: number;
    facets: Array<Facet>;
}

export interface ReduxFacet {
    type: FacetType;
    value: string;
}
