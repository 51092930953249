import React, { memo } from 'react';
import {
  Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme, Tooltip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Draggable } from 'react-beautiful-dnd';
import { Remove } from '@material-ui/icons';
import { PlaylistItem } from '../../../../types/layout/topBar/Montage';
import getSourceTypeIcon from '../../story/StoryReflection/utils/IconTypeUtils';

type PlaylistItemDetailsPropTypes = {
  playlistItem: PlaylistItem;
  index: number;
  onRemove?: (playlistItem: PlaylistItem) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  badgeIcon: {
    marginRight: theme.spacing(1),
  },
  item: {
    backgroundColor: 'white',
  },
  draggable: {
    cursor: 'grab',
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

function PlaylistItemDetails(props: PlaylistItemDetailsPropTypes): JSX.Element {
  const classes = useStyles();
  const {
    playlistItem, index, onRemove,
  } = props;

  const uniqueIdentity = playlistItem.source.id + index;

  const handleRemove = (): void => {
    if (onRemove) {
      onRemove(playlistItem);
    }
  };

  return (
      <Draggable draggableId={`${uniqueIdentity}`} index={index}>
          {(provided): JSX.Element => (
              <ListItem
                key={uniqueIdentity}
                innerRef={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classes.item}
                alignItems="flex-start"
                button
              >
                  <ListItemAvatar>
                      <Avatar className={classes.avatar} src={playlistItem.source.image}>
                          {getSourceTypeIcon(playlistItem.story.type)}
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                        <Typography variant="h6" color="inherit" component="span">
                            {playlistItem.source.name}
                        </Typography>
                    )}
                    secondary={(
                        <Typography variant="caption" color="textPrimary">
                            <br />
                            {playlistItem.range.start.toString()}
                            <br />
                            {playlistItem.range.end.toString()}
                        </Typography>
                    )}
                  />
                  {onRemove
                    && (
                        <Box ml={2} mt={1} display="flex" flexDirection="column" alignItems="flex-end">
                            <Tooltip title="Remove">
                                <IconButton onClick={handleRemove} className={classes.badgeIcon}>
                                    <Remove />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
              </ListItem>
          )}
      </Draggable>
  );
}

const skipRender = (
  prev: PlaylistItemDetailsPropTypes,
  next: PlaylistItemDetailsPropTypes,
): boolean => prev.index === next.index && prev.playlistItem === next.playlistItem;

export default memo(PlaylistItemDetails, skipRender);
