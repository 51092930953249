import React from 'react';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createMatcherAdditionRequest, createSuggestion } from '../SuggestionServiceUtils';
import {
  AdditionRequest,
  EntityRange,
  SubstituteRequest,
} from '../../../../../../../types/layout/topBar/search/editor';
import ConditionMatcher from '../../../QueryEditor/ConditionMatcher';
import ExactTextMatch from '../../../QueryEditor/ExactTextMatch';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';

const createConditionMatcherPreview = (text, matcher): JSX.Element => (
    <>
        {text}
        {' '}
        <ConditionMatcher>{matcher}</ConditionMatcher>
    </>
);

const createORConditionMatcherSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const preview = createConditionMatcherPreview(entityRange.text, EntityTypesEnum.OR_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(
    EntityTypesEnum.OR_CONDITION_MATCHER, entityRange,
  );

  return createSuggestion(preview, request);
};

const createANDConditionMatcherSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const preview = createConditionMatcherPreview(entityRange.text, EntityTypesEnum.AND_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(
    EntityTypesEnum.AND_CONDITION_MATCHER, entityRange,
  );

  return createSuggestion(preview, request);
};

const createExactTextMatchSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const text = entityRange.text.replace(/"/g, EMPTY_STRING);
  const requestedText = `"${text}"`;
  const preview = (<ExactTextMatch>{requestedText}</ExactTextMatch>);
  const request: SubstituteRequest<EntityTypesEnum> = {
    substituteText: requestedText,
    reference: entityRange,
    cursorPositionWithinText: requestedText.length,
    entityMetadata: [
      {
        type: EntityTypesEnum.EXACT_TEXT_MATCH,
        start: 0,
        end: requestedText.length,
      },
    ],
  };
  return createSuggestion(preview, request);
};

const simpleInputSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  const andSuggestion = createANDConditionMatcherSuggestion(entityRange);
  const orSuggestion = createORConditionMatcherSuggestion(entityRange);
  const exactTextMatchSuggestion = createExactTextMatchSuggestion(entityRange);

  return Array.of(andSuggestion, orSuggestion, exactTextMatchSuggestion);
};

export default simpleInputSuggestions;
