import React, { MutableRefObject, useCallback } from 'react';
import {
  CreateTagRequest,
  EMPTY_STRING,
  getArrayAsString, GetTagsRemoteParams,
  NOT_FOUND,
  NotFound, PagedContent,
  PowerSearchFilterTypes,
  Shallow,
  Tag, UpdateTagRequest,
} from 'mediascouting-core-ui-common';
import { CancelTokenSource } from 'axios';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setQueryResults } from '../../../../../../../../../constants/actions/creators/QueryResultsActions';
import {
  createTag,
  deleteTag,
  getTags,
  REMOTE_USER_PARAM_TAG_TYPE,
  updateTag,
} from '../../../../../../../../../remote/Tag';
import { LimePickerFetchDataParams } from '../../../../../../../LimePicker';
import { AppDispatch } from '../../../../../../../../../redux/store';
import { PortalPathMatch } from '../../../../../../../../../types/PortalTabs';
import { ReduxState } from '../../../../../../../../../redux/reducers';
import UrlParams from '../../../../../../../../../types/UrlParams';

interface ExposedUseNeoBarTagManipulationProperties {
    handleFetchTags: (
        params: LimePickerFetchDataParams, cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
    ) => Promise<PagedContent<Tag>>;
    handleTagCreate: (tag: Partial<Tag>) => Promise<Tag>;
    handleTagUpdate: (id: number, tag: Partial<Tag>) => Promise<Tag>;
    handleTagDelete: (id: number) => Promise<void>;
    handleTagEditClick: (event) => void;
    handleTagEditorClose: () => void;
    tagPopperAnchorEl: HTMLButtonElement | null;
}

const useNeoBarTagManipulation = (): ExposedUseNeoBarTagManipulationProperties => {
  const location = useLocation();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const match = useRouteMatch<PortalPathMatch>({ path: '/portal/:tab' });
  const querySearchSpace = useSelector((state: ReduxState) => state.querySearchSpace);
  const stories = useSelector((state: ReduxState) => state.queryStoryResults.stories);
  const [tagPopperAnchorEl, setTagPopperAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const updateUrl = useCallback((updatedParams?: URLSearchParams): void => {
    const currentTab = match ? match.params.tab : 'search';
    const params = updatedParams || new URLSearchParams(location.search);

    history.push({
      pathname: `/portal/${currentTab}`,
      search: params.toString(),
    });
  }, [history, location.search, match]);

  const updateTagUrlParams = useCallback((updatedTags: Array<Shallow>): URLSearchParams => {
    const params = new URLSearchParams(location.search);

    if (updatedTags.length > 0) {
      params.set(UrlParams.TAGS, getArrayAsString(updatedTags));
    } else {
      params.delete(UrlParams.TAGS);
    }

    return params;
  }, [location.search]);

  const deleteTagOnVisibleStories = useCallback((deletedTagId: number): void => {
    const updatedStories = stories.map((story) => {
      const updatedUserTags = story.userTags.filter((userTag) => deletedTagId !== userTag.tag.id);

      return {
        ...story,
        userTags: updatedUserTags,
      };
    });

    dispatch(setQueryResults(updatedStories));
  }, [dispatch, stories]);

  const deleteTagFromUrlParameters = useCallback((id: number): void => {
    try {
      const foundTag = querySearchSpace[PowerSearchFilterTypes.TAGS]
        .find((searchableTag) => searchableTag.id === id);

      if (foundTag) {
        const filteredTags = querySearchSpace[PowerSearchFilterTypes.TAGS]
          .filter((searchableTag) => searchableTag.id !== id);

        const updatedParams = updateTagUrlParams(filteredTags);

        return updateUrl(updatedParams);
      }

      return deleteTagOnVisibleStories(id);
    } catch (e) {
      return NOT_FOUND;
    }
  }, [deleteTagOnVisibleStories, querySearchSpace, updateTagUrlParams, updateUrl]);

  const handleTagEditClick = useCallback((
    event,
  ): void => setTagPopperAnchorEl(event.currentTarget), []);

  const updateTagOnVisibleStories = useCallback((updatedTag: Tag): void => {
    const updatedStories = stories.map((story) => {
      const updatedUserTags = story.userTags.map((userTag) => {
        if (updatedTag.id === userTag.tag.id) {
          return {
            ...userTag,
            tag: updatedTag,
          };
        }

        return userTag;
      });

      return {
        ...story,
        userTags: updatedUserTags,
      };
    });

    dispatch(setQueryResults(updatedStories));
  }, [dispatch, stories]);

  const handleTagEditorClose = useCallback((): void => setTagPopperAnchorEl(null), []);

  const handleTagDelete = useCallback((id: number): Promise<void> => dispatch(deleteTag(id))
    .then(() => {
      deleteTagFromUrlParameters(id);
    }), [deleteTagFromUrlParameters, dispatch]);

  const handleFetchTags = useCallback((
    params: LimePickerFetchDataParams, cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
  ): Promise<PagedContent<Tag>> => {
    const revisedParams: GetTagsRemoteParams = {
      ...REMOTE_USER_PARAM_TAG_TYPE,
      name: params?.searchText,
      page: params?.requestedPage,
    };

    return dispatch(
      getTags(revisedParams, cancelToken?.current?.token),
    );
  }, [dispatch]);

  const handleTagUpdate = useCallback((id: number, tag: Partial<Tag>): Promise<Tag> => {
    const request: UpdateTagRequest = {
      name: tag.name || EMPTY_STRING,
      description: tag.description || EMPTY_STRING,
      attributes: JSON.stringify(tag.attributes),
    };

    return dispatch(updateTag(id, request))
      .then((updatedTag) => {
        updateTagOnVisibleStories(updatedTag);

        return updatedTag;
      });
  }, [dispatch, updateTagOnVisibleStories]);

  const handleTagCreate = useCallback((tagDto: Partial<Tag>): Promise<Tag> => {
    const createRequest: CreateTagRequest = {
      name: tagDto.name || EMPTY_STRING,
      description: tagDto.description || EMPTY_STRING,
      attributes: JSON.stringify(tagDto.attributes),
    };

    return dispatch(createTag(createRequest))
      .then((createdTag) => createdTag);
  },
  [dispatch]);

  return {
    handleFetchTags,
    handleTagCreate,
    handleTagUpdate,
    handleTagDelete,
    handleTagEditClick,
    handleTagEditorClose,
    tagPopperAnchorEl,
  };
};

export default useNeoBarTagManipulation;
