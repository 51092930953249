import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type ErrorFallbackPropTypes = {
  onReset?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/fallback.jpg")',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  text: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
  button: {
    border: 'none',
    outline: 0,
    display: 'inline-block',
    padding: '10px 25px',
    color: 'black',
    backgroundColor: '#ddd',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },
}));

function ErrorFallback(props: ErrorFallbackPropTypes): JSX.Element {
  const classes = useStyles();
  const { onReset } = props;

  return (
      <div className={classes.root}>
          <div className={classes.text}>
              <Typography variant="h1">
                  Something went wrong
              </Typography>
              <Typography variant="subtitle1">
                  We apologise for any inconvenience
              </Typography>
              <Button onClick={onReset} className={classes.button}>
                  TRY AGAIN
              </Button>
          </div>
      </div>

  );
}

export default ErrorFallback;
