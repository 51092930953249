import React, { memo, MutableRefObject } from 'react';
import { Box } from '@material-ui/core';
import {
  GetItemsResponse,
  MojitoSelectFetchDataParams,
  NotFound,
  PowerSearch,
  PowerSearchFilter,
  PowerSearchQueryFilters,
  PowerSearchSynchronousQueryFilters,
  Shallow,
  PowerSearchFilterConfiguration,
} from 'mediascouting-core-ui-common';
import { CancelTokenSource } from 'axios';

type FiltersPropTypes = {
    state: PowerSearchQueryFilters;
    synchronousQueryFilterOptions: PowerSearchSynchronousQueryFilters;
    onQueryFilterOptionSearch: (
        type: PowerSearchFilter,
        params: MojitoSelectFetchDataParams,
        cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
        otherParams?: object,
    ) => Promise<GetItemsResponse<Shallow>>;
    onChange: (type: PowerSearchFilter, values: Array<Shallow> | string | null) => void;
    configuration?: PowerSearchFilterConfiguration;
}

function Filters(props: FiltersPropTypes): JSX.Element {
  const {
    state, synchronousQueryFilterOptions, onQueryFilterOptionSearch, onChange, configuration,
  } = props;

  return (
      <Box mt={3}>
          <PowerSearch
            state={state}
            synchronousQueryFilterOptions={synchronousQueryFilterOptions}
            onQueryFilterOptionSearch={onQueryFilterOptionSearch}
            onChange={onChange}
            configuration={configuration}
          />
      </Box>
  );
}

export default memo(Filters);
