import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { NeoSearchEntityDecider, NeoSearchEntityTypeEnum, NeoSearchRequest } from '../types/NeoSearch';

const getQueryTagTypeIfIncludesType = (
  ifTypeCondition: NeoSearchEntityTypeEnum,
  oppositeTypeCondition: NeoSearchEntityTypeEnum,
  existingTypes: Array<NeoSearchEntityTypeEnum>,
  selectedTypes: Array<NeoSearchEntityTypeEnum>,
  typeToAddIfConditionIsOK: NeoSearchEntityTypeEnum,
): Array<NeoSearchEntityTypeEnum> => {
  if (selectedTypes.includes(ifTypeCondition)
      || !selectedTypes.includes(oppositeTypeCondition)) {
    return [...existingTypes, typeToAddIfConditionIsOK];
  }

  return existingTypes;
};

const handleQuery = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    fetchQueries: !types.includes(NeoSearchEntityTypeEnum.SHARED),
  },
  tags: {
    ...existingRequest.tags,
    fetchTags: !types.includes(NeoSearchEntityTypeEnum.SHARED)
        && types.includes(NeoSearchEntityTypeEnum.TAG),
    fetchSharedTags: types.includes(NeoSearchEntityTypeEnum.TAG),
  },
});

const handleTag = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    fetchQueries: !types.includes(NeoSearchEntityTypeEnum.SHARED)
        && types.includes(NeoSearchEntityTypeEnum.QUERY),
    fetchSharedQueries: types.includes(NeoSearchEntityTypeEnum.QUERY),
  },
  tags: {
    ...existingRequest.tags,
    fetchTags: !types.includes(NeoSearchEntityTypeEnum.SHARED),
  },
});

const handleShared = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => {
  const enablingSharingIf = (
    relatedSharingType: NeoSearchEntityTypeEnum,
    otherSharingType: NeoSearchEntityTypeEnum,
  ): boolean => {
    if (types.includes(relatedSharingType)) {
      return true;
    }

    return !types.includes(otherSharingType);
  };

  return {
    ...existingRequest,
    queries: {
      ...existingRequest.queries,
      fetchQueries: false,
      fetchSharedQueries: enablingSharingIf(
        NeoSearchEntityTypeEnum.QUERY,
        NeoSearchEntityTypeEnum.TAG,
      ),
    },
    tags: {
      ...existingRequest.tags,
      fetchTags: false,
      fetchSharedTags: enablingSharingIf(
        NeoSearchEntityTypeEnum.TAG,
        NeoSearchEntityTypeEnum.QUERY,
      ),
    },
  };
};

const handleGeneral = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.QUERY,
      NeoSearchEntityTypeEnum.TAG,
      existingRequest.queries.types,
      types,
      NeoSearchEntityTypeEnum.GENERAL,
    ),
  },
  tags: {
    ...existingRequest.tags,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.TAG,
      NeoSearchEntityTypeEnum.QUERY,
      existingRequest.tags.types,
      types,
      NeoSearchEntityTypeEnum.GENERAL,
    ),
  },
});

const handleOrganization = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.QUERY,
      NeoSearchEntityTypeEnum.TAG,
      existingRequest.queries.types,
      types,
      NeoSearchEntityTypeEnum.ORGANIZATION,
    ),
  },
  tags: {
    ...existingRequest.tags,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.TAG,
      NeoSearchEntityTypeEnum.QUERY,
      existingRequest.tags.types,
      types,
      NeoSearchEntityTypeEnum.ORGANIZATION,
    ),
  },
});

const handleTopic = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.QUERY,
      NeoSearchEntityTypeEnum.TAG,
      existingRequest.queries.types,
      types,
      NeoSearchEntityTypeEnum.TOPIC,
    ),
  },
  tags: {
    ...existingRequest.tags,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.TAG,
      NeoSearchEntityTypeEnum.QUERY,
      existingRequest.tags.types,
      types,
      NeoSearchEntityTypeEnum.TOPIC,
    ),
  },
});

const handlePerson = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.QUERY,
      NeoSearchEntityTypeEnum.TAG,
      existingRequest.queries.types,
      types,
      NeoSearchEntityTypeEnum.PERSON,
    ),
  },
  tags: {
    ...existingRequest.tags,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.TAG,
      NeoSearchEntityTypeEnum.QUERY,
      existingRequest.tags.types,
      types,
      NeoSearchEntityTypeEnum.PERSON,
    ),
  },
});

const handleGeolocation = (
  existingRequest: NeoSearchRequest,
  types: Array<NeoSearchEntityTypeEnum>,
): NeoSearchRequest => ({
  ...existingRequest,
  queries: {
    ...existingRequest.queries,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.QUERY,
      NeoSearchEntityTypeEnum.TAG,
      existingRequest.queries.types,
      types,
      NeoSearchEntityTypeEnum.GEOLOCATION,
    ),
  },
  tags: {
    ...existingRequest.tags,
    types: getQueryTagTypeIfIncludesType(
      NeoSearchEntityTypeEnum.TAG,
      NeoSearchEntityTypeEnum.QUERY,
      existingRequest.tags.types,
      types,
      NeoSearchEntityTypeEnum.GEOLOCATION,
    ),
  },
});

const ENTITY_DECIDER: NeoSearchEntityDecider = {
  [NeoSearchEntityTypeEnum.GENERAL]: handleGeneral,
  [NeoSearchEntityTypeEnum.QUERY]: handleQuery,
  [NeoSearchEntityTypeEnum.TAG]: handleTag,
  [NeoSearchEntityTypeEnum.SHARED]: handleShared,
  [NeoSearchEntityTypeEnum.ORGANIZATION]: handleOrganization,
  [NeoSearchEntityTypeEnum.TOPIC]: handleTopic,
  [NeoSearchEntityTypeEnum.PERSON]: handlePerson,
  [NeoSearchEntityTypeEnum.GEOLOCATION]: handleGeolocation,
};

export const DEFAULT_NEO_SEARCH_REQUEST: NeoSearchRequest = {
  text: EMPTY_STRING,
  queries: {
    types: [],
    fetchSharedQueries: true,
    fetchQueries: true,
  },
  tags: {
    types: [],
    fetchSharedTags: true,
    fetchTags: true,
  },
};
const getNeoSearchRequest = (text: string, types: Array<NeoSearchEntityTypeEnum>): NeoSearchRequest => {
  let request: NeoSearchRequest = {
    ...DEFAULT_NEO_SEARCH_REQUEST,
    text,
  };

  types.forEach((type) => {
    const optionalHandler = ENTITY_DECIDER[type];

    if (optionalHandler) {
      request = optionalHandler(request, types);
    }
  });

  return request;
};

export default getNeoSearchRequest;
