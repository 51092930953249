import { makeStyles, Theme, useTheme } from '@material-ui/core';
import React, { memo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { PowerSearchQueryFilters } from 'mediascouting-core-ui-common';
import MobileActions from './MobileActions';
import DesktopActions from './DesktopActions';

type SearchActionsPropTypes = {
    onClean: (event) => void;
    onSearch: (event) => void;
    onOpenSettings: (event) => void;
    activeFilters: Array<Partial<PowerSearchQueryFilters>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    paddingRight: theme.spacing(1),
    opacity: '0.75',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function SearchActions(props: SearchActionsPropTypes): JSX.Element {
  const {
    onClean, onSearch, onOpenSettings, activeFilters,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const provideMobileActions = useMediaQuery(theme.breakpoints.down('xl'));

  return (
      <div className={classes.root}>
          {provideMobileActions
            ? (
                <MobileActions
                  onSearch={onSearch}
                  onClean={onClean}
                  onOpenSettings={onOpenSettings}
                  activeFilters={activeFilters}
                />
            )
            : (
                <DesktopActions
                  onSearch={onSearch}
                  onClean={onClean}
                  onOpenSettings={onOpenSettings}
                  activeFilters={activeFilters}
                />
            )}
      </div>
  );
}

export default memo(SearchActions);
