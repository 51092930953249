import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

interface CreateButtonPropTypes {
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    background:
    'linear-gradient(to right, #FFC371, #FF5F6D)',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:disabled': {
      background: '#b2b2b2',
    },
  },
  label: {
    color: theme.palette.common.white,
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 700,
  },
  contained: {
    minHeight: 30,
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0],
    },
  },
}));

function CreateButton(props: CreateButtonPropTypes): JSX.Element {
  const { disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <Button
        disabled={disabled}
        type="submit"
        form="montage-settings-form"
        classes={{
          root: classes.root,
          label: classes.label,
          contained: classes.contained,
        }}
      >
          {t('layout.topBar.search.searchBar.montage.createButton')}
      </Button>
  );
}

export default memo(CreateButton);
