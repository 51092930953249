/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box, Collapse, Divider, ListSubheader, makeStyles, Theme,
} from '@material-ui/core';
import React, {
  createRef, memo, useEffect, useRef,
} from 'react';
import clsx from 'clsx';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { FocusState, Suggestion } from '../../../../../types/layout/topBar/search/suggestion';
import {
  AdditionRequest,
  EditorRequest,
  SubstituteRequest,
} from '../../../../../types/layout/topBar/search/editor';
import { createFocusState } from '../utils/FocusStateUtils';
import { isAdditionRequest, isSubstituteRequest } from '../utils/editor/draft-js/EditorRequestUtils';
import { EntityTypesEnum } from '../../../../../constants/search/editor/EntityTypes';

type AdvancedFilterSuggestionsPropTypes = {
  suggestions: Array<Suggestion<EntityTypesEnum>>;
  onEditorRequest: (request: EditorRequest<EntityTypesEnum>) => void;
  focusIndex: number;
  focusTrigger: boolean;
  onChangeFocus: (focusState: FocusState) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  previewWrapper: {
    textTransform: 'uppercase',
    cursor: 'hand',
    padding: theme.spacing(1),
  },
  selected: {
    opacity: '0.5',
  },
}));

const createId = (request: EditorRequest<EntityTypesEnum>, index: number): string => {
  if (isAdditionRequest(request)) {
    const additionRequest = request as AdditionRequest<EntityTypesEnum>;

    return additionRequest.additionText + index;
  }

  if (isSubstituteRequest(request)) {
    const substituteRequest = request as SubstituteRequest<EntityTypesEnum>;

    return substituteRequest.substituteText + index;
  }

  return EMPTY_STRING + index;
};

function FilteringSuggestions(props: AdvancedFilterSuggestionsPropTypes): JSX.Element {
  const {
    suggestions, onEditorRequest, focusIndex, focusTrigger, onChangeFocus,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const elRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (elRefs.current.length !== suggestions.length) {
    elRefs.current = suggestions.map((_, i) => elRefs.current[i] || createRef());
  }

  const handleOnClick = (suggestion) => (): void => {
    onEditorRequest(suggestion.request);
    onChangeFocus(createFocusState(-1, false, false));
  };

  useEffect(() => {
    const focusWithinSuggestions = focusIndex >= 0 && focusIndex < suggestions.length;

    if (focusTrigger && focusWithinSuggestions) {
      const foundSuggestion = suggestions[focusIndex];

      if (foundSuggestion) {
        onEditorRequest(foundSuggestion.request);
        onChangeFocus(createFocusState(-1, false, false));
      }
    }
  }, [focusTrigger, focusIndex, onEditorRequest, suggestions, onChangeFocus]);

  useEffect(() => {
    const elRef = elRefs.current[focusIndex];

    if (elRef && elRef.current) {
      elRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [focusIndex, suggestions]);

  return (
      <Collapse in={suggestions.length > 0}>
          <Divider />
          <ListSubheader disableSticky component="div" id="advanced-filter-checklist">
              {t('layout.topBar.search.searchBar.suggestion.filter')}
          </ListSubheader>
          <Box className={classes.root}>
              {suggestions.map(
                (suggestion, index) => (
                    <Box
                        // @ts-ignore
                      ref={elRefs.current[index]}
                      key={createId(suggestion.request, index)}
                      className={clsx(classes.previewWrapper, { [classes.selected]: index === focusIndex })}
                      onClick={handleOnClick(suggestion)}
                    >
                        {suggestion.preview}
                    </Box>
                ),
              )}
          </Box>
      </Collapse>
  );
}

export default memo(FilteringSuggestions);
