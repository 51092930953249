import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY_CODE,
  ESCAPE_KEY,
  TAB_KEY_CODE,
} from '../../../../../constants/KeyPropertyValues';
import { FocusState, FocusStateKeyBehaviors } from '../../../../../types/layout/topBar/search/suggestion';

export const createFocusState = (index: number, trigger: boolean, active: boolean): FocusState => ({
  index,
  trigger,
  active,
});

const createFocusStateKeyBehaviors = (focusState: FocusState): FocusStateKeyBehaviors => ({
  [ARROW_DOWN_KEY]: createFocusState(focusState.index + 1, focusState.trigger, focusState.active),
  [ARROW_UP_KEY]: createFocusState(focusState.index - 1, focusState.trigger, focusState.active),
  [ENTER_KEY_CODE]: createFocusState(focusState.index, focusState.active, focusState.active),
  [TAB_KEY_CODE]: createFocusState(focusState.index + 1, false, true),
  [ESCAPE_KEY]: createFocusState(-1, false, false),
});

export const updateFocusStateForKey = (key: number, focusState: FocusState): FocusState => {
  const behaviors = createFocusStateKeyBehaviors(focusState);
  const keyBehavior = behaviors[key];

  if (keyBehavior) {
    return keyBehavior;
  }

  return createFocusState(-1, false, false);
};
