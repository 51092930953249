import React from 'react';
import { FormikHelpers } from 'formik';
import FormFields, { GeolocationTypeConfigurationFormFields } from './FormFields';

export interface GeolocationTypeConfigurationPropTypes {
    onSubmit: (
        values: GeolocationTypeConfigurationFormFields,
        helpers: FormikHelpers<GeolocationTypeConfigurationFormFields>,
    ) => Promise<void>;
    onBack: () => void;
    onClose: () => void;
}

function GeolocationTypeConfiguration(props: GeolocationTypeConfigurationPropTypes): JSX.Element {
  const { onSubmit, onBack, onClose } = props;

  return (
      <FormFields
        onSubmit={onSubmit}
        onBack={onBack}
        onClose={onClose}
      />
  );
}

export default GeolocationTypeConfiguration;
