import {
  Badge, Divider, IconButton, Tooltip, useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import React, { memo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING, PowerSearchQueryFilters } from 'mediascouting-core-ui-common';
import SearchTabs from './SearchTabs';

type MobileActionsPropTypes = {
    onClean: (event) => void;
    onSearch: (event) => void;
    onOpenSettings: (event) => void;
    activeFilters: Array<Partial<PowerSearchQueryFilters>>;
}

function MobileActions(props: MobileActionsPropTypes): JSX.Element {
  const {
    onClean, onSearch, onOpenSettings, activeFilters,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
      <>
          {!onMobile && (
              <>
                  <SearchTabs />
                  <Divider orientation="vertical" flexItem />
              </>
          )}
          <Tooltip title={t('layout.topBar.search.searchBar.actions.mobile.clear') || EMPTY_STRING}>
              <IconButton size="small" disableFocusRipple onClick={onClean}>
                  <ClearIcon fontSize="inherit" />
              </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <Badge badgeContent={activeFilters.length} color="primary" invisible={activeFilters.length < 1}>
              <Tooltip title={t('layout.topBar.search.searchBar.actions.mobile.query.settings') || EMPTY_STRING}>
                  <IconButton size="small" disableFocusRipple onClick={onOpenSettings}>
                      <TuneIcon fontSize="inherit" />
                  </IconButton>
              </Tooltip>
          </Badge>
          <Divider orientation="vertical" flexItem />
          <Tooltip title={t('layout.topBar.search.searchBar.actions.mobile.search') || EMPTY_STRING}>
              <IconButton size="small" disableFocusRipple onClick={onSearch}>
                  <SearchIcon fontSize="inherit" />
              </IconButton>
          </Tooltip>
      </>
  );
}

export default memo(MobileActions);
