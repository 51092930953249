import jwtDecode from 'jwt-decode';
import axios from '../utils/common/AxiosUtils';
import { ACCESS_TOKEN } from '../constants/LocalStorage';

class AuthService {
  setAxiosInterceptors = ({ onLogout }): void => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      },
    );
  };

  handleAuthentication(): void {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  logout = (): void => {
    this.setSession(null);
  };

  setSession = (accessToken): void => {
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN);

  isValidToken = (accessToken): boolean => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = (): boolean => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
