interface MontageTypeOption {
  id: string;
  name: string;
}

export const MONTAGE_TYPE_OPTIONS: Array<MontageTypeOption> = [
  {
    id: 'video',
    name: 'Video',
  },
  {
    id: 'audio',
    name: 'Audio',
  },
];

export const getDefaultTypeOption = (): MontageTypeOption => MONTAGE_TYPE_OPTIONS[0];
