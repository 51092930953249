import React from 'react';
import { SelectionState } from 'draft-js';
import {
  EMPTY_STRING, addHoursToDate,
  addMinutesToDate,
  addSecondsToDate, subtractHoursFromDate,
  subtractMonthsFromDate,
  subtractYearsFromDate,
} from 'mediascouting-core-ui-common';
import moment from 'moment';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createSuggestion } from '../SuggestionServiceUtils';
import {
  EntityRange,
  FilterEntityData,
  FilterOptionEntityData,
  SubstituteRequest,
} from '../../../../../../../types/layout/topBar/search/editor';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';
import { mergeSelection } from '../../../../../../../utils/draft-js/SelectionUtils';
import Filter from '../../../QueryEditor/Filter';

const formatDate = (date: Date | string): string => moment(date).format('MMMM Do YYYY, h:mm:ss a');

const getReference = (
  recognizedType: EntityTypesEnum, usingType: EntityTypesEnum, ref: string,
): string => {
  if (recognizedType === EntityTypesEnum.START && usingType === EntityTypesEnum.START) {
    return ref;
  }

  return Date.now().toString();
};

const createDateRangeSuggestion = (
  entityRange: EntityRange,
  existingSuggestions: Array<Suggestion<EntityTypesEnum>>,
  startDate: Date,
  endDate: Date,
  label: React.ReactNode,
): Array<Suggestion<EntityTypesEnum>> => {
  const entityType = entityRange.entity.getType() as EntityTypesEnum;
  const data = entityRange.entity.getData() as FilterEntityData;
  const startDatePreview = formatDate(startDate);
  const endDatePreview = formatDate(endDate);
  const requestedText = `start: (${startDatePreview}) AND end: (${endDatePreview})`;
  const selectionState = SelectionState.createEmpty(entityRange.blockKey);
  const selectedRange = mergeSelection(selectionState, entityRange.start, entityRange.start + (data.length || 0));
  const preview = label;
  const startRef = getReference(entityType, EntityTypesEnum.START, data?.reference || EMPTY_STRING);
  const endRef = getReference(entityType, EntityTypesEnum.END, data?.reference || EMPTY_STRING);

  const startFilterEntityData: FilterEntityData = {
    length: `start: (${startDatePreview})`.length,
    reference: startRef,
  };

  const endFilterEntityData: FilterEntityData = {
    length: `end: (${endDatePreview})`.length,
    reference: endRef,
  };

  const startDateFilterOptionEntityData: FilterOptionEntityData = {
    filterType: entityType,
    parentReference: startRef.toString(),
    option: {
      id: startDate.getTime(),
      name: startDatePreview,
    },
  };

  const endDateFilterOptionEntityData: FilterOptionEntityData = {
    filterType: entityType,
    parentReference: endRef.toString(),
    option: {
      id: endDate.getTime(),
      name: endDatePreview,
    },
  };

  const request: SubstituteRequest<EntityTypesEnum> = {
    substituteText: requestedText,
    range: selectedRange,
    reference: entityRange,
    cursorPositionWithinText: requestedText.length,
    entityMetadata: [
      {
        type: EntityTypesEnum.START,
        start: EMPTY_STRING.length,
        end: EntityTypesEnum.START.length,
        data: startFilterEntityData,
      },
      {
        type: EntityTypesEnum.FILTER_OPTION,
        start: 'start: ('.length,
        end: `start: (${startDatePreview}`.length,
        data: startDateFilterOptionEntityData,
      },
      {
        type: EntityTypesEnum.AND_CONDITION_MATCHER,
        start: `start: (${startDatePreview})`.length,
        end: `start: (${startDatePreview}) AND`.length,
      },
      {
        type: EntityTypesEnum.END,
        start: `start: (${startDatePreview}) AND `.length,
        end: `start: (${startDatePreview}) AND end`.length,
        data: endFilterEntityData,
      },
      {
        type: EntityTypesEnum.FILTER_OPTION,
        start: `start: (${startDatePreview}) AND end: (`.length,
        end: `start: (${startDatePreview}) AND end: (${endDatePreview}`.length,
        data: endDateFilterOptionEntityData,
      },
    ],
  };

  return [createSuggestion(preview, request), ...existingSuggestions];
};

const createDateFilterSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  const dateInfo = new Date();
  const dateOfToday = new Date(
    dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), 0, 0, 0,
  );
  const dateOfMonthAgo = subtractMonthsFromDate(dateOfToday, 1);
  const dateOfYearAgo = subtractYearsFromDate(dateOfToday, 1);

  const lastHourRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    [],
    subtractHoursFromDate(dateInfo, 1),
    dateInfo,
      <>
          Set
          {' '}
          <Filter type={EntityTypesEnum.START}>Start</Filter>
          {' '}
          and
          {' '}
          <Filter type={EntityTypesEnum.END}>End</Filter>
          {' '}
          dates to match last hour&apos;s results
      </>,
  );

  const oneDayRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    lastHourRangeSuggestion,
    dateOfToday,
    addSecondsToDate(addMinutesToDate(addHoursToDate(dateOfToday, 23), 59), 59),
      <>
          Set
          {' '}
          <Filter type={EntityTypesEnum.START}>Start</Filter>
          {' '}
          and
          {' '}
          <Filter type={EntityTypesEnum.END}>End</Filter>
          {' '}
          dates to match Today&apos;s results
      </>,
  );

  const oneMonthRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    oneDayRangeSuggestion,
    dateOfMonthAgo,
    addSecondsToDate(addMinutesToDate(addHoursToDate(dateOfToday, 23), 59), 59),
      <>
          Set
          {' '}
          <Filter type={EntityTypesEnum.START}>Start</Filter>
          {' '}
          and
          {' '}
          <Filter type={EntityTypesEnum.END}>End</Filter>
          {' '}
          dates to match a Month&apos;s results
      </>,
  );

  const oneYearRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    oneMonthRangeSuggestion,
    dateOfYearAgo,
    addSecondsToDate(addMinutesToDate(addHoursToDate(dateOfToday, 23), 59), 59),
      <>
          Set
          {' '}
          <Filter type={EntityTypesEnum.START}>Start</Filter>
          {' '}
          and
          {' '}
          <Filter type={EntityTypesEnum.END}>End</Filter>
          {' '}
          dates to match a Year&apos;s results
      </>,
  );

  return oneYearRangeSuggestion.reverse();
};

export default createDateFilterSuggestions;
