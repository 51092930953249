import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ReduxState } from '../../../redux/reducers';

type GuestGuardPropTypes = {
  children: React.ReactNode;
};

function GuestGuard(props: GuestGuardPropTypes): React.ReactNode {
  const { children } = props;
  const user = useSelector((state: ReduxState) => state.auth.user);

  if (user) {
    return <Redirect to="/" />;
  }

  return children;
}

export default GuestGuard;
