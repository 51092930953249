import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Dialog, makeStyles, TextField, Theme, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import DialogContent from '@material-ui/core/DialogContent';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { SharingInfo } from '../../../../types/query/Share';
import { SchemaDisabled } from '../../../../configuration/schema';

interface SharePropTypes {
    onShare: () => void;
    onClose: () => void;
    open: boolean;
    sharingInfo?: SharingInfo;
    configuration?: Partial<StorySharingConfiguration>;
}

export enum SharingOptionEnum {
    TWITTER = 'Twitter',
    WHATS_APP = 'WhatsApp',
    EMAIL = 'Email',
}

interface SharingOption {
    type: SharingOptionEnum;
    href: (text: string) => string;
    text: string;
    icon: React.ReactElement;
}

export interface StorySharingConfiguration extends SchemaDisabled<SharingOptionEnum>{
    disableExternalLinks?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0,
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.5),
  },
  parent: {
    position: 'relative',
  },
}));

function ShareDialog(props: SharePropTypes): JSX.Element {
  const {
    onClose, onShare, sharingInfo, open, configuration,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(EMPTY_STRING);
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const SHARING_OPTIONS: Array<SharingOption> = useMemo(() => [
    {
      text: `${t('share.option.via')} Twitter`,
      href: (text: string): string => `https://twitter.com/intent/tweet?text=${text}`,
      icon: <TwitterIcon />,
      type: SharingOptionEnum.TWITTER,
    },
    {
      text: `${t('share.option.via')} WhatsApp`,
      href: (text: string): string => `https://wa.me/?text=${text}`,
      icon: <WhatsAppIcon />,
      type: SharingOptionEnum.WHATS_APP,
    },
    {
      text: `${t('share.option.via')}  Email`,
      href: (text: string): string => `mailto:?subject=${text.substring(0, 15)}...&body=${text}`,
      icon: <EmailIcon />,
      type: SharingOptionEnum.EMAIL,
    },
  ], [t]);

  const handleChange = (event): void => {
    event.persist();

    setValue(event.target.value);
  };

  const handleShareClick = (): void => {
    onShare();
  };

  const getOptions = (): Array<SharingOption> => {
    if (configuration?.disabled && configuration?.disabled?.length > 0) {
      return SHARING_OPTIONS.filter((option) => !configuration?.disabled?.includes(option.type));
    }

    return SHARING_OPTIONS;
  };

  const handleSharingInfoUpdate = useCallback(() => {
    const text = `${sharingInfo?.text || EMPTY_STRING}`;
    setValue(text);
  }, [sharingInfo]);

  useEffect(() => {
    handleSharingInfoUpdate();
  }, [sharingInfo, handleSharingInfoUpdate]);

  return (
      <Dialog maxWidth="md" fullWidth fullScreen={fullScreen} onClose={onClose} open={open}>
          <DialogContent className={classes.parent}>
              {fullScreen
              && (
                  <Tooltip title={t('share.close') || EMPTY_STRING}>
                      <IconButton onClick={onClose} className={classes.fab}>
                          <CloseOutlinedIcon color="inherit" fontSize="inherit" />
                      </IconButton>
                  </Tooltip>
              )}
              <div className={classes.root}>
                  <Typography gutterBottom variant="h3" color="textPrimary">
                      {t('share.title')}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                      {t('share.subtitle')}
                  </Typography>
                  <Box mt={3}>
                      <TextField
                        autoFocus
                        FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        fullWidth
                        label={t('share.input')}
                        multiline
                        onChange={handleChange}
                        rows={10}
                        value={value}
                        variant="outlined"
                      />
                  </Box>
                  <Box mt={3} p={3}>
                      {
                          getOptions().map((sharingOption, index) => {
                            const syntheticKey = `${sharingOption?.type}${index}`;
                            return (
                                <Tooltip title={sharingOption.text} key={syntheticKey}>
                                    <IconButton
                                      onClick={handleShareClick}
                                      target="_blank"
                                      href={sharingOption.href(value)}
                                    >
                                        {sharingOption.icon}
                                    </IconButton>
                                </Tooltip>
                            );
                          })
                      }
                  </Box>
              </div>
          </DialogContent>
      </Dialog>
  );
}

export default memo(ShareDialog);
