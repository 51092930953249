import { PageableProperties, ShallowStory } from 'mediascouting-core-ui-common';
import {
  CLEAR_SELECTION,
  RESET_QUERY_RESULTS,
  SET_FACET_GROUPS,
  SET_PAGINATION_INFO,
  SET_QUERY_RESULTS,
  SET_SELECTION,
} from '../types/QueryResultsActionTypes';
import { SelectTarget } from '../../../types/query/Selector';
import { FacetGroup } from '../../../types/query/results/Facets';

export const setPaginationInfo = (pagination: PageableProperties) => ({
  type: SET_PAGINATION_INFO,
  pagination,
} as const);

export const setQueryResults = (stories: Array<ShallowStory>) => ({
  type: SET_QUERY_RESULTS,
  stories,
} as const);

export const setSelection = (selection: SelectTarget) => ({
  type: SET_SELECTION,
  selection,
} as const);

export const clearAllSelection = () => ({
  type: CLEAR_SELECTION,
} as const);

export const setFacetGroups = (groups: Array<FacetGroup>) => ({
  type: SET_FACET_GROUPS,
  groups,
} as const);

export const resetQueryResults = (maintainFacets?: boolean) => ({
  type: RESET_QUERY_RESULTS,
  payload: maintainFacets,
} as const);

export type QueryResultsActions =
    | ReturnType<typeof setPaginationInfo>
    | ReturnType<typeof setQueryResults>
    | ReturnType<typeof setSelection>
    | ReturnType<typeof clearAllSelection>
    | ReturnType<typeof setFacetGroups>
    | ReturnType<typeof resetQueryResults>
