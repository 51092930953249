import React, { memo } from 'react';
import { Avatar, Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { NeoSearchEntityTypeEnum } from '../../../../types/NeoSearch';
import useNeoBarColors from '../../../../hooks/useNeoBarColors';
import { getNeoSearchTypeIcon } from '../../utils/NeoSearchSuggestionServiceUtils';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';
import NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER from '../../../../constants/NeoSearchEntityTypeTranslationMapper';

interface NeoSearchTypeSuggestionPropTypes {
  type: NeoSearchEntityTypeEnum;
  onDelete?: VoidFunction;
  onClick?: (type: NeoSearchEntityTypeEnum) => void;
}

const useStyles = makeStyles(() => ({
  white: {
    color: '#fff',
  },
  chip: {
    borderRadius: '4px',
  },
}));

function NeoSearchTypeSuggestion(props: NeoSearchTypeSuggestionPropTypes): JSX.Element {
  const {
    type, onDelete, onClick,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);

  const { getNeoBarNavigationItemTypeColor } = useNeoBarColors({});

  const getAvatar = (): React.ReactElement => {
    const colorClassName = getNeoBarNavigationItemTypeColor(type);

    return (
        <Avatar
          aria-label="Query Type"
          className={clsx(colorClassName)}
        >
            {getNeoSearchTypeIcon(type, 'inherit', classes.white)}
        </Avatar>
    );
  };

  const handleClick = (): void => {
    if (onClick) {
      onClick(type);
    }
  };

  const getLabel = (): string => {
    const i18Key = NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER[type];

    if (i18Key) {
      return t(i18Key);
    }

    return EMPTY_STRING;
  };

  return (
      <Chip
        size="medium"
        variant="outlined"
        label={getLabel()}
        avatar={getAvatar()}
        deleteIcon={<DoneIcon />}
        className={classes.chip}
        onDelete={onDelete}
        onClick={handleClick}
        clickable
      />
  );
}

export default memo(NeoSearchTypeSuggestion);
