/* eslint-disable space-infix-ops,space-unary-ops,no-plusplus,no-await-in-loop */
import queryString from 'query-string';
import {
  addSecondsToDate,
  createInitialPowerSearchQueryFiltersState,
  EMPTY_STRING,
  getDateInUTCByAddingZ,
  getStringAsArray,
  MultivaluedPowerSearchQueryFilters,
  NOT_FOUND,
  NotFound,
  PowerSearchFilterTypes,
  QueryDTO,
  Shallow,
  SupportedRemoteDataRetrieverType,
} from 'mediascouting-core-ui-common';
import { getDefaultSort, getStorySortingOptions } from '../../constants/SortOptions';
import MULTIVALUED_QUERY_FILTER_URL_KEYS, { UrlQueryOption } from '../../constants/QueryOptionKeys';
import { parseFacetsFromUrl } from './FacetUtils';
import { QuerySearchSpaceReducer } from '../../redux/reducers/QuerySearchSpace';
import DEFAULT_SEARCH_PAGE_NUMBER from '../../constants/search/QuerySearchSpace';
import { getDefaultDateSlug, getStoryDateSlugOptions } from '../../constants/DateSlugOptions';

type MatchedOptions = {
    recoveredOptions: Array<Shallow>;
    nonRecoveredIdentities: Array<string>;
};

export const QueryOptionFilterToSearchEntityMapper = {
  tags: PowerSearchFilterTypes.TAGS,
  subQueries: PowerSearchFilterTypes.SUBQUERIES,
  sources: PowerSearchFilterTypes.SOURCES,
  sourceGroups: PowerSearchFilterTypes.SOURCE_GROUPS,
  sourceTypes: PowerSearchFilterTypes.SOURCE_TYPES,
  markets: PowerSearchFilterTypes.MARKETS,
  authors: PowerSearchFilterTypes.AUTHORS,
  marketGroups: PowerSearchFilterTypes.MARKET_GROUPS,
  languages: PowerSearchFilterTypes.LANGUAGES,
  geolocationTags: PowerSearchFilterTypes.GEOLOCATION_TAGS,
  persons: PowerSearchFilterTypes.PEOPLE,
  organizations: PowerSearchFilterTypes.ORGANIZATIONS,
  origins: PowerSearchFilterTypes.ORIGIN,
};

const matchOptions = (options: Array<Shallow> | NotFound, ids: Array<string>): MatchedOptions => {
  try {
    const recoveredOptions: Array<Shallow> = [];
    const nonRecoveredIdentities: Array<string> = [];

    if (options) {
      ids.forEach((missingOptionIdentity) => {
        const found = options.find((searchableOption) => searchableOption.id.toString() === missingOptionIdentity);

        if (found) {
          recoveredOptions.push(found);
        } else {
          nonRecoveredIdentities.push(missingOptionIdentity);
        }
      });
    } else {
      nonRecoveredIdentities.push(...ids);
    }

    return {
      recoveredOptions,
      nonRecoveredIdentities,
    };
  } catch {
    return {
      recoveredOptions: [],
      nonRecoveredIdentities: ids,
    };
  }
};

export const decodeUrlParams = (urlParams: queryString.ParsedQuery<string>): QuerySearchSpaceReducer => {
  const querySearchSpace: QuerySearchSpaceReducer = {
    query: NOT_FOUND,
    searchText: EMPTY_STRING,
    facets: [],
    page: 1,
    pageSize: DEFAULT_SEARCH_PAGE_NUMBER,
    sort: getDefaultSort(),
    dateSlug: getDefaultDateSlug(),
    previewNewItems: false,
    ...createInitialPowerSearchQueryFiltersState(),
  };

  if (urlParams.page) {
    querySearchSpace.page = parseInt(urlParams.page as string, 10);
  }

  if (urlParams.size) {
    querySearchSpace.pageSize = parseInt(urlParams.size as string, 10);
  }

  if (urlParams.sort) {
    const found = getStorySortingOptions()
      .find((searchableSortingOption) => searchableSortingOption.value === urlParams.sort);

    if (found) {
      querySearchSpace.sort = found;
    }
  }

  if (urlParams.dateSlug) {
    const found = getStoryDateSlugOptions()
      .find((searchable) => searchable.value === urlParams.dateSlug);

    if (found) {
      querySearchSpace.dateSlug = found;
    }
  }

  if (urlParams.t) {
    querySearchSpace.searchText = urlParams.t as string;
  }

  if (urlParams.startDate) {
    const unixTimestamp = urlParams.startDate as string;
    const optionalDate = new Date(parseInt(unixTimestamp, 10));

    if (!Number.isNaN(optionalDate.getTime())) {
      querySearchSpace[PowerSearchFilterTypes.START] = optionalDate.toString();
    }
  }

  if (urlParams.endDate) {
    const unixTimestamp = urlParams.endDate as string;
    const optionalDate = new Date(parseInt(unixTimestamp, 10));

    if (!Number.isNaN(optionalDate.getTime())) {
      querySearchSpace[PowerSearchFilterTypes.END] = optionalDate.toString();
    }
  }

  if (urlParams.startTimeOfDay) {
    const millisecondsInDay = urlParams.startTimeOfDay as string;
    const currentDate = new Date();
    const initDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const parsedMilliseconds = parseInt(millisecondsInDay, 10);
    const addedSecondsToDate = addSecondsToDate(initDate, parsedMilliseconds / 1000);
    const optionalDate = getDateInUTCByAddingZ(addedSecondsToDate.toString());

    if (!Number.isNaN(optionalDate.getTime())) {
      querySearchSpace[PowerSearchFilterTypes.START_TIME] = optionalDate.toString();
    }
  }

  if (urlParams.endTimeOfDay) {
    const millisecondsInDay = urlParams.endTimeOfDay as string;
    const currentDate = new Date();
    const initDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const parsedMilliseconds = parseInt(millisecondsInDay, 10);
    const addedSecondsToDate = addSecondsToDate(initDate, parsedMilliseconds / 1000);
    const optionalDate = getDateInUTCByAddingZ(addedSecondsToDate.toString());

    if (!Number.isNaN(optionalDate.getTime())) {
      querySearchSpace[PowerSearchFilterTypes.END_TIME] = optionalDate.toString();
    }
  }

  if (urlParams.title) {
    querySearchSpace[PowerSearchFilterTypes.TITLE] = urlParams.title as string;
  }

  if (urlParams.programs) {
    querySearchSpace[PowerSearchFilterTypes.PROGRAMS] = getStringAsArray(urlParams.programs as string);
  }

  if (urlParams.previewNewItems) {
    querySearchSpace.previewNewItems = urlParams.previewNewItems === 'true';
  }

  return querySearchSpace;
};

const recoverFilterOptionsByAskingForThem = (
  querySearchSpace: QuerySearchSpaceReducer,
  urlQueryOption: UrlQueryOption,
  urlIds: Array<string>,
  onFetchMissingData: (
        type: PowerSearchFilterTypes, id: number
    ) => Promise<SupportedRemoteDataRetrieverType | NotFound>,
): Promise<QuerySearchSpaceReducer> => {
  const alteredQuerySearchSpace = { ...querySearchSpace };
  const reference = QueryOptionFilterToSearchEntityMapper[urlQueryOption.name];

  if (reference) {
    const retrievedData: Array<Promise<SupportedRemoteDataRetrieverType | NotFound>> = [];

    for (let index = urlIds.length - 1; index >= 0; index--) {
      const id = parseInt(urlIds[index], 10);

      retrievedData.push(onFetchMissingData(reference, id));
    }

    return Promise.all(retrievedData)
      .then((optionalFilteringOptions) => {
        const retrievedOptions: Array<SupportedRemoteDataRetrieverType> = [];

        optionalFilteringOptions.forEach((option) => {
          if (option) {
            retrievedOptions.push(option);
          }
        });

        const previousOptions = alteredQuerySearchSpace[urlQueryOption.powerSearchFilterType.toString()];

        alteredQuerySearchSpace[urlQueryOption.powerSearchFilterType.toString()] = [
          ...previousOptions,
          ...retrievedOptions,
        ];

        return alteredQuerySearchSpace;
      })
      .catch(() => Promise.resolve(alteredQuerySearchSpace));
  }

  return Promise.resolve(alteredQuerySearchSpace);
};

const handleAsyncQuerySearchFilter = async (
  querySearchSpace: QuerySearchSpaceReducer,
  urlQueryOption: UrlQueryOption,
  urlIds: Array<string>,
  onFetchMissingData: (
        type: PowerSearchFilterTypes, id: number
    ) => Promise<SupportedRemoteDataRetrieverType | NotFound>,
): Promise<QuerySearchSpaceReducer> => {
  try {
    return recoverFilterOptionsByAskingForThem(querySearchSpace, urlQueryOption, urlIds, onFetchMissingData);
  } catch {
    return querySearchSpace;
  }
};

const handleFilterRecovery = async (
  querySearchSpace: QuerySearchSpaceReducer,
  urlQueryOption: UrlQueryOption,
  powerSearchOptions: MultivaluedPowerSearchQueryFilters,
  urlIds: Array<string>,
  onFetchMissingData: (
        type: PowerSearchFilterTypes, id: number
    ) => Promise<SupportedRemoteDataRetrieverType | NotFound>,
): Promise<QuerySearchSpaceReducer> => {
  const alteredQuerySearchSpace = { ...querySearchSpace };
  const previousOptions = alteredQuerySearchSpace[urlQueryOption.powerSearchFilterType.toString()];
  const options = powerSearchOptions[urlQueryOption.powerSearchFilterType];
  const { recoveredOptions, nonRecoveredIdentities } = matchOptions(options, urlIds);

  alteredQuerySearchSpace[urlQueryOption.powerSearchFilterType.toString()] = [
    ...previousOptions,
    ...recoveredOptions,
  ];

  if (nonRecoveredIdentities.length === 0) {
    return alteredQuerySearchSpace;
  }

  return handleAsyncQuerySearchFilter(
    alteredQuerySearchSpace, urlQueryOption, nonRecoveredIdentities, onFetchMissingData,
  );
};

const handleStoryDurationExistence = (
  storyDuration: string,
  powerSearchOptions: MultivaluedPowerSearchQueryFilters,
  querySearchSpace: QuerySearchSpaceReducer,
): QuerySearchSpaceReducer => {
  const alteredQuerySearchSpace: QuerySearchSpaceReducer = { ...querySearchSpace };

  const id = parseInt(storyDuration, 10);
  const found = powerSearchOptions[PowerSearchFilterTypes.STORY_DURATION]
    .find((searchableOption) => searchableOption.id === id);

  if (found) {
    if (querySearchSpace.previewNewItems && !found.availableForLiveQueries) {
      const firstOption = powerSearchOptions[PowerSearchFilterTypes.STORY_DURATION][0];
      if (found.id === 4 && firstOption) {
        alteredQuerySearchSpace[PowerSearchFilterTypes.STORY_DURATION] = firstOption;
      }
      return alteredQuerySearchSpace;
    }

    alteredQuerySearchSpace[PowerSearchFilterTypes.STORY_DURATION] = found;
  }
  return alteredQuerySearchSpace;
};

const handleStoryDurationNonExistence = (
  powerSearchOptions: MultivaluedPowerSearchQueryFilters,
  querySearchSpace: QuerySearchSpaceReducer,
): QuerySearchSpaceReducer => {
  const alteredQuerySearchSpace: QuerySearchSpaceReducer = { ...querySearchSpace };

  if (querySearchSpace[PowerSearchFilterTypes.STORY_DURATION] === NOT_FOUND) {
    const foundOptions = powerSearchOptions[
      PowerSearchFilterTypes.STORY_DURATION]
      .filter((option) => !querySearchSpace.previewNewItems && option.availableForLiveQueries);
    const firstAvailableOption = foundOptions[0];
    if (foundOptions.length > 0 && firstAvailableOption) {
      alteredQuerySearchSpace[PowerSearchFilterTypes.STORY_DURATION] = firstAvailableOption;
    }
  }
  return alteredQuerySearchSpace;
};

const updateStoryDurationFilter = (
  querySearchSpace: QuerySearchSpaceReducer,
  urlParams: queryString.ParsedQuery<string>,
  powerSearchOptions: MultivaluedPowerSearchQueryFilters,
): QuerySearchSpaceReducer => {
  try {
    if (urlParams.storyDuration !== NOT_FOUND) {
      return handleStoryDurationExistence(
          urlParams.storyDuration as string,
          powerSearchOptions,
          querySearchSpace,
      );
    }
    if (urlParams.storyDuration === NOT_FOUND) {
      return handleStoryDurationNonExistence(
        powerSearchOptions,
        querySearchSpace,
      );
    }
  } catch {
    return querySearchSpace;
  }

  return querySearchSpace;
};

const updateQuerySearchSpaceFilters = async (
  querySearchSpace: QuerySearchSpaceReducer, urlParams: queryString.ParsedQuery<string>,
  powerSearchOptions: MultivaluedPowerSearchQueryFilters,
  onFetchMissingData: (
        type: PowerSearchFilterTypes, id: number
    ) => Promise<SupportedRemoteDataRetrieverType | NotFound>,
): Promise<QuerySearchSpaceReducer> => {
  let alteredQuerySearchSpace = updateStoryDurationFilter(querySearchSpace, urlParams, powerSearchOptions);

  try {
    for (let index = MULTIVALUED_QUERY_FILTER_URL_KEYS.length - 1; index >= 0; index--) {
      const urlQueryOption = MULTIVALUED_QUERY_FILTER_URL_KEYS[index];
      const urlParam = urlParams[urlQueryOption.name] as string | NotFound;

      if (urlParam) {
        const urlParamIdentities = getStringAsArray(urlParam);

        alteredQuerySearchSpace = await handleFilterRecovery(
          alteredQuerySearchSpace, urlQueryOption, powerSearchOptions, urlParamIdentities, onFetchMissingData,
        );
      }
    }

    return Promise.resolve(alteredQuerySearchSpace);
  } catch (e) {
    return Promise.resolve(querySearchSpace);
  }
};

export const updateQuerySearchSpaceFacets = (
  querySearchSpace: QuerySearchSpaceReducer, urlParams: queryString.ParsedQuery<string>,
): QuerySearchSpaceReducer => {
  if (urlParams.f) {
    const alteredQuerySearchSpace = querySearchSpace;
    const { f: facetParams } = urlParams;

    alteredQuerySearchSpace.facets = parseFacetsFromUrl(facetParams as string);

    return alteredQuerySearchSpace;
  }

  return querySearchSpace;
};

const createQuerySearchSpace = async (
  urlParams: queryString.ParsedQuery<string>,
  multivaluedPowerSearchOptions: MultivaluedPowerSearchQueryFilters,
  onFetchMissingData: (
        type: PowerSearchFilterTypes, id: number
    ) => Promise<SupportedRemoteDataRetrieverType | NotFound>,
  onFetchQuery: (id: number, prevQueryId?: number) => Promise<QueryDTO>,
  prevUrlParams: queryString.ParsedQuery<string>,
): Promise<QuerySearchSpaceReducer> => {
  const querySearchSpace = decodeUrlParams(urlParams);

  if (urlParams.qId) {
    const id = urlParams.qId.toString();
    const parsedId = parseInt(id, 10);
    const prevQueryId = prevUrlParams?.qId?.toString();
    const prevParsedId = parseInt(prevQueryId || '0', 10);

    if (!Number.isNaN(parsedId)) {
      querySearchSpace.query = await onFetchQuery(parsedId, prevParsedId);
    }
  }

  const updatedFacetsQuerySearchSpace = updateQuerySearchSpaceFacets(querySearchSpace, urlParams);

  return updateQuerySearchSpaceFilters(
    updatedFacetsQuerySearchSpace, urlParams, multivaluedPowerSearchOptions, onFetchMissingData,
  );
};

export default createQuerySearchSpace;
