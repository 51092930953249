import {
  Box, Fade, Grid, makeStyles, Typography,
} from '@material-ui/core';
import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { extractMultiValuedFilterOptions, ParsingError, User } from 'mediascouting-core-ui-common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EntityRange } from '../../../../../types/layout/topBar/search/editor';
import { EntityTypesEnum, MULTIPLE_VALUE_FILTERS } from '../../../../../constants/search/editor/EntityTypes';
import { ReduxState } from '../../../../../redux/reducers';

type AssistancePropTypes = {
    queryText: string;
    loading: boolean;
    error?: ParsingError;
    entityRange?: EntityRange;
    assistantOptions?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
  },
  correct: {
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
  incorrect: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
  assistant: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    float: 'left',
  },
}));

function Assistant(props: AssistancePropTypes): JSX.Element {
  const classes = useStyles();
  const {
    error, loading, entityRange, queryText, assistantOptions,
  } = props;
  const { t } = useTranslation();
  const user = useSelector((reduxState: ReduxState) => reduxState.auth.user);

  const getPromo = useCallback((innerUser?: User): string => {
    const date = new Date();
    const hours = date.getHours();

    if (hours < 12) {
      return `${t('layout.topBar.search.searchBar.assistant.greeting.morning')} ${innerUser?.fullName}`;
    }

    if (hours < 18) {
      return `${t('layout.topBar.search.searchBar.assistant.greeting.afternoon')} ${innerUser?.fullName}`;
    }

    return `${t('layout.topBar.search.searchBar.assistant.greeting.evening')} ${innerUser?.fullName}`;
  }, [t]);

  const [feedbackText, setFeedbackText] = useState(getPromo(user));

  const handleFeedback = useCallback((): void => {
    if (loading) {
      return setFeedbackText(t('layout.topBar.search.searchBar.assistant.inspecting'));
    }

    if (error) {
      return setFeedbackText(t('layout.topBar.search.searchBar.assistant.incomplete'));
    }

    if (entityRange) {
      const entityType = entityRange.entity.getType() as EntityTypesEnum;

      if (MULTIPLE_VALUE_FILTERS.includes(entityType)
              && extractMultiValuedFilterOptions(entityRange.text).length === 0) {
        return setFeedbackText(`${t('layout.topBar.search.searchBar.assistant.filter.search')} ${entityType}`);
      }
    }

    if (queryText.length > 0) {
      return setFeedbackText(t('layout.topBar.search.searchBar.assistant.valid.text'));
    }

    return setFeedbackText(getPromo(user));
  }, [loading, error, entityRange, queryText.length, getPromo, user, t]);

  useEffect(() => {
    handleFeedback();
  }, [handleFeedback]);

  return (
      <Fade in={feedbackText.length > 0} appear timeout={1000}>
          <Grid container className={classes.root}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Box className={classes.assistant}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={clsx(
                          { [classes.correct]: !error },
                          { [classes.incorrect]: error },
                        )}
                      >
                          {feedbackText}
                      </Typography>
                  </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {assistantOptions && assistantOptions}
              </Grid>
          </Grid>
      </Fade>
  );
}

export default memo(Assistant);
