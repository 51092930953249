import { COMMA, EMPTY_STRING } from 'mediascouting-core-ui-common';

export const isFloat = (n: number | string): boolean => Number(n) === n && n % 1 !== 0;

export const trimBigNumber = (num: number): string => {
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(1).replace(/\.0$/, EMPTY_STRING)}B`;
  }
  if (num >= 1e6) {
    return `${(num / 1e6).toFixed(1).replace(/\.0$/, EMPTY_STRING)}M`;
  }
  if (num >= 1e3) {
    return `${(num / 1e3).toFixed(1).replace(/\.0$/, EMPTY_STRING)}K`;
  }
  return num.toString();
};

export const separateThousands = (value: number | string): string => {
  if (isFloat(value)) {
    const parsedFloat = parseFloat(value as string);
    if (!Number.isNaN(parsedFloat)) {
      return parsedFloat.toFixed(1);
    }
    return value as string;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, COMMA);
};
