import React, { memo, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import {
  EMPTY_STRING,
  MontageItem,
  MontageOutputOptionDTO,
  MontageQualityOptionDTO,
  getDefaultQualityOption,
  MontageQualityOption,
  MontageFormatOption,
  getDefaultFormatOption,
} from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { getDefaultTypeOption } from '../../../../../constants/montage/TypeOptions';
import { getDefaultDeliveryOption } from '../../../../../constants/montage/DeliveryOptions';
import { getDefaultPositionOption } from '../../../../../constants/montage/PositionOptions';
import MainOptions from './MainOptions';
import TranslationNameSpaceEnum from '../../../../../types/translation/TranslationNameSpaceEnum';

type MontageFormFieldsPropTypes = {
  montageItem?: MontageItem;
  montageQualityOptions: Array<MontageQualityOption>;
  montageFormatOptions: Array<MontageFormatOption>;
  disabled?: boolean;
  onCreate?: (fieldValues: MontageSettingsFormFields) => Promise<void>;
};

export interface MontageSettingsFormFields {
  fileName: string;
  type: string;
  format: MontageOutputOptionDTO | string;
  quality: MontageQualityOptionDTO | string;
  blackSilenceTransition?: number;
  overlayImage?: string;
  overlayPosition?: string;
  overlayPadding?: number;
  overlayOpacity?: number;
  ftp?: boolean;
  reportingPlatform?: boolean;
  delivery: string;
}

function Settings(props: MontageFormFieldsPropTypes): JSX.Element {
  const {
    onCreate, montageItem, montageQualityOptions, montageFormatOptions, disabled,
  } = props;
  const { t } = useTranslation([TranslationNameSpaceEnum.MONTAGES, TranslationNameSpaceEnum.DEFAULT]);

  const initialFormValues: MontageSettingsFormFields = useMemo(() => (
    {
      fileName: montageItem?.name || EMPTY_STRING,
      type: getDefaultTypeOption().id,
      format: montageItem?.outputOption || getDefaultFormatOption().id,
      quality: montageItem?.qualityOption || montageQualityOptions[0]?.id || getDefaultQualityOption(montageQualityOptions).id,
      overlayPosition: getDefaultPositionOption().id,
      delivery: getDefaultDeliveryOption().id,
      blackSilenceTransition: 0,
      overlayImage: EMPTY_STRING,
      overlayPadding: 0,
      overlayOpacity: 1,
      ftp: false,
      reportingPlatform: false,
    }), [montageItem, montageQualityOptions]);

  const validationSchema = Yup.object().shape({
    fileName: Yup
      .string()
      .required(
        t(`${TranslationNameSpaceEnum.DEFAULT}:form.fields.required`,
          { field: t('item.form.filename') }),
      ),
    type: Yup.string(),
    format: Yup.string(),
    quality: Yup.string(),
    blackSilenceTransition: Yup.number(),
    overlayImage: Yup.string(),
    overlayPosition: Yup.string(),
    overlayPadding: Yup.number(),
    overlayOpacity: Yup.number()
      .min(0, t(
        `${TranslationNameSpaceEnum.DEFAULT}:form.fields.greaterThan`,
        {
          field: t('item.form.opacity'),
          value: 0,
        },
      ))
      .max(1, t(
        `${TranslationNameSpaceEnum.DEFAULT}:form.fields.lessThan`,
        {
          field: t('item.form.opacity'),
          value: 1,
        },
      )),
    ftp: Yup.boolean(),
    reportingPlatform: Yup.boolean(),
    delivery: Yup.string(),
  });

  const onSubmit = async (values: MontageSettingsFormFields, {
    resetForm, setErrors, setStatus, setSubmitting,
  }): Promise<void> => {
    if (onCreate) {
      try {
        onCreate(values)
          .then(() => {
            setStatus({ success: true });
            setSubmitting(false);
            resetForm();
          })
          .catch(() => {
            setStatus({ success: false });
            setSubmitting(false);
          });
      } catch (err) {
        setErrors({ submit: err.message });
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  };

  return (
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
          {(formikProps): JSX.Element => (
              <form id="montage-settings-form" onSubmit={formikProps.handleSubmit}>
                  <Box>
                      <MainOptions
                        formikProps={formikProps}
                        montageQualityOptions={montageQualityOptions}
                        montageFormatOptions={montageFormatOptions}
                        disabled={disabled}
                      />
                  </Box>
                  {/* <Box mt={1}> */}
                  {/*    <OverlayOptions */}
                  {/*      formikProps={formikProps} */}
                  {/*      disabled={disabled} */}
                  {/*    /> */}
                  {/* </Box> */}
                  {/* <Box mt={1}> */}
                  {/*    <SharingOptions */}
                  {/*      formikProps={formikProps} */}
                  {/*      disabled={disabled} */}
                  {/*    /> */}
                  {/* </Box> */}
                  {/* <Box mt={1}> */}
                  {/*    <DeliveryOptions */}
                  {/*      formikProps={formikProps} */}
                  {/*      disabled={disabled} */}
                  {/*    /> */}
                  {/* </Box> */}
                  {/* <Box mt={2}> */}
                  {/*    {!formikProps.isSubmitting && onCreate */}
                  {/*  && <CreateButton />} */}
                  {/* </Box> */}
              </form>
          )}
      </Formik>
  );
}

export default memo(Settings);
