import React, { memo } from 'react';
import {
  DraftHandleValue, Editor, EditorState, getDefaultKeyBinding, Modifier, RichUtils,
} from 'draft-js';
import { Typography } from '@material-ui/core';
import { COLON } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { HANDLED, NOT_HANDLED } from '../../../../../constants/draft-js/DraftCommands';
import STYLE_MAP from '../../../../../constants/search/editor/InlineStyling';
import { mergeSelection } from '../../../../../utils/draft-js/SelectionUtils';
import { FocusState } from '../../../../../types/layout/topBar/search/suggestion';
import {
  ADD_NEW_LINE,
  COMMAND_KEY_MAPPER,
  FOCUS_STATE_COMMANDS,
  KEY_COMMAND_MAPPER,
} from '../../../../../constants/search/editor/KeyBinding';
import { ENTER_KEY_CODE } from '../../../../../constants/KeyPropertyValues';

type IntelligentQuerySearchBarPropTypes = {
  editorState: EditorState;
  focusState: FocusState;
  onChangeEditorState: (updatedEditorState: EditorState) => void;
  onKeyAction: (key: number) => void;
};

function QueryEditor(props: IntelligentQuerySearchBarPropTypes): JSX.Element {
  const {
    editorState, focusState, onChangeEditorState, onKeyAction,
  } = props;
  const { t } = useTranslation();
  const addFilterAutocompletionCharacters = (chars: string): string => {
    if (chars === COLON) {
      return `${chars} ()`;
    }

    return chars;
  };

  const updateSelectionToMatchAutocompletionCharacters = (updatedEditorState: EditorState): EditorState => {
    const selectionState = updatedEditorState.getSelection();
    const anchorOffset = selectionState.getAnchorOffset();
    const focusOffset = selectionState.getFocusOffset();
    const mergedSelection = mergeSelection(selectionState, anchorOffset - 1, focusOffset - 1);

    return EditorState.forceSelection(updatedEditorState, mergedSelection);
  };

  const handleBeforeInput = (chars: string, currentEditorState: EditorState): DraftHandleValue => {
    const contentState = currentEditorState.getCurrentContent();
    const selectionState = currentEditorState.getSelection();
    const updatedChars = addFilterAutocompletionCharacters(chars);
    const updatedContent = Modifier.replaceText(contentState, selectionState, updatedChars);
    const updatedEditorState = EditorState.push(currentEditorState, updatedContent, 'insert-characters');

    if (chars !== updatedChars) {
      const updatedEditorSelection = updateSelectionToMatchAutocompletionCharacters(updatedEditorState);

      onChangeEditorState(updatedEditorSelection);

      return HANDLED;
    }

    onChangeEditorState(updatedEditorState);

    return HANDLED;
  };

  const handleLineAddition = (latestEditorState: EditorState): DraftHandleValue => {
    const selectionState = latestEditorState.getSelection();

    if (selectionState.isCollapsed()) {
      const updatedEditorState = RichUtils.insertSoftNewline(latestEditorState);

      onChangeEditorState(updatedEditorState);

      return HANDLED;
    }

    return NOT_HANDLED;
  };

  const handleKeyCommand = (command: string, latestEditorState: EditorState): DraftHandleValue => {
    const key = COMMAND_KEY_MAPPER[command];

    if (command === ADD_NEW_LINE) {
      return handleLineAddition(latestEditorState);
    }

    if (key) {
      onKeyAction(key);

      return HANDLED;
    }

    return NOT_HANDLED;
  };

  const handleKeyBinding = (e): string | null => {
    if (e.keyCode === ENTER_KEY_CODE && e.shiftKey) {
      return ADD_NEW_LINE;
    }

    const command = KEY_COMMAND_MAPPER[e.keyCode];

    if (!command || (FOCUS_STATE_COMMANDS.includes(command) && !focusState.active)) {
      return getDefaultKeyBinding(e);
    }

    return command;
  };

  return (
      <Typography component="span">
          <Editor
            editorState={editorState}
            onChange={onChangeEditorState}
            customStyleMap={STYLE_MAP}
            handleBeforeInput={handleBeforeInput}
            keyBindingFn={handleKeyBinding}
            handleKeyCommand={handleKeyCommand}
            placeholder={t('layout.topBar.search.searchBar.editor')}
            stripPastedStyles
          />
      </Typography>
  );
}

export default memo(QueryEditor);
