import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';

const NEO_SEARCH_ENTITY_TYPES_ARRAY: Array<NeoSearchEntityTypeEnum> = [
  NeoSearchEntityTypeEnum.ORGANIZATION,
  NeoSearchEntityTypeEnum.GEOLOCATION,
  NeoSearchEntityTypeEnum.PERSON,
  NeoSearchEntityTypeEnum.TOPIC,
  NeoSearchEntityTypeEnum.GENERAL,
  NeoSearchEntityTypeEnum.QUERY,
  NeoSearchEntityTypeEnum.TAG,
  NeoSearchEntityTypeEnum.SHARED,
];

export const NEO_SEARCH_ENTITY_TYPES_WITHOUT_GENERIC_QUERY_TAG_SHARED_ARRAY: Array<NeoSearchEntityTypeEnum> = [
  NeoSearchEntityTypeEnum.GENERAL,
  NeoSearchEntityTypeEnum.TOPIC,
  NeoSearchEntityTypeEnum.PERSON,
  NeoSearchEntityTypeEnum.ORGANIZATION,
  NeoSearchEntityTypeEnum.GEOLOCATION,
];

export default NEO_SEARCH_ENTITY_TYPES_ARRAY;
