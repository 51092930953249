import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { EMPTY_STRING, ParsingError, QueryFilterOptionShallow } from 'mediascouting-core-ui-common';
import FilteringOptions from './FilteringOptions';
import {
  EditorRequest,
  EntityRecognition,
  FilterOptionEntityData,
  SearchableEntityData,
  SubstituteRequest,
} from '../../../../../types/layout/topBar/search/editor';
import QuickSuggestions from './IntelligentSuggestions';
import Assistant from './Assistant';
import { FocusState, Suggestion } from '../../../../../types/layout/topBar/search/suggestion';
import { createAdvancedFilterSuggestions, createEntitySuggestions } from '../utils/suggestion/SuggestionServiceUtils';
import FilteringSuggestions from './FilteringSuggestions';
import { createFocusState } from '../utils/FocusStateUtils';
import { EntityTypesEnum } from '../../../../../constants/search/editor/EntityTypes';
import DateSuggestions, { DateSuggestion } from './DateSuggestions';
import TimeSectionSuggestions from './TimeSectionSuggestions';
import { IntelligentQuerySearchBarConfiguration } from '../../../../../configuration/schema';

type SuggestionServicePropTypes = {
    queryText: string;
    loading: boolean;
    focusState: FocusState;
    onEditorRequest: (request: EditorRequest<EntityTypesEnum>) => void;
    onChangeFocus: (focusState: FocusState) => void;
    onDataRequest: (type: EntityTypesEnum, search: string) => Promise<Array<QueryFilterOptionShallow>>;
    entityRecognition?: EntityRecognition;
    error?: ParsingError;
    assistantOptions?: React.ReactNode;
    configuration?: IntelligentQuerySearchBarConfiguration;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: '1px solid #dcdcdc',
    borderRadius: '0 0 4px 4px',
    maxHeight: '50vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
}));

function SuggestionService(props: SuggestionServicePropTypes): JSX.Element {
  const {
    queryText,
    loading,
    error,
    entityRecognition,
    onEditorRequest,
    focusState,
    onChangeFocus,
    onDataRequest,
    assistantOptions,
    configuration,
  } = props;
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [filterOptions, setFilterOptions] = useState<Array<QueryFilterOptionShallow>>([]);
  const [intelligentSuggestions, setIntelligentSuggestions] = useState<Array<Suggestion<EntityTypesEnum>>>([]);
  const [filteringSuggestions, setFilteringSuggestions] = useState<Array<Suggestion<EntityTypesEnum>>>([]);
  const [dateSuggestions, setDateSuggestions] = useState<Array<DateSuggestion>>([]);
  const [timeSuggestions, setTimeSuggestions] = useState<Array<DateSuggestion>>([]);

  const handleSelectedFilterOption = useCallback((selectedOption: QueryFilterOptionShallow) => {
    if (entityRecognition) {
      const { entityRange } = entityRecognition;
      const type = entityRange.entity.getType() as EntityTypesEnum;

      if (EntityTypesEnum.SEARCHABLE_FILTER_OPTION === type) {
        const { filterType, parentReference } = entityRange.entity.getData() as SearchableEntityData;

        const filterOptionEntityData: FilterOptionEntityData = {
          filterType,
          parentReference,
          option: selectedOption,
        };

        const editorRequest: SubstituteRequest<EntityTypesEnum> = {
          substituteText: selectedOption.name,
          entityMetadata: [{
            type: EntityTypesEnum.FILTER_OPTION,
            start: 0,
            end: selectedOption.name.length,
            data: filterOptionEntityData,
          }],
          cursorPositionWithinText: selectedOption.name.length,
          reference: entityRange,
        };

        onEditorRequest(editorRequest);
      }
    }
  }, [entityRecognition, onEditorRequest]);

  const loadFilterOptions = useCallback((recognizedEntities: EntityRecognition) => {
    const { entityRange } = recognizedEntities;
    const type = entityRange.entity.getType() as EntityTypesEnum;

    if (type === EntityTypesEnum.SEARCHABLE_FILTER_OPTION) {
      const { filterType, searchText } = entityRange.entity.getData() as SearchableEntityData;

      if (filterType && searchText) {
        return onDataRequest(filterType, searchText)
          .then((options) => {
            setFilterOptions(options);

            if (options.length > 0) {
              onChangeFocus(createFocusState(0, false, true));
            }
          });
      }
    }

    return setFilterOptions([]);
  }, [onChangeFocus, onDataRequest]);

  const reset = useCallback((): void => {
    setIntelligentSuggestions([]);
    setFilterOptions([]);
  }, []);

  const loadFilteringSuggestions = useCallback((recognizedEntities?: EntityRecognition) => {
    const filterSuggestions = createAdvancedFilterSuggestions(recognizedEntities, configuration);

    setFilteringSuggestions(filterSuggestions);
  }, [configuration]);

  const loadIntelligentSuggestions = useCallback((recognizedEntities: EntityRecognition) => {
    const suggestions = createEntitySuggestions(recognizedEntities);

    setIntelligentSuggestions(suggestions);
  }, []);

  useEffect(() => {
    if (entityRecognition) {
      loadIntelligentSuggestions(entityRecognition);
      loadFilterOptions(entityRecognition);
    } else {
      reset();
    }

    loadFilteringSuggestions(entityRecognition);
  }, [entityRecognition, loadIntelligentSuggestions, loadFilterOptions, loadFilteringSuggestions, reset]);

  useEffect(() => {
    const { index, active } = focusState;
    const maxLength = filterOptions.length + filteringSuggestions.length + intelligentSuggestions.length;
    const onBottomBoundaryLimit = index >= maxLength && maxLength > 0 && active;
    const onUpperBoundaryLimit = index <= -1 && maxLength > 0 && active;

    if (onBottomBoundaryLimit) {
      onChangeFocus(createFocusState(0, false, true));
    } else if (onUpperBoundaryLimit) {
      onChangeFocus(createFocusState(maxLength - 1, false, true));
    }
  }, [focusState, filterOptions, filteringSuggestions, intelligentSuggestions, onChangeFocus]);

  return (
      <div ref={containerRef} className={classes.paper}>
          <Assistant
            queryText={queryText}
            loading={loading}
            entityRange={entityRecognition?.entityRange}
            error={error}
            assistantOptions={assistantOptions}
          />
          <FilteringOptions
            options={filterOptions}
            focusIndex={focusState.index}
            focusTrigger={focusState.trigger}
            filterType={entityRecognition?.entityRange.entity.getType() || EMPTY_STRING}
            onSelectedOption={handleSelectedFilterOption}
            onChangeFocus={onChangeFocus}
          />
          <DateSuggestions
            entityRecognition={entityRecognition}
            dateSuggestions={dateSuggestions}
            onSetDateSuggestions={setDateSuggestions}
            focusIndex={focusState.index - filterOptions.length}
            focusTrigger={focusState.trigger}
            onEditorRequest={onEditorRequest}
            onChangeFocus={onChangeFocus}
          />
          <TimeSectionSuggestions
            entityRecognition={entityRecognition}
            dateSuggestions={timeSuggestions}
            onSetDateSuggestions={setTimeSuggestions}
            focusIndex={focusState.index - filterOptions.length - dateSuggestions.length}
            focusTrigger={focusState.trigger}
            onEditorRequest={onEditorRequest}
            onChangeFocus={onChangeFocus}
          />
          <QuickSuggestions
            suggestions={intelligentSuggestions}
            focusIndex={focusState.index - dateSuggestions.length - filterOptions.length - timeSuggestions.length}
            focusTrigger={focusState.trigger}
            onEditorRequest={onEditorRequest}
            onChangeFocus={onChangeFocus}
          />
          <FilteringSuggestions
            suggestions={filteringSuggestions}
            onEditorRequest={onEditorRequest}
            focusIndex={
              focusState.index - dateSuggestions.length - filterOptions.length
                - intelligentSuggestions.length - timeSuggestions.length
            }
            focusTrigger={focusState.trigger}
            onChangeFocus={onChangeFocus}
          />
      </div>
  );
}

export default memo(SuggestionService);
