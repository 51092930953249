import React, { memo } from 'react';
import {
  Backdrop, Box, ClickAwayListener, makeStyles, Paper, Popper,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

type DesktopDetachmentPropTypes = {
  anchorEl: Element | null;
  width: number;
  children: React.ReactNode;
  containerRef?: React.RefObject<HTMLElement>;
  onClickAway: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function DesktopDetachment(props: DesktopDetachmentPropTypes): JSX.Element {
  const classes = useStyles();
  const {
    anchorEl, width, children, containerRef, onClickAway,
  } = props;
  const open = Boolean(anchorEl);
  const popperModifiers = {
    flip: {
      enabled: false,
    },
    offset: {
      enabled: true,
      offset: '0px, 0px',
    },
  };

  return (
    anchorEl ? (
        <Backdrop
          open={open}
          transitionDuration={{ enter: 0, exit: 0 }}
          className={classes.root}
        >
            <Popper
              id="detachment"
              placement="top-start"
              open={open}
              anchorEl={anchorEl}
              modifiers={popperModifiers}
              container={containerRef?.current}
              disablePortal
              transition
            >
                <ClickAwayListener onClickAway={onClickAway}>
                    <Box width={width}>
                        <Paper>
                            {children}
                        </Paper>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </Backdrop>
    )
      : (
          <>
              {children}
          </>
      )

  );
}

export default memo(DesktopDetachment);
