import React, { ReactNode } from 'react';
import { getLogoValueFromRedux, LogoStorage, LogoTypeEnum } from 'mediascouting-core-ui-common';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ReduxState } from '../../redux/reducers';
import TopBar from './TopBar';

const useStyles = makeStyles(() => createStyles({
  content: {
    flexGrow: 1,
    width: '100%',
    height: '98%',
    paddingTop: 80,
    margin: 'auto',
    paddingLeft: 40,
  },
}));

interface TemplateLayoutPropTypes{
    children: ReactNode;
}
function TemplateLayout(props: TemplateLayoutPropTypes): JSX.Element {
  const { children } = props;
  const classes = useStyles();
  const reduxLogos: Array<LogoStorage> = useSelector((state: ReduxState) => state.logoStorage.logos);
  const logo = getLogoValueFromRedux(
    reduxLogos,
    LogoTypeEnum.PORTAL,
  );

  return (
      <>
          <TopBar
            logo={logo}
          />
          <main className={classes.content}>
              {children}
          </main>
      </>
  );
}

export default TemplateLayout;
