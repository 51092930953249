import React, { memo, useState } from 'react';
import {
  Avatar, Card, CardContent, CardHeader, Divider, Grid, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { NOT_FOUND } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import NeoSearchTypeSuggestion
  from '../../../../../../../components/common/NeoBar/components/NeoSearch/components/NeoSearchSuggestionService/NeoSearchTypeSuggestion';
import { NeoSearchEntityTypeEnum } from '../../../../../../../components/common/NeoBar/types/NeoSearch';
import {
  NEO_SEARCH_ENTITY_TYPES_WITHOUT_GENERIC_QUERY_TAG_SHARED_ARRAY,
} from '../../../../../../../components/common/NeoBar/components/NeoSearch/consts/NeoSearchEntityTypes';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';

interface QueryTypeSettingsPropTypes {
  onSetMetadata: (type: NeoSearchEntityTypeEnum) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  shouldPercolateCheckBox: {
    paddingTop: 10,
  },
}));

function QueryTypeSettings(props: QueryTypeSettingsPropTypes): JSX.Element {
  const { onSetMetadata } = props;
  const classes = useStyles();
  const { t } = useTranslation([TranslationNameSpaceEnum.QUERY_TYPES, TranslationNameSpaceEnum.DEFAULT]);
  const [selectedEntity, setSelectedEntity] = useState<NeoSearchEntityTypeEnum>(
    NeoSearchEntityTypeEnum.GENERAL,
  );

  const getSubheader = (): JSX.Element => (
      <div>
          <Typography variant="body2" color="textPrimary">
              {t('query.type.selection.settings.card.header')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
              {t('query.type.selection.settings.card.description')}
          </Typography>
      </div>
  );

  const handleSelect = (type: NeoSearchEntityTypeEnum): void => {
    setSelectedEntity(type);
    onSetMetadata(type);
  };

  const handleDelete = (): void => NOT_FOUND;

  return (
      <>
          <Card variant="outlined">
              <CardHeader
                avatar={(
                    <Avatar className={classes.avatar}>
                        <BubbleChartIcon />
                    </Avatar>
                )}
                subheader={getSubheader()}
              />
              <Divider />
              <CardContent>
                  <Grid container spacing={1}>
                      {NEO_SEARCH_ENTITY_TYPES_WITHOUT_GENERIC_QUERY_TAG_SHARED_ARRAY.map((type) => (
                          <Grid key={type} item>
                              <NeoSearchTypeSuggestion
                                type={type}
                                onDelete={selectedEntity === type ? handleDelete : NOT_FOUND}
                                onClick={handleSelect}
                              />
                          </Grid>
                      ))}
                  </Grid>
              </CardContent>
          </Card>
      </>

  );
}

export default memo(QueryTypeSettings);
