export enum BroadcastStoryTextTabEnum{
    TEXT = 'TEXT',
    ABSTRACT = 'ABSTRACT',
}

export enum BroadcastStoryTabEnum{
    CONTENT = 'CONTENT',
    THUMBNAILS = 'THUMBNAILS',
    METADATA = 'METADATA',
}
