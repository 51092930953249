export const SPLIT_PARAGRAPH = 'split-paragraph';
export const APPLY_SEARCH_TERMS = 'APPLY_SEARCH_TERMS';
export const KEYBOARD_SHORTCUTS = 'keyboard-shortcuts';
export const HANDLED = 'handled';
export const NOT_HANDLED = 'not-handled';
export const SPLIT_BLOCK = 'split-block';
export const HANDLE_ARROW_UP_KEY = 'HANDLE_UP_ARROW_KEY';
export const HANDLE_ARROW_DOWN_KEY = 'HANDLE_ARROW_DOWN_KEY';
export const HANDLE_TAB_KEY = 'HANDLE_TAB_KEY';
export const HANDLE_ESCAPE = 'HANDLE_ESCAPE';
