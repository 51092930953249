import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from '@material-ui/icons/Close';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING, User } from 'mediascouting-core-ui-common';
import ViewSettings, { QuickSettingsConfiguration } from './ViewSettings';
import FullScreenSwitch from './FullScreenSettings';
import ErrorBasedNotificationSwitch from './ErrorBasedNotificationSwitch';
import LanguageSettings from './LanguageSettings';
import PLATFORM_CONFIGURATION from '../../../../configuration';
import FacetSwitchSettings from './FacetSwitchSettings';
import Visible from '../../../../components/auth/Visible';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  block: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  drawer: {
    width: 500,
    maxWidth: '100%',
  },
}));

interface SettingsPropTypes{
    configuration?: QuickSettingsConfiguration;
}

export const AFFILIATE_ADMIN_ROLE_ID = 6;

function Settings(props: SettingsPropTypes): JSX.Element {
  const { configuration } = props;
  const classes = useStyles();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const languageSettingsConfiguration = PLATFORM_CONFIGURATION.schema.settings.language;

  const getUserAffiliateAdmin = (user: User): boolean => user?.role?.id === AFFILIATE_ADMIN_ROLE_ID;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
      <>
          <Tooltip title={t('layout.topBar.search.searchBar.settings') || EMPTY_STRING}>
              <IconButton
                color="inherit"
                onClick={handleOpen}
                ref={ref}
              >
                  <SettingsApplicationsRoundedIcon />
              </IconButton>
          </Tooltip>
          <Drawer
            anchor="right"
            classes={{ paper: classes.drawer }}
            ModalProps={{ BackdropProps: { invisible: true } }}
            onClose={handleClose}
            open={open}
            variant="temporary"
          >
              <PerfectScrollbar>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                          {t('layout.topBar.search.searchBar.settings.quick.title')}
                      </Typography>
                      <IconButton onClick={handleClose}>
                          <SvgIcon fontSize="small">
                              <CloseIcon />
                          </SvgIcon>
                      </IconButton>
                  </Box>
                  <Box pl={2} pr={2}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        size="small"
                        component={RouterLink}
                        onClick={handleClose}
                        to="/account/general"
                        className={classes.block}
                      >
                          {t('layout.topBar.search.searchBar.settings.all.title')}
                      </Button>
                      <Divider variant="fullWidth" />
                      <FullScreenSwitch />
                      <Divider variant="fullWidth" />
                      <Visible when={{ userCondition: getUserAffiliateAdmin }}>
                          <>
                              <FacetSwitchSettings />
                              <Divider variant="fullWidth" />
                          </>
                      </Visible>
                      <ErrorBasedNotificationSwitch />
                      <Divider variant="fullWidth" />
                      <div className={classes.block}>
                          <ViewSettings configuration={configuration} />
                      </div>
                      {languageSettingsConfiguration.enabled && (
                          <LanguageSettings languages={languageSettingsConfiguration.options} />)}
                  </Box>
              </PerfectScrollbar>
          </Drawer>
      </>
  );
}

export default Settings;
