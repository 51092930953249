import {
  DASH,
  NOT_FOUND,
  NotFound,
  subtractDaysFromDate,
  subtractHoursFromDate,
  subtractYearsFromDate,
} from 'mediascouting-core-ui-common';
import moment from 'moment';
import { DateSlugEnum, DateSlugOption } from '../../types/query/results/DateSlug';

export const getDateSlugExceedsAllowedDropdownLength = (dateSlug: DateSlugOption): boolean => dateSlug.value.length > 8;

const constructDateSlugRangeValueFormat = (
  start: Date, end: Date,
): string => `${start.getTime().toString()}${DASH}${end.getTime().toString()}`;

export const getTodayDates = (optionalDate?: Date): string => {
  const endDate = optionalDate || new Date();
  const startDate = new Date(endDate.getTime());
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getYesterdayDates = (optionalDate?: Date): string => {
  const date = optionalDate || new Date();
  const endDate = subtractDaysFromDate(date, 1);
  endDate.setHours(23, 59, 59);
  const startDate = subtractDaysFromDate(date, 1);
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getLastTwentyFourHoursDates = (optionalDate?: Date): string => {
  const endDate = optionalDate || new Date();
  const startDate = subtractHoursFromDate(endDate, 24);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getYesterdayAndTodayDates = (optionalDate?: Date): string => {
  const endDate = optionalDate || new Date();
  const startDate = subtractDaysFromDate(endDate, 1);
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getThisWeekDates = (optionalDate?: Date | NotFound): string => {
  const momentDateStartOfWeekSunday = moment(optionalDate).startOf('week');
  const isoStringMomentDateStartOfWeekSunday = momentDateStartOfWeekSunday.toISOString();
  const startDate = new Date(isoStringMomentDateStartOfWeekSunday);
  startDate.setHours(0, 0, 0);
  const endDate = optionalDate || new Date();

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getLastSevenDaysDates = (optionalDate?: Date | NotFound): string => {
  const endDate = optionalDate || new Date();
  const startDate = subtractDaysFromDate(endDate, 7);
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getThisMonthDates = (optionalDate?: Date | NotFound): string => {
  const momentDateStartOfMonth = moment(optionalDate).startOf('month');
  const isoStringMomentDateStartOfMonth = momentDateStartOfMonth.toISOString();
  const startDate = new Date(isoStringMomentDateStartOfMonth);
  startDate.setHours(0, 0, 0);
  const endDate = optionalDate || new Date();

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getPreviousMonthDates = (optionalDate?: Date | NotFound): string => {
  const momentDateStartOfMonth = moment(optionalDate).subtract(1, 'month').startOf('month');
  const isoStringMomentDateStartOfMonth = momentDateStartOfMonth.toISOString();
  const startDate = new Date(isoStringMomentDateStartOfMonth);
  startDate.setHours(0, 0, 0);

  const momentDateEndOfMonth = optionalDate || moment(optionalDate).subtract(1, 'month').endOf('month');
  const isoStringMomentDateEndOfMonth = momentDateEndOfMonth.toISOString();
  const endDate = new Date(isoStringMomentDateEndOfMonth);
  endDate.setHours(23, 59, 59);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getThisYearDates = (optionalDate?: Date | NotFound): string => {
  const endDate = optionalDate || new Date();
  const startDate = new Date(endDate.getTime());
  startDate.setMonth(0);
  startDate.setDate(1);
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

export const getLastTwelveMonths = (optionalDate?: Date | NotFound): string => {
  const endDate = optionalDate || new Date();
  const startDate = subtractYearsFromDate(endDate, 1);
  startDate.setHours(0, 0, 0);

  return constructDateSlugRangeValueFormat(startDate, endDate);
};

const FACTORY_OF_SLUG_DATE_HANDLERS = {
  [DateSlugEnum.TODAY]: getTodayDates,
  [DateSlugEnum.YESTERDAY]: getYesterdayDates,
  [DateSlugEnum.LAST_TWENTY_FOUR_HOURS]: getLastTwentyFourHoursDates,
  [DateSlugEnum.YESTERDAY_AND_TODAY]: getYesterdayAndTodayDates,
  [DateSlugEnum.THIS_WEEK]: getThisWeekDates,
  [DateSlugEnum.LAST_SEVEN_DAYS]: getLastSevenDaysDates,
  [DateSlugEnum.THIS_MONTH]: getThisMonthDates,
  [DateSlugEnum.PREVIOUS_MONTH]: getPreviousMonthDates,
  [DateSlugEnum.THIS_YEAR]: getThisYearDates,
  [DateSlugEnum.LAST_TWELVE_MONTHS]: getLastTwelveMonths,
};

const getSlugDateRepresentation = (dateSlug: DateSlugOption): string | NotFound => {
  const handler = FACTORY_OF_SLUG_DATE_HANDLERS[dateSlug.id];

  try {
    if (handler) {
      return handler();
    }

    return NOT_FOUND;
  } catch (e) {
    return NOT_FOUND;
  }
};

export default getSlugDateRepresentation;
