import { NOT_FOUND } from 'mediascouting-core-ui-common';
import { HeartbeatActions } from '../../constants/actions/creators/HeartbeatActions';
import { CLEAR_ACTION, NOTIFY_ACTION } from '../../constants/actions/types/HeatbeatActionTypes';

export enum HeartbeatDataType {
  QUERY,
  TAG,
  EXPORT,
  MONTAGE,
}

export enum HeartbeatDataChange {
  CREATE,
  UPDATE,
  DELETE,
  ANY,
}

export interface HeartbeatIdentification {
  id?: number;
  payload?: unknown;
}

export interface HeartbeatMonitor {
  type: HeartbeatDataType;
  cause: HeartbeatDataChange;
  identification: HeartbeatIdentification;
}

export interface HeartbeatReducer {
  action?: HeartbeatMonitor;
}

const INIT_STATE: HeartbeatReducer = {
  action: NOT_FOUND,
};

export default (state = INIT_STATE, action: HeartbeatActions): HeartbeatReducer => {
  switch (action.type) {
    case NOTIFY_ACTION: {
      return {
        ...state,
        action: action.payload,
      };
    }
    case CLEAR_ACTION: {
      return {
        ...state,
        action: NOT_FOUND,
      };
    }
    default:
      return state;
  }
};
