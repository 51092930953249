import React, {
  memo, useCallback, useState,
} from 'react';
import {
  Box,
  Drawer,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ShopRoundedIcon from '@material-ui/icons/ShopRounded';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import {
  CreateMontageListItemDTO,
  CreateMontageRequest,
  EMPTY_STRING,
  hasPermission,
  UserPermission,
} from 'mediascouting-core-ui-common';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../redux/reducers';
import { AppDispatch } from '../../../redux/store';
import {
  movePlaylistItem,
  removeFromPlaylist,
  resetPlaylist,
} from '../../../constants/actions/creators/MontageActions';
import getDefaultSnackbarOptions from '../../../utils/common/SnackbarUtils';
import MontageSetup from '../../../components/features/montage/MontageSetup';
import { createMontage } from '../../../remote/Montage';
import { MontageSettingsFormFields } from '../../../components/features/montage/MontageSetup/MontageFormFields';
import CreateButton from '../../../components/features/montage/MontageSetup/CreateButton';
import { PlaylistItem } from '../../../types/layout/topBar/Montage';
import Visible from '../../../components/auth/Visible';
import useMontageQualityOptions from '../../../hooks/useMontageQualityOptions';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1.5),
  },
  drawer: {
    width: 500,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  sticky: {
    position: 'sticky',
    bottom: '1px',
    zIndex: 20,
    padding: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

const StyledBadge = withStyles((theme: Theme) => createStyles({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#E91E63',
    color: 'white',
  },
}))(Badge);

function Montage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const playlist = useSelector((state: ReduxState) => state.montage.playlist);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { montageQualityOptions, montageOutputTypes } = useMontageQualityOptions();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleReorder = useCallback((sourceIndex: number, destinationIndex: number): void => {
    dispatch(movePlaylistItem({ sourceIndex, destinationIndex }));
    enqueueSnackbar(t('layout.topBar.search.searchBar.montage.reorder.message'), getDefaultSnackbarOptions('success'));
  }, [dispatch, enqueueSnackbar, t]);

  const handleRemovePlaylistItem = useCallback((playlistItem: PlaylistItem) => {
    dispatch(removeFromPlaylist(playlistItem));
    enqueueSnackbar(t('layout.topBar.search.searchBar.montage.remove.message'), getDefaultSnackbarOptions('success'));
  }, [dispatch, enqueueSnackbar, t]);

  const handleCreate = useCallback((fieldValues: MontageSettingsFormFields): Promise<void> => {
    const request: CreateMontageRequest = {
      name: fieldValues.fileName,
      description: fieldValues.fileName,
      segments: playlist.map((item) => {
        const mappedItem: CreateMontageListItemDTO = {
          source: {
            id: item.source.id,
          },
          startDate: `${moment(item.range.start.toISOString()).utc().format('YYYY-MM-DD HH:mm:ss')}`,
          endDate: `${moment(item.range.end.toISOString()).utc().format('YYYY-MM-DD HH:mm:ss')}`,
          order: item.order,
        };

        return mappedItem;
      }),
      outputOption: fieldValues.format,
      qualityOption: fieldValues.quality,
    };

    setLoading(true);

    return dispatch(createMontage(request))
      .then(() => {
        dispatch(resetPlaylist());
        enqueueSnackbar(t('layout.topBar.search.searchBar.montage.create.message'), getDefaultSnackbarOptions('info'));

        return setOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, enqueueSnackbar, playlist, t]);

  return (
      <Visible when={{ userCondition: hasPermission(UserPermission.INITIATE_MONTAGE) }}>
          <>
              <Fade in={playlist.length > 0} timeout={350} unmountOnExit mountOnEnter>
                  <Tooltip title={t('layout.topBar.search.searchBar.montage') || EMPTY_STRING}>
                      <IconButton
                        color="inherit"
                        onClick={handleOpen}
                        className={classes.icon}
                      >
                          <StyledBadge badgeContent={playlist.length}>
                              <ShopRoundedIcon />
                          </StyledBadge>
                      </IconButton>
                  </Tooltip>
              </Fade>
              <Drawer
                anchor="right"
                classes={{ paper: classes.drawer }}
                ModalProps={{ BackdropProps: { invisible: true } }}
                onClose={handleClose}
                open={open}
                variant="temporary"
              >
                  <PerfectScrollbar options={{ suppressScrollX: true }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                      >
                          <Typography
                            variant="h4"
                            color="textPrimary"
                          >
                              {t('layout.topBar.search.searchBar.montage.title')}
                          </Typography>
                          <IconButton onClick={handleClose}>
                              <SvgIcon fontSize="small">
                                  <CloseIcon />
                              </SvgIcon>
                          </IconButton>
                      </Box>
                      <Box pl={2} pr={2} height="100%">
                          <MontageSetup
                            playlist={playlist}
                            onCreate={handleCreate}
                            onReorder={handleReorder}
                            onRemovePlaylistItem={handleRemovePlaylistItem}
                            montageQualityOptions={montageQualityOptions}
                            montageFormatOptions={montageOutputTypes}
                          />
                      </Box>
                  </PerfectScrollbar>
                  <Paper className={clsx(classes.sticky, classes.flex)}>
                      <CreateButton disabled={loading || montageQualityOptions.length === 0} />
                  </Paper>
              </Drawer>
          </>
      </Visible>
  );
}

export default memo(Montage);
