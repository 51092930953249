import {
  Drawer, Hidden, makeStyles, Theme, Toolbar,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

interface DesktopDrawerPropTypes {
    open: boolean;
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: 300,
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  drawerOpen: {
    width: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));

function DesktopDrawer(props: DesktopDrawerPropTypes): JSX.Element {
  const { children, open } = props;
  const classes = useStyles();

  return (
      <Hidden mdDown>
          <Drawer
            anchor="left"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{ paper: classes.drawerPaper }}
            open={open}
            variant="persistent"
          >
              <Toolbar />
              <div className={classes.drawerContainer}>
                  {children}
              </div>
          </Drawer>
      </Hidden>
  );
}

export default DesktopDrawer;
