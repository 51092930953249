import { PowerSearchSynchronousQueryFilters } from 'mediascouting-core-ui-common';
import { ADD_TO_CACHE, CacheUpdate, SET_CACHE } from '../types/CacheActionTypes';

export const setDefaultPowerSearchOptionsInCache = (params: PowerSearchSynchronousQueryFilters) => ({
  type: SET_CACHE,
  payload: params,
} as const);

export const addToCache = (cacheUpdate: CacheUpdate) => ({
  type: ADD_TO_CACHE,
  payload: cacheUpdate,
} as const);

export type CacheActions =
    | ReturnType<typeof setDefaultPowerSearchOptionsInCache>
    | ReturnType<typeof addToCache>
