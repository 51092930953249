import React, { useCallback, useMemo } from 'react';
import { NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import { NavigationSectionItem, NeoBarItemType } from '../../../../../types/layout/navBar';
import { NeoBarDisplayFormatEnum, NeoBarItemSettings } from '../../types/NeoSearch';
import NeoBarItemCard from './NeoBarItemCard';
import NeoBarListItem from './NeoBarListItem';
import NeoBarDefaultListItem from './NeoBarDefaultListItem';
import { NeoBarConfiguration } from '../../types/NeoBarConfiguration';

export interface NeoBarItemPropTypes {
    type: NeoBarItemType;
    item: NavigationSectionItem;
    configuration: NeoBarConfiguration;
    settings: NeoBarItemSettings;
}

function NeoBarItem(props: NeoBarItemPropTypes): JSX.Element {
  const {
    item, type, configuration, settings,
  } = props;

  const getPreviousListItemDefaultImplementation = useCallback((): JSX.Element => (
      <NeoBarDefaultListItem
        configuration={configuration}
        item={item}
        type={type}
        settings={settings}
      />
  ), [configuration, item, settings, type]);

  const getCardImplementation = useCallback((): JSX.Element => (
      <NeoBarItemCard
        configuration={configuration}
        type={type}
        item={item}
        settings={settings}
      />
  ), [configuration, item, settings, type]);

  const getListImplementation = useCallback((): JSX.Element => (
      <NeoBarListItem
        configuration={configuration}
        item={item}
        type={type}
        settings={settings}
      />
  ), [configuration, item, settings, type]);

  const MEMOIZED_DISPLAY_FORMAT_DECIDER = useMemo(
    (): { [key in NeoBarDisplayFormatEnum]: () => JSX.Element } => ({
      [NeoBarDisplayFormatEnum.CARD]: getCardImplementation,
      [NeoBarDisplayFormatEnum.LIST]: getListImplementation,
      [NeoBarDisplayFormatEnum.DEFAULT]: getPreviousListItemDefaultImplementation,
    }), [getCardImplementation, getListImplementation, getPreviousListItemDefaultImplementation],
  );

  const getDisplayFormat = (): JSX.Element | NotFound => {
    const optionalHandler = MEMOIZED_DISPLAY_FORMAT_DECIDER[configuration.display.type];

    if (optionalHandler) {
      return optionalHandler();
    }

    return NOT_FOUND;
  };

  return (
      <>
          {getDisplayFormat()}
      </>
  );
}

export default NeoBarItem;
