import React, {
  memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {
  EMPTY_STRING,
  getLogoValueFromRedux,
  getMissingData,
  LogoStorage,
  LogoTypeEnum,
  NOT_FOUND,
  NotFound,
  PowerSearchFilterTypes,
  QueryDTO,
  SupportedRemoteDataRetrieverType,
} from 'mediascouting-core-ui-common';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { ReduxState } from '../../redux/reducers';
import TopBar from './TopBar';
import createQuerySearchSpace from '../../utils/query/QuerySearchSpaceUtils';
import { AppDispatch } from '../../redux/store';
import { createDataRemoteRetrievers } from '../../utils/data/RemoteRetrieverUtils';
import { getQuery } from '../../remote/Query';
import { setQuerySearchSpace } from '../../constants/actions/creators/QuerySearchSpaceActions';
import useCache from '../../hooks/UseCache';
import useExportPollingWrapper from '../../hooks/useExportPollingWrapper';
import useMontagePollingWrapper from '../../hooks/useMontagePollingWrapper';
import NeoBar from '../../components/common/NeoBar';
import { INIT_SELECT_TARGET } from '../../redux/reducers/QueryStoryResults';
import { createFacets } from '../../utils/query/FacetUtils';
import Configuration from '../../configuration';
import { setFacetGroups, setSelection } from '../../constants/actions/creators/QueryResultsActions';

const drawerWidth = 240;

type PortalLayoutPropTypes = {
    children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
}));

function PortalLayout(props: PortalLayoutPropTypes): JSX.Element {
  const classes = useStyles();
  const { children } = props;
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { onRetrieveFromCache, onCache } = useCache();
  const fullScreen = useSelector((state: ReduxState) => state.settings.fullScreen);
  const cached = useSelector((state: ReduxState) => state.cache);
  const neoBarConfiguration = useSelector((state: ReduxState) => state.settings.neoBar);
  const reduxLogos: Array<LogoStorage> = useSelector((state: ReduxState) => state.logoStorage.logos);
  const logo = getLogoValueFromRedux(
    reduxLogos,
    onMobile ? LogoTypeEnum.DEFAULT : LogoTypeEnum.PORTAL,
  );
  const [navOpen, setNavOpen] = useState(() => false);
  const prevLocationSearch = useRef<string>(EMPTY_STRING);

  const handleNavOpen = useCallback(() => {
    setNavOpen(true);
  }, []);

  const handleNavClose = useCallback(() => {
    setNavOpen(false);
  }, []);

  const retrievers = useMemo(() => dispatch(createDataRemoteRetrievers(onCache)), [dispatch, onCache]);

  const getMissingDataWrapper = useCallback((
    type: PowerSearchFilterTypes, id: number,
  ): Promise<SupportedRemoteDataRetrieverType | NotFound> => getMissingData(
    type, id, retrievers, onRetrieveFromCache,
  ),
  [onRetrieveFromCache, retrievers]);

  const handleQueryFetch = useCallback((id: number, prevQueryId?: number): Promise<QueryDTO> => dispatch(getQuery(id))
    .then((response) => {
      const queryChanged = prevQueryId && prevQueryId !== id;
      const querySelectedWhenNonWasSelected = (prevQueryId === NOT_FOUND || prevQueryId === 0) && id;
      if (queryChanged || querySelectedWhenNonWasSelected) {
        dispatch(setFacetGroups(createFacets({}, Configuration.schema.facets)));
      }
      return response;
    }), [dispatch]);

  const handleQueryParams = useCallback(() => {
    if (prevLocationSearch.current !== location.search) {
      const urlParams = queryString.parse(location.search);
      const prevUrlParams = queryString.parse(prevLocationSearch.current);
      if (prevUrlParams?.qId !== NOT_FOUND && (prevUrlParams?.qId !== urlParams?.qId)) {
        dispatch(setSelection(INIT_SELECT_TARGET));
      }
      createQuerySearchSpace(urlParams, cached, getMissingDataWrapper, handleQueryFetch, prevUrlParams)
        .then((querySearchSpace) => {
          dispatch(setQuerySearchSpace(querySearchSpace));
        });
    }
  }, [dispatch, getMissingDataWrapper, handleQueryFetch, location.search, cached]);

  useEffect(() => {
    if (fullScreen) {
      setNavOpen(false);
    }
  }, [fullScreen]);

  useEffect(() => {
    if (!onMobile) {
      setNavOpen(true);
    } else {
      setNavOpen(false);
    }
  }, [onMobile]);

  useEffect(() => {
    handleQueryParams();
  }, [location, handleQueryParams]);

  useEffect(() => {
    prevLocationSearch.current = location.search;
  });

  useExportPollingWrapper();
  useMontagePollingWrapper();

  return (
      <div className={classes.root}>
          <TopBar
            onNavOpen={handleNavOpen}
            onNavClose={handleNavClose}
            navOpen={navOpen}
            logo={logo}
          />
          <NeoBar
            open={navOpen}
            logo={logo}
            onClose={handleNavClose}
            configuration={neoBarConfiguration}
          />
          <main className={classes.content}>
              <Toolbar />
              {children}
          </main>
      </div>
  );
}

export default memo(PortalLayout);
