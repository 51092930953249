import React, {
  memo, MutableRefObject, useCallback, useEffect, useState,
} from 'react';
import {
  createStyles, fade, makeStyles, Theme,
} from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { InputBase } from '@material-ui/core';
import {
  EMPTY_STRING, GetItemsResponse, useInfiniteScrolling, useScheduler, NotFound, useCancelPolicy,
} from 'mediascouting-core-ui-common';
import clsx from 'clsx';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { InputBaseProps } from '@material-ui/core/InputBase/InputBase';
import { CancelTokenSource } from 'axios';
import sort from '../../../utils/common/SortingUtils';

interface SupportedParams {
  searchText: string;
  requestedPage: number;
}

export interface LimePickerFetchDataParams extends Partial<SupportedParams>{

}

interface LimePickerPropTypes<T> extends Partial<AutocompleteProps<T, true, false, false>>{
  onFetchData: (
      params: LimePickerFetchDataParams, cancelToken: MutableRefObject<CancelTokenSource | NotFound>
  ) => Promise<GetItemsResponse<T>>;
  onGetLabel: (option: T) => string;
  onGetDescription: (option: T) => string;
  onGetSelected: (option: T, value: T) => boolean;
  onGetColor?: (option: T) => string;
  inputProps?: Partial<InputBaseProps>;
}

enum EventTypesEnum {
  CHANGE = 'change',
}

const ALLOWED_CHANGE_EVENT_TYPES = [EventTypesEnum.CHANGE];

const DEFAULT_PAGE = 1;

const useStyles = makeStyles((theme: Theme) => createStyles({
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
    backgroundColor: theme.palette.grey.A100,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

function LimePicker<T>(props: LimePickerPropTypes<T>): JSX.Element {
  const classes = useStyles();
  const {
    onFetchData, onGetLabel, onGetDescription, onGetColor, onGetSelected, inputProps, ...autocompleteProps
  } = props;
  const { onSchedule } = useScheduler();
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const {
    cancelablePromise, cancel, cancelToken,
  } = useCancelPolicy<GetItemsResponse<T>>();

  const getPage = useCallback((): number => page, [page]);

  const handleInfiniteScrollingFetching = useCallback((
    requestedPage: number, otherParams?: object,
  ): Promise<GetItemsResponse<T>> => {
    const revisedParams: LimePickerFetchDataParams = {
      requestedPage,
      ...otherParams,
    };

    return cancelablePromise(() => onFetchData(revisedParams, cancelToken));
  }, [cancelToken, cancelablePromise, onFetchData]);

  const {
    loadNext, hasNext, items, initLoad, loading,
  } = useInfiniteScrolling({
    getPage,
    setPage,
    getItems: handleInfiniteScrollingFetching,
  });

  const renderTag = (option: T, { selected }): JSX.Element => {
    const color = onGetColor ? onGetColor(option) : EMPTY_STRING;

    return (
        <>
            <DoneIcon
              className={classes.iconSelected}
              style={{ visibility: selected ? 'visible' : 'hidden' }}
            />
            <span
              className={clsx(classes.color, {
                [classes[color || EMPTY_STRING]]: color,
              })}
              style={{ backgroundColor: color }}
            />
            <div className={classes.text}>
                {onGetLabel(option)}
                <br />
                {onGetDescription(option)}
            </div>
            <CloseIcon
              className={classes.close}
              style={{ visibility: selected ? 'visible' : 'hidden' }}
            />
        </>
    );
  };
  const handleInputChange = (event, newInputValue: string): void => {
    if (ALLOWED_CHANGE_EVENT_TYPES.includes(event?.type)) {
      const params: Partial<SupportedParams> = {
        searchText: newInputValue,
      };

      cancel();
      setInputValue(newInputValue);
      setPage(DEFAULT_PAGE);
      onSchedule(() => initLoad(params));
    }
  };

  const handleLoadMore = (): void => {
    if (hasNext) {
      loadNext();
    }
  };

  const handleScroll = (event: React.SyntheticEvent): void => {
    const listboxNode = event.currentTarget;
    const reachedEnd = listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight;

    if (reachedEnd) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    initLoad();
  }, [initLoad]);

  return (
      <>
          <Autocomplete
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            open
            multiple
            loading={loading}
            {...autocompleteProps}
            onInputChange={handleInputChange}
            disableCloseOnSelect
            inputValue={inputValue}
            disablePortal
            renderTags={(): null => null}
            renderOption={renderTag}
            options={sort(items)}
            filterOptions={(options): Array<T> => options}
            getOptionLabel={onGetLabel}
            getOptionSelected={onGetSelected}
            renderInput={(params): JSX.Element => (
                <InputBase
                  ref={params.InputProps.ref}
                  className={classes.inputBase}
                  inputProps={params.inputProps}
                  value={inputValue}
                  {...inputProps}
                />
            )}
            ListboxProps={{
              onScroll: handleScroll,
            }}
          />
      </>

  );
}

export default memo(LimePicker);
