import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core/styles';
import { getAdvancedFilterIcon } from '../utils/suggestion/SuggestionServiceUtils';
import { EntityTypesEnum } from '../../../../../constants/search/editor/EntityTypes';

type AdvancedFilterPropTypes = {
  type: EntityTypesEnum;
  children: React.ReactNode;
  hideIcon?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-flex',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    fontSize: '0.8125rem',
    alignItems: 'center',
    verticalAlign: 'middle',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    // borderRadius: '16px',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: theme.spacing(0.25),
    paddingRight: theme.spacing(0.75),
    paddingLeft: theme.spacing(0.75),
  },
  icon: {
    color: '#546e7a',
    display: 'inline-flex',
    paddingRight: theme.spacing(0.25),
  },
}));

function Filter(props: AdvancedFilterPropTypes): JSX.Element {
  const {
    type, children, hideIcon, className,
  } = props;
  const classes = useStyles();

  return (
      <Typography className={clsx(classes.root, className)} component="span">
          {!hideIcon && (
              <div className={classes.icon}>
                  {getAdvancedFilterIcon(type, 'small')}
              </div>
          )}
          {children}
      </Typography>
  );
}

export default memo(Filter);
