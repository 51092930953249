import EntityMutabilityEnum from '../../../../../../constants/draft-js/EntityMutability';
import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';

export const NEO_SEARCH_ENTITY_MUTABILITY_MAPPER: { [key in NeoSearchEntityTypeEnum]: EntityMutabilityEnum } = {
  [NeoSearchEntityTypeEnum.ORGANIZATION]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.GEOLOCATION]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.PERSON]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.TOPIC]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.GENERAL]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.QUERY]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.TAG]: EntityMutabilityEnum.IMMUTABLE,
  [NeoSearchEntityTypeEnum.SHARED]: EntityMutabilityEnum.IMMUTABLE,
};

export default NEO_SEARCH_ENTITY_MUTABILITY_MAPPER;
