import { Group, NotFound, User } from 'mediascouting-core-ui-common';
import {
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SessionLoginPayload,
  USER_DATA, USER_GROUP,
} from '../types/AuthActionTypes';

export const sessionLoginAction = (payload: SessionLoginPayload) => ({
  type: SESSION_LOGIN,
  payload,
} as const);

export const sessionLogoutAction = () => ({
  type: SESSION_LOGOUT,
} as const);

export const userDataAction = (payload: User | NotFound) => ({
  type: USER_DATA,
  payload,
} as const);

export const setUserGroup = (payload: Group) => ({
  type: USER_GROUP,
  payload,
} as const);

export type AuthActions =
    | ReturnType<typeof sessionLoginAction>
    | ReturnType<typeof sessionLogoutAction>
    | ReturnType<typeof userDataAction>
    | ReturnType<typeof setUserGroup>
