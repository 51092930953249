import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ContentState } from 'draft-js';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';
import useNeoBarColors from '../../../hooks/useNeoBarColors';

interface QueryTagEntityDecoratorPropTypes {
  children?: Array<React.ReactNode>;
  entityKey: string;
  contentState: ContentState;
}

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.8125rem',
    textTransform: 'uppercase',
    padding: '0 2px 1px 2px',
    borderRadius: '4px',
  },
}));

function QueryTagEntityDecorator(props: QueryTagEntityDecoratorPropTypes): JSX.Element {
  const { children, contentState, entityKey } = props;
  const classes = useStyles();
  const { getNeoBarNavigationItemTypeColor } = useNeoBarColors({});

  const getType = (): string => {
    if (entityKey !== null) {
      const type = contentState.getEntity(entityKey).getType() as NeoSearchEntityTypeEnum;

      if (type) {
        return getNeoBarNavigationItemTypeColor(type);
      }
    }

    return EMPTY_STRING;
  };

  return (
      <Typography component="span" className={clsx(classes.root, getType())}>
          {children}
      </Typography>
  );
}

export default memo(QueryTagEntityDecorator);
