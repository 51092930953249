import { SelectionOption } from '../types/query/Selector';

export const SELECT = 'SELECT';
export const DESELECT = 'DESELECT';

export enum StorySnapSelectionRangeEnum {
  QUERY = 'QUERY',
  PAGE = 'PAGE',
  INVERT = 'INVERT',
}

export const SELECTION_RANGE_OPTIONS: Array<SelectionOption> = [
  {
    title: 'toolbar.action.select.option.select.page',
    range: StorySnapSelectionRangeEnum.PAGE,
  },
  {
    title: 'toolbar.action.select.option.select.query',
    range: StorySnapSelectionRangeEnum.QUERY,
  },
  {
    title: 'toolbar.action.select.option.select.invert',
    range: StorySnapSelectionRangeEnum.INVERT,
  },
];
