import React from 'react';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { NeoBarShortcutKeyCodeEnum } from './NeoBarConfiguration';

export enum NeoSearchEntityTypeEnum {
    GEOLOCATION = 'Location',
    PERSON = 'Person',
    ORGANIZATION = 'Organization',
    TOPIC = 'Topic',
    GENERAL = 'General',
    QUERY = 'Query',
    TAG = 'Tag',
    SHARED = 'Shared'
}

export enum NeoBarDisplayFormatEnum {
    'CARD' = 'CARD',
    'LIST' = 'LIST',
    'DEFAULT' = 'DEFAULT',
}

export type NeoSearchEntityDecider = {[key in NeoSearchEntityTypeEnum]: (
    existingRequest: NeoSearchRequest,
    types: Array<NeoSearchEntityTypeEnum>,
) => NeoSearchRequest };

export interface NeoBarItemSettings {
    selected: boolean;
    live: boolean;
}

export interface NeoSearchTerms {
    searchText: string;
    types: Array<NeoSearchEntityTypeEnum>;
}

export interface NeoSearchQueryRequestDetails {
    types: Array<NeoSearchEntityTypeEnum>;
    fetchQueries: boolean;
    fetchSharedQueries: boolean;
}

export interface NeoSearchTagRequestDetails {
    types: Array<NeoSearchEntityTypeEnum>;
    fetchTags: boolean;
    fetchSharedTags: boolean;
}

export interface NeoSearchRequest {
    text: string;
    queries: NeoSearchQueryRequestDetails;
    tags: NeoSearchTagRequestDetails;
}

export interface NeoSearchConditionHandlers {
    handleQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
    handleSharedQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
    handleTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
    handleSharedTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
}

export interface NeoSearchConditionHandler {
    if: boolean;
    then: VoidFunction;
}

export const SHORTCUT_ICON_MAPPER: { [key in NeoBarShortcutKeyCodeEnum]: React.ReactNode } = {
  [NeoBarShortcutKeyCodeEnum.ENTER]: <KeyboardReturnIcon />,
  [NeoBarShortcutKeyCodeEnum.TAB]: <KeyboardTabIcon />,
};

export const SHORTCUT_TEXT_TRANSLATION_KEY_MAPPER: { [key in NeoBarShortcutKeyCodeEnum]: string } = {
  [NeoBarShortcutKeyCodeEnum.ENTER]: 'neoBar.neoSearch.keys.enter',
  [NeoBarShortcutKeyCodeEnum.TAB]: 'neoBar.neoSearch.keys.tab',
};
