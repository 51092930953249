import React, { memo, useCallback } from 'react';
import {
  Box, Card, CardContent, CardHeader, Divider, TextField,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import {
  EMPTY_STRING,
  MontageFormatOption,
  MontageOutputOptionDTO,
  MontageQualityOption,
} from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { MontageSettingsFormFields } from './index';
import TranslationNameSpaceEnum from '../../../../../types/translation/TranslationNameSpaceEnum';

interface MontageMainOptionsFormFieldsPropTypes {
    formikProps: FormikProps<MontageSettingsFormFields>;
    montageQualityOptions: Array<MontageQualityOption>;
    montageFormatOptions: Array<MontageFormatOption>;
    disabled?: boolean;
}

function MainOptions(props: MontageMainOptionsFormFieldsPropTypes): JSX.Element {
  const { t } = useTranslation(TranslationNameSpaceEnum.MONTAGES);
  const {
    formikProps, montageQualityOptions, montageFormatOptions, disabled,
  } = props;

  const getFormatOptions = useCallback(() => montageFormatOptions.map((format) => (
      <option
        key={format.id}
        value={format.id}
      >
          {format.name}
      </option>
  )), [montageFormatOptions]);

  const getQualityOptions = useCallback(() => montageQualityOptions.map((quality) => (
      <option
        key={quality.id}
        value={quality.id}
      >
          {quality.name}
      </option>
  )), [montageQualityOptions]);

  const getQualityOptionsDisabled = useCallback((
  ) => disabled
      || formikProps.values.format === MontageOutputOptionDTO.MP3
      || montageQualityOptions.length === 0, [disabled, formikProps.values.format, montageQualityOptions.length]);

  return (
      <Card variant="outlined">
          <CardHeader title={t('item.main.options.title') || EMPTY_STRING} />
          <Divider />
          <CardContent>
              <TextField
                error={Boolean(formikProps.touched.fileName && formikProps.errors.fileName)}
                helperText={formikProps.touched.fileName && formikProps.errors.fileName}
                fullWidth
                disabled={disabled}
                label={t('item.main.options.filename')}
                name="fileName"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                value={formikProps.values.fileName}
                variant="outlined"
              />
              {/* <Box mt={2}> */}
              {/*    <TextField */}
              {/*      error={Boolean(formikProps.touched.type && formikProps.errors.type)} */}
              {/*      fullWidth */}
              {/*      disabled={disabled} */}
              {/*      helperText={formikProps.touched.type && formikProps.errors.type} */}
              {/*      label="Type" */}
              {/*      name="type" */}
              {/*      onChange={formikProps.handleChange} */}
              {/*      select */}
              {/*      SelectProps={{ native: true }} */}
              {/*      value={formikProps.values.type} */}
              {/*      variant="outlined" */}
              {/*    > */}
              {/*        {MONTAGE_TYPE_OPTIONS.map((type) => ( */}
              {/*            <option */}
              {/*              key={type.id} */}
              {/*              value={type.id} */}
              {/*            > */}
              {/*                {type.name} */}
              {/*            </option> */}
              {/*        ))} */}
              {/*    </TextField> */}
              {/* </Box> */}
              <Box mt={2}>
                  <TextField
                    error={Boolean(formikProps.touched.format && formikProps.errors.format)}
                    fullWidth
                    helperText={formikProps.touched.format && formikProps.errors.format}
                    disabled={disabled}
                    label={t('item.main.options.format')}
                    name="format"
                    onChange={formikProps.handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={formikProps.values.format}
                    variant="outlined"
                  >
                      {getFormatOptions()}
                  </TextField>
              </Box>
              <Box mt={2}>
                  <TextField
                    error={Boolean(formikProps.touched.quality && formikProps.errors.quality)}
                    helperText={formikProps.touched.quality && formikProps.errors.quality}
                    label={t('item.main.options.quality')}
                    name="quality"
                    variant="outlined"
                    fullWidth
                    select
                    disabled={getQualityOptionsDisabled()}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.quality}
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: montageQualityOptions.length !== 0 }}
                  >
                      {getQualityOptions()}
                  </TextField>
              </Box>
              {/* <Box mt={2}> */}
              {/*    <TextField */}
              {/*      error={Boolean( */}
              {/*        formikProps.touched.blackSilenceTransition && formikProps.errors.blackSilenceTransition, */}
              {/*      )} */}
              {/*      helperText={formikProps.touched.blackSilenceTransition
              && formikProps.errors.blackSilenceTransition} */}
              {/*      disabled={disabled} */}
              {/*      label="Black / Silence transition milliseconds" */}
              {/*      name="blackSilenceTransition" */}
              {/*      type="number" */}
              {/*      variant="outlined" */}
              {/*      fullWidth */}
              {/*      onBlur={formikProps.handleBlur} */}
              {/*      onChange={formikProps.handleChange} */}
              {/*      value={formikProps.values.blackSilenceTransition} */}
              {/*    /> */}
              {/* </Box> */}
          </CardContent>
      </Card>
  );
}

export default memo(MainOptions);
