import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

type AdvancedFilterDecoratorPropTypes = {
  children?: Array<React.ReactNode>;
};

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px dotted',
  },
}));

function SearchableFilterOptionDecorator(props: AdvancedFilterDecoratorPropTypes): JSX.Element {
  const { children } = props;
  const classes = useStyles();

  return (
      <Typography component="span" className={classes.root}>
          {children}
      </Typography>
  );
}

export default memo(SearchableFilterOptionDecorator);
