import { useDispatch } from 'react-redux';
import {
  GetUserSettingsRemoteParams,
  MontageQualityOptionDTO,
  UserSettingsKey,
  MontageQualityOption,
  MontageFormatOption,
  MONTAGE_QUALITY_OPTIONS,
  MONTAGE_FORMAT_OPTIONS,
} from 'mediascouting-core-ui-common';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { AppDispatch } from '../redux/store';
import getUserSettings from '../remote/Setting';

interface ExposedUseMontageQualityOptionsProperties {
  montageQualityOptions: Array<MontageQualityOption>;
  montageOutputTypes: Array<MontageFormatOption>;
}

const useMontageQualityOptions = (): ExposedUseMontageQualityOptionsProperties => {
  const dispatch: AppDispatch = useDispatch();
  const [montageQualityOptions, setMontageQualityOptions] = useState<Array<MontageQualityOption>>([]);
  const [montageOutputTypes, setMontageOutputTypes] = useState<Array<MontageFormatOption>>([]);

  const handleMontageOutputTypesFetching = useCallback((): void => {
    const params: GetUserSettingsRemoteParams = {
      key: UserSettingsKey.MontageQualityOutputType,
    };

    dispatch(getUserSettings(params))
      .then((response) => {
        const mappedMontageOutputTypeOptions = response.map((result) => {
          const found = MONTAGE_FORMAT_OPTIONS
            .find((option) => (option.id.toLowerCase()) === result?.toLowerCase());

          if (found) {
            return {
              ...found,
              id: result,
            };
          }

          return {
            id: result,
            name: result,
          };
        });

        const sortedOptions = mappedMontageOutputTypeOptions.sort((a, b) => {
          if (a?.order && b?.order) {
            return a?.order - b?.order;
          }
          return 0;
        });
        const mappedOptionsWithoutUnknownValues = sortedOptions
          .filter((option) => option.id !== MontageQualityOptionDTO.UNKNOWN);

        setMontageOutputTypes(mappedOptionsWithoutUnknownValues);
      });
  }, [dispatch]);

  const handleMontageQualityOptionFetching = useCallback((): void => {
    const params: GetUserSettingsRemoteParams = {
      key: UserSettingsKey.MontageQuality,
    };

    dispatch(getUserSettings(params))
      .then((response) => {
        const mappedMontageQualityOptions = response.map((result) => {
          const found = MONTAGE_QUALITY_OPTIONS
            .find((option) => (option.id.toLowerCase()) === result?.toLowerCase());

          if (found) {
            return {
              ...found,
              id: result,
            };
          }

          return {
            id: result,
            name: result,
          };
        });

        const sortedOptions = mappedMontageQualityOptions.sort((a, b) => {
          if (a?.order && b?.order) {
            return a?.order - b?.order;
          }
          return 0;
        });
        const mappedOptionsWithoutUnknownValues = sortedOptions
          .filter((option) => option.id !== MontageQualityOptionDTO.UNKNOWN);

        setMontageQualityOptions(mappedOptionsWithoutUnknownValues);
      });
  }, [dispatch]);

  useEffect(() => {
    handleMontageQualityOptionFetching();
  }, [handleMontageQualityOptionFetching]);

  useEffect(() => {
    handleMontageOutputTypesFetching();
  }, [handleMontageOutputTypesFetching]);

  const properties: ExposedUseMontageQualityOptionsProperties = useMemo(() => ({
    montageQualityOptions,
    montageOutputTypes,
  }), [montageOutputTypes, montageQualityOptions]);

  return {
    ...properties,
  };
};

export default useMontageQualityOptions;
