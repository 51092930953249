import React, { ReactNode, useState } from 'react';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme, Tooltip,
  Typography,
} from '@material-ui/core';
import { EMPTY_STRING, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { useTranslation } from 'react-i18next';
import { NavigationSectionItem, NeoBarItemType } from '../../../../../../types/layout/navBar';
import queryUrlParamsBuilder from '../../../../../../utils/query/QueryUrlUtils';
import useNeoBarRouter from '../../../hooks/useNeoBarRouter';
import { NeoBarItemSettings } from '../../../types/NeoSearch';
import TranslationNameSpaceEnum from '../../../../../../types/translation/TranslationNameSpaceEnum';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';
import { Subquery } from '../../../../../../utils/common/NavBarUtils';

export interface NeoBarDefaultListItemPropTypes {
    children?: ReactNode;
    type: NeoBarItemType;
    item: NavigationSectionItem;
    configuration: NeoBarConfiguration;
    settings: NeoBarItemSettings;
}

const useStyles = makeStyles((theme: Theme) => ({
  isLiveQuery: {
    marginTop: theme.spacing(0.4),
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(0.4),
    paddingRight: theme.spacing(0.4),
    width: '28px',
    textAlign: 'center',
    border: '1px solid gray',
    fontSize: 10,
    opacity: 0.5,
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.3),
  },
  title: {
    marginRight: 'auto',
    alignSelf: 'center',
  },
  active: {
    '&$title': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    borderColor: theme.palette.secondary.main,
  },
  containsSubQueries: {
    marginLeft: '24px',
  },
  nested: {
    paddingLeft: theme.spacing(5),
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  border: {
    borderRight: 3,
  },
  listText: {
    marginTop: '7.5px',
    marginBottom: '7.5px',
  },
}));

function NeoBarDefaultListItem(props: NeoBarDefaultListItemPropTypes): JSX.Element {
  const {
    item, type, settings, configuration,
  } = props;
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { getPath } = useNeoBarRouter();

  const handleToggle = (event): void => {
    event.preventDefault();
    event.stopPropagation();

    setOpen((prevState) => !prevState);
  };

  const handleSubQueryClick = (subquery: Subquery) => (): void => {
    const queryParams = queryUrlParamsBuilder(type, subquery.id);
    const pathname = getPath();
    const queryUrl = {
      pathname,
      search: queryParams,
    };

    history.push(queryUrl);
  };

  const handleClick = (): void => {
    const queryParams = queryUrlParamsBuilder(
      type, item.id, item.text || EMPTY_STRING, item.subQueries, item.filters,
    );

    const pathname = getPath();

    const queryUrl = {
      pathname,
      search: queryParams,
    };

    if (settings.selected) {
      history.push({
        pathname: queryUrl.pathname,
        search: EMPTY_STRING,
      });
    } else {
      history.push(queryUrl);
    }
  };

  const getArrowsBasedOnOpenState = (): JSX.Element | NotFound => {
    if (item.subQueries.length === 0) {
      return NOT_FOUND;
    }

    if (open) {
      return (
          <Tooltip title={t('neoBar.neoItem.hide.subqueries') || EMPTY_STRING}>
              <ArrowDropDown
                color="inherit"
                onClick={handleToggle}
              />
          </Tooltip>
      );
    }

    return (
        <Tooltip title={t('neoBar.neoItem.show.subqueries') || EMPTY_STRING}>
            <ArrowRight
              color="inherit"
              onClick={handleToggle}
            />
        </Tooltip>
    );
  };

  const getExtendedActions = (): JSX.Element => {
    if (item.live) {
      return (
          <Typography className={classes.isLiveQuery}>
              {t('neoBar.neoItem.live')}
          </Typography>
      );
    }

    return (
        <>
        </>
    );
  };

  const getTitle = (): JSX.Element => (
      <Typography
        variant="h5"
        className={clsx(
          classes.title,
          { [classes.active]: settings.selected },
          { [classes.containsSubQueries]: item.subQueries.length === 0 },
        )}
      >
          {item.name}
      </Typography>
  );

  const getSubQueries = (): Array<JSX.Element> => item.subQueries.map((subquery) => (
      <ListItem
        key={subquery.id}
        className={clsx(classes.item, classes.button, classes.nested)}
        onClick={handleSubQueryClick(subquery)}
        button
      >
          {configuration.display.settings.defaultList.displaySubQueryIcon && (
              <ListItemIcon>
                  <SubdirectoryArrowRightIcon />
              </ListItemIcon>
          )}
          <ListItemText primary={(
              <Typography
                variant="h5"
                className={classes.title}
              >
                  {subquery.name}
              </Typography>
          )}
          />
      </ListItem>
  ));

  const getListImplementation = (): JSX.Element => (
      <>
          <ListItem
            className={clsx(classes.item, classes.button)}
            selected={settings.selected}
            onClick={handleClick}
            dense
            button
            disableGutters
          >
              <Box
                className={clsx(
                  classes.box,
                  { [classes.border]: settings.selected },
                )}
                px={1}
              >
                  {getArrowsBasedOnOpenState()}
                  <ListItemText
                    className={classes.listText}
                    primary={getTitle()}
                  />
                  {getExtendedActions()}
              </Box>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div">
                  {getSubQueries()}
              </List>
          </Collapse>
      </>
  );

  return getListImplementation();
}

export default NeoBarDefaultListItem;
