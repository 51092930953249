export const ENTER_KEY_CODE = 13;
export const SPACE_KEY = 32;
export const K_KEY = 75;
export const L_KEY = 76;
export const J_KEY = 74;
export const EQUAL_KEY = 187;
export const MINUS_KEY = 189;
export const R_KEY = 82;
export const T_KEY = 84;
export const ARROW_UP_KEY = 38;
export const ARROW_DOWN_KEY = 40;
export const TAB_KEY_CODE = 9;
export const ESCAPE_KEY = 27;
