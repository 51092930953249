enum MetadataTypeEnum {
PUBLICITY_VALUE = 'Publicity Value',
AUDIENCE_ESTIMATE = 'Audience Estimate',
CALC_AD_VALUE = 'Calc Ad Value',
CPM = 'CPM',
MARKET = 'Market',
DATE = 'Date',
MARKET_TIMEZONE = 'Market Timezone',
MARKET_RANK = 'Market Rank',
PLACEMENT = 'Placement',
TONE = 'Tone',
PROMINENCE = 'Prominence',
RUNTIME = 'Runtime',
RANK = 'Rank',
THIRTY_SEC_AD_VALUE = '30s Ad Value',
SOURCE_TYPE = 'Source Type',
PAGES = 'Page',
PAGE_VIEWS = 'Page Views',
SURFACE = 'Surface',
TEXT_TYPE = 'Text Type',
AUTHOR = 'Author',
AVERAGE_STAY = 'Average Stay',
FREQUENCY = 'Frequency',
LANGUAGE = 'Language',
WORD_COUNT = 'Word Count',
TEXT = 'TEXT',
ABSTRACT_TEXT = 'Abstract Text',
ORIGIN= 'Captions Type',
UNIQUE_VISITORS= 'Unique Visitors',
UNKNOWN = 'UNKNOWN',
}

export type MetadataTypeEnums = TelevisionMetadataTypeEnum
    | RadioMetadataTypeEnum
    | PrintMetadataTypeEnum
    | WebMetadataTypeEnum;

export enum TelevisionMetadataTypeEnum {
    PUBLICITY_VALUE = 'Publicity Value',
    AUDIENCE_ESTIMATE = 'Audience Estimate',
    CALC_AD_VALUE = 'Calc Ad Value',
    MARKET = 'Market',
    DATE = 'Date',
    MARKET_RANK = 'Market Rank',
    PLACEMENT = 'Placement',
    TONE = 'Tone',
    PROMINENCE = 'Prominence',
    RUNTIME = 'Runtime',
    THIRTY_SEC_AD_VALUE = '30s Ad Value',
    SOURCE_TYPE = 'Source Type',
    LANGUAGE = 'Language',
    UNKNOWN = 'UNKNOWN',
}

export enum RadioMetadataTypeEnum {
    PUBLICITY_VALUE = 'Publicity Value',
    AUDIENCE_ESTIMATE = 'Audience Estimate',
    CALC_AD_VALUE = 'Calc Ad Value',
    MARKET = 'Market',
    DATE = 'Date',
    MARKET_RANK = 'Market Rank',
    PLACEMENT = 'Placement',
    TONE = 'Tone',
    PROMINENCE = 'Prominence',
    RUNTIME = 'Runtime',
    THIRTY_SEC_AD_VALUE = '30s Ad Value',
    SOURCE_TYPE = 'Source Type',
    LANGUAGE = 'Language',
    UNKNOWN = 'UNKNOWN',
}

export enum PrintMetadataTypeEnum {
    PUBLICITY_VALUE = 'Publicity Value',
    AUDIENCE_ESTIMATE = 'Audience Estimate',
    CALC_AD_VALUE = 'Calc Ad Value',
    MARKET = 'Market',
    DATE = 'Date',
    MARKET_RANK = 'Market Rank',
    PLACEMENT = 'Placement',
    TONE = 'Tone',
    PROMINENCE = 'Prominence',
    THIRTY_SEC_AD_VALUE = '30s Ad Value',
    SOURCE_TYPE = 'Source Type',
    PAGES = 'Page',
    SURFACE = 'Surface',
    TEXT_TYPE = 'Text Type',
    AUTHOR = 'Author',
    FREQUENCY = 'Frequency',
    LANGUAGE = 'Language',
    WORD_COUNT = 'Word Count',
    UNKNOWN = 'UNKNOWN',
}

export enum WebMetadataTypeEnum {
    PUBLICITY_VALUE = 'Publicity Value',
    AUDIENCE_ESTIMATE = 'Audience Estimate',
    CALC_AD_VALUE = 'Calc Ad Value',
    MARKET = 'Market',
    DATE = 'Date',
    MARKET_RANK = 'Market Rank',
    PLACEMENT = 'Placement',
    TONE = 'Tone',
    PROMINENCE = 'Prominence',
    THIRTY_SEC_AD_VALUE = '30s Ad Value',
    SOURCE_TYPE = 'Source Type',
    TEXT_TYPE = 'Text Type',
    AUTHOR = 'Author',
    FREQUENCY = 'Frequency',
    LANGUAGE = 'Language',
    WORD_COUNT = 'Word Count',
    UNKNOWN = 'UNKNOWN',
}

export default MetadataTypeEnum;
