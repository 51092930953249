import {
  APPLY_SEARCH_TERMS,
  HANDLE_ARROW_DOWN_KEY,
  HANDLE_ARROW_UP_KEY,
  HANDLE_ESCAPE,
  HANDLE_TAB_KEY,
} from '../../draft-js/DraftCommands';
import {
  ARROW_DOWN_KEY, ARROW_UP_KEY, ENTER_KEY_CODE, ESCAPE_KEY, TAB_KEY_CODE,
} from '../../KeyPropertyValues';

export const ADD_NEW_LINE = 'ADD_NEW_LINE';

export const FOCUS_STATE_COMMANDS = [
  // APPLY_SEARCH_TERMS,
  HANDLE_ARROW_UP_KEY,
  HANDLE_ARROW_DOWN_KEY,
];

export const COMMAND_KEY_MAPPER = {
  [APPLY_SEARCH_TERMS]: ENTER_KEY_CODE,
  [HANDLE_ARROW_UP_KEY]: ARROW_UP_KEY,
  [HANDLE_ARROW_DOWN_KEY]: ARROW_DOWN_KEY,
  [HANDLE_TAB_KEY]: TAB_KEY_CODE,
  [HANDLE_ESCAPE]: ESCAPE_KEY,
};

export const KEY_COMMAND_MAPPER = {
  [ENTER_KEY_CODE]: APPLY_SEARCH_TERMS,
  [ARROW_UP_KEY]: HANDLE_ARROW_UP_KEY,
  [ARROW_DOWN_KEY]: HANDLE_ARROW_DOWN_KEY,
  [TAB_KEY_CODE]: HANDLE_TAB_KEY,
  [ESCAPE_KEY]: HANDLE_ESCAPE,
};
