import React, { memo, useCallback } from 'react';
import {
  IconButton, ListItemText, Menu, MenuItem, Tooltip,
} from '@material-ui/core';
import { NotFound } from 'mediascouting-core-ui-common';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import {
  SELECTION_RANGE_OPTIONS,
  StorySnapSelectionRangeEnum,
} from '../../../../constants/StorySnapToolbarSelectionOptions';
import { separateThousands } from '../../../../utils/common/NumberUtils';
import { SelectionOption } from '../../../../types/query/Selector';
import TranslationNameSpaceEnum from '../../../../types/translation/TranslationNameSpaceEnum';

type SelectMenuOptionsPropTypes = {
    selectRef: React.RefObject<HTMLButtonElement>;
    open: boolean;
    handleMenuClose: () => void;
    onOptionSelected: (option) => void;
    totalStories: number | NotFound;
}

export const SELECT_QUERY_ALL_STORIES_LIMIT = 100_000;

function SelectionRangeMenuOptions(props: SelectMenuOptionsPropTypes): JSX.Element {
  const {
    handleMenuClose, onOptionSelected, selectRef, open, totalStories,
  } = props;
  const { t } = useTranslation(TranslationNameSpaceEnum.STORIES);

  const handleOptionSelected = useCallback((option: SelectionOption) => (): void => {
    onOptionSelected(option);
  }, [onOptionSelected]);

  const renderListItems = useCallback(() => SELECTION_RANGE_OPTIONS.map(
    (option) => {
      if (option.range === StorySnapSelectionRangeEnum.QUERY
            && totalStories
            && totalStories >= SELECT_QUERY_ALL_STORIES_LIMIT) {
        return (
            <Tooltip
              placement="top"
              title={(
                  <>
                      <Typography color="inherit">
                          {t('toolbar.action.select.count.unavailable', { sum: separateThousands(totalStories) })}
                      </Typography>
                  </>
              )}
            >
                <span>
                    <MenuItem
                      key={option.title}
                      onClick={handleOptionSelected(option)}
                      disabled
                    >
                        <ListItemText primary={t(option.title)} />

                        <IconButton>
                            <InfoIcon color="disabled" />
                        </IconButton>
                    </MenuItem>
                </span>
            </Tooltip>
        );
      }
      return (
          <MenuItem
            key={option.title}
            onClick={handleOptionSelected(option)}
          >
              <ListItemText primary={t(option.title)} />
          </MenuItem>
      );
    },
  ), [handleOptionSelected, t, totalStories]);
  return (
      <Menu
        anchorEl={selectRef.current}
        onClose={handleMenuClose}
        open={open}
        elevation={1}
      >
          {renderListItems()}
      </Menu>
  );
}

export default memo(SelectionRangeMenuOptions);
