import {
  getArrayAsString,
  getArrayOfStringsAsString,
  getMillisecondsInDay,
  PowerSearchFilterTypes,
  ShallowPowerSearchQueryFilters,
} from 'mediascouting-core-ui-common';
import { NeoBarItemType } from '../../types/layout/navBar';
import UrlParams from '../../types/UrlParams';
import { Subquery } from '../common/NavBarUtils';
import { PowerSearchFilterTypeToQueryFilterParameterMapper } from '../common/PowerSearchUtils';

export const applyQuerySearchParameters = (
  params: URLSearchParams,
  filters: PowerSearchFilterTypes,
  getSearchText: () => string,
  existingQueryId?: number,
  existingStoryDurationId?: number,
): URLSearchParams => {
  params.delete(UrlParams.FACETS);
  params.delete(UrlParams.PAGE);
  params.set(UrlParams.TEXT, getSearchText());

  if (existingQueryId !== undefined) {
    params.set(UrlParams.QUERY_ID, existingQueryId.toString());
  }

  if (filters[PowerSearchFilterTypes.START]) {
    const unix = Date.parse(filters[PowerSearchFilterTypes.START] as string);

    if (!Number.isNaN(unix)) {
      params.set(UrlParams.START_DATE, unix.toString());
    }
  } else {
    params.delete(UrlParams.START_DATE);
  }

  if (filters[PowerSearchFilterTypes.END]) {
    const unix = Date.parse(filters[PowerSearchFilterTypes.END] as string);

    if (!Number.isNaN(unix)) {
      params.set(UrlParams.END_DATE, unix.toString());
    }
  } else {
    params.delete(UrlParams.END_DATE);
  }

  if (filters[PowerSearchFilterTypes.START_TIME]) {
    const unix = Date.parse(filters[PowerSearchFilterTypes.START_TIME] as string);

    if (!Number.isNaN(unix)) {
      params.set(UrlParams.START_TIME_OF_DAY, `${getMillisecondsInDay(unix)}`);
    }
  } else {
    params.delete(UrlParams.START_TIME_OF_DAY);
  }

  if (filters[PowerSearchFilterTypes.END_TIME]) {
    const unix = Date.parse(filters[PowerSearchFilterTypes.END_TIME] as string);

    if (!Number.isNaN(unix)) {
      params.set(UrlParams.END_TIME_OF_DAY, `${getMillisecondsInDay(unix)}`);
    }
  } else {
    params.delete(UrlParams.END_TIME_OF_DAY);
  }

  if (filters[PowerSearchFilterTypes.TAGS].length) {
    params.set(UrlParams.TAGS, getArrayAsString(filters[PowerSearchFilterTypes.TAGS]));
  } else {
    params.delete(UrlParams.TAGS);
  }

  if (filters[PowerSearchFilterTypes.TITLE]) {
    params.set(UrlParams.TITLE, filters[PowerSearchFilterTypes.TITLE]);
  } else {
    params.delete(UrlParams.TITLE);
  }

  if (filters[PowerSearchFilterTypes.SOURCE_TYPES].length) {
    params.set(UrlParams.SOURCE_TYPES, getArrayAsString(filters[PowerSearchFilterTypes.SOURCE_TYPES]));
  } else {
    params.delete(UrlParams.SOURCE_TYPES);
  }

  if (filters[PowerSearchFilterTypes.LANGUAGES].length) {
    params.set(UrlParams.LANGUAGES, getArrayAsString(filters[PowerSearchFilterTypes.LANGUAGES]));
  } else {
    params.delete(UrlParams.LANGUAGES);
  }

  if (filters[PowerSearchFilterTypes.SOURCES].length) {
    params.set(UrlParams.SOURCES, getArrayAsString(filters[PowerSearchFilterTypes.SOURCES]));
  } else {
    params.delete(UrlParams.SOURCES);
  }

  if (filters[PowerSearchFilterTypes.SOURCE_GROUPS].length) {
    params.set(UrlParams.SOURCE_GROUPS, getArrayAsString(filters[PowerSearchFilterTypes.SOURCE_GROUPS]));
  } else {
    params.delete(UrlParams.SOURCE_GROUPS);
  }

  if (filters[PowerSearchFilterTypes.GEOLOCATION_TAGS].length) {
    params.set(UrlParams.GEOLOCATION_TAGS, getArrayAsString(filters[PowerSearchFilterTypes.GEOLOCATION_TAGS]));
  } else {
    params.delete(UrlParams.GEOLOCATION_TAGS);
  }

  if (filters[PowerSearchFilterTypes.MARKETS].length) {
    params.set(UrlParams.MARKETS, getArrayAsString(filters[PowerSearchFilterTypes.MARKETS]));
  } else {
    params.delete(UrlParams.MARKETS);
  }

  if (filters[PowerSearchFilterTypes.MARKET_GROUPS].length) {
    params.set(UrlParams.MARKET_GROUPS, getArrayAsString(filters[PowerSearchFilterTypes.MARKET_GROUPS]));
  } else {
    params.delete(UrlParams.MARKET_GROUPS);
  }

  if (filters[PowerSearchFilterTypes.SENTIMENTS].length) {
    params.set(UrlParams.SENTIMENTS, getArrayAsString(filters[PowerSearchFilterTypes.SENTIMENTS]));
  } else {
    params.delete(UrlParams.SENTIMENTS);
  }

  if (filters[PowerSearchFilterTypes.DAYS].length) {
    params.set(UrlParams.DAYS, getArrayAsString(filters[PowerSearchFilterTypes.DAYS]));
  } else {
    params.delete(UrlParams.DAYS);
  }

  if (filters[PowerSearchFilterTypes.TIME_SECTIONS].length) {
    params.set(UrlParams.TIME_SECTIONS, getArrayAsString(filters[PowerSearchFilterTypes.TIME_SECTIONS]));
  } else {
    params.delete(UrlParams.TIME_SECTIONS);
  }

  if (filters[PowerSearchFilterTypes.SUBQUERIES].length) {
    params.set(UrlParams.SUB_QUERIES, getArrayAsString(filters[PowerSearchFilterTypes.SUBQUERIES]));
  } else {
    params.delete(UrlParams.SUB_QUERIES);
  }

  if (filters[PowerSearchFilterTypes.PEOPLE].length) {
    params.set(UrlParams.PEOPLE, getArrayAsString(filters[PowerSearchFilterTypes.PEOPLE]));
  } else {
    params.delete(UrlParams.PEOPLE);
  }

  if (filters[PowerSearchFilterTypes.ORGANIZATIONS].length) {
    params.set(UrlParams.ORGANIZATIONS, getArrayAsString(filters[PowerSearchFilterTypes.ORGANIZATIONS]));
  } else {
    params.delete(UrlParams.ORGANIZATIONS);
  }

  if (filters[PowerSearchFilterTypes.AUTHORS].length) {
    params.set(UrlParams.AUTHORS, getArrayAsString(filters[PowerSearchFilterTypes.AUTHORS]));
  } else {
    params.delete(UrlParams.AUTHORS);
  }

  if (existingStoryDurationId || filters[PowerSearchFilterTypes.STORY_DURATION]) {
    params.set(UrlParams.STORY_DURATION, existingStoryDurationId || filters[PowerSearchFilterTypes.STORY_DURATION].id);
  } else {
    params.delete(UrlParams.STORY_DURATION);
  }

  if (filters[PowerSearchFilterTypes.ORIGIN]?.length) {
    params.set(UrlParams.ORIGIN, getArrayAsString(filters[PowerSearchFilterTypes.ORIGIN]));
  } else {
    params.delete(UrlParams.ORIGIN);
  }

  if (filters[PowerSearchFilterTypes.PROGRAMS]?.length) {
    params.set(UrlParams.PROGRAMS, getArrayOfStringsAsString(filters[PowerSearchFilterTypes.PROGRAMS]));
  } else {
    params.delete(UrlParams.PROGRAMS);
  }
  return params;
};

const queryUrlParamsBuilder = (
  type: NeoBarItemType,
  id?: number, queryText?: string,
  subQueries?: Array<Subquery>,
  activeFilters?: ShallowPowerSearchQueryFilters,
): string => {
  const querySearch = new URLSearchParams();

  if (id && type === NeoBarItemType.QUERY_TYPE) {
    querySearch.set(UrlParams.QUERY_ID, `${id}`);
  }

  if (queryText) {
    querySearch.set(UrlParams.TEXT, queryText);
  }

  if (subQueries && subQueries.length > 0) {
    querySearch.set(UrlParams.SUB_QUERIES, `[${subQueries.map((subQuery) => subQuery.id).join(',')}]`);
  }

  if (activeFilters) {
    Object.keys(activeFilters)
      .forEach((clipFilter) => {
        const urlParameterName = PowerSearchFilterTypeToQueryFilterParameterMapper[clipFilter];

        if (activeFilters[clipFilter] !== null && urlParameterName) {
          if (Array.isArray(activeFilters[clipFilter])) {
            querySearch.set(urlParameterName, `[${activeFilters[clipFilter].map((obj) => obj.id || obj).join(',')}]`);
          } else if (typeof activeFilters[clipFilter] === 'object') {
            if (activeFilters[clipFilter] instanceof Date) {
              querySearch.set(urlParameterName, activeFilters[clipFilter]);
            } else {
              querySearch.set(urlParameterName, activeFilters[clipFilter].id);
            }
          } else {
            querySearch.set(urlParameterName, activeFilters[clipFilter]);
          }
        }
      });
  }

  return querySearch.toString();
};

export default queryUrlParamsBuilder;
