import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';
import { setDisableFacets } from '../../../../constants/actions/creators/SettingsActions';
import { AppDispatch } from '../../../../redux/store';
import { ReduxState } from '../../../../redux/reducers';
import AntSwitch from '../../../../components/styled/AntSwitch';
import { LOCAL_STORAGE_DISABLE_FACETS } from '../../../../constants/LocalStorage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function FacetSwitchSettings(): JSX.Element {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const disableFacets = useSelector((state: ReduxState) => state.settings.disableFacets);
  const { t } = useTranslation();

  const handleChange = (event): void => {
    if (event.target.checked) {
      dispatch(setDisableFacets(true));
      localStorage.setItem(LOCAL_STORAGE_DISABLE_FACETS, 'true');
    } else {
      dispatch(setDisableFacets(false));
      localStorage.setItem(LOCAL_STORAGE_DISABLE_FACETS, 'false');
    }
  };

  return (
      <FormGroup>
          <FormLabel component="legend">
              <Typography variant="overline">
                  {t('layout.topBar.search.searchBar.settings.facets')}
              </Typography>
          </FormLabel>
          <Typography className={classes.root} component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>{t('layout.topBar.search.searchBar.settings.facets.off')}</Grid>
                  <Grid item>
                      <AntSwitch
                        checked={disableFacets}
                        onChange={handleChange}
                        name="facets"
                      />
                  </Grid>
                  <Grid item>{t('layout.topBar.search.searchBar.settings.facets.on')}</Grid>
              </Grid>
          </Typography>
      </FormGroup>
  );
}

export default FacetSwitchSettings;
