import {
  DateRange,
  MontageItem,
  MontageListItem,
  MontageStatusEnum,
  NOT_FOUND,
  NotFound,
  NotificationTypeEnum,
} from 'mediascouting-core-ui-common';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { HeartbeatDataChange, HeartbeatDataType } from '../redux/reducers/Heartbeat';
import { getMontages } from '../remote/Montage';
import useDataUpdateProbes from './useDataUpdateProbes';
import { DEFAULT_MONTAGE_ITEM } from '../components/features/montage/MontageHistory/constants';

function useMontagePollingWrapper(): void {
  const history = useHistory();

  const onNotificationClick = (): void => {
    if (history) {
      history.push({
        pathname: '/portal/montages',
      });
    }
  };

  const isMontageListItemPendingStatus = (item: MontageListItem): boolean => item.status === MontageStatusEnum.PENDING;

  const hasMontageListItemPending = useCallback((montageItem: MontageItem): boolean => montageItem.list
    .map((item) => isMontageListItemPendingStatus(item)).some((bool) => bool), []);

  const findMontageInList = (id: number, montageList: Array<MontageItem>): MontageItem | NotFound => montageList
    .find((montage) => montage.id === id);

  const findMontageListItem = (
    dateRanges: Array<DateRange>,
    montageListItems: Array<MontageListItem> | NotFound,
  ): MontageListItem | NotFound => montageListItems
    ?.find((montageListItem) => montageListItem.ranges[0]?.start.getTime() === dateRanges[0]?.start.getTime()
      && montageListItem.ranges[0]?.end.getTime() === dateRanges[0]?.end.getTime());

  const montagesMatch = useCallback((
    prevMontages: Array<MontageItem>,
    incomingMontages: Array<MontageItem>,
  ): boolean => {
    const booleans = prevMontages
      .map((prevMontageItem) => {
        const incomingMontageItem = findMontageInList(prevMontageItem.id, incomingMontages);

        const prevMatchesIncomingStatus = prevMontageItem.list
          .map((prevMontageListItem) => prevMontageListItem
            .status === findMontageListItem(prevMontageListItem.ranges, incomingMontageItem?.list)?.status);
        return prevMatchesIncomingStatus.every((matched) => matched);
      });
    return booleans.every((matches) => matches);
  }, []);

  const matches = useCallback((prevMontages: Array<MontageItem>, incomingMontages: Array<MontageItem>): boolean => {
    if (incomingMontages.length > prevMontages.length) {
      return false;
    }
    if (incomingMontages.length === prevMontages.length) {
      return montagesMatch(prevMontages, incomingMontages);
    }
    return true;
  }, [montagesMatch]);

  const generatedId = useMemo(() => new Date().getTime(), []);

  useDataUpdateProbes<MontageItem>({
    fetchData: getMontages,
    getPending: hasMontageListItemPending,
    matches,
    millisecondInterval: 120_000,
    notification: {
      id: `${generatedId}`,
      title: 'Media Archive',
      description: 'Montages have been updated',
      type: NotificationTypeEnum.INFO,
      onClick: onNotificationClick,
      read: false,
      date: new Date(),
    },
    heartbeat: {
      type: HeartbeatDataType.MONTAGE,
      cause: HeartbeatDataChange.CREATE,
      identification: {
        id: generatedId,
      },
    },
    defaultObjectOnCreate: DEFAULT_MONTAGE_ITEM,
  });

  return NOT_FOUND;
}

export default useMontagePollingWrapper;
