import React, { memo } from 'react';
import {
  Card, CardContent, CardHeader, Divider, List, makeStyles, Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import Item from './Item';
import { ShoppingItem } from '../../../../../types/layout/topBar/Cart';

type ShoppingListPropTypes = {
  shoppingList: Array<ShoppingItem>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function ShoppingList(props: ShoppingListPropTypes): JSX.Element {
  const { shoppingList } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <Card variant="outlined">
          <CardHeader title={t('layout.topBar.search.searchBar.cart.shopping.list.title') || EMPTY_STRING} />
          <Divider />
          <CardContent>
              <List
                className={classes.root}
                classes={{ root: classes.listRoot }}
              >
                  {shoppingList.map((shoppingItem, index) => {
                    const uniqueIdentity = shoppingItem.itemId + index;

                    return (
                        <React.Fragment key={uniqueIdentity}>
                            <Item
                              key={uniqueIdentity}
                              shoppingItem={shoppingItem}
                              index={index}
                            />
                            <Divider key={`${uniqueIdentity}divider`} variant="inset" component="li" />
                        </React.Fragment>
                    );
                  })}
              </List>
          </CardContent>
      </Card>

  );
}

export default memo(ShoppingList);
