import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

type ConditionMatcherPropTypes = {
    children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'uppercase',
  },
}));

function ConditionMatcher(props: ConditionMatcherPropTypes): JSX.Element {
  const classes = useStyles();
  const { children } = props;

  return (
      <Typography className={classes.root} component="span" color="primary">
          <b>{children}</b>
      </Typography>

  );
}

export default memo(ConditionMatcher);
