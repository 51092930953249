import React from 'react';

interface PublicGuardPropTypes {
  children: React.ReactNode;
}

function PublicGuard(props: PublicGuardPropTypes): React.ReactNode {
  const { children } = props;

  return children;
}

export default PublicGuard;
