import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  Box, LinearProgress, makeStyles, Theme, useMediaQuery,
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

function LoadingScreen(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  const getWidth = (): number => {
    if (onMobile) {
      return 300;
    }

    return 400;
  };

  return (
      <div className={classes.root}>
          <Box width={getWidth()}>
              <LinearProgress />
          </Box>
      </div>
  );
}

export default LoadingScreen;
