import {
  buildFeedbackMessage,
  convertResponseIntoPowerSearchModel,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetPowerSearchOptionsResponse,
  onFeedback,
  PowerSearchFilterTypes,
  PowerSearchSynchronousQueryFilters,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';

import axios from '../utils/common/AxiosUtils';

import { setDefaultPowerSearchOptionsInCache } from '../constants/actions/creators/CacheActions';
import { setStoryDuration } from '../constants/actions/creators/QuerySearchSpaceActions';

const getPowerSearchOptions = (
  cancelToken?: CancelToken,
) => (dispatch): Promise<PowerSearchSynchronousQueryFilters> => axios
  .get<GetPowerSearchOptionsResponse>('/power-search-params', {
    cancelToken,
  })
  .then(({ data }) => {
    const options = convertResponseIntoPowerSearchModel(data);

    if (options[PowerSearchFilterTypes.STORY_DURATION].length > 0) {
      dispatch(setStoryDuration(options[PowerSearchFilterTypes.STORY_DURATION][0]));
    }
    dispatch(setDefaultPowerSearchOptionsInCache(options));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_POWER_SEARCH_OPTIONS]}`)
      .setNotifyUser(false)
      .build()));

    return options;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_POWER_SEARCH_OPTIONS]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export default getPowerSearchOptions;
