import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetGroupsResponse,
  getGroupTransformationUtilities,
  Group, GroupDTO,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';

import axios from '../utils/common/AxiosUtils';

const getGroups = (params?: object, cancelToken?: CancelToken) => (dispatch): Promise<PagedContent<Group>> => axios
  .get<GetGroupsResponse>('/groups', {
    params,
    cancelToken,
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_GROUPS]}`)
      .setNotifyUser(false)
      .build()));
    return getGroupTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_GROUPS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getGroup = (id: number) => (dispatch): Promise<Group> => axios
  .get<GroupDTO>(`/groups/${id}`, {
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_GROUP]}`)
      .setNotifyUser(false)
      .build()));

    return getGroupTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_GROUP]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export default getGroups;
