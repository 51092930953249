import { SettingsActions } from '../../constants/actions/creators/SettingsActions';
import {
  APPLY_VIEW_TYPE,
  ENTER_FULL_SCREEN,
  EXIT_FULL_SCREEN,
  SET_DISABLE_FACETS,
  SET_DISPLAY_ERROR_NOTIFICATIONS,
  SET_NEO_BAR,
} from '../../constants/actions/types/ScreenSettingsActionTypes';
import { ViewTypeEnum } from '../../types/query/results/ViewTypes';
import {
  DEFAULT_VIEW_TYPE,
  DISPLAY_ERROR_BASED_NOTIFICATIONS,
  LOCAL_STORAGE_DISABLE_FACETS, LOCAL_STORAGE_NEO_SETTINGS,
} from '../../constants/LocalStorage';
import { NeoBarConfiguration } from '../../components/common/NeoBar/types/NeoBarConfiguration';
import PLATFORM_CONFIGURATION from '../../configuration';

export type SettingsReducer = {
  displayErrorNotifications: boolean;
  fullScreen: boolean;
  disableFacets: boolean;
  mode: ViewTypeEnum;
  neoBar: NeoBarConfiguration;
}

const getInitialStateOfDisplayErrorNotifications = (): boolean => {
  const localState = localStorage.getItem(DISPLAY_ERROR_BASED_NOTIFICATIONS);

  return localState === 'true';
};

const getInitialStateOfDisabledFacets = (): boolean => {
  const localState = localStorage.getItem(LOCAL_STORAGE_DISABLE_FACETS);

  return localState === 'true';
};

const getNeoSettingsFromLocalMemoryOrConfiguration = (): NeoBarConfiguration => {
  const neoSettingsInStringFormat = localStorage.getItem(LOCAL_STORAGE_NEO_SETTINGS);

  if (neoSettingsInStringFormat) {
    try {
      const parsed: NeoBarConfiguration = JSON.parse(neoSettingsInStringFormat);

      if (parsed?.version === PLATFORM_CONFIGURATION.schema.neoNavigationSideBar.neo.version) {
        return parsed;
      }
    } catch (e) {
      return PLATFORM_CONFIGURATION.schema.neoNavigationSideBar.neo;
    }
  }

  return PLATFORM_CONFIGURATION.schema.neoNavigationSideBar.neo;
};

const INIT_STATE: SettingsReducer = {
  fullScreen: false,
  disableFacets: getInitialStateOfDisabledFacets(),
  displayErrorNotifications: getInitialStateOfDisplayErrorNotifications(),
  mode: (localStorage.getItem(DEFAULT_VIEW_TYPE) as ViewTypeEnum | null) || ViewTypeEnum.GRID_VIEW_TYPE,
  neoBar: { ...getNeoSettingsFromLocalMemoryOrConfiguration() },
};

export default (state = INIT_STATE, action: SettingsActions): SettingsReducer => {
  switch (action.type) {
    case ENTER_FULL_SCREEN: {
      return {
        ...state,
        fullScreen: true,
      };
    }
    case EXIT_FULL_SCREEN: {
      return {
        ...state,
        fullScreen: false,
      };
    }
    case APPLY_VIEW_TYPE: {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case SET_DISPLAY_ERROR_NOTIFICATIONS: {
      return {
        ...state,
        displayErrorNotifications: action.payload,
      };
    }
    case SET_DISABLE_FACETS: {
      return {
        ...state,
        disableFacets: action.payload,
      };
    }
    case SET_NEO_BAR: {
      localStorage.setItem(LOCAL_STORAGE_NEO_SETTINGS, JSON.stringify(action.payload));

      return {
        ...state,
        neoBar: action.payload,
      };
    }
    default:
      return state;
  }
};
