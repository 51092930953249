import {
  Drawer, Hidden, makeStyles,
} from '@material-ui/core';
import React from 'react';

interface MobileDrawerPropTypes {
    open: boolean;
    onNavClose: VoidFunction;
    children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: 280,
  },
}));

function MobileDrawer(props: MobileDrawerPropTypes): JSX.Element {
  const { children, onNavClose, open } = props;
  const classes = useStyles();

  return (
      <Hidden lgUp>
          <Drawer
            anchor="left"
            variant="temporary"
            classes={{ paper: classes.drawerPaper }}
            onClose={onNavClose}
            open={open}
          >
              {children}
          </Drawer>
      </Hidden>
  );
}

export default MobileDrawer;
