import React from 'react';
import { FormikHelpers } from 'formik';
import FormFields, { OrganizationTypeConfigurationFormFields } from './FormFields';

export interface OrganizationTypeConfigurationPropTypes {
    onSubmit: (
        values: OrganizationTypeConfigurationFormFields,
        helpers: FormikHelpers<OrganizationTypeConfigurationFormFields>,
    ) => Promise<void>;
    onBack: () => void;
    onClose: () => void;
}

function OrganizationTypeConfiguration(props: OrganizationTypeConfigurationPropTypes): JSX.Element {
  const { onSubmit, onClose, onBack } = props;

  return (
      <FormFields
        onSubmit={onSubmit}
        onClose={onClose}
        onBack={onBack}
      />
  );
}

export default OrganizationTypeConfiguration;
