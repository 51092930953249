import { ParsedSegmentType } from 'mediascouting-core-ui-common';
import { EntityTypesEnum } from './EntityTypes';

const PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER = {
  [ParsedSegmentType.EXACT_TEXT_MATCH]: EntityTypesEnum.EXACT_TEXT_MATCH,
  [ParsedSegmentType.NAND]: EntityTypesEnum.NAND_CONDITION_MATCHER,
  [ParsedSegmentType.AND]: EntityTypesEnum.AND_CONDITION_MATCHER,
  [ParsedSegmentType.OR]: EntityTypesEnum.OR_CONDITION_MATCHER,
  [ParsedSegmentType.NOT]: EntityTypesEnum.NOT_CONDITION_MATCHER,
  [ParsedSegmentType.FREE_STYLE_TEXT]: EntityTypesEnum.SIMPLE_INPUT,
  [ParsedSegmentType.SUBQUERIES]: EntityTypesEnum.SUBQUERIES,
  [ParsedSegmentType.TAGS]: EntityTypesEnum.TAGS,
  [ParsedSegmentType.START]: EntityTypesEnum.START,
  [ParsedSegmentType.END]: EntityTypesEnum.END,
  [ParsedSegmentType.START_TIME]: EntityTypesEnum.START_TIME,
  [ParsedSegmentType.END_TIME]: EntityTypesEnum.END_TIME,
  [ParsedSegmentType.DAYS]: EntityTypesEnum.DAYS,
  [ParsedSegmentType.TIME]: EntityTypesEnum.TIME_SECTIONS,
  [ParsedSegmentType.SOURCES]: EntityTypesEnum.SOURCES,
  [ParsedSegmentType.SOURCE_GROUPS]: EntityTypesEnum.SOURCE_GROUPS,
  [ParsedSegmentType.TYPE]: EntityTypesEnum.SOURCE_TYPES,
  [ParsedSegmentType.MARKETS]: EntityTypesEnum.MARKET_TAGS,
  [ParsedSegmentType.MARKET_GROUPS]: EntityTypesEnum.MARKET_GROUPS,
  [ParsedSegmentType.OWNER_GROUPS]: EntityTypesEnum.OWNER_GROUPS,
  [ParsedSegmentType.AUTHORS]: EntityTypesEnum.AUTHORS,
  [ParsedSegmentType.TITLE]: EntityTypesEnum.TITLE,
  [ParsedSegmentType.LANGUAGES]: EntityTypesEnum.LANGUAGE,
  [ParsedSegmentType.GEOLOCATION]: EntityTypesEnum.GEOLOCATION_TAGS,
  [ParsedSegmentType.SENTIMENT]: EntityTypesEnum.SENTIMENT,
  [ParsedSegmentType.PEOPLE]: EntityTypesEnum.PEOPLE,
  [ParsedSegmentType.ORGANIZATIONS]: EntityTypesEnum.ORGANIZATIONS,
  [ParsedSegmentType.FILTER_OPTION]: EntityTypesEnum.FILTER_OPTION,
  [ParsedSegmentType.TEXT_TYPES]: EntityTypesEnum.ORIGIN,
  [ParsedSegmentType.PROGRAMS]: EntityTypesEnum.PROGRAMS,
};

export default PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER;
