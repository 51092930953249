import {
  EMPTY_STRING,
  MontageItem,
  MontageOutputOptionDTO,
  MontageQualityOptionDTO,
} from 'mediascouting-core-ui-common';

export const NEW_MONTAGE_ITEM_ID = -1;

export const DEFAULT_MONTAGE_ITEM: MontageItem = {
  id: NEW_MONTAGE_ITEM_ID,
  name: EMPTY_STRING,
  description: EMPTY_STRING,
  createdAt: new Date(),
  list: [],
  outputOption: MontageOutputOptionDTO.MP3,
  qualityOption: MontageQualityOptionDTO.UNKNOWN,
};
