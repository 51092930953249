import { makeStyles, Theme } from '@material-ui/core';
import {
  blue, deepOrange, deepPurple, green, grey, indigo, orange,
} from '@material-ui/core/colors';
import { BaseCreateCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { EMPTY_OBJECT, NotFound } from 'mediascouting-core-ui-common';
import { NeoSearchEntityTypeEnum } from '../types/NeoSearch';

interface ExportedUseNeoBarRouterProperties {
  getNeoBarNavigationItemTypeColor: (type: NeoSearchEntityTypeEnum) => string;
}

interface UseNeoBarColorsPropTypes {
  desiredBackgroundColor?: string;
}

export interface DesiredAvatarProps {
  desiredBackgroundColor: string | NotFound;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: grey[250],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  blue: {
    color: '#fff',
    backgroundColor: blue[500],
  },
  indigo: {
    color: '#fff',
    backgroundColor: indigo[500],
  },
  yellow: {
    color: '#fff',
    backgroundColor: orange[500],
  },
  desiredAvatarColors: (styleProps: DesiredAvatarProps): BaseCreateCSSProperties<DesiredAvatarProps> => {
    if (styleProps.desiredBackgroundColor) {
      return {
        color: theme.palette.getContrastText(styleProps.desiredBackgroundColor),
        backgroundColor: styleProps.desiredBackgroundColor,
      };
    }

    return EMPTY_OBJECT;
  },
}));

const useNeoBarColors = (props: UseNeoBarColorsPropTypes): ExportedUseNeoBarRouterProperties => {
  const { desiredBackgroundColor } = props;
  const classes = useStyles({
    desiredBackgroundColor,
  });

  const MAPPER: { [key in NeoSearchEntityTypeEnum]: string } = {
    [NeoSearchEntityTypeEnum.GENERAL]: classes.indigo,
    [NeoSearchEntityTypeEnum.ORGANIZATION]: classes.orange,
    [NeoSearchEntityTypeEnum.GEOLOCATION]: classes.green,
    [NeoSearchEntityTypeEnum.PERSON]: classes.blue,
    [NeoSearchEntityTypeEnum.TOPIC]: classes.purple,
    [NeoSearchEntityTypeEnum.SHARED]: classes.yellow,
    [NeoSearchEntityTypeEnum.QUERY]: classes.yellow,
    [NeoSearchEntityTypeEnum.TAG]: classes.yellow,
  };

  const getNeoBarNavigationItemTypeColor = (type: NeoSearchEntityTypeEnum): string => {
    const color = MAPPER[type];

    if (desiredBackgroundColor) {
      return classes.desiredAvatarColors;
    }

    if (color) {
      return color;
    }

    return classes.yellow;
  };

  return {
    getNeoBarNavigationItemTypeColor,
  };
};

export default useNeoBarColors;
