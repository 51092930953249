import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { NOT_FOUND, NotFound, User } from 'mediascouting-core-ui-common';
import { ReduxState } from '../../../redux/reducers';

type AuthGuardPropTypes = {
  children: React.ReactNode;
};

function AuthGuard(props: AuthGuardPropTypes): React.ReactNode {
  const { children } = props;
  const location = useLocation();
  const user = useSelector((state: ReduxState) => state.auth.user);
  const previousUser = useRef<User | NotFound>(NOT_FOUND);

  useEffect(() => {
    previousUser.current = user;
  });

  if (!user) {
    const redirectionState = previousUser.current ? NOT_FOUND : {
      from: location,
    };

    return (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: redirectionState,
          }}
        />
    );
  }

  return children;
}

export default AuthGuard;
