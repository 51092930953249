import initialAxiosInstance, { CancelToken } from 'axios';
import {
  buildFeedbackMessage,
  CreateDataExportTaskRequest,
  CreateDataExportTaskResponse,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  ExportItem,
  FeedbackType,
  GetDataExportTasksResponse,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import axios from '../utils/common/AxiosUtils';

import { beat } from '../constants/actions/creators/HeartbeatActions';
import { HeartbeatDataChange, HeartbeatDataType, HeartbeatMonitor } from '../redux/reducers/Heartbeat';
import getExportTransformationUtilities from '../utils/transformations/export/ExportTransformationUtils';

export const getDataExportTasks = (
  params?: object, cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<ExportItem>> => axios.get<GetDataExportTasksResponse>('/export', {
  params, cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_DATA_EXPORT_TASKS]}`)
      .setNotifyUser(false)
      .build()));
    return getExportTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_DATA_EXPORT_TASKS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

const createDataExportTask = (
  request: CreateDataExportTaskRequest,
) => (dispatch): Promise<ExportItem> => axios
  .post<CreateDataExportTaskResponse>('/export/new', request)
  .then(({ data }) => {
    const heartbeatPayload: HeartbeatMonitor = {
      type: HeartbeatDataType.EXPORT,
      cause: HeartbeatDataChange.CREATE,
      identification: {},
    };

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.CREATE_DATA_EXPORT_TASK]}`)
      .setNotifyUser(true)
      .build()));
    dispatch(beat(heartbeatPayload));

    return getExportTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.CREATE_DATA_EXPORT_TASK]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export default createDataExportTask;
