/* eslint-disable @typescript-eslint/camelcase */
const FACET_TO_FILTER_MAPPER = {
  'sub-queries': 'subqueries',
  tags: 'tags',
  market: 'markets',
  language: 'languages',
  geolocation: 'geolocations',
  sentiment: 'sentiments',
  time_sections: 'times',
  sources: 'sources',
  source_type: 'sourcetypes',
  days: 'days',
  authors: 'authors',
};

export default FACET_TO_FILTER_MAPPER;
