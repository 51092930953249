export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const RESET_QUERY_PARAMS = 'RESET_QUERY_PARAMS';
export const SET_QUERY_TEXT = 'SET_QUERY_TEXT';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_TITLE = 'SET_TITLE';
export const SET_SUB_QUERIES = 'SET_SUB_QUERIES';
export const SET_TAGS = 'SET_TAGS';
export const SET_SOURCE_TYPES = 'SET_SOURCE_TYPES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SOURCES = 'SET_SOURCES';
export const SET_GEOLOCATION_TAGS = 'SET_GEOLOCATION_TAGS';
export const SET_MARKET = 'SET_MARKET';
export const SET_SENTIMENT = 'SET_SENTIMENT';
export const SET_ADS = 'SET_ADS';
export const SET_DAYS = 'SET_DAYS';
export const SET_TIME_SECTION = 'SET_TIME_SECTION';
export const SET_PEOPLE = 'SET_PEOPLE';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PREVIEW_NEW_ITEMS = 'SET_PREVIEW_NEW_ITEMS';
export const SET_STORY_DURATION = 'SET_STORY_DURATION';
