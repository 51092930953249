import { NavigationBarSection } from '../../types/layout/navBar';

export const compare = (a: NavigationBarSection, b: NavigationBarSection): number => {
  if (a.priority < b.priority) {
    return -1;
  }

  if (a.priority > b.priority) {
    return 1;
  }

  return 0;
};

const sort = <T> (items: Array<T>): Array<T> => [...items].sort((a, b) => {
  let ai = items.indexOf(a);
  let bi = items.indexOf(b);

  if (ai === -1) {
    ai = items.length + items.indexOf(a);
  }

  if (bi === -1) {
    bi = items.length + items.indexOf(b);
  }

  return ai - bi;
});

export default sort;
