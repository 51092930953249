import React from 'react';
import { Box } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
import { MontageFormatOption, MontageQualityOption } from 'mediascouting-core-ui-common';
import Playlist, { MontagePlaylistPropTypes } from '../MontagePlaylist';
import MontageSettings, { MontageSettingsFormFields } from './MontageFormFields';

interface MontagePropTypes extends MontagePlaylistPropTypes {
    onCreate: (fieldValues: MontageSettingsFormFields) => Promise<void>;
    onReorder: (sourceIndex: number, destinationIndex: number) => void;
    montageQualityOptions: Array<MontageQualityOption>;
    montageFormatOptions: Array<MontageFormatOption>;
}

function MontageSetup(props: MontagePropTypes): JSX.Element {
  const {
    playlist, onCreate, onRemovePlaylistItem, onReorder, montageQualityOptions, montageFormatOptions,
  } = props;

  const onDragEnd = (result): void => {
    const { source, destination } = result;
    try {
      if (!destination || (destination.index === source.index)) {
        return;
      }

      onReorder(source.index, destination.index);
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
      <>
          <DragDropContext onDragEnd={onDragEnd}>
              <Playlist
                playlist={playlist}
                onRemovePlaylistItem={onRemovePlaylistItem}
              />
          </DragDropContext>
          <Box mt={1}>
              <MontageSettings
                onCreate={onCreate}
                montageQualityOptions={montageQualityOptions}
                montageFormatOptions={montageFormatOptions}
              />
          </Box>
      </>

  );
}

export default MontageSetup;
