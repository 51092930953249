import { NeoSearchEntityTypeEnum } from '../types/NeoSearch';

const NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER: { [key in NeoSearchEntityTypeEnum]: string } = {
  [NeoSearchEntityTypeEnum.ORGANIZATION]: 'neoBar.entities.organization',
  [NeoSearchEntityTypeEnum.GEOLOCATION]: 'neoBar.entities.geolocation',
  [NeoSearchEntityTypeEnum.PERSON]: 'neoBar.entities.person',
  [NeoSearchEntityTypeEnum.TOPIC]: 'neoBar.entities.topic',
  [NeoSearchEntityTypeEnum.GENERAL]: 'neoBar.entities.general',
  [NeoSearchEntityTypeEnum.QUERY]: 'neoBar.entities.query',
  [NeoSearchEntityTypeEnum.TAG]: 'neoBar.entities.tag',
  [NeoSearchEntityTypeEnum.SHARED]: 'neoBar.entities.shared',
};

export default NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER;
