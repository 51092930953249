import BusinessIcon from '@material-ui/icons/Business';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import TitleIcon from '@material-ui/icons/Title';
import PublicIcon from '@material-ui/icons/Public';
import TheatersIcon from '@material-ui/icons/Theaters';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import ExploreIcon from '@material-ui/icons/Explore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import TvIcon from '@material-ui/icons/Tv';
import { EntityTypesEnum } from '../editor/EntityTypes';

const ADVANCED_FILTER_ENTITY_TYPE_ICONS = {
  [EntityTypesEnum.SUBQUERIES]: SubdirectoryArrowRightIcon,
  [EntityTypesEnum.TAGS]: LabelImportantIcon,
  [EntityTypesEnum.START]: TodayIcon,
  [EntityTypesEnum.END]: InsertInvitationIcon,
  [EntityTypesEnum.START_TIME]: TodayIcon,
  [EntityTypesEnum.END_TIME]: InsertInvitationIcon,
  [EntityTypesEnum.DAYS]: DateRangeIcon,
  [EntityTypesEnum.TIME_SECTIONS]: AccessTimeIcon,
  [EntityTypesEnum.SOURCES]: TheatersIcon,
  [EntityTypesEnum.SOURCE_GROUPS]: PermMediaIcon,
  [EntityTypesEnum.SOURCE_TYPES]: SettingsInputAntennaIcon,
  [EntityTypesEnum.MARKET_TAGS]: PublicIcon,
  [EntityTypesEnum.MARKET_GROUPS]: ExploreIcon,
  [EntityTypesEnum.OWNER_GROUPS]: SupervisedUserCircleIcon,
  [EntityTypesEnum.AUTHORS]: PersonIcon,
  [EntityTypesEnum.TITLE]: TitleIcon,
  [EntityTypesEnum.LANGUAGE]: LanguageIcon,
  [EntityTypesEnum.GEOLOCATION_TAGS]: RoomIcon,
  [EntityTypesEnum.SENTIMENT]: EmojiEmotionsIcon,
  [EntityTypesEnum.PEOPLE]: PersonIcon,
  [EntityTypesEnum.ORGANIZATIONS]: BusinessIcon,
  [EntityTypesEnum.ORIGIN]: SubtitlesIcon,
  [EntityTypesEnum.PROGRAMS]: TvIcon,
};

export default ADVANCED_FILTER_ENTITY_TYPE_ICONS;
