import { NavigationSectionItem, NavigationBarSection, NeoBarItemType } from '../../../../types/layout/navBar';
import DEFAULT_NEO_BAR_SECTION from './Defaults';
import { NeoSearchEntityTypeEnum } from '../../../../components/common/NeoBar/types/NeoSearch';

interface NavigationSectionAttributeBuilder {
    setSubheader: (subheader: string) => NavigationSectionAttributeBuilder;
    setEmptyMessage: (message: string) => NavigationSectionAttributeBuilder;
    setItems: (items: Array<NavigationSectionItem>) => NavigationSectionAttributeBuilder;
    setType: (type: NeoBarItemType) => NavigationSectionAttributeBuilder;
    setPriority: (priority: number) => NavigationSectionAttributeBuilder;
    setOnNext: (onNext?: () => void) => NavigationSectionAttributeBuilder;
    setOnPrevious: (onPrevious?: () => void) => NavigationSectionAttributeBuilder;
    setOnSearch: (
        onSearch?: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void
    ) => NavigationSectionAttributeBuilder;
    setOnToggleCollapsed: (onToggleCollapsed?: () => void) => NavigationSectionAttributeBuilder;
    build: () => NavigationBarSection;
}

const buildNavigationSection = (): NavigationSectionAttributeBuilder => {
  const section: NavigationBarSection = {
    ...DEFAULT_NEO_BAR_SECTION,
  };

  return {
    setSubheader(subheader: string): NavigationSectionAttributeBuilder {
      section.subheaderTextI18Key = subheader;

      return this;
    },
    setEmptyMessage(message: string): NavigationSectionAttributeBuilder {
      section.messages.noItemsAvailableI18Key = message;

      return this;
    },
    setItems(items: Array<NavigationSectionItem>): NavigationSectionAttributeBuilder {
      section.items = items;

      return this;
    },
    setType(type: NeoBarItemType): NavigationSectionAttributeBuilder {
      section.type = type;

      return this;
    },
    setPriority(priority: number): NavigationSectionAttributeBuilder {
      section.priority = priority;

      return this;
    },
    setOnNext(onNext?: () => void): NavigationSectionAttributeBuilder {
      section.handlers = {
        ...section.handlers,
        onNextPage: onNext,
      };

      return this;
    },
    setOnPrevious(onPrevious?: () => void): NavigationSectionAttributeBuilder {
      section.handlers = {
        ...section.handlers,
        onPreviousPage: onPrevious,
      };

      return this;
    },
    setOnSearch(onSearch?: (
        text: string, types: Array<NeoSearchEntityTypeEnum>
    ) => void): NavigationSectionAttributeBuilder {
      section.handlers = {
        ...section.handlers,
        onSearch,
      };

      return this;
    },
    setOnToggleCollapsed(onToggleCollapsed?: () => void): NavigationSectionAttributeBuilder {
      section.handlers = {
        ...section.handlers,
        onToggleCollapsed,
      };

      return this;
    },
    build: (): NavigationBarSection => section,
  };
};

export default buildNavigationSection;
