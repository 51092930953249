import createExactTextMatchSuggestions from './ExactTextMatchSuggestions';
import createConditionMatcherSuggestions from './ConditionMatcherSuggestions';
import simpleInputSuggestions from './SimpleInputSuggestions';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { EntityRecognition } from '../../../../../../../types/layout/topBar/search/editor';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';
import createDateFilterSuggestions from './DateFilterSuggestions';
import createAdvancedFilterSuggestions from './FilterSuggestions';
import createTimeSectionFilterSuggestions from './TimeSectionFilterSuggestions';

const createEditorSuggestions = (recognizedEntities: EntityRecognition): Array<Suggestion<EntityTypesEnum>> => {
  const { entityRange } = recognizedEntities;
  const type = entityRange.entity.getType() as EntityTypesEnum;

  switch (type) {
    case EntityTypesEnum.EXACT_TEXT_MATCH: {
      return createExactTextMatchSuggestions(entityRange);
    }
    case EntityTypesEnum.AND_CONDITION_MATCHER: {
      return createConditionMatcherSuggestions(entityRange);
    }
    case EntityTypesEnum.OR_CONDITION_MATCHER: {
      return createConditionMatcherSuggestions(entityRange);
    }
    case EntityTypesEnum.SIMPLE_INPUT: {
      return simpleInputSuggestions(entityRange);
    }
    case EntityTypesEnum.SUBQUERIES: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.TAGS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.START: {
      const advancedSuggestions = createAdvancedFilterSuggestions(entityRange);
      const dateSuggestions = createDateFilterSuggestions(entityRange);

      return [...dateSuggestions, ...advancedSuggestions];
    }
    case EntityTypesEnum.END: {
      const advancedSuggestions = createAdvancedFilterSuggestions(entityRange);
      const dateSuggestions = createDateFilterSuggestions(entityRange);

      return [...dateSuggestions, ...advancedSuggestions];
    }
    case EntityTypesEnum.START_TIME: {
      const advancedSuggestions = createAdvancedFilterSuggestions(entityRange);
      const timeSectionSuggestions = createTimeSectionFilterSuggestions(entityRange);

      return [...timeSectionSuggestions, ...advancedSuggestions];
    }
    case EntityTypesEnum.END_TIME: {
      const advancedSuggestions = createAdvancedFilterSuggestions(entityRange);
      const timeSectionSuggestions = createTimeSectionFilterSuggestions(entityRange);

      return [...timeSectionSuggestions, ...advancedSuggestions];
    }
    case EntityTypesEnum.DAYS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.TIME_SECTIONS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.SOURCES: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.SOURCE_TYPES: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.MARKET_TAGS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.TITLE: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.LANGUAGE: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.GEOLOCATION_TAGS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.SENTIMENT: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.PEOPLE: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    case EntityTypesEnum.ORGANIZATIONS: {
      return createAdvancedFilterSuggestions(entityRange);
    }
    default: {
      return [];
    }
  }
};

export default createEditorSuggestions;
