import React, { memo } from 'react';
import {
  Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BrokenImageOutlined } from '@material-ui/icons';
import { ShoppingItem } from '../../../../../types/layout/topBar/Cart';
import getSourceTypeIcon from '../../../story/StoryReflection/utils/IconTypeUtils';

type ShoppingItemPropTypes = {
  shoppingItem: ShoppingItem;
  index: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  badgeIcon: {
    marginRight: theme.spacing(1),
  },
  item: {
    backgroundColor: 'white',
  },
  draggable: {
    cursor: 'grab',
  },
}));

function Item(props: ShoppingItemPropTypes): JSX.Element {
  const classes = useStyles();
  const {
    shoppingItem, index,
  } = props;

  const uniqueIdentity = shoppingItem.itemId + index;

  return (
      <ListItem
        key={uniqueIdentity}
        className={classes.item}
        alignItems="flex-start"
        button
      >
          <ListItemAvatar>
              <Avatar src={shoppingItem.image}>
                  <BrokenImageOutlined />
              </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={(
                <Typography variant="h6" color="inherit" component="span">
                    {shoppingItem.channel}
                </Typography>
            )}
            secondary={(
                <Typography variant="caption" color="textPrimary">
                    <br />
                    {shoppingItem.range.start.toString()}
                    <br />
                    {shoppingItem.range.end.toString()}
                </Typography>
            )}
          />
          <Box ml={2} mt={1} display="flex" flexDirection="column" alignItems="flex-end">
              <SvgIcon fontSize="small" className={classes.badgeIcon}>
                  {getSourceTypeIcon(shoppingItem.sourceType)}
              </SvgIcon>
          </Box>
      </ListItem>
  );
}

export default memo(Item);
