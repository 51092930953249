import React, { memo, MutableRefObject } from 'react';
import { Box } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  GetItemsResponse,
  MojitoSelectFetchDataParams,
  NOT_FOUND,
  NotFound,
  PowerSearchFilter,
  PowerSearchFilterConfiguration,
  PowerSearchFilterTypes,
  PowerSearchQueryFilters,
  PowerSearchSynchronousQueryFilters,
  Query,
  Shallow,
} from 'mediascouting-core-ui-common';
import { CancelTokenSource } from 'axios';
import Header from './Header';
import Filters from './Filters';
import Footer from './Footer';
import { SettingFormFields } from './Footer/SettingsDialog';

type QuerySettingsPropTypes = {
    query?: Query;
    previewNewItemsIsEnabled: boolean;
    powerSearch: PowerSearchQueryFilters;
    synchronousQueryFilterOptions: PowerSearchSynchronousQueryFilters;
    onQueryFilterOptionSearch: (
        type: PowerSearchFilter,
        params: MojitoSelectFetchDataParams,
        cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
        otherParams?: object,
    ) => Promise<GetItemsResponse<Shallow>>;
    onChangePowerSearch: (type: PowerSearchFilter, values: Array<Shallow> | string | null) => void;
    onSave: (values: SettingFormFields) => Promise<void>;
    onDelete: () => void;
    onSearch: () => void;
    onShare: () => void;
    onClose: () => void;
    configuration?: PowerSearchFilterConfiguration;
}

function QuerySettings(props: QuerySettingsPropTypes): JSX.Element {
  const {
    query, powerSearch, synchronousQueryFilterOptions, previewNewItemsIsEnabled, onSave, onSearch, onDelete, onClose,
    onQueryFilterOptionSearch, onChangePowerSearch, onShare, configuration,
  } = props;

  const getRestrictedFilterOptions = (): PowerSearchSynchronousQueryFilters => {
    if (query && !query.isLiveQuery && !previewNewItemsIsEnabled) {
      return {
        ...synchronousQueryFilterOptions,
        [PowerSearchFilterTypes.STORY_DURATION]: [],
      };
    }

    if (query === NOT_FOUND || query?.isLiveQuery || previewNewItemsIsEnabled) {
      return {
        ...synchronousQueryFilterOptions,
        [PowerSearchFilterTypes.STORY_DURATION]: synchronousQueryFilterOptions[PowerSearchFilterTypes.STORY_DURATION]
          .filter((storyDuration) => storyDuration.availableForLiveQueries),
      };
    }

    return synchronousQueryFilterOptions;
  };

  return (
      <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
              <Header onClose={onClose} />
              <Filters
                state={powerSearch}
                synchronousQueryFilterOptions={getRestrictedFilterOptions()}
                onQueryFilterOptionSearch={onQueryFilterOptionSearch}
                onChange={onChangePowerSearch}
                configuration={configuration}
              />
          </Box>
          <Footer
            query={query}
            synchronousQueryFilterOptions={synchronousQueryFilterOptions}
            onSearch={onSearch}
            onDelete={onDelete}
            onSave={onSave}
            onShare={onShare}
          />
      </PerfectScrollbar>
  );
}

export default memo(QuerySettings);
