import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCancelPolicy } from 'mediascouting-core-ui-common';
import { getUser, logoutAfterExpiredAccess } from '../../../remote/Auth';
import authService from '../../../services/authService';
import { AppDispatch } from '../../../redux/store';
import SplashScreen from '../../system/SplashScreen';

interface AuthPropTypes {
  children: React.ReactNode;
}

function Auth(props: AuthPropTypes): JSX.Element {
  const { children } = props;
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const {
    cancelToken, cancelablePromise, cancel,
  } = useCancelPolicy();

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logoutAfterExpiredAccess()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        await cancelablePromise(() => dispatch(getUser(cancelToken?.current?.token)));
      }

      setLoading(false);
    };

    initAuth()
      .then((r) => r)
      .catch((e) => e);
  }, [cancelToken, cancelablePromise, dispatch]);

  useEffect(() => (): void => {
    cancel();
  });

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}

export default Auth;
