import React, { memo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';
import theme from '../../../../../theme';
import MobileDetachment from './MobileDetachment';
import DesktopDetachment from './DesktopDetachment';

type DetachPropTypes = {
  anchorEl: Element | null;
  width: number;
  containerRef?: React.RefObject<HTMLElement>;
  children: React.ReactNode;
  onClickAway: () => void;
};

function Detachment(props: DetachPropTypes): JSX.Element {
  const {
    anchorEl, width, children, containerRef, onClickAway,
  } = props;
  const open = Boolean(anchorEl);
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const detach = (): JSX.Element => {
    if (onSmallScreen) {
      return (
          <MobileDetachment open={open} onClose={onClickAway}>
              {children}
          </MobileDetachment>
      );
    }

    return (
        <DesktopDetachment anchorEl={anchorEl} width={width} containerRef={containerRef} onClickAway={onClickAway}>
            {children}
        </DesktopDetachment>
    );
  };

  return (
    anchorEl
      ? detach()
      : (
          <Box width="100%" maxWidth={width}>
              {children}
          </Box>
      )
  );
}

export default memo(Detachment);
