import React from 'react';
import { FormikHelpers } from 'formik';
import { NeoSearchEntityTypeEnum } from '../../../../../../../../components/common/NeoBar/types/NeoSearch';
import OrganizationTypeConfiguration
  from '../../../../../../../../components/features/queryTypes/OrganizationTypeConfiguration';
import GeolocationTypeConfiguration
  from '../../../../../../../../components/features/queryTypes/GeolocationTypeConfiguration';
import PersonTypeConfiguration from '../../../../../../../../components/features/queryTypes/PersonTypeConfiguration';
import TopicTypeConfiguration from '../../../../../../../../components/features/queryTypes/TopicTypeConfiguration';
import {
  OrganizationTypeConfigurationFormFields,
} from '../../../../../../../../components/features/queryTypes/OrganizationTypeConfiguration/FormFields';
import {
  GeolocationTypeConfigurationFormFields,
} from '../../../../../../../../components/features/queryTypes/GeolocationTypeConfiguration/FormFields';
import {
  PersonTypeConfigurationFormFields,
} from '../../../../../../../../components/features/queryTypes/PersonTypeConfiguration/FormFields';
import {
  TopicTypeConfigurationFormFields,
} from '../../../../../../../../components/features/queryTypes/TopicTypeConfiguration/FormFields';

type MetadataSubmitHandler <T> = (values: T, helpers: FormikHelpers<T>) => Promise<void>

interface SubmitHandlers {
    organizationSubmit: MetadataSubmitHandler<OrganizationTypeConfigurationFormFields>;
    geolocationSubmit: MetadataSubmitHandler<GeolocationTypeConfigurationFormFields>;
    personSubmit: MetadataSubmitHandler<PersonTypeConfigurationFormFields>;
    topicSubmit: MetadataSubmitHandler<TopicTypeConfigurationFormFields>;
}

interface BasicHandlers {
    onClose: VoidFunction;
    onBack: VoidFunction;
}

type NeoSearchEntityTypeMetadataFormMapper = {
    [key in NeoSearchEntityTypeEnum]: React.ReactNode
};

const createNeoSearchEntityTypeMetadataFormMapper = (
  handlers: SubmitHandlers,
  basicHandlers: BasicHandlers,
): NeoSearchEntityTypeMetadataFormMapper => ({
  [NeoSearchEntityTypeEnum.ORGANIZATION]: <OrganizationTypeConfiguration
    onSubmit={handlers.organizationSubmit}
    onClose={basicHandlers.onClose}
    onBack={basicHandlers.onBack}
  />,
  [NeoSearchEntityTypeEnum.GEOLOCATION]: <GeolocationTypeConfiguration
    onSubmit={handlers.geolocationSubmit}
    onClose={basicHandlers.onClose}
    onBack={basicHandlers.onBack}
  />,
  [NeoSearchEntityTypeEnum.PERSON]: <PersonTypeConfiguration
    onSubmit={handlers.personSubmit}
    onClose={basicHandlers.onClose}
    onBack={basicHandlers.onBack}
  />,
  [NeoSearchEntityTypeEnum.TOPIC]: <TopicTypeConfiguration
    onSubmit={handlers.topicSubmit}
    onClose={basicHandlers.onClose}
    onBack={basicHandlers.onBack}
  />,
  [NeoSearchEntityTypeEnum.GENERAL]: <></>,
  [NeoSearchEntityTypeEnum.TAG]: <></>,
  [NeoSearchEntityTypeEnum.QUERY]: <></>,
  [NeoSearchEntityTypeEnum.SHARED]: <></>,
});

export default createNeoSearchEntityTypeMetadataFormMapper;
