import { Avatar, Badge, Tooltip } from '@material-ui/core';
import React from 'react';
import SmallAvatar from '../../styled/SmallAvatar';

interface BadgeAvatarPropTypes {
    badgeIcon: React.ReactNode;
    avatarIcon: React.ReactNode;
    avatarTooltipText: string;
    avatarClassName: string;
}

function BadgeAvatar(props: BadgeAvatarPropTypes): JSX.Element {
  const {
    badgeIcon, avatarIcon, avatarTooltipText, avatarClassName,
  } = props;

  return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={(
            <SmallAvatar variant="circle" className={avatarClassName}>
                {badgeIcon}
            </SmallAvatar>
        )}
      >
          <Tooltip title={avatarTooltipText}>
              <Avatar variant="rounded" className={avatarClassName}>
                  {avatarIcon}
              </Avatar>
          </Tooltip>
      </Badge>
  );
}

export default BadgeAvatar;
