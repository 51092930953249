import {
  Box, Button, Divider, makeStyles, Paper,
} from '@material-ui/core';
import React, { memo, useCallback, useState } from 'react';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import clsx from 'clsx';
import { FormikHelpers } from 'formik';
import {
  all,
  any,
  EMPTY_STRING,
  hasPermission,
  NOT_FOUND,
  PowerSearchSynchronousQueryFilters,
  QueryDTO,
  useIsMountedRef,
  UserPermission,
} from 'mediascouting-core-ui-common';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SettingsDialog, { SettingFormFields } from './SettingsDialog';
import Visible from '../../../../../../components/auth/Visible';
import { ReduxState } from '../../../../../../redux/reducers';

type FooterPropTypes = {
    query?: QueryDTO;
    synchronousQueryFilterOptions: PowerSearchSynchronousQueryFilters;
    onSearch: () => void;
    onDelete: () => void;
    onShare: () => void;
    onSave: (values: SettingFormFields) => Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    bottom: '1px',
    zIndex: 20,
    padding: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  shareButton: {
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.dark,
  },
  deleteButton: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
  },
  saveButton: {
    color: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
  },
}));

function Footer(props: FooterPropTypes): JSX.Element {
  const {
    query, synchronousQueryFilterOptions, onSearch, onDelete, onSave, onShare,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isMounted = useIsMountedRef();
  const [settingsOpen, setSettingOpen] = useState(false);
  const user = useSelector((reduxState: ReduxState) => reduxState.auth.user);

  const handleSaveClick = (): void => {
    setSettingOpen(true);
  };

  const handleSettingsCancel = useCallback((): void => {
    setSettingOpen(false);
  }, []);

  const handleSettingsSubmit = useCallback((
    values: SettingFormFields, helpers: FormikHelpers<SettingFormFields>,
  ): void => {
    onSave(values)
      .then(() => {
        if (isMounted.current) {
          helpers.setSubmitting(false);
          setSettingOpen(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          helpers.setStatus({ success: false });
        }
      });
  }, [isMounted, onSave]);

  const permissionsPermitsPercolatedQueries = (): boolean => !!(
    user && hasPermission(UserPermission.CREATE_PERCOLATED_QUERIES)(user)
  );

  const getQuerySettingsInitialValues = (): SettingFormFields => {
    if (query) {
      return {
        name: query.name,
        description: query.description,
        isLiveQuery: query.isLiveQuery,
        storyDurationId: query.storyDurationFilterId,
        shouldAutoPercolate: query.shouldAutoPercolate,
      };
    }

    return {
      name: EMPTY_STRING,
      description: EMPTY_STRING,
      isLiveQuery: !permissionsPermitsPercolatedQueries(),
      storyDurationId: permissionsPermitsPercolatedQueries() ? 4 : 1,
      shouldAutoPercolate: true,
    };
  };

  return (
      <Paper
        variant="outlined"
        className={clsx(
          classes.root,
          classes.flex,
          { [classes.spaceBetween]: query !== NOT_FOUND },
          { [classes.flexEnd]: query === NOT_FOUND },
        )}
      >
          {query
          && (
              <Box display="flex" alignItems="center">
                  <Visible when={{ userCondition: hasPermission(UserPermission.DELETE_QUERY) }}>
                      <Button
                        variant="text"
                        className={classes.deleteButton}
                        startIcon={<DeleteOutlineIcon fontSize="inherit" />}
                        onClick={onDelete}
                      >
                          {t('layout.topBar.search.searchBar.query.settings.footer.delete')}
                      </Button>
                      <Divider light orientation="vertical" variant="middle" flexItem />
                  </Visible>
                  <Visible when={{
                    userCondition: all(
                      hasPermission(UserPermission.READ_QUERY_SHARES),
                      hasPermission(UserPermission.CREATE_QUERY_SHARES),
                    ),
                  }}
                  >
                      <Button
                        variant="text"
                        className={classes.shareButton}
                        startIcon={<ShareOutlinedIcon fontSize="inherit" />}
                        onClick={onShare}
                      >
                          {t('layout.topBar.search.searchBar.query.settings.footer.share')}
                      </Button>
                      <Divider light orientation="vertical" variant="middle" flexItem />
                  </Visible>
                  <Visible when={{ userCondition: hasPermission(UserPermission.UPDATE_QUERY) }}>
                      <Button
                        variant="text"
                        className={classes.saveButton}
                        startIcon={<UpdateOutlinedIcon fontSize="inherit" />}
                        onClick={handleSaveClick}
                      >
                          {t('layout.topBar.search.searchBar.query.settings.footer.save')}
                      </Button>
                  </Visible>
              </Box>
          )}
          <Box display="flex" alignItems="center">
              <Visible when={{
                userCondition: any(
                  hasPermission(UserPermission.CREATE_PERCOLATED_QUERIES),
                  hasPermission(UserPermission.CREATE_LIVE_QUERIES),
                  hasPermission(UserPermission.UPDATE_QUERY),
                ),
              }}
              >
                  {!query
                && (
                    <Visible when={{
                      userCondition: any(
                        hasPermission(UserPermission.CREATE_PERCOLATED_QUERIES),
                        hasPermission(UserPermission.CREATE_LIVE_QUERIES),
                      ),
                    }}
                    >
                        <Button
                          variant="text"
                          className={classes.saveButton}
                          startIcon={<SaveOutlinedIcon fontSize="inherit" />}
                          onClick={handleSaveClick}
                        >
                            {t('layout.topBar.search.searchBar.query.settings.footer.save')}
                        </Button>
                        <Divider light orientation="vertical" variant="middle" flexItem />
                    </Visible>
                )}
              </Visible>
              <Button
                variant="text"
                color="primary"
                startIcon={<SearchOutlinedIcon />}
                onClick={onSearch}
              >
                  {t('layout.topBar.search.searchBar.query.settings.footer.search')}
              </Button>
          </Box>
          <SettingsDialog
            open={settingsOpen}
            initialValues={getQuerySettingsInitialValues()}
            synchronousQueryFilterOptions={synchronousQueryFilterOptions}
            onSubmit={handleSettingsSubmit}
            onClose={handleSettingsCancel}
          />
      </Paper>
  );
}

export default memo(Footer);
