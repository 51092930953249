import React, { memo } from 'react';
import {
  Box, Button, List, makeStyles, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import moment from 'moment';
import {
  NOTIFICATION_ICON_TYPES,
  clearNotifications,
  Notification, NotificationTypeEnum,
} from 'mediascouting-core-ui-common';
import { ReduxState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../redux/store';

interface NotificationListPropTypes {
    onClick: (notification: Notification) => void;
}

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 380,
  },
  successIcon: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  infoIcon: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  errorIcon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NotificationList(props: NotificationListPropTypes): JSX.Element {
  const classes = useStyles();
  const { onClick } = props;
  const dispatch: AppDispatch = useDispatch();
  const notifications = useSelector((state: ReduxState) => state.notifications.notifications);

  const handleClearAll = (): void => {
    dispatch(clearNotifications());
  };

  const renderSecondary = (notification: Notification): JSX.Element => (
      <Typography component="span">
          {notification.description}
          <br />
          {moment(notification.date).fromNow()}
      </Typography>
  );

  const handleClick = (notification) => (): void => {
    onClick(notification);
  };

  return (
      <>
          <List disablePadding>
              {notifications.map((notification) => {
                const Icon = NOTIFICATION_ICON_TYPES[notification.type];

                return (
                    <ListItem
                      key={notification.id}
                      onClick={handleClick(notification)}
                      divider
                      button
                    >
                        <ListItemAvatar>
                            <Avatar className={clsx(
                              { [classes.successIcon]: notification.type === 'success' },
                              { [classes.infoIcon]: notification.type === NotificationTypeEnum.INFO },
                              { [classes.errorIcon]: notification.type === NotificationTypeEnum.ERROR },
                            )}
                            >
                                <Icon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={notification.title}
                          primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                          secondary={renderSecondary(notification)}
                        />
                    </ListItem>
                );
              })}
          </List>
          <Box p={1} display="flex" justifyContent="center">
              <Button size="small" onClick={handleClearAll}>
                  CLEAR ALL
              </Button>
          </Box>
      </>
  );
}

export default memo(NotificationList);
