import React from 'react';
import { useSelector } from 'react-redux';
import {
  ApplicationPropertyFeature, NOT_FOUND, NotFound, TypeOfCheck, User,
} from 'mediascouting-core-ui-common';
import { ReduxState } from '../../../redux/reducers';

enum VisibleConditionsEnum{
  USER_CONDITION = 'userCondition',
  FLAG_CONDITION = 'flagCondition'
}

interface FlagConditionFunction{
  [VisibleConditionsEnum.FLAG_CONDITION]?: (flag: ApplicationPropertyFeature) => boolean;
}

interface UserConditionFunction{
  [VisibleConditionsEnum.USER_CONDITION]?: (user: User) => boolean;
}

interface WhenConditionFunctions extends UserConditionFunction, FlagConditionFunction{
}

interface VisiblePropTypes {
    when: WhenConditionFunctions;
    children: React.ReactNode;
    typeOfCheck?: TypeOfCheck;
    fallbackComponent?: JSX.Element;
}

function Visible(props: VisiblePropTypes): JSX.Element {
  const {
    when, typeOfCheck, children, fallbackComponent,
  } = props;
  const user = useSelector((state: ReduxState) => state.auth.user);
  const featureFlags = useSelector((state: ReduxState) => state.applicationProperties.featureFlags);

  const isConditionPresentInWhen = (condition: VisibleConditionsEnum): boolean => condition in when;

  const validUser = (): boolean | NotFound => {
    if (isConditionPresentInWhen(VisibleConditionsEnum.USER_CONDITION)) {
      return !!(user && when?.userCondition?.(user));
    }
    return NOT_FOUND;
  };

  const validFlags = (): boolean | NotFound => {
    if (isConditionPresentInWhen(VisibleConditionsEnum.FLAG_CONDITION)) {
      return !!(user && when?.flagCondition?.(featureFlags));
    }
    return NOT_FOUND;
  };

  const handleAND = (functions: Array<(() => (boolean | NotFound))>): boolean => functions.every((func) => func());

  const handleOR = (functions: Array<(() => (boolean | NotFound))>): boolean => functions.some((func) => func());

  const actBetweenConditions = {
    [TypeOfCheck.AND]: handleAND,
    [TypeOfCheck.OR]: handleOR,
  };

  const getActiveConditions = (allConditions: Array<(() => (boolean | NotFound))>):
      Array<(() => (boolean | NotFound))> => allConditions
    .filter((func) => func() !== NOT_FOUND);

  const renderChildren = (): React.ReactNode => {
    const allConditions = [validFlags, validUser];
    const activeConditions = getActiveConditions(allConditions);

    const actBetweenCondition = actBetweenConditions[typeOfCheck || TypeOfCheck.AND];

    if (actBetweenCondition(activeConditions)) {
      return children;
    }

    return fallbackComponent;
  };

  return (
      <>
          {renderChildren()}
      </>
  );
}

export default Visible;
