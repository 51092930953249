import { SortFieldDTO, SortOrderDTO } from 'mediascouting-core-ui-common';
import { SortOption, SortTypeEnum } from '../types/query/results/Sort';
import PLATFORM_CONFIGURATION from '../configuration';

const SORT_OPTIONS: Array<SortOption> = [
  {
    id: SortTypeEnum.NEWEST,
    title: 'sort.option.newest',
    value: `${SortFieldDTO.PUBLISH_DATETIME},${SortOrderDTO.DESCENDING}`,
    sortField: SortFieldDTO.PUBLISH_DATETIME,
    sortOrder: SortOrderDTO.DESCENDING,
  },
  {
    id: SortTypeEnum.OLDEST,
    title: 'sort.option.oldest',
    value: `${SortFieldDTO.PUBLISH_DATETIME}, ${SortOrderDTO.ASCENDING}`,
    sortField: SortFieldDTO.PUBLISH_DATETIME,
    sortOrder: SortOrderDTO.ASCENDING,
  },
  {
    id: SortTypeEnum.RELEVANCE,
    title: 'sort.option.relevance',
    value: `${SortFieldDTO.SCORE},${SortOrderDTO.DESCENDING}`,
    sortField: SortFieldDTO.SCORE,
    sortOrder: SortOrderDTO.DESCENDING,
  },
  {
    id: SortTypeEnum.MARKET,
    title: 'sort.option.market',
    value: `${SortFieldDTO.MARKET},${SortOrderDTO.ASCENDING}`,
    sortField: SortFieldDTO.MARKET,
    sortOrder: SortOrderDTO.ASCENDING,
  },
  {
    id: SortTypeEnum.MARKET_RANK,
    title: 'sort.option.market.rank',
    value: `${SortFieldDTO.MARKET_RANK},${SortOrderDTO.ASCENDING}`,
    sortField: SortFieldDTO.MARKET_RANK,
    sortOrder: SortOrderDTO.ASCENDING,
  },
  {
    id: SortTypeEnum.SOURCE,
    title: 'sort.option.source',
    value: `${SortFieldDTO.SOURCE},${SortOrderDTO.ASCENDING}`,
    sortField: SortFieldDTO.SOURCE,
    sortOrder: SortOrderDTO.ASCENDING,
  },
  {
    id: SortTypeEnum.TEXT_TYPE,
    title: 'sort.option.text.type',
    value: `${SortFieldDTO.TEXT_TYPE},${SortOrderDTO.DESCENDING}`,
    sortField: SortFieldDTO.TEXT_TYPE,
    sortOrder: SortOrderDTO.DESCENDING,
  },
];
export const getStorySortingOptions = (): Array<SortOption> => {
  const disabledSortingOptions = PLATFORM_CONFIGURATION.schema.stories.sorting.disabled;

  return SORT_OPTIONS.filter((sortOption) => !disabledSortingOptions.includes(sortOption.id));
};

export const getDefaultSort = (): SortOption => {
  const options = getStorySortingOptions();

  if (options.length > 0) {
    return options[0];
  }

  return SORT_OPTIONS[0];
};
