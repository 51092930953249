import { FacetType } from '../../../types/query/results/Facets';

export enum EntityTypesEnum {
EXACT_TEXT_MATCH = 'Exact Text Match',
NAND_CONDITION_MATCHER = 'AND NOT',
AND_CONDITION_MATCHER = 'AND',
OR_CONDITION_MATCHER = 'OR',
NOT_CONDITION_MATCHER = 'NOT',
SUBQUERIES = 'Subqueries',
TAGS = 'Tags',
START = 'Start',
END = 'End',
START_TIME = 'StartingHours',
END_TIME = 'EndingHours',
DAYS = 'Days',
TIME_SECTIONS = 'Time',
SOURCES = 'Sources',
SOURCE_GROUPS = 'SourceGroups',
SOURCE_TYPES = 'Type',
MARKET_TAGS = 'Markets',
MARKET_GROUPS = 'MarketGroups',
OWNER_GROUPS = 'OwnerGroups',
AUTHORS = 'Authors',
TITLE = 'Title',
LANGUAGE = 'Languages',
GEOLOCATION_TAGS = 'Geolocation',
SENTIMENT = 'Sentiment',
PEOPLE = 'People',
ORGANIZATIONS = 'Organizations',
FILTER_OPTION = 'Filter Option',
SEARCHABLE_FILTER_OPTION = 'Searchable Filter Option',
SIMPLE_INPUT = 'Simple Input',
ORIGIN = 'Origin',
PROGRAMS = 'Programs',
}

export const FILTER_ENTITY_TYPES: Array<EntityTypesEnum> = [
  EntityTypesEnum.SUBQUERIES,
  EntityTypesEnum.TAGS,
  EntityTypesEnum.START,
  EntityTypesEnum.END,
  EntityTypesEnum.START_TIME,
  EntityTypesEnum.END_TIME,
  EntityTypesEnum.DAYS,
  EntityTypesEnum.TIME_SECTIONS,
  EntityTypesEnum.SOURCES,
  EntityTypesEnum.SOURCE_GROUPS,
  EntityTypesEnum.SOURCE_TYPES,
  EntityTypesEnum.MARKET_TAGS,
  EntityTypesEnum.MARKET_GROUPS,
  EntityTypesEnum.OWNER_GROUPS,
  EntityTypesEnum.AUTHORS,
  EntityTypesEnum.TITLE,
  EntityTypesEnum.LANGUAGE,
  EntityTypesEnum.GEOLOCATION_TAGS,
  EntityTypesEnum.SENTIMENT,
  EntityTypesEnum.PEOPLE,
  EntityTypesEnum.ORGANIZATIONS,
  EntityTypesEnum.ORIGIN,
  EntityTypesEnum.PROGRAMS,
];

export const MULTIPLE_VALUE_FILTERS: Array<EntityTypesEnum> = [
  EntityTypesEnum.SUBQUERIES,
  EntityTypesEnum.TAGS,
  EntityTypesEnum.DAYS,
  EntityTypesEnum.TIME_SECTIONS,
  EntityTypesEnum.SOURCES,
  EntityTypesEnum.SOURCE_GROUPS,
  EntityTypesEnum.SOURCE_TYPES,
  EntityTypesEnum.MARKET_TAGS,
  EntityTypesEnum.MARKET_GROUPS,
  EntityTypesEnum.OWNER_GROUPS,
  EntityTypesEnum.AUTHORS,
  EntityTypesEnum.LANGUAGE,
  EntityTypesEnum.GEOLOCATION_TAGS,
  EntityTypesEnum.SENTIMENT,
  EntityTypesEnum.PEOPLE,
  EntityTypesEnum.ORGANIZATIONS,
  EntityTypesEnum.ORIGIN,
];

export const TIME_OF_DAY_FILTERS: Array<EntityTypesEnum> = [
  EntityTypesEnum.START_TIME,
  EntityTypesEnum.END_TIME,
];

export const DATE_FILTERS: Array<EntityTypesEnum> = [
  EntityTypesEnum.START,
  EntityTypesEnum.END,
];

export const ASYNCHRONOUS_MULTIPLE_VALUE_FILTERS: Array<EntityTypesEnum> = [
  EntityTypesEnum.SUBQUERIES,
  EntityTypesEnum.TAGS,
  EntityTypesEnum.SOURCES,
  EntityTypesEnum.SOURCE_GROUPS,
  EntityTypesEnum.MARKET_TAGS,
  EntityTypesEnum.MARKET_GROUPS,
  EntityTypesEnum.OWNER_GROUPS,
  EntityTypesEnum.AUTHORS,
  EntityTypesEnum.GEOLOCATION_TAGS,
  EntityTypesEnum.PEOPLE,
  EntityTypesEnum.ORGANIZATIONS,
  EntityTypesEnum.ORIGIN,
];

export const SEARCHABLE_CONVERSION_FILTERS: Array<EntityTypesEnum> = [
  ...MULTIPLE_VALUE_FILTERS,
  EntityTypesEnum.START,
  EntityTypesEnum.END,
  EntityTypesEnum.START_TIME,
  EntityTypesEnum.END_TIME,
];

export const FRONT_TO_BACK_FILTER_MAPPER = {
  [EntityTypesEnum.SUBQUERIES]: 'subqueries',
  [EntityTypesEnum.TAGS]: 'tags',
  [EntityTypesEnum.DAYS]: 'days',
  [EntityTypesEnum.TIME_SECTIONS]: 'times',
  [EntityTypesEnum.SOURCE_TYPES]: 'sourcetypes',
  [EntityTypesEnum.MARKET_TAGS]: 'markets',
  [EntityTypesEnum.MARKET_GROUPS]: 'marketgroups',
  [EntityTypesEnum.OWNER_GROUPS]: 'ownergroups',
  [EntityTypesEnum.AUTHORS]: 'authors',
  [EntityTypesEnum.LANGUAGE]: 'languages',
  [EntityTypesEnum.GEOLOCATION_TAGS]: 'geolocations',
  [EntityTypesEnum.SENTIMENT]: 'sentiments',
  [EntityTypesEnum.PEOPLE]: 'people',
  [EntityTypesEnum.ORGANIZATIONS]: 'organizations',
  [EntityTypesEnum.START]: 'start',
  [EntityTypesEnum.END]: 'end',
  [EntityTypesEnum.SOURCES]: 'sources',
  [EntityTypesEnum.SOURCE_GROUPS]: 'sourcegroups',
  [EntityTypesEnum.START_TIME]: 'timeStart',
  [EntityTypesEnum.END_TIME]: 'timeEnd',
  [EntityTypesEnum.ORIGIN]: 'textTypes',
  [EntityTypesEnum.PROGRAMS]: 'programs',
};

export const ENTITY_TO_POWER_SEARCH_TYPE_MAPPER = {
  [EntityTypesEnum.SUBQUERIES]: 'Sub-Queries',
  [EntityTypesEnum.TAGS]: 'Tags',
  [EntityTypesEnum.DAYS]: 'Days',
  [EntityTypesEnum.TIME_SECTIONS]: 'Time sections',
  [EntityTypesEnum.SOURCE_TYPES]: 'Source Types',
  [EntityTypesEnum.MARKET_TAGS]: 'Markets',
  [EntityTypesEnum.MARKET_GROUPS]: 'Market Groups',
  [EntityTypesEnum.OWNER_GROUPS]: 'Owner Groups',
  [EntityTypesEnum.AUTHORS]: 'Authors',
  [EntityTypesEnum.LANGUAGE]: 'Languages',
  [EntityTypesEnum.GEOLOCATION_TAGS]: 'Geolocation Tags',
  [EntityTypesEnum.SENTIMENT]: 'Sentiments',
  [EntityTypesEnum.PEOPLE]: 'People',
  [EntityTypesEnum.ORGANIZATIONS]: 'Organizations',
  [EntityTypesEnum.START]: 'Start',
  [EntityTypesEnum.END]: 'End',
  [EntityTypesEnum.START_TIME]: 'Start Time',
  [EntityTypesEnum.END_TIME]: 'End Time',
  [EntityTypesEnum.SOURCES]: 'Sources',
  [EntityTypesEnum.SOURCE_GROUPS]: 'Source Groups',
  [EntityTypesEnum.ORIGIN]: 'Origin',
  [EntityTypesEnum.PROGRAMS]: 'Programs',
};

export const FACET_TYPE_TO_POWER_SEARCH_TYPE_MAPPER = {
  [FacetType.SUB_QUERIES]: 'Sub-Queries',
  [FacetType.DAYS]: 'Days',
  [FacetType.TIME_SECTIONS]: 'Time sections',
  [FacetType.SOURCE_TYPE]: 'Source Types',
  [FacetType.MARKET]: 'Markets',
  [FacetType.LANGUAGE]: 'Languages',
  [FacetType.SENTIMENT]: 'Sentiments',
  [FacetType.SOURCES]: 'Sources',
  [FacetType.TAGS]: 'Tags',
  [FacetType.AUTHORS]: 'Authors',
};
