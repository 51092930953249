import { EMPTY_STRING, NOT_FOUND } from 'mediascouting-core-ui-common';
import {
  NeoBarItemType,
  NavigationBarSection,
  NeoBarSectionHandlers,
  NeoBarSectionMessages,
} from '../../../../types/layout/navBar';

const DEFAULT_NEO_BAR_SECTION_HANDLERS: NeoBarSectionHandlers = {
  onSearch: NOT_FOUND,
  onNextPage: NOT_FOUND,
  onPreviousPage: NOT_FOUND,
  onToggleCollapsed: NOT_FOUND,
};

const DEFAULT_NEO_BAR_SECTION_MESSAGES: NeoBarSectionMessages = {
  noItemsAvailableI18Key: EMPTY_STRING,
};

const DEFAULT_NEO_BAR_SECTION: NavigationBarSection = {
  items: [],
  priority: 0,
  loading: false,
  hidden: false,
  collapsed: false,
  type: NeoBarItemType.UNKNOWN,
  subheaderTextI18Key: EMPTY_STRING,
  handlers: {
    ...DEFAULT_NEO_BAR_SECTION_HANDLERS,
  },
  messages: {
    ...DEFAULT_NEO_BAR_SECTION_MESSAGES,
  },
};

export default DEFAULT_NEO_BAR_SECTION;
