enum TranslationNameSpaceEnum{
    'DEFAULT' = 'translation',
    'BREADCRUMB' = 'breadcrumb',
    'NAV_BAR' = 'navBar',
    'ACCOUNT' = 'account',
    'ALERTS' = 'alerts',
    'CONTACTS' = 'contacts',
    'EXPORTS' = 'exports',
    'MONTAGE_EXPORTS' = 'montageExports',
    'MONTAGES' = 'montages',
    'TEMPLATES' = 'templates',
    'PLAYER_CONTROLS' = 'playerControls',
    'SHARES' = 'shares',
    'STORY_LOGGING' = 'storyLogging',
    'SHARED_STORY' = 'sharedStory',
    'STORY' = 'story',
    'STORIES' = 'stories',
    'GRAPHS' = 'graphs',
    'REMOTE' = 'remote',
    'QUERY_TYPES' = 'queryTypes',
    'SETTINGS' = 'settings',
}

export default TranslationNameSpaceEnum;
