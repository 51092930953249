import {
  extractMultiValuedFilterOptions,
  extractSingleValuedFilterOption,
  Identifiable,
  NotFound,
  PowerSearchFilterTypes,
  PowerSearchQueryFilters,
  ShallowPowerSearchQueryFilters,
  User,
  UserPermission,
} from 'mediascouting-core-ui-common';
import { ParsedSegment } from '../../types/layout/topBar/search/editor';
import { EntityTypesEnum, MULTIPLE_VALUE_FILTERS } from '../../constants/search/editor/EntityTypes';
import PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER from '../../constants/search/editor/Mappers';
import UrlParams from '../../types/UrlParams';
import { QuerySearchSpaceReducer } from '../../redux/reducers/QuerySearchSpace';
import { IntelligentQuerySearchBarConfiguration } from '../../configuration/schema';

export const createPowerSearchFiltersFromQueryReducer = (
  query: QuerySearchSpaceReducer,
): PowerSearchQueryFilters => ({
  [PowerSearchFilterTypes.SOURCE_TYPES]: query[PowerSearchFilterTypes.SOURCE_TYPES],
  [PowerSearchFilterTypes.TIME_SECTIONS]: query[PowerSearchFilterTypes.TIME_SECTIONS],
  [PowerSearchFilterTypes.ORGANIZATIONS]: query[PowerSearchFilterTypes.ORGANIZATIONS],
  [PowerSearchFilterTypes.LANGUAGES]: query[PowerSearchFilterTypes.LANGUAGES],
  [PowerSearchFilterTypes.GEOLOCATION_TAGS]: query[PowerSearchFilterTypes.GEOLOCATION_TAGS],
  [PowerSearchFilterTypes.DAYS]: query[PowerSearchFilterTypes.DAYS],
  [PowerSearchFilterTypes.TAGS]: query[PowerSearchFilterTypes.TAGS],
  [PowerSearchFilterTypes.START]: query[PowerSearchFilterTypes.START],
  [PowerSearchFilterTypes.END]: query[PowerSearchFilterTypes.END],
  [PowerSearchFilterTypes.START_TIME]: query[PowerSearchFilterTypes.START_TIME],
  [PowerSearchFilterTypes.END_TIME]: query[PowerSearchFilterTypes.END_TIME],
  [PowerSearchFilterTypes.TITLE]: query[PowerSearchFilterTypes.TITLE],
  [PowerSearchFilterTypes.PEOPLE]: query[PowerSearchFilterTypes.PEOPLE],
  [PowerSearchFilterTypes.SENTIMENTS]: query[PowerSearchFilterTypes.SENTIMENTS],
  [PowerSearchFilterTypes.SUBQUERIES]: query[PowerSearchFilterTypes.SUBQUERIES],
  [PowerSearchFilterTypes.SOURCES]: query[PowerSearchFilterTypes.SOURCES],
  [PowerSearchFilterTypes.SOURCE_GROUPS]: query[PowerSearchFilterTypes.SOURCE_GROUPS],
  [PowerSearchFilterTypes.MARKETS]: query[PowerSearchFilterTypes.MARKETS],
  [PowerSearchFilterTypes.MARKET_GROUPS]: query[PowerSearchFilterTypes.MARKET_GROUPS],
  [PowerSearchFilterTypes.AUTHORS]: query[PowerSearchFilterTypes.AUTHORS],
  [PowerSearchFilterTypes.STORY_DURATION]: query[PowerSearchFilterTypes.STORY_DURATION],
  [PowerSearchFilterTypes.ORIGIN]: query[PowerSearchFilterTypes.ORIGIN],
  [PowerSearchFilterTypes.PROGRAMS]: query[PowerSearchFilterTypes.PROGRAMS],
});

export const PowerSearchFilterTypeToQueryFilterParameterMapper = {
  [PowerSearchFilterTypes.START]: UrlParams.START_DATE,
  [PowerSearchFilterTypes.END]: UrlParams.END_DATE,
  [PowerSearchFilterTypes.START_TIME]: UrlParams.START_TIME_OF_DAY,
  [PowerSearchFilterTypes.END_TIME]: UrlParams.END_TIME_OF_DAY,
  [PowerSearchFilterTypes.TITLE]: UrlParams.TITLE,
  [PowerSearchFilterTypes.PROGRAMS]: UrlParams.PROGRAMS,
  [PowerSearchFilterTypes.TAGS]: UrlParams.TAGS,
  [PowerSearchFilterTypes.SOURCE_TYPES]: UrlParams.SOURCE_TYPES,
  [PowerSearchFilterTypes.LANGUAGES]: UrlParams.LANGUAGES,
  [PowerSearchFilterTypes.SOURCES]: UrlParams.SOURCES,
  [PowerSearchFilterTypes.SOURCE_GROUPS]: UrlParams.SOURCE_GROUPS,
  [PowerSearchFilterTypes.GEOLOCATION_TAGS]: UrlParams.GEOLOCATION_TAGS,
  [PowerSearchFilterTypes.MARKETS]: UrlParams.MARKETS,
  [PowerSearchFilterTypes.MARKET_GROUPS]: UrlParams.MARKET_GROUPS,
  [PowerSearchFilterTypes.AUTHORS]: UrlParams.AUTHORS,
  [PowerSearchFilterTypes.SENTIMENTS]: UrlParams.SENTIMENTS,
  [PowerSearchFilterTypes.DAYS]: UrlParams.DAYS,
  [PowerSearchFilterTypes.TIME_SECTIONS]: UrlParams.TIME_SECTIONS,
  [PowerSearchFilterTypes.SUBQUERIES]: UrlParams.SUB_QUERIES,
  [PowerSearchFilterTypes.PEOPLE]: UrlParams.PEOPLE,
  [PowerSearchFilterTypes.ORGANIZATIONS]: UrlParams.ORGANIZATIONS,
  [PowerSearchFilterTypes.STORY_DURATION]: UrlParams.STORY_DURATION,
  [PowerSearchFilterTypes.ORIGIN]: UrlParams.ORIGIN,
};

export const EntityToPowerSearchFilterTypeMapper = {
  [EntityTypesEnum.START]: PowerSearchFilterTypes.START,
  [EntityTypesEnum.END]: PowerSearchFilterTypes.END,
  [EntityTypesEnum.START_TIME]: PowerSearchFilterTypes.START_TIME,
  [EntityTypesEnum.END_TIME]: PowerSearchFilterTypes.END_TIME,
  [EntityTypesEnum.TITLE]: PowerSearchFilterTypes.TITLE,
  [EntityTypesEnum.TAGS]: PowerSearchFilterTypes.TAGS,
  [EntityTypesEnum.SOURCE_TYPES]: PowerSearchFilterTypes.SOURCE_TYPES,
  [EntityTypesEnum.LANGUAGE]: PowerSearchFilterTypes.LANGUAGES,
  [EntityTypesEnum.SOURCES]: PowerSearchFilterTypes.SOURCES,
  [EntityTypesEnum.SOURCE_GROUPS]: PowerSearchFilterTypes.SOURCE_GROUPS,
  [EntityTypesEnum.GEOLOCATION_TAGS]: PowerSearchFilterTypes.GEOLOCATION_TAGS,
  [EntityTypesEnum.MARKET_TAGS]: PowerSearchFilterTypes.MARKETS,
  [EntityTypesEnum.MARKET_GROUPS]: PowerSearchFilterTypes.MARKET_GROUPS,
  [EntityTypesEnum.AUTHORS]: PowerSearchFilterTypes.AUTHORS,
  [EntityTypesEnum.SENTIMENT]: PowerSearchFilterTypes.SENTIMENTS,
  [EntityTypesEnum.DAYS]: PowerSearchFilterTypes.DAYS,
  [EntityTypesEnum.TIME_SECTIONS]: PowerSearchFilterTypes.TIME_SECTIONS,
  [EntityTypesEnum.SUBQUERIES]: PowerSearchFilterTypes.SUBQUERIES,
  [EntityTypesEnum.PEOPLE]: PowerSearchFilterTypes.PEOPLE,
  [EntityTypesEnum.ORGANIZATIONS]: PowerSearchFilterTypes.ORGANIZATIONS,
  [EntityTypesEnum.ORIGIN]: PowerSearchFilterTypes.ORIGIN,
  [EntityTypesEnum.PROGRAMS]: PowerSearchFilterTypes.PROGRAMS,
};

export const getMultiValuedFilterOptionIdentities = (filterText: string): Array<Identifiable> => {
  const filterOptionIdentities: Array<Identifiable> = [];
  const extractedOptions = extractMultiValuedFilterOptions(filterText);

  extractedOptions.forEach((option) => {
    const filterOptionIdInStringFormat = option.trimStart().trimEnd();
    const filterOptionId = parseInt(filterOptionIdInStringFormat, 10);

    filterOptionIdentities.push({
      id: filterOptionId,
    });
  });

  return filterOptionIdentities;
};

export const getActiveFilters = (
  parsedSegments: Array<ParsedSegment>, selectedQueryFiltersAsText: string,
): ShallowPowerSearchQueryFilters => {
  const activeFilters: ShallowPowerSearchQueryFilters = {};

  parsedSegments.forEach((segment) => {
    const { start, end, type } = segment;
    const mappedType = PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER[type] as EntityTypesEnum;

    if (end) {
      const filterText = selectedQueryFiltersAsText.slice(start, end + 1);
      const activeFilterType = EntityToPowerSearchFilterTypeMapper[mappedType];

      if (MULTIPLE_VALUE_FILTERS.includes(mappedType)) {
        const filterIdentities = getMultiValuedFilterOptionIdentities(filterText);
        if (activeFilterType && filterIdentities.length > 0) {
          activeFilters[activeFilterType] = filterIdentities;
        }
      } else {
        const extractedValue = extractSingleValuedFilterOption(filterText);

        if (activeFilterType && extractedValue.length > 0) {
          activeFilters[activeFilterType] = extractedValue;
        }
      }
    }
  });

  return activeFilters;
};

export const createFilterPermissions = (user: User | NotFound): {
  [key in EntityTypesEnum]: boolean | NotFound} => ({
  [EntityTypesEnum.EXACT_TEXT_MATCH]: true,
  [EntityTypesEnum.NAND_CONDITION_MATCHER]: true,
  [EntityTypesEnum.AND_CONDITION_MATCHER]: true,
  [EntityTypesEnum.OR_CONDITION_MATCHER]: true,
  [EntityTypesEnum.NOT_CONDITION_MATCHER]: true,
  [EntityTypesEnum.SUBQUERIES]: true,
  [EntityTypesEnum.TAGS]: true,
  [EntityTypesEnum.START]: true,
  [EntityTypesEnum.END]: true,
  [EntityTypesEnum.START_TIME]: true,
  [EntityTypesEnum.END_TIME]: true,
  [EntityTypesEnum.DAYS]: true,
  [EntityTypesEnum.TIME_SECTIONS]: true,
  [EntityTypesEnum.SOURCES]: true,
  [EntityTypesEnum.SOURCE_GROUPS]: true,
  [EntityTypesEnum.SOURCE_TYPES]: true,
  [EntityTypesEnum.MARKET_TAGS]: true,
  [EntityTypesEnum.MARKET_GROUPS]: true,
  [EntityTypesEnum.OWNER_GROUPS]: user?.permissions.includes(UserPermission.USE_OWNER_GROUP_FILTER),
  [EntityTypesEnum.AUTHORS]: true,
  [EntityTypesEnum.TITLE]: true,
  [EntityTypesEnum.LANGUAGE]: true,
  [EntityTypesEnum.GEOLOCATION_TAGS]: true,
  [EntityTypesEnum.SENTIMENT]: true,
  [EntityTypesEnum.PEOPLE]: true,
  [EntityTypesEnum.ORGANIZATIONS]: true,
  [EntityTypesEnum.FILTER_OPTION]: true,
  [EntityTypesEnum.SEARCHABLE_FILTER_OPTION]: true,
  [EntityTypesEnum.SIMPLE_INPUT]: true,
  [EntityTypesEnum.ORIGIN]: user?.permissions.includes(UserPermission.USE_TEXT_TYPE_FILTER),
  [EntityTypesEnum.PROGRAMS]: true,
});

export const getSearchBarFilterConfiguration = (
  user: User | NotFound,
  configuration?: IntelligentQuerySearchBarConfiguration | NotFound,
): IntelligentQuerySearchBarConfiguration => {
  const filterPermissions = createFilterPermissions(user);
  const disabledFilters: Array<EntityTypesEnum> = [...configuration?.disabled || []];

  const keys = Object.values(EntityTypesEnum);
  keys.forEach((filterType) => {
    if (filterType as EntityTypesEnum
        && !configuration?.disabled.includes(filterType as EntityTypesEnum)
        && !filterPermissions[filterType as EntityTypesEnum]
    ) {
      disabledFilters.push(filterType as EntityTypesEnum);
    }
  });
  return ({ disabled: disabledFilters });
};
