import { QueryFilterOptionShallow, StoryDuration, Tag } from 'mediascouting-core-ui-common';
import {
  RESET_QUERY_PARAMS,
  SET_ADS,
  SET_DAYS,
  SET_END_TIME,
  SET_GEOLOCATION_TAGS,
  SET_LANGUAGE,
  SET_MARKET,
  SET_SOURCES,
  SET_ORGANIZATIONS, SET_PAGE,
  SET_PAGE_SIZE,
  SET_PEOPLE,
  SET_PREVIEW_NEW_ITEMS,
  SET_QUERY_PARAMS,
  SET_QUERY_TEXT,
  SET_SENTIMENT,
  SET_SOURCE_TYPES,
  SET_START_TIME, SET_STORY_DURATION,
  SET_SUB_QUERIES,
  SET_TAGS,
  SET_TIME_SECTION,
  SET_TITLE,
} from '../types/QuerySearchSpaceActionTypes';
import { QuerySearchSpaceReducer } from '../../../redux/reducers/QuerySearchSpace';

export const setQuerySearchSpace = (queryParams: QuerySearchSpaceReducer) => ({
  type: SET_QUERY_PARAMS,
  queryParams,
} as const);

export const resetQuerySearchSpace = () => ({
  type: RESET_QUERY_PARAMS,
} as const);

export const setPageAction = (page: number) => ({
  type: SET_PAGE,
  page,
} as const);

export const setPageSizeAction = (size: number) => ({
  type: SET_PAGE_SIZE,
  size,
} as const);

export const setQueryTextAction = (queryText: string) => ({
  type: SET_QUERY_TEXT,
  queryText,
} as const);

export const setStartTimeAction = (startTime: string | null) => ({
  type: SET_START_TIME,
  startTime,
} as const);

export const setEndTimeAction = (endTime: string | null) => ({
  type: SET_END_TIME,
  endTime,
} as const);

export const setTitleAction = (title: string) => ({
  type: SET_TITLE,
  title,
} as const);

export const setSubQueriesAction = (subQueries: Array<QueryFilterOptionShallow>) => ({
  type: SET_SUB_QUERIES,
  subQueries,
} as const);

export const setTagsAction = (tags: Array<Tag>) => ({
  type: SET_TAGS,
  tags,
} as const);

export const setSourceTypesAction = (sourceTypes: Array<QueryFilterOptionShallow>) => ({
  type: SET_SOURCE_TYPES,
  sourceTypes,
} as const);

export const setLanguageAction = (language: Array<QueryFilterOptionShallow>) => ({
  type: SET_LANGUAGE,
  language,
} as const);

export const setSourcesAction = (sources: Array<QueryFilterOptionShallow>) => ({
  type: SET_SOURCES,
  sources,
} as const);

export const setGeolocationTagsAction = (geolocationTags: Array<QueryFilterOptionShallow>) => ({
  type: SET_GEOLOCATION_TAGS,
  geolocationTags,
} as const);

export const setMarketAction = (market: Array<QueryFilterOptionShallow>) => ({
  type: SET_MARKET,
  market,
} as const);

export const setSentimentAction = (sentiment: Array<QueryFilterOptionShallow>) => ({
  type: SET_SENTIMENT,
  sentiment,
} as const);

export const setAdsAction = (ads: Array<QueryFilterOptionShallow>) => ({
  type: SET_ADS,
  ads,
} as const);

export const setDaysAction = (days: Array<QueryFilterOptionShallow>) => ({
  type: SET_DAYS,
  days,
} as const);

export const setTimeSectionAction = (timeSections: Array<QueryFilterOptionShallow>) => ({
  type: SET_TIME_SECTION,
  timeSections,
} as const);

export const setPeopleAction = (people: Array<QueryFilterOptionShallow>) => ({
  type: SET_PEOPLE,
  people,
} as const);

export const setOrganizationsAction = (organizations: Array<QueryFilterOptionShallow>) => ({
  type: SET_ORGANIZATIONS,
  organizations,
} as const);

export const setPreviewNewItems = (previewNewItems: boolean) => ({
  type: SET_PREVIEW_NEW_ITEMS,
  previewNewItems,
} as const);

export const setStoryDuration = (storyDuration: StoryDuration) => ({
  type: SET_STORY_DURATION,
  storyDuration,
} as const);

export type QuerySearchSpaceActions =
    | ReturnType<typeof setQuerySearchSpace>
    | ReturnType<typeof resetQuerySearchSpace>
    | ReturnType<typeof setQueryTextAction>
    | ReturnType<typeof setStartTimeAction>
    | ReturnType<typeof setEndTimeAction>
    | ReturnType<typeof setTitleAction>
    | ReturnType<typeof setSubQueriesAction>
    | ReturnType<typeof setTagsAction>
    | ReturnType<typeof setSourceTypesAction>
    | ReturnType<typeof setLanguageAction>
    | ReturnType<typeof setSourcesAction>
    | ReturnType<typeof setGeolocationTagsAction>
    | ReturnType<typeof setMarketAction>
    | ReturnType<typeof setSentimentAction>
    | ReturnType<typeof setAdsAction>
    | ReturnType<typeof setDaysAction>
    | ReturnType<typeof setTimeSectionAction>
    | ReturnType<typeof setPeopleAction>
    | ReturnType<typeof setOrganizationsAction>
    | ReturnType<typeof setPageAction>
    | ReturnType<typeof setPageSizeAction>
    | ReturnType<typeof setPreviewNewItems>
    | ReturnType<typeof setStoryDuration>
