import React, { memo, useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';

function Contacts(): JSX.Element {
  const history = useHistory();
  const ref = useRef(null);
  const { t } = useTranslation();
  const handleOpen = (): void => {
    history.push('/portal/contacts');
  };

  return (
      <>
          <Tooltip title={t('layout.topBar.search.searchBar.contacts') || EMPTY_STRING}>
              <IconButton
                color="inherit"
                onClick={handleOpen}
                ref={ref}
              >
                  <RecentActorsRoundedIcon />
              </IconButton>
          </Tooltip>
      </>
  );
}

export default memo(Contacts);
