import React, { memo } from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import {
  DialogTitle, IconButton, Theme, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

type MobileDetachmentPropTypes = {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContentRoot: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

function MobileDetachment(props: MobileDetachmentPropTypes): JSX.Element {
  const {
    open, children, onClose,
  } = props;
  const classes = useStyles();

  const handleClose = (event): void => {
    event.stopPropagation();

    onClose();
  };

  return (
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen
        fullWidth
        disableEnforceFocus
      >
          <DialogTitle id="search-dialog" disableTypography className={classes.root}>
              <Typography variant="h6">Search</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            classes={{ root: classes.dialogContentRoot }}
          >
              {children}
          </DialogContent>
      </Dialog>
  );
}

export default memo(MobileDetachment);
