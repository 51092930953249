import {
  Badge,
  Box, Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  DraftHandleValue, Editor, EditorState, getDefaultKeyBinding,
} from 'draft-js';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NotFound } from 'mediascouting-core-ui-common';
import SearchIcon from '@material-ui/icons/Search';
import { TFunction } from 'i18next';
import createNeoSearchEditorDecorators from './utils/NeoSearchEditorDecoratorUtils';
import TranslationNameSpaceEnum from '../../../../../types/translation/TranslationNameSpaceEnum';
import { EditorRequest } from '../../../../../types/layout/topBar/search/editor';
import fulfilEditorRequest
  from '../../../../features/search/IntelligentQuerySearchBar/utils/editor/draft-js/EditorRequestUtils';
import NeoSearchSuggestionService from './components/NeoSearchSuggestionService';
import NEO_SEARCH_ENTITY_MUTABILITY_MAPPER from './consts/DraftJSEntityMutability';
import { NeoSearchEntityTypeEnum, SHORTCUT_TEXT_TRANSLATION_KEY_MAPPER } from '../../types/NeoSearch';
import { Suggestion } from '../../../../../types/layout/topBar/search/suggestion';
import { HANDLED, NOT_HANDLED } from '../../../../../constants/draft-js/DraftCommands';
import NeoSearchCommandsEnum from '../../types/NeoSearchCommands';
import { NeoBarConfiguration } from '../../types/NeoBarConfiguration';
import LinearBuffer from '../LinearBuffer';
import { createNeoSearchSuggestions, findSuggestionRelatedToText } from './utils/NeoSearchSuggestionServiceUtils';

interface NeoSearchPropTypes {
    configuration: NeoBarConfiguration;
    loading: boolean;
    onSearch: (editorState: EditorState) => void;
    onSearchEditorStateChanged: (editorState: EditorState) => void;
}

type SearchGridSize = 11 | 9 | 7;

const useInputStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
}));

const editorDecorators = createNeoSearchEditorDecorators();

const getInitEditorState = (
  configuration: NeoBarConfiguration,
  t: TFunction,
  onSearch: (editorState: EditorState) => void,
): EditorState => {
  const editorState = EditorState.createEmpty(editorDecorators);

  if (configuration.search.neoSearch.initializeWithEntityType) {
    const suggestions = createNeoSearchSuggestions(configuration, t);
    const found = findSuggestionRelatedToText(suggestions, configuration.search.neoSearch.initializeWithEntityType);

    if (found) {
      const updatedEditorState = fulfilEditorRequest(editorState, found.request, NEO_SEARCH_ENTITY_MUTABILITY_MAPPER);

      onSearch(updatedEditorState);

      return updatedEditorState;
    }
  }

  return editorState;
};
function NeoSearch(props: NeoSearchPropTypes): JSX.Element {
  const {
    configuration, loading, onSearch, onSearchEditorStateChanged,
  } = props;
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const inputStyles = useInputStyles();
  const [editorState, setEditorState] = useState<EditorState>(() => getInitEditorState(
    configuration, t, onSearch,
  ));
  const [expandFilters, setExpandFilters] = useState(false);
  const [suggestedSuggestion, setSuggestedSuggestion] = useState<(Suggestion<NeoSearchEntityTypeEnum>) | NotFound>();
  const prevEditorState = useRef(editorState);

  const handleEditorRequest = useCallback((editorRequest: EditorRequest<NeoSearchEntityTypeEnum>) => {
    setEditorState((prevState) => fulfilEditorRequest(
      prevState, editorRequest, NEO_SEARCH_ENTITY_MUTABILITY_MAPPER,
    ));
  }, []);

  const handleShowMore = (): void => {
    setExpandFilters((prev) => !prev);
  };

  useEffect(() => {
    if (prevEditorState.current.getCurrentContent().getPlainText() !== editorState.getCurrentContent().getPlainText()) {
      onSearchEditorStateChanged(editorState);
    }
  }, [editorState, onSearchEditorStateChanged]);

  useEffect(() => {
    prevEditorState.current = editorState;
  });

  const getExploreIcon = (): JSX.Element => {
    if (expandFilters) {
      return (
          <ExpandLessIcon />
      );
    }

    return (
        <ExpandMoreIcon />
    );
  };

  const handleSearching = (currentEditorState: EditorState): DraftHandleValue => {
    if (onSearch) {
      onSearch(currentEditorState);

      return HANDLED;
    }

    return NOT_HANDLED;
  };

  const handleApplyingOptionalSuggestion = (currentEditorState: EditorState): DraftHandleValue => {
    if (suggestedSuggestion) {
      handleEditorRequest(suggestedSuggestion.request);

      return HANDLED;
    }

    if (onSearch
        && configuration.shortcuts.manualSearchTriggerKey === configuration.shortcuts.selectSuggestedSuggestionKey
    ) {
      onSearch(currentEditorState);

      return HANDLED;
    }

    return NOT_HANDLED;
  };

  const handleKeyBinding = (e): string | null => {
    if (e.keyCode === configuration.shortcuts.selectSuggestedSuggestionKey) {
      return NeoSearchCommandsEnum.APPLY_OPTIONAL_SUGGESTION;
    }

    if (e.keyCode === configuration.shortcuts.manualSearchTriggerKey) {
      return NeoSearchCommandsEnum.SEARCH;
    }

    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command: string, currentEditorState: EditorState): DraftHandleValue => {
    if (command === NeoSearchCommandsEnum.APPLY_OPTIONAL_SUGGESTION) {
      return handleApplyingOptionalSuggestion(currentEditorState);
    }

    if (command === NeoSearchCommandsEnum.SEARCH) {
      return handleSearching(currentEditorState);
    }

    return NOT_HANDLED;
  };

  const getEditor = (): JSX.Element => (
      <Typography component="span" classes={inputStyles}>
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            keyBindingFn={handleKeyBinding}
            handleKeyCommand={handleKeyCommand}
            placeholder={t('categorySubHeader.search')}
            stripPastedStyles
          />
      </Typography>
  );

  const getGridNumber = (): SearchGridSize => {
    let initial = 11;

    if (!configuration.search.neoSearch.autoSearch) {
      initial -= 2;
    }

    if (configuration.search.neoSearch.suggestions.allowUserToExploreOptions) {
      initial -= 2;
    }

    if (initial) {
      return initial as SearchGridSize;
    }

    return 7;
  };

  const handleSearch = (): void => {
    onSearch(editorState);
  };

  const getManualSearchExplanationTooltipText = (): string => {
    const initialSearchText = t('neoBar.neoSearch.search');

    const found = SHORTCUT_TEXT_TRANSLATION_KEY_MAPPER[
      configuration.shortcuts.manualSearchTriggerKey
    ];

    if (found) {
      return initialSearchText.concat(` (${t(found)})`);
    }

    return initialSearchText;
  };

  const getSearch = (): React.ReactNode => (
      <Grid container alignContent="center" alignItems="center">
          <Grid
            item
            xs={getGridNumber()}
            sm={getGridNumber()}
            md={getGridNumber()}
            lg={getGridNumber()}
            xl={getGridNumber()}
          >
              {getEditor()}
          </Grid>
          {!configuration.search.neoSearch.autoSearch
                  && (
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Tooltip title={getManualSearchExplanationTooltipText()}>
                              <IconButton onClick={handleSearch}>
                                  <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible
                                  >
                                      <SearchIcon />
                                  </Badge>
                              </IconButton>
                          </Tooltip>
                      </Grid>
                  )}
          {configuration.search.neoSearch.suggestions.allowUserToExploreOptions
                  && (
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Tooltip title={t('neoBar.neoSearch.explore') as string}>
                              <IconButton onClick={handleShowMore}>
                                  {getExploreIcon()}
                              </IconButton>
                          </Tooltip>
                      </Grid>
                  )}
      </Grid>
  );

  return (
      <div>
          <Box pl={2}>
              {getSearch()}
          </Box>
          {configuration.search.neoSearch.showSearchProgress
            && <LinearBuffer loading={loading} />}
          <NeoSearchSuggestionService
            editorState={editorState}
            expandFilters={expandFilters}
            suggestedSuggestion={suggestedSuggestion}
            configuration={configuration}
            onEditorRequest={handleEditorRequest}
            onChangeSuggestedSuggestion={setSuggestedSuggestion}
          />
      </div>
  );
}

export default NeoSearch;
