import React, { useCallback, useEffect, useState } from 'react';
import { getDefaultLogo, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PLATFORM_CONFIGURATION from '../../../configuration';

const useStyles = makeStyles(() => createStyles({
  '@keyframes pulse-animation': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.7,
    },
    '100%': {
      transform: 'scale(1.3)',
      opacity: 0,
    },
  },
  circle: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    boxShadow: '0px 0px 1px 1px #0000001a',
  },
  pulse: {
    animation: '$pulse-animation 2s infinite',
  },
  blurredImage: {
    color: 'grey',
  },
}));

interface LogoPropTypes{
  logo: string | NotFound;
}
function Logo(props: LogoPropTypes): JSX.Element {
  const { logo } = props;
  const [logoSource, setLogoSource] = useState<string | NotFound>(NOT_FOUND);
  const classes = useStyles();

  useEffect(() => {
    if (logo !== NOT_FOUND) {
      const defaultLogo = getDefaultLogo(logo, PLATFORM_CONFIGURATION?.defaultLogoPath);
      if (defaultLogo) {
        setLogoSource(defaultLogo);
      }
    }
  }, [logo]);

  const renderLogo = useCallback(() => {
    if (logoSource !== NOT_FOUND) {
      return (
          <img
            height={60}
            alt="Logo"
            src={logoSource}
          />
      );
    }

    return <PanoramaIcon className={clsx([classes.blurredImage, classes.circle, classes.pulse])} />;
  }, [classes.blurredImage, classes.circle, classes.pulse, logoSource]);

  return renderLogo();
}

export default Logo;
