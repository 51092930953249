import React, { ReactNode, useCallback, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { COMMA, EMPTY_STRING } from 'mediascouting-core-ui-common';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Settings } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import LabelIcon from '@material-ui/icons/Label';
import { useTranslation } from 'react-i18next';
import { NavigationSectionItem, NeoBarItemType } from '../../../../../../types/layout/navBar';
import queryUrlParamsBuilder from '../../../../../../utils/query/QueryUrlUtils';
import { NeoBarItemSettings, NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';
import useNeoBarRouter from '../../../hooks/useNeoBarRouter';
import getNeoSearchNavigationItemAvatarIcon from '../../../utils/NeoSearchAvatarUtils';
import useNeoBarColors from '../../../hooks/useNeoBarColors';
import TranslationNameSpaceEnum from '../../../../../../types/translation/TranslationNameSpaceEnum';
import NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER from '../../../constants/NeoSearchEntityTypeTranslationMapper';
import NEO_BAR_ITEM_TYPE_TRANSLATION_MAPPER from '../../../constants/NeoBarItemTypeTranslationMapper';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';
import BadgeAvatar from '../../../../BadgeAvatar';

export interface NeoBarItemCardPropTypes {
  children?: ReactNode;
  type: NeoBarItemType;
  item: NavigationSectionItem;
  settings: NeoBarItemSettings;
  configuration: NeoBarConfiguration;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 345,
    borderRadius: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  isLiveQuery: {
    marginTop: theme.spacing(0.4),
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(0.4),
    paddingRight: theme.spacing(0.4),
    width: '28px',
    textAlign: 'center',
    border: '1px solid gray',
    fontSize: 10,
    opacity: 0.5,
  },
  magicIcon: {
    width: '16px',
    height: '16px',
  },
  pointer: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

function NeoBarItemCard(props: NeoBarItemCardPropTypes): JSX.Element {
  const {
    item, type, settings, configuration,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(false);
  const [summaryExpanded, setSummaryExpanded] = React.useState(false);
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const { getNeoBarNavigationItemTypeColor } = useNeoBarColors({});
  const { getPath } = useNeoBarRouter();

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const getEntityAndItemTypeTranslated = (
    entityType: NeoSearchEntityTypeEnum, itemType: NeoBarItemType,
  ): string => {
    const i18EntityTypeKey = NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER[entityType];
    const i18ItemTypeKey = NEO_BAR_ITEM_TYPE_TRANSLATION_MAPPER[itemType];

    return `${t(i18EntityTypeKey)} ${t(i18ItemTypeKey)}`;
  };

  const getSubheader = (
    currentNavBarItem: NavigationSectionItem,
  ): React.ReactNode => getEntityAndItemTypeTranslated(currentNavBarItem.entityType, currentNavBarItem.type);

  const getAvatar = (): React.ReactElement => {
    const icon = getNeoSearchNavigationItemAvatarIcon(item.entityType);
    const className = getNeoBarNavigationItemTypeColor(item.entityType);
    const tooltipText = getEntityAndItemTypeTranslated(item.entityType, item.type);

    if (configuration.display.settings.neoCard.displayTypeBadge) {
      const badgeIcon = item.type === NeoBarItemType.QUERY_TYPE
        ? <SearchIcon className={classes.magicIcon} />
        : <LabelIcon className={classes.magicIcon} />;

      return (
          <BadgeAvatar
            badgeIcon={badgeIcon}
            avatarIcon={icon}
            avatarTooltipText={tooltipText}
            avatarClassName={className}
          />
      );
    }

    return (
        <Tooltip title={tooltipText}>
            <Avatar variant="rounded" aria-label="Query Type" className={className}>
                {icon}
            </Avatar>
        </Tooltip>
    );
  };

  const handleClick = (): void => {
    const queryParams = queryUrlParamsBuilder(
      type, item.id, item.text || EMPTY_STRING, item.subQueries, item.filters,
    );
    const pathname = getPath();

    const queryUrl = {
      pathname,
      search: queryParams,
    };

    if (settings.selected) {
      history.push({
        pathname: queryUrl.pathname,
        search: EMPTY_STRING,
      });
    } else {
      history.push(queryUrl);
    }
  };

  const handleSummary = useCallback(() => {
    if (configuration.display.settings.neoCard.displaySummary) {
      setSummaryExpanded(true);
    } else {
      setSummaryExpanded(false);
    }
  }, [configuration.display.settings.neoCard.displaySummary]);

  useEffect(() => {
    handleSummary();
  }, [handleSummary]);

  const getTitle = (): JSX.Element => {
    if (settings.live) {
      return (
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
              {settings.selected ? <b>{item.name}</b> : <div>{item.name}</div>}
              <Typography className={classes.isLiveQuery}>
                  {t('neoBar.neoItem.live')}
              </Typography>
          </Box>
      );
    }

    if (settings.selected) {
      return <b>{item.name}</b>;
    }

    return <>{item.name}</>;
  };

  const getCardImplementation = (): JSX.Element => (
      <Card raised={settings.selected} className={classes.root}>
          <CardHeader
            avatar={getAvatar()}
            onClick={handleClick}
            action={(
                <IconButton aria-label="settings">
                    <Settings color="disabled" />
                </IconButton>
            )}
            title={getTitle()}
            subheader={getSubheader(item)}
            className={classes.pointer}
          />
          <Collapse in={summaryExpanded} timeout="auto" unmountOnExit>
              <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica
                  </Typography>
              </CardContent>
          </Collapse>
          {item.subQueries.length > 0
            && (
                <CardActions disableSpacing>
                    <Typography variant="subtitle2">
                        {t('neoBar.neoItem.card.subQueries')}
                    </Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
            )}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                  <Typography paragraph>
                      {item.subQueries.map((sub) => sub.name).join(COMMA)}
                  </Typography>
              </CardContent>
          </Collapse>
      </Card>
  );

  return getCardImplementation();
}

export default NeoBarItemCard;
