import React from 'react';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import { SelectionState } from 'draft-js';
import { Suggestion } from '../../../../../../types/layout/topBar/search/suggestion';
import {
  AdditionRequest,
  EditorRequest,
  EntityRange,
  EntityRecognition,
} from '../../../../../../types/layout/topBar/search/editor';
import ADVANCED_FILTER_ENTITY_TYPE_ICONS
  from '../../../../../../constants/search/suggestions/AdvancedFilterEntityTypeIcons';
import createEditorSuggestions from './SuggestionFactory';
import { mergeSelection } from '../../../../../../utils/draft-js/SelectionUtils';
import Filter from '../../QueryEditor/Filter';
import { EntityTypesEnum, FILTER_ENTITY_TYPES } from '../../../../../../constants/search/editor/EntityTypes';
import { IntelligentQuerySearchBarConfiguration } from '../../../../../../configuration/schema';

export const getAdvancedFilterIcon = (
  type?: EntityTypesEnum,
  size?: 'inherit' | 'default' | 'small' | 'large',
): JSX.Element => {
  if (type) {
    const Icon = ADVANCED_FILTER_ENTITY_TYPE_ICONS[type] || BrokenImageOutlinedIcon;

    return <Icon fontSize={size} />;
  }

  return <BrokenImageOutlinedIcon />;
};

export const createSuggestion = (
  preview: React.ReactNode,
  request: EditorRequest<EntityTypesEnum>,
): Suggestion<EntityTypesEnum> => ({
  preview,
  request,
});

export const createSuggestionReference = (entityRange: EntityRange): SelectionState => {
  const selectionState = SelectionState.createEmpty(entityRange.blockKey);

  return mergeSelection(selectionState, entityRange.end, entityRange.end);
};

export const createMatcherAdditionRequest = (
  matcher: EntityTypesEnum, entityRange: EntityRange,
): AdditionRequest<EntityTypesEnum> => {
  const selectionReference = createSuggestionReference(entityRange);
  return {
    additionText: ` ${matcher} `,
    reference: selectionReference,
    cursorPositionWithinText: ` ${matcher} `.length,
    entityMetadata: [
      {
        type: matcher,
        start: ' '.length,
        end: ` ${matcher}`.length,
      },
    ],
  };
};

export const createAdvancedFilterSuggestions = (
  recognizedEntities?: EntityRecognition,
  configuration?: IntelligentQuerySearchBarConfiguration,
): Array<Suggestion<EntityTypesEnum>> => {
  let reference;

  if (recognizedEntities) {
    if (recognizedEntities.parentEntityRange) {
      const { entity, blockKey, start } = recognizedEntities.parentEntityRange;
      const { length } = entity.getData();
      if (length) {
        const selectionState = SelectionState.createEmpty(blockKey);

        reference = mergeSelection(selectionState, start + length, start + length);
      }
    } else {
      const { blockKey, entity, start } = recognizedEntities.entityRange;
      const type = entity.getType() as EntityTypesEnum;

      if (FILTER_ENTITY_TYPES.includes(type)) {
        const { length } = entity.getData();

        if (length) {
          const selectionState = SelectionState.createEmpty(blockKey);

          reference = mergeSelection(selectionState, start + length, start + length);
        }
      } else {
        reference = createSuggestionReference(recognizedEntities.entityRange);
      }
    }
  }

  return FILTER_ENTITY_TYPES.filter((
    filterableOption,
  ) => !(configuration && configuration?.disabled.includes(filterableOption))).map((option) => {
    const preview = (<Filter type={option}>{option}</Filter>);
    const request: AdditionRequest<EntityTypesEnum> = {
      additionText: ` ${option}: ()`,
      entityMetadata: [
        {
          type: option,
          start: 1,
          end: ` ${option}`.length,
          data: {
            reference: Date.now() + option,
            length: `${option}: ()`.length,
          },
        },
      ],
      reference,
      cursorPositionWithinText: ` ${option}: (`.length,
    };

    return createSuggestion(preview, request);
  });
};

export const createEntitySuggestions = (
  recognizedEntities: EntityRecognition,
): Array<Suggestion<EntityTypesEnum>> => createEditorSuggestions(recognizedEntities);
