import {
  Group, NOT_FOUND, User,
} from 'mediascouting-core-ui-common';
import { AuthActions } from '../../constants/actions/creators/AuthActions';
import {
  SESSION_LOGIN, SESSION_LOGOUT, USER_DATA, USER_GROUP,
} from '../../constants/actions/types/AuthActionTypes';
import { ACCESS_TOKEN } from '../../constants/LocalStorage';

export interface AuthReducer {
    token?: string;
    user?: User;
    group?: Group;
}

const accessToken = localStorage.getItem(ACCESS_TOKEN);

const INIT_STATE: AuthReducer = {
  token: (accessToken !== null) ? accessToken : NOT_FOUND,
  user: NOT_FOUND,
};

export default (state = INIT_STATE, action: AuthActions): AuthReducer => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        token: action.payload.token,
        user: state.user,
      };
    }
    case SESSION_LOGOUT: {
      return {
        ...state,
        token: NOT_FOUND,
        user: NOT_FOUND,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case USER_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    default:
      return state;
  }
};
