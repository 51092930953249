import { CartActions } from '../../constants/actions/creators/CartActions';
import { ADD_TO_CART, REMOVE_FROM_CART, RESET_CART } from '../../constants/actions/types/CartActionTypes';
import { ShoppingItem } from '../../types/layout/topBar/Cart';

export type CartReducer = {
    shoppingList: Array<ShoppingItem>;
}

const INIT_STATE: CartReducer = {
  shoppingList: [],
};

export default (state = INIT_STATE, action: CartActions): CartReducer => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        shoppingList: [...state.shoppingList, action.payload],
      };
    }
    case REMOVE_FROM_CART: {
      const updatedShoppingList = [...state.shoppingList];
      const foundIndex = updatedShoppingList.findIndex((value) => value.itemId === action.payload.itemId);

      if (foundIndex !== -1) {
        updatedShoppingList.splice(foundIndex, 1);
      }

      return {
        ...state,
        shoppingList: updatedShoppingList,
      };
    }

    case RESET_CART: {
      return {
        ...state,
        shoppingList: [],
      };
    }

    default:
      return state;
  }
};
