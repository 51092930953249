import EntityMutabilityEnum from '../../draft-js/EntityMutability';
import { EntityTypesEnum } from './EntityTypes';

const ENTITY_MUTABILITY_MAPPER = {
  [EntityTypesEnum.EXACT_TEXT_MATCH]: EntityMutabilityEnum.MUTABLE,
  [EntityTypesEnum.NAND_CONDITION_MATCHER]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.AND_CONDITION_MATCHER]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.OR_CONDITION_MATCHER]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.NOT_CONDITION_MATCHER]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SIMPLE_INPUT]: EntityMutabilityEnum.MUTABLE,
  [EntityTypesEnum.SUBQUERIES]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.TAGS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.START]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.END]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.START_TIME]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.END_TIME]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.DAYS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.TIME_SECTIONS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SOURCES]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SOURCE_GROUPS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SOURCE_TYPES]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.MARKET_TAGS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.TITLE]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.LANGUAGE]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.GEOLOCATION_TAGS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SENTIMENT]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.PEOPLE]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.ORGANIZATIONS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.PROGRAMS]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.FILTER_OPTION]: EntityMutabilityEnum.IMMUTABLE,
  [EntityTypesEnum.SEARCHABLE_FILTER_OPTION]: EntityMutabilityEnum.MUTABLE,
};

export default ENTITY_MUTABILITY_MAPPER;
