import { NeoSearchConditionHandler, NeoSearchConditionHandlers, NeoSearchRequest } from '../types/NeoSearch';

const getConditionHandlers = (
  request: NeoSearchRequest,
  handlers: NeoSearchConditionHandlers,
): Array<NeoSearchConditionHandler> => ([
  {
    if: request.queries.fetchQueries,
    then: (): void => handlers.handleQuerySearch(request.text, request.queries.types),
  },
  {
    if: request.queries.fetchSharedQueries,
    then: (): void => handlers.handleSharedQuerySearch(request.text, request.queries.types),
  },
  {
    if: request.tags.fetchTags,
    then: (): void => handlers.handleTagSearch(request.text, request.tags.types),
  },
  {
    if: request.tags.fetchTags,
    then: (): void => handlers.handleTagSearch(request.text, request.tags.types),
  },
]);

export default getConditionHandlers;
