import React, { ReactNode, useState } from 'react';
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { EMPTY_STRING, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import SearchIcon from '@material-ui/icons/Search';
import LabelIcon from '@material-ui/icons/Label';
import { useHistory } from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { useTranslation } from 'react-i18next';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import { NavigationSectionItem, NeoBarItemType } from '../../../../../../types/layout/navBar';
import queryUrlParamsBuilder from '../../../../../../utils/query/QueryUrlUtils';
import useNeoBarRouter from '../../../hooks/useNeoBarRouter';
import getNeoSearchNavigationItemAvatarIcon from '../../../utils/NeoSearchAvatarUtils';
import useNeoBarColors from '../../../hooks/useNeoBarColors';
import BadgeAvatar from '../../../../BadgeAvatar';
import { NeoBarItemSettings, NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';
import TranslationNameSpaceEnum from '../../../../../../types/translation/TranslationNameSpaceEnum';
import NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER from '../../../constants/NeoSearchEntityTypeTranslationMapper';
import NEO_BAR_ITEM_TYPE_TRANSLATION_MAPPER from '../../../constants/NeoBarItemTypeTranslationMapper';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';
import { Subquery } from '../../../../../../utils/common/NavBarUtils';

export interface NeoBarListItemPropTypes {
    children?: ReactNode;
    type: NeoBarItemType;
    configuration: NeoBarConfiguration;
    settings: NeoBarItemSettings;
    item: NavigationSectionItem;
}

interface AvatarSettings {
    displayBadgeOfNavigationItemType: boolean;
}

const AVATAR_SETTINGS: AvatarSettings = {
  displayBadgeOfNavigationItemType: false,
};

const useStyles = makeStyles((theme: Theme) => ({
  isLiveQuery: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(0.4),
    paddingRight: theme.spacing(0.4),
    width: '28px',
    textAlign: 'center',
    border: '1px solid gray',
    fontSize: 10,
    opacity: 0.5,
  },
  magicIcon: {
    width: '16px',
    height: '16px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  containsSubQueries: {
    marginLeft: '24px',
  },
  guttersOverride: {
    paddingLeft: '5px',
    paddingRight: '16px',
  },
}));

function NeoBarListItem(props: NeoBarListItemPropTypes): JSX.Element {
  const {
    item, type, configuration, settings,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const { getPath } = useNeoBarRouter();
  const [open, setOpen] = useState(false);
  const { getNeoBarNavigationItemTypeColor } = useNeoBarColors({
    desiredBackgroundColor: configuration.display.settings.neoList.useDesiredBackgroundColor
      ? item.desiredBackgroundColor : NOT_FOUND,
  });

  const handleToggle = (event): void => {
    event.stopPropagation();

    setOpen((prev) => !prev);
  };

  const getEntityAndItemTypeTranslated = (
    entityType: NeoSearchEntityTypeEnum, itemType: NeoBarItemType,
  ): string => {
    const i18EntityTypeKey = NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER[entityType];
    const i18ItemTypeKey = NEO_BAR_ITEM_TYPE_TRANSLATION_MAPPER[itemType];

    return `${t(i18EntityTypeKey)} ${t(i18ItemTypeKey)}`;
  };

  const getSubheader = (currentNavBarItem: NavigationSectionItem): React.ReactNode | NotFound => {
    if (configuration.display.settings.neoList.displayType) {
      return getEntityAndItemTypeTranslated(currentNavBarItem.entityType, currentNavBarItem.type);
    }

    return NOT_FOUND;
  };

  const getAvatar = (avatarSettings: AvatarSettings): React.ReactElement => {
    const icon = getNeoSearchNavigationItemAvatarIcon(item.entityType);
    const className = getNeoBarNavigationItemTypeColor(item.entityType);
    const tooltipText = getEntityAndItemTypeTranslated(item.entityType, item.type);
    const badgeIcon = item.type === NeoBarItemType.QUERY_TYPE
      ? <SearchIcon className={classes.magicIcon} />
      : <LabelIcon className={classes.magicIcon} />;

    if (avatarSettings.displayBadgeOfNavigationItemType) {
      return (
          <BadgeAvatar
            badgeIcon={badgeIcon}
            avatarIcon={icon}
            avatarTooltipText={tooltipText}
            avatarClassName={className}
          />
      );
    }

    return (
        <Tooltip title={tooltipText}>
            <Avatar
              variant="rounded"
              aria-label="Navigation Type"
              className={className}
            >
                {icon}
            </Avatar>
        </Tooltip>
    );
  };

  const handleClick = (): void => {
    const queryParams = queryUrlParamsBuilder(
      type,
      item.id,
      item.text || EMPTY_STRING,
      item.subQueries,
      item.filters,
    );

    const pathname = getPath();

    const queryUrl = {
      pathname,
      search: queryParams,
    };

    if (settings.selected) {
      history.push({
        pathname: queryUrl.pathname,
        search: EMPTY_STRING,
      });
    } else {
      history.push(queryUrl);
    }
  };

  const getTitle = (): JSX.Element => <>{item.name}</>;

  const getListItemAvatarBasedOnSettings = (): JSX.Element | NotFound => {
    if (configuration.display.settings.neoList.displayAvatar) {
      return (
          <ListItemAvatar
            className={clsx(
              { [classes.containsSubQueries]: item.subQueries.length === 0 },
            )}
          >
              {getAvatar(AVATAR_SETTINGS)}
          </ListItemAvatar>
      );
    }

    return NOT_FOUND;
  };

  const handleSubQueryClick = (subquery: Subquery) => (): void => {
    const queryParams = queryUrlParamsBuilder(type, subquery.id);
    const pathname = getPath();
    const queryUrl = {
      pathname,
      search: queryParams,
    };

    history.push(queryUrl);
  };

  const getLiveQueryExtension = (): JSX.Element => {
    if (settings.live) {
      return (
          <Typography className={classes.isLiveQuery}>
              {t('neoBar.neoItem.live')}
          </Typography>
      );
    }

    return (
        <>
        </>
    );
  };

  const getSubQueryControlIcons = (): JSX.Element | NotFound => {
    if (item.subQueries.length === 0) {
      return NOT_FOUND;
    }

    if (open) {
      return (
          <Tooltip title={t('neoBar.neoItem.hide.subqueries') || EMPTY_STRING}>
              <ArrowDropDown
                color="inherit"
                onClick={handleToggle}
              />
          </Tooltip>
      );
    }

    return (
        <Tooltip title={t('neoBar.neoItem.show.subqueries') || EMPTY_STRING}>
            <ArrowRight
              color="inherit"
              onClick={handleToggle}
            />
        </Tooltip>
    );
  };

  const getListImplementation = (): JSX.Element => (
      <>
          <ListItem
            selected={settings.selected}
            onClick={handleClick}
            button
            divider
            dense
            classes={{
              gutters: classes.guttersOverride,
            }}
          >
              {getSubQueryControlIcons()}
              {getListItemAvatarBasedOnSettings()}
              <ListItemText
                className={clsx(
                  {
                    [classes.containsSubQueries]: item.subQueries.length === 0
                        && !configuration.display.settings.neoList.displayAvatar,
                  },
                )}
                primary={getTitle()}
                secondary={getSubheader(item)}
              />
              {getLiveQueryExtension()}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  {item.subQueries.map((subQuery) => (
                      <ListItem
                        key={subQuery.id}
                        className={classes.nested}
                        onClick={handleSubQueryClick(subQuery)}
                        button
                      >
                          {configuration.display.settings.neoList.displaySubQueryIcon
                              && (
                                  <ListItemIcon>
                                      <SubdirectoryArrowRightIcon />
                                  </ListItemIcon>
                              )}
                          <ListItemText primary={subQuery.name} />
                      </ListItem>
                  ))}
              </List>
          </Collapse>
      </>

  );

  return getListImplementation();
}

export default NeoBarListItem;
