import { INIT_PAGINATION, PageableProperties, ShallowStory } from 'mediascouting-core-ui-common';
import { QueryResultsActions } from '../../constants/actions/creators/QueryResultsActions';
import {
  CLEAR_SELECTION,
  RESET_QUERY_RESULTS,
  SET_FACET_GROUPS,
  SET_PAGINATION_INFO,
  SET_QUERY_RESULTS,
  SET_SELECTION,
} from '../../constants/actions/types/QueryResultsActionTypes';
import { SelectionState, SelectionType, SelectTarget } from '../../types/query/Selector';
import { FacetGroup } from '../../types/query/results/Facets';
import { StorySnapSelectionRangeEnum } from '../../constants/StorySnapToolbarSelectionOptions';
import { createFacets } from '../../utils/query/FacetUtils';
import Configuration from '../../configuration';
import { getSelectionStateOnPaginationChange, getUpdatedState } from '../../utils/query/QueryStoryResultsUitls';

export interface QueryStoryResults {
    stories: Array<ShallowStory>;
    selection: SelectionState;
    facetGroups: Array<FacetGroup>;
    pageable?: PageableProperties;
}

export const INIT_SELECT_TARGET: SelectTarget = {
  type: SelectionType.SELECT,
  storiesToSelect: [],
  storiesVisibleInViewAtMoment: 0,
  range: StorySnapSelectionRangeEnum.PAGE,
  latestAvailableStories: [],
};

export const INIT_SELECTION: SelectionState = {
  selectedStoriesBetweenPages: [],
  storiesVisibleInViewAtMoment: 0,
  range: StorySnapSelectionRangeEnum.PAGE,
  latestAvailableStories: [],
};

export const INIT_QUERY_SEARCH_SPACE_REDUCER_STATE: QueryStoryResults = {
  stories: [],
  facetGroups: createFacets({}, Configuration.schema.facets),
  selection: INIT_SELECTION,
  pageable: INIT_PAGINATION,
};

export default (
  state = INIT_QUERY_SEARCH_SPACE_REDUCER_STATE, action: QueryResultsActions,
): QueryStoryResults => {
  switch (action.type) {
    case SET_PAGINATION_INFO: {
      return {
        ...state,
        pageable: action.pagination,
        selection: getSelectionStateOnPaginationChange(action.pagination, state),
      };
    }
    case SET_QUERY_RESULTS: {
      return {
        ...state,
        stories: action.stories,
      };
    }
    case CLEAR_SELECTION: {
      return {
        ...state,
        selection: INIT_SELECTION,
      };
    }
    case SET_SELECTION: {
      const mergedActionWithPrevState: SelectTarget = {
        ...action.selection,
        storiesVisibleInViewAtMoment: action.selection.storiesVisibleInViewAtMoment
            || state.selection.storiesVisibleInViewAtMoment,
        latestAvailableStories: action.selection.latestAvailableStories || state.selection.latestAvailableStories,
      };
      const updatedState = getUpdatedState(mergedActionWithPrevState, state.selection);
      return {
        ...state,
        selection: updatedState,
      };
    }
    case SET_FACET_GROUPS: {
      return {
        ...state,
        facetGroups: action.groups,
      };
    }

    case RESET_QUERY_RESULTS: {
      const maintainFacets = action.payload;

      if (maintainFacets) {
        const updatedState = { ...INIT_QUERY_SEARCH_SPACE_REDUCER_STATE };

        return {
          ...updatedState,
          facetGroups: state.facetGroups,
        };
      }
      return { ...INIT_QUERY_SEARCH_SPACE_REDUCER_STATE };
    }
    default:
      return state;
  }
};
