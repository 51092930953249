import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
  EMPTY_STRING,
  initializePersistedFeedbackTypes,
  LanguageSettingsEnum,
  LogoTypeEnum,
  saveLogo,
  useErrorBoundaryThrow,
  useInitialCall,
} from 'mediascouting-core-ui-common';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/es';
import theme from './theme';
import Routes from './Routes';
import Auth from './components/auth/Auth';
import './intl/index';
import ErrorFallback from './components/system/ErrorFallback';
import FeedbackCentralAgent from './components/system/FeedbackCentralAgent';
import getUserLogo from './remote/Logo';
import { AppDispatch } from './redux/store';
import PLATFORM_CONFIGURATION from './configuration';
import { getLanguageValueWithoutPrefix } from './layouts/PortalLayout/TopBar/Settings/LanguageSettings';

const history = createBrowserHistory();

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    em: {
      backgroundColor: '#f7f8b4',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
  },
}));

function App(): JSX.Element {
  useStyles();
  const logoConfiguration = PLATFORM_CONFIGURATION.schema.settings.logo;
  const { handleOnError } = useErrorBoundaryThrow();
  const dispatch: AppDispatch = useDispatch();
  const handleErrorBoundaryError = useCallback((error) => {
    dispatch(handleOnError(error));
  }, [dispatch, handleOnError]);

  const handleMomentLanguageSettings = useCallback(() => {
    moment
      .locale(getLanguageValueWithoutPrefix() || LanguageSettingsEnum.ENGLISH);
  }, []);

  const getLogo = useCallback((logoType: LogoTypeEnum) => dispatch(getUserLogo(logoType))
    .then((logoData) => {
      dispatch(saveLogo(logoData, logoType));
      return logoData;
    })
    .catch((error) => {
      dispatch(saveLogo(EMPTY_STRING, logoType));
      return Promise.reject(error);
    }), [dispatch]);

  const handleGetAllLogos = useCallback(() => {
    logoConfiguration.availableLogos.forEach((logoToRequest) => {
      getLogo(logoToRequest);
    });
  }, [getLogo, logoConfiguration.availableLogos]);

  useLayoutEffect(() => {
    handleMomentLanguageSettings();
  }, [handleMomentLanguageSettings]);

  useEffect(() => {
    handleGetAllLogos();
  }, [handleGetAllLogos]);

  useInitialCall({
    callback: () => {
      dispatch(
        initializePersistedFeedbackTypes(
          PLATFORM_CONFIGURATION.schema.logging.activeFeedbackTypes,
        ),
      );
    },
    condition: !!PLATFORM_CONFIGURATION.schema,
  });

  return (
      <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={2}>
                  <Router history={history}>
                      <ErrorBoundary
                        onError={handleErrorBoundaryError}
                        fallbackRender={(props: FallbackProps): JSX.Element => (
                            <ErrorFallback onReset={props.resetErrorBoundary} />
                        )}
                      >
                          <Auth>
                              <FeedbackCentralAgent />
                              <Routes />
                          </Auth>
                      </ErrorBoundary>
                  </Router>
              </SnackbarProvider>
          </MuiPickersUtilsProvider>
      </ThemeProvider>
  );
}

export default App;
