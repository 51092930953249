import { ShallowStory } from 'mediascouting-core-ui-common';
import { StorySnapSelectionRangeEnum } from '../../constants/StorySnapToolbarSelectionOptions';

export enum SelectionType {
 SELECT= 'SELECT',
 DESELECT = 'DESELECT',
 UPDATE = 'UPDATE',
}

export type SelectionRequest = {
  range: StorySnapSelectionRangeEnum;
  type: SelectionType;
}

export interface SelectTarget {
  storiesToSelect: Array<ShallowStory>;
  range: StorySnapSelectionRangeEnum;
  storiesVisibleInViewAtMoment?: number;
  latestAvailableStories?: Array<ShallowStory>;
  type: SelectionType;
}

export type Selector = {
  apply: (target: SelectTarget) => void;
  request: (type: SelectionType, range: StorySnapSelectionRangeEnum) => void;
  respond: (target: SelectTarget) => void;
}

export type SelectionOption = {
  title: string;
  range: StorySnapSelectionRangeEnum;
}

export type SelectionState = {
  selectedStoriesBetweenPages: Array<ShallowStory>;
  latestAvailableStories: Array<ShallowStory>;
  storiesVisibleInViewAtMoment: number;
  range: StorySnapSelectionRangeEnum;
};
