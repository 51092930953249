import {
  AlertItemsDisplayMode,
  AlertItemsGrouping,
  DataExportTaskStatusEnum,
  EMPTY_STRING,
  ExportItem,
  ReportResourceTypeDTO,
} from 'mediascouting-core-ui-common';

export const NEW_EXPORT_ITEM_ID = -1;

export const DEFAULT_EXPORT_ITEM: ExportItem = {
  id: NEW_EXPORT_ITEM_ID,
  name: EMPTY_STRING,
  description: EMPTY_STRING,
  emailSubject: EMPTY_STRING,
  itemsDisplayMode: AlertItemsDisplayMode.SUMMARY,
  itemsGrouping: AlertItemsGrouping.QUERY,
  percolatables: [],
  addPrintMargins: false,
  linkItemFiles: true,
  includeFrontPage: false,
  produceExternalLinks: false,
  embedItemsToEmail: false,
  attachResourcesToEmail: false,
  removeLiveQueryGroup: false,
  renderTimezone: null,
  createdAt: new Date(),
  recipientEmails: [],
  resources: [],
  summary: EMPTY_STRING,
  type: ReportResourceTypeDTO.HTML,
  status: DataExportTaskStatusEnum.REQUESTED,
};
