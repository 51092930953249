import { ContentState, SelectionState } from 'draft-js';

export const mergeSelection = (
  selectionState: SelectionState, start: number, end: number,
): SelectionState => selectionState.merge({
  anchorOffset: start,
  focusOffset: end,
});

export const getContentBlockSelection = (
  contentState: ContentState,
  start: number,
  end: number,
): SelectionState | undefined => {
  let currentBlock = contentState.getFirstBlock();
  let currentBlockStartPosition = 0;
  let currentBlockEndPosition = currentBlock.getLength();

  while ((start < currentBlockStartPosition || end > currentBlockEndPosition) && currentBlock) {
    const currentBlockKey = currentBlock.getKey();

    currentBlock = contentState.getBlockAfter(currentBlockKey);

    if (currentBlock) {
      currentBlockStartPosition = currentBlockEndPosition + 1;
      currentBlockEndPosition += currentBlock.getLength() + 1;
    }
  }

  if (currentBlock) {
    const newStart = start - currentBlockStartPosition;
    const newEnd = end - currentBlockStartPosition;
    const selection = SelectionState.createEmpty(currentBlock.getKey());

    return mergeSelection(selection, newStart, newEnd);
  }

  return undefined;
};
