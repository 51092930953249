import {
  buildFeedbackMessage,
  CreateTagRequest,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType, GetTagsRemoteParams,
  GetTagsResponse,
  getTagTransformationUtilities,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  RemoteParamTagTypesEnum,
  Tag,
  TagDTO,
  UpdateTagRequest,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';
import { beat } from '../constants/actions/creators/HeartbeatActions';
import { HeartbeatDataChange, HeartbeatDataType } from '../redux/reducers/Heartbeat';

export const REMOTE_PARAM_TAG_TYPE_PROPERTY = 'tagType.name';

export const REMOTE_USER_PARAM_TAG_TYPE = {
  [REMOTE_PARAM_TAG_TYPE_PROPERTY]: RemoteParamTagTypesEnum.USER,
};

export const getTags = (
  params?: GetTagsRemoteParams,
  cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<Tag>> => axios
  .get<GetTagsResponse>('/tags', {
    params,
    cancelToken,
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_TAGS]}`)
      .setNotifyUser(false)
      .build()));

    return getTagTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_TAGS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getTag = (id: number, cancelToken?: CancelToken) => (dispatch): Promise<Tag> => axios
  .get<TagDTO>(`/tags/${id}`, {
    cancelToken,
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_TAG]}`)
      .setNotifyUser(false)
      .build()));

    return getTagTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_TAG]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export const createTag = (tag: CreateTagRequest) => (dispatch): Promise<Tag> => axios
  .post<TagDTO>('/tags', tag)
  .then(({ data }) => {
    dispatch(beat({
      type: HeartbeatDataType.TAG,
      cause: HeartbeatDataChange.CREATE,
      identification: {
        id: data.id,
        payload: data,
      },
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.CREATE_TAG]}`)
      .setNotifyUser(true)
      .build()));

    return getTagTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.CREATE_TAG]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const updateTag = (id: number, tag: UpdateTagRequest) => (dispatch): Promise<Tag> => axios
  .put<TagDTO>(`/tags/${id}`, tag)
  .then(({ data }) => {
    dispatch(beat({
      type: HeartbeatDataType.TAG,
      cause: HeartbeatDataChange.UPDATE,
      identification: {
        id: data.id,
        payload: data,
      },
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.UPDATE_TAG]}`)
      .setNotifyUser(true)
      .build()));

    return getTagTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.UPDATE_TAG]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const deleteTag = (id: number) => (dispatch): Promise<void> => axios
  .delete<void>(`/tags/${id}`)
  .then(({ data }) => {
    dispatch(beat({
      type: HeartbeatDataType.TAG,
      cause: HeartbeatDataChange.DELETE,
      identification: {
        id,
      },
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.DELETE_TAG]}`)
      .setNotifyUser(true)
      .build()));

    return data;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.DELETE_TAG]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });
