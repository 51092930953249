import {
  Collapse, Divider, List, ListItem, ListItemText, ListSubheader,
} from '@material-ui/core';
import React, {
  createRef,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SelectionState } from 'draft-js';
import { EMPTY_STRING, getMillisecondsInDay } from 'mediascouting-core-ui-common';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  EditorRequest,
  EntityRange,
  EntityRecognition,
  FilterEntityData,
  FilterOptionEntityData,
  SearchableEntityData,
  SubstituteRequest,
} from '../../../../../types/layout/topBar/search/editor';
import { EntityTypesEnum, TIME_OF_DAY_FILTERS } from '../../../../../constants/search/editor/EntityTypes';
import { mergeSelection } from '../../../../../utils/draft-js/SelectionUtils';
import { createFocusState } from '../utils/FocusStateUtils';
import { FocusState } from '../../../../../types/layout/topBar/search/suggestion';

export type DateSuggestion = {
  label: string;
  onClick: () => void;
}

export type DateSuggestionsPropTypes = {
    entityRecognition?: EntityRecognition;
    dateSuggestions: Array<DateSuggestion>;
    onSetDateSuggestions: Dispatch<SetStateAction<Array<DateSuggestion>>>;
    focusIndex: number;
    focusTrigger: boolean;
    onEditorRequest: (request: EditorRequest<EntityTypesEnum>) => void;
    onChangeFocus: (focusState: FocusState) => void;
};

const formatDate = (date: Date | string): string => moment(date).format('h:mm:ss a');

function TimeSectionSuggestions(props: DateSuggestionsPropTypes): JSX.Element {
  const {
    entityRecognition, focusIndex, focusTrigger, dateSuggestions, onSetDateSuggestions, onEditorRequest, onChangeFocus,
  } = props;
  const { t } = useTranslation();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const elRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (elRefs.current.length !== dateSuggestions.length) {
    elRefs.current = dateSuggestions.map((_, i) => elRefs.current[i] || createRef());
  }

  const DATE_PICKER_SUGGESTION: DateSuggestion = useMemo(() => ({
    label: t('layout.topBar.search.searchBar.suggestion.time.pick'),
    onClick: (): void => setDatePickerOpen(true),
  }), [t]);

  const constructDateReplacementEditorRequest = (
    entityRange: EntityRange, date: Date,
  ): SubstituteRequest<EntityTypesEnum> => {
    const entityType = entityRange.entity.getType() as EntityTypesEnum;
    const data = entityRange.entity.getData() as FilterEntityData;
    const datePreview = formatDate(date);
    const requestedText = `${entityRange.text}: (${datePreview})`;
    const selectionState = SelectionState.createEmpty(entityRange.blockKey);
    const selectedRange = mergeSelection(
      selectionState, entityRange.start, entityRange.start + (data?.length || 0),
    );

    const filterOptionEntityData: FilterOptionEntityData = {
      filterType: entityType,
      parentReference: data?.reference || EMPTY_STRING,
      option: {
        id: getMillisecondsInDay(date.toString()) || 0,
        name: datePreview,
      },
    };

    const filterData: FilterEntityData = {
      length: requestedText.length,
      reference: data.reference || EMPTY_STRING,
    };

    return {
      substituteText: requestedText,
      range: selectedRange,
      reference: entityRange,
      cursorPositionWithinText: requestedText.length,
      entityMetadata: [
        {
          type: entityType,
          start: EMPTY_STRING.length,
          end: entityRange.text.length,
          data: filterData,
        },
        {
          type: EntityTypesEnum.FILTER_OPTION,
          start: `${entityRange.text}: (`.length,
          end: `${entityRange.text}: (${datePreview}`.length,
          data: filterOptionEntityData,
        },
      ],
    };
  };

  const constructDateSuggestionEditorRequest = useCallback((
    searchableEntityData: SearchableEntityData,
    date: Date,
    datePreview: string,
    label: string,
    entityRange: EntityRange,
  ): DateSuggestion => {
    const filterOptionEntityData: FilterOptionEntityData = {
      filterType: searchableEntityData.filterType,
      parentReference: searchableEntityData.parentReference,
      option: {
        id: getMillisecondsInDay(date.toString()) || 0,
        name: datePreview,
      },
    };

    const request: SubstituteRequest<EntityTypesEnum> = {
      substituteText: datePreview,
      entityMetadata: [{
        type: EntityTypesEnum.FILTER_OPTION,
        start: EMPTY_STRING.length,
        end: datePreview.length,
        data: filterOptionEntityData,
      }],
      cursorPositionWithinText: datePreview.length,
      reference: entityRange,
    };

    return {
      label,
      onClick: (): void => onEditorRequest(request),
    };
  }, [onEditorRequest]);

  const handleSuggestionGeneration = useCallback((): void => {
    const suggestions: Array<DateSuggestion> = [];

    if (entityRecognition?.entityRange) {
      const entityType = entityRecognition.entityRange.entity.getType() as EntityTypesEnum;
      const isSearchableFilterOption = entityType === EntityTypesEnum.SEARCHABLE_FILTER_OPTION;
      const isCustomTimeOfDayFilter = TIME_OF_DAY_FILTERS.includes(entityType);

      if (isCustomTimeOfDayFilter) {
        suggestions.push(DATE_PICKER_SUGGESTION);
      }

      if (isSearchableFilterOption) {
        const data = entityRecognition.entityRange.entity.getData() as SearchableEntityData;

        if (data && TIME_OF_DAY_FILTERS.includes(data.filterType)) {
          const { searchText } = data;
          const optionalDate = moment(searchText).toDate();

          if (!Number.isNaN(optionalDate.getTime())) {
            const formattedDate = formatDate(optionalDate);
            const parsedDate = constructDateSuggestionEditorRequest(
              data, optionalDate, formattedDate,
              `Set ${formattedDate}`, entityRecognition.entityRange,
            );

            suggestions.push(parsedDate);
          }

          suggestions.push(DATE_PICKER_SUGGESTION);
        }
      }
    }

    onSetDateSuggestions(suggestions);
  }, [DATE_PICKER_SUGGESTION, constructDateSuggestionEditorRequest, entityRecognition, onSetDateSuggestions]);

  const handleCancel = (): void => {
    setDatePickerOpen(false);
  };

  const handleDateChange = (date: MaterialUiPickersDate): void => {
    if (date && entityRecognition) {
      const { entityRange, parentEntityRange } = entityRecognition;
      const approvedEntityRange = parentEntityRange || entityRange;
      const dateToAdd = date.toDate();
      const request = constructDateReplacementEditorRequest(approvedEntityRange, dateToAdd);

      onEditorRequest(request);
    }

    setDatePickerOpen(false);
  };

  useEffect(() => {
    const focusWithinSuggestions = focusIndex >= 0 && focusIndex < dateSuggestions.length;

    if (focusTrigger && focusWithinSuggestions) {
      const foundSuggestion = dateSuggestions[focusIndex];

      if (foundSuggestion) {
        setDatePickerOpen(true);
        onChangeFocus(createFocusState(-1, false, false));
      }
    }
  }, [focusTrigger, focusIndex, onEditorRequest, dateSuggestions, onChangeFocus]);

  useEffect(() => {
    const elRef = elRefs.current[focusIndex];

    if (elRef && elRef.current) {
      elRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [focusIndex, dateSuggestions]);

  useEffect(() => {
    handleSuggestionGeneration();
  }, [handleSuggestionGeneration]);

  return (
      <>
          <Collapse in={dateSuggestions.length > 0}>
              <Divider />
              <List subheader={(
                  <ListSubheader disableSticky component="div" id="date-suggestions">
                      {t('layout.topBar.search.searchBar.suggestion.time')}
                  </ListSubheader>
              )}
              >
                  {dateSuggestions.map((dateSuggestion, index) => (
                      <ListItem
                        key={dateSuggestion.label}
                        onClick={dateSuggestion.onClick}
                        selected={focusIndex === index}
                        button
                      >
                          <ListItemText primary={dateSuggestion.label} />
                      </ListItem>
                  ))}
              </List>
          </Collapse>
          <TimePicker
            open={datePickerOpen}
            fullWidth
            clearable
            label="Time Picker"
            name="time-picker-suggestions"
            views={['hours', 'minutes', 'seconds']}
            inputVariant="outlined"
            onChange={handleDateChange}
            onClose={handleCancel}
            TextFieldComponent={(): null => null}
            value={null}
          />
      </>
  );
}

export default memo(TimeSectionSuggestions);
