import {
  ExportSupportedDisabledFieldEnum,
  LanguageSettingsEnum,
  LogoTypeEnum, NOT_FOUND,
  StatisticsGraphEnum,
  StatisticsGraphIconEnum,
} from 'mediascouting-core-ui-common';
import {
  AlertSchema,
  ExportSchema,
  LoggingSchema,
  NeoNavigationSideBarSchema,
  SearchBarSchema,
  SettingsSchema,
  StatisticsSchema,
  StatisticsTabConfiguration,
  StoriesSchema,
  StoriesSharingSchema,
  StoriesSortingSchema,
  StoriesViewsSchema,
  UserInterfaceConfigurationSchema,
} from '../schema';
import { StoryConfiguration } from '../../components/features/story/StoryReflection';
import MetadataTypeEnum from '../../constants/MetadataTypes';
import { FacetConfiguration } from '../../types/query/results/Facets';
import {
  NeoBarDisplayConfiguration,
  NeoBarSearchConfiguration,
  NeoBarShortcutConfiguration,
  NeoBarShortcutKeyCodeEnum,
  TypingSearchSuggestionModeEnum,
} from '../../components/common/NeoBar/types/NeoBarConfiguration';
import { NeoBarDisplayFormatEnum, NeoSearchEntityTypeEnum } from '../../components/common/NeoBar/types/NeoSearch';
import { BroadcastStoryTextTabEnum } from '../../constants/story/BroadcastStoryTabEnum';

const DEFAULT_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA: StoriesSharingSchema = {
  disabled: [],
};

const DEFAULT_USER_INTERFACE_STORY_SORTING_CONFIGURATION_SCHEMA: StoriesSortingSchema = {
  disabled: [],
};

const DEFAULT_USER_INTERFACE_STORY_VIEWS_CONFIGURATION_SCHEMA: StoriesViewsSchema = {
  brief: {
    card: {
      showDateWithTimeZone: true,
    },
  },
  disabled: [],
};

const DEFAULT_USER_INTERFACE_STORY_LONG_CONFIGURATION_SCHEMA: StoryConfiguration = {
  broadcast: {
    displayMediaInTimeline: false,
    seeking: {
      limitSeekingToStoryRange: false,
      askForMissingSegment: true,
      maximumSeekingHoursBeforeStory: 6,
      maximumSeekingHoursAfterStory: 6,
    },
    actions: {
      montage: true,
    },
    tabs: {
      availableTabs: [
        {
          id: BroadcastStoryTextTabEnum.TEXT,
          label: 'tabs.broadcast.text',
        },
        {
          id: BroadcastStoryTextTabEnum.ABSTRACT,
          label: 'tabs.broadcast.abstract',
        },
      ],
    },
  },
  textualItems: {
    web: {
      presentation: {
        abstractTextOpen: false,
      },
    },
    print: {
      presentation: {
        abstractTextOpen: false,
      },
    },
  },
  metadata: {
    disabled: [],
    editable: {
      [MetadataTypeEnum.DATE]: false,
      [MetadataTypeEnum.MARKET_TIMEZONE]: false,
      [MetadataTypeEnum.AUDIENCE_ESTIMATE]: false,
      [MetadataTypeEnum.CALC_AD_VALUE]: false,
      [MetadataTypeEnum.PUBLICITY_VALUE]: false,
      [MetadataTypeEnum.PLACEMENT]: false,
      [MetadataTypeEnum.TONE]: false,
      [MetadataTypeEnum.PROMINENCE]: false,
      [MetadataTypeEnum.RUNTIME]: false,
      [MetadataTypeEnum.THIRTY_SEC_AD_VALUE]: false,
      [MetadataTypeEnum.WORD_COUNT]: false,
      [MetadataTypeEnum.UNKNOWN]: false,
      [MetadataTypeEnum.PAGES]: false,
      [MetadataTypeEnum.SURFACE]: false,
      [MetadataTypeEnum.TEXT_TYPE]: false,
      [MetadataTypeEnum.FREQUENCY]: false,
      [MetadataTypeEnum.LANGUAGE]: false,
      [MetadataTypeEnum.AUTHOR]: false,
      [MetadataTypeEnum.MARKET]: false,
      [MetadataTypeEnum.MARKET_RANK]: false,
      [MetadataTypeEnum.SOURCE_TYPE]: false,
      [MetadataTypeEnum.TEXT]: false,
      [MetadataTypeEnum.ABSTRACT_TEXT]: false,
      [MetadataTypeEnum.ORIGIN]: false,
      [MetadataTypeEnum.AVERAGE_STAY]: false,
      [MetadataTypeEnum.UNIQUE_VISITORS]: false,
      [MetadataTypeEnum.RANK]: false,
      [MetadataTypeEnum.CPM]: false,
      [MetadataTypeEnum.PAGE_VIEWS]: false,
    },
  },
  common: {
    actions: {
      share: DEFAULT_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA,
    },
  },
};

const DEFAULT_SETTINGS: SettingsSchema = {
  language: {
    options: [LanguageSettingsEnum.ENGLISH, LanguageSettingsEnum.ARABIC, LanguageSettingsEnum.SPANISH],
    enabled: true,
  },
  logo: {
    availableLogos: [LogoTypeEnum.LOGIN, LogoTypeEnum.PORTAL, LogoTypeEnum.DEFAULT],
  },
};

const DEFAULT_USER_INTERFACE_STORY_CONFIGURATION_SCHEMA: StoriesSchema = {
  views: DEFAULT_USER_INTERFACE_STORY_VIEWS_CONFIGURATION_SCHEMA,
  reflection: DEFAULT_USER_INTERFACE_STORY_LONG_CONFIGURATION_SCHEMA,
  sorting: DEFAULT_USER_INTERFACE_STORY_SORTING_CONFIGURATION_SCHEMA,
  sharing: DEFAULT_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA,
};

const DEFAULT_USER_INTERFACE_STATISTICS_TABS_CONFIGURATION_SCHEMA: Array<StatisticsTabConfiguration> = [
  {
    name: 'monitor',
    title: 'media.title',
    icon: StatisticsGraphIconEnum.THEATERS,
    defaultTabGraphGridSize: [6, 12, 12, 12, 12],
    graphs: [
      {
        id: StatisticsGraphEnum.MARKET_TRENDS,
      },
      {
        id: StatisticsGraphEnum.MARKET_MENTIONS,
      },
      {
        id: StatisticsGraphEnum.MARKET_MENTIONS_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SOURCE_TRENDS,
      },
      {
        id: StatisticsGraphEnum.SOURCE_MENTIONS,
      },
      {
        id: StatisticsGraphEnum.SOURCE_MENTIONS_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.CALCULATED_VALUE_BY_MARKET_TRENDS,
      },
      {
        id: StatisticsGraphEnum.CALCULATED_VALUE_BY_SOURCE_TRENDS,
      },
      {
        id: StatisticsGraphEnum.PIVOT_TABLE,
        graphGridSize: [12, 12, 12, 12, 12],
      },
    ],
  },
  {
    name: 'marketeer',
    title: 'marketeer.title',
    icon: StatisticsGraphIconEnum.PEOPLE,
    defaultTabGraphGridSize: [6, 12, 12, 12, 12],
    graphs: [
      {
        id: StatisticsGraphEnum.QUERY_CALCULATED_VALUE_DIFFERENT_IN_TIME,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.QUERY_CALCULATED_VALUE_BY_MARKETS,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.QUERY_CALCULATED_VALUE_BY_SOURCES,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.QUERY_MENTIONS_BY_MARKETS,
        graphGridSize: [7, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.TIME_OF_DAY_PERCENTAGE,
        graphGridSize: [5, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.QUERY_MENTIONS_BY_SOURCES,
        graphGridSize: [7, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SOURCE_TYPE_PERCENTAGE,
        graphGridSize: [5, 12, 12, 12, 12],
      },
    ],
  },
  {
    name: 'analyst',
    title: 'analyst.title',
    icon: StatisticsGraphIconEnum.SHOW_CHART,
    defaultTabGraphGridSize: [6, 12, 12, 12, 12],
    graphs: [
      {
        id: StatisticsGraphEnum.MENTIONS_DIFFERENCE_IN_TIME,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.FOAM_TREE,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.SUB_QUERY_MENTIONS,
      },
      {
        id: StatisticsGraphEnum.TAG_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_PERCENTAGE,
      },
    ],
  },
];

export const DEFAULT_APEX_CHART_COLORS: Array<string> = [
  '#4E79A7', '#F28E2B', '#E15759',
  '#76B7B2', '#59A14F', '#EDC948',
  '#B07AA1', '#FF9DA7', '#9C755F',
  '#BAB0AC', '#525252', '#FFBE7D',
  '#4A6FA5', '#9D7660', '#B4A76C',
  '#79706E', '#D37295', '#92AFC0',
  '#E58606', '#5D69B1', '#52BCA3',
  '#99C945', '#CC61B0', '#24796C',
  '#DAA51B', '#2F8AC4', '#764E9F',
  '#ED645A', '#CC3A8E', '#A5AA99',
];

const DEFAULT_USER_INTERFACE_STATISTICS_CONFIGURATION_SCHEMA: StatisticsSchema = {
  tabs: DEFAULT_USER_INTERFACE_STATISTICS_TABS_CONFIGURATION_SCHEMA,
  defaultTab: 'monitor',
  defaultColors: DEFAULT_APEX_CHART_COLORS,
};

const DEFAULT_SEARCH_BAR_CONFIGURATION_SCHEMA: SearchBarSchema = {
  filters: {
    disabled: [
    ],
  },
  querySettings: {
    disabled: [
    ],
  },
};

const DEFAULT_USER_INTERFACE_FACET_CONFIGURATION_SCHEMA: FacetConfiguration = {
  disabled: [],
};

const DEFAULT_EXPORTS_CONFIGURATION_SCHEMA: ExportSchema = {
  moreOptions: {
    disabled: [],
    defaults: {
    },
  },
  fields: {
    disabled: [ExportSupportedDisabledFieldEnum.SUMMARY],
  },
};

const DEFAULT_ALERT_CONFIGURATION_SCHEMA: AlertSchema = {
  reportOptions: {
    disabled: DEFAULT_EXPORTS_CONFIGURATION_SCHEMA.moreOptions.disabled,
    defaults: DEFAULT_EXPORTS_CONFIGURATION_SCHEMA.moreOptions.defaults,
  },
};

const DEFAULT_LOGGING_CONFIGURATION_SCHEMA: LoggingSchema = {
  activeFeedbackTypes: [
  ],
};

export const DEFAULT_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA: NeoBarSearchConfiguration = {
  neoSearch: {
    enabled: false,
    autoSearch: true,
    showSearchProgress: true,
    initializeWithEntityType: NOT_FOUND,
    suggestions: {
      allowUserToExploreOptions: true,
      suggestOptionsWhileUserIsTyping: true,
      displayTypingSuggestionMode: TypingSearchSuggestionModeEnum.LIST,
    },
  },
  autoSearchInterval: 1000,
};

export const DEFAULT_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA: NeoBarDisplayConfiguration = {
  type: NeoBarDisplayFormatEnum.DEFAULT,
  disabled: [],
  settings: {
    neoCard: {
      displayTypeBadge: false,
      displaySummary: false,
      displaySubQueryIcon: false,
    },
    neoList: {
      displayAvatar: true,
      displayType: true,
      displaySubQueryIcon: true,
      useDesiredBackgroundColor: false,
    },
    defaultList: {
      displaySubQueryIcon: true,
    },
  },
};

export const DEFAULT_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA: NeoBarShortcutConfiguration = {
  manualSearchTriggerKey: NeoBarShortcutKeyCodeEnum.ENTER,
  selectSuggestedSuggestionKey: NeoBarShortcutKeyCodeEnum.ENTER,
};

export const DEFAULT_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES: Array<NeoSearchEntityTypeEnum> = [
  NeoSearchEntityTypeEnum.QUERY,
  NeoSearchEntityTypeEnum.TAG,
  NeoSearchEntityTypeEnum.SHARED,
];

export const NEO_NAVIGATION_SIDE_BAR_VERSION = 1;

export const DEFAULT_NEO_NAVIGATION_SIDE_BAR_SCHEMA: NeoNavigationSideBarSchema = {
  neo: {
    version: NEO_NAVIGATION_SIDE_BAR_VERSION,
    search: DEFAULT_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA,
    display: DEFAULT_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA,
    shortcuts: DEFAULT_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA,
    types: DEFAULT_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES,
  },
  savingOptions: {
    createAllowed: false,
    updateAllowed: false,
  },
  userInterfaceSettings: {
    enabled: false,
    disabled: [],
  },
};

const DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA: UserInterfaceConfigurationSchema = {
  stories: DEFAULT_USER_INTERFACE_STORY_CONFIGURATION_SCHEMA,
  search: DEFAULT_SEARCH_BAR_CONFIGURATION_SCHEMA,
  settings: DEFAULT_SETTINGS,
  statistics: DEFAULT_USER_INTERFACE_STATISTICS_CONFIGURATION_SCHEMA,
  facets: DEFAULT_USER_INTERFACE_FACET_CONFIGURATION_SCHEMA,
  exports: DEFAULT_EXPORTS_CONFIGURATION_SCHEMA,
  alerts: DEFAULT_ALERT_CONFIGURATION_SCHEMA,
  logging: DEFAULT_LOGGING_CONFIGURATION_SCHEMA,
  neoNavigationSideBar: DEFAULT_NEO_NAVIGATION_SIDE_BAR_SCHEMA,
};

export default DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;
