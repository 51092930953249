import { getUrlAddressFromEnv, NotFound } from 'mediascouting-core-ui-common';
import { PlatformConfiguration, UserInterfaceConfigurationSchema } from './schema';
import DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA from './defaults';

const getClientSchema = (): UserInterfaceConfigurationSchema => {
  const client: string | NotFound = process.env.REACT_APP_CLIENT;
  if (client) {
    try {
      const customSchema = require(`./overrides/${client}/index`)
        .default as UserInterfaceConfigurationSchema;
      return { ...DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA, ...customSchema };
    } catch (error) {
      return DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;
    }
  }
  return DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;
};

const PLATFORM_CONFIGURATION: PlatformConfiguration = {
  apiAddress: getUrlAddressFromEnv(process.env.REACT_APP_API_ADDRESS),
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE,
  defaultFallbackLocale: process.env.REACT_APP_DEFAULT_FALLBACK_LOCALE,
  appAddress: process.env.REACT_APP_ADDRESS,
  schema: getClientSchema(),
  debugger: String(process.env.REACT_APP_DEBUGGER_ENABLED) === 'true',
  defaultLogoPath: process.env.REACT_APP_DEFAULT_LOGO_PATH,
};

export default PLATFORM_CONFIGURATION;
