import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NOT_FOUND } from 'mediascouting-core-ui-common';
import withStyles from '@material-ui/core/styles/withStyles';
import { Fade } from '@material-ui/core';

interface LinearBufferPropTypes {
  loading: boolean;
}

const StyledBorderLinearProgress = withStyles((theme: Theme) => createStyles({
  root: {
    height: 1,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.grey[400],
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function LinearBuffer(props: LinearBufferPropTypes): JSX.Element {
  const { loading } = props;
  const classes = useStyles();
  const progressRef = React.useRef<VoidFunction>(() => NOT_FOUND);
  const [progress, setProgress] = React.useState<number>(0);
  const [buffer, setBuffer] = React.useState<number>(10);

  const setupProgress = (): void => {
    progressRef.current = (): void => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const randomNumber = Math.random() * 10;
        const secondRandomNumber = Math.random() * 10;

        setProgress(progress + randomNumber);
        setBuffer(progress + randomNumber + secondRandomNumber);
      }
    };
  };

  useEffect(() => {
    setupProgress();
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    if (!loading) {
      clearInterval(timer);
      setProgress(100);
      setBuffer(100);
    } else {
      setProgress(0);
      setProgress(0);
    }

    return (): void => {
      clearInterval(timer);
    };
  }, [loading]);

  return (
      <div className={classes.root}>
          <Fade
            in={loading}
            timeout={{
              appear: 300,
              enter: 500,
              exit: 1000,
            }}
          >
              <StyledBorderLinearProgress
                variant="buffer"
                color="primary"
                value={progress}
                valueBuffer={buffer}
              />
          </Fade>
      </div>
  );
}

export default LinearBuffer;
