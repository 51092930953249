import axios from 'axios';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import PLATFORM_CONFIGURATION from '../../configuration';

const AXIOS_INSTANCE = axios.create({
  baseURL: PLATFORM_CONFIGURATION.apiAddress,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const LOCAL_FILE_AXIOS_INSTANCE = axios.create({
  baseURL: EMPTY_STRING,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default AXIOS_INSTANCE;
