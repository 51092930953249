import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  addOverrideTranslationsToi18next,
  COMMON_PACKAGE_LOCALES,
  DEFAULT_FILES_DIRECTORY_PREFIX,
} from 'mediascouting-core-ui-common';
import PLATFORM_CONFIGURATION from '../configuration';
import { LOCAL_STORAGE_DEFAULT_LANGUAGE_KEY } from '../constants/LocalStorage';

const currentLanguage = localStorage.getItem('i18nextLng');

if (currentLanguage === null) {
  localStorage.setItem('i18nextLng',
    PLATFORM_CONFIGURATION.defaultLocale || PLATFORM_CONFIGURATION.defaultFallbackLocale || 'en');
}

const getDefaultLanguage = (): string => {
  const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_DEFAULT_LANGUAGE_KEY);
  if (selectedLanguage !== null && PLATFORM_CONFIGURATION.defaultLocale?.includes(selectedLanguage)) {
    return PLATFORM_CONFIGURATION.defaultLocale || 'en';
  }
  if (selectedLanguage === null) {
    return PLATFORM_CONFIGURATION.defaultLocale || PLATFORM_CONFIGURATION.defaultFallbackLocale || 'en';
  }
  return selectedLanguage;
};

i18n
  .use(Backend) // load translation using http -> see /public/locales
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({ // init i18next
    fallbackLng: PLATFORM_CONFIGURATION.defaultFallbackLocale || 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: getDefaultLanguage(),
  });

COMMON_PACKAGE_LOCALES
  .forEach((componentLocales) => componentLocales
    .forEach((locale) => {
      i18n.addResourceBundle(`${DEFAULT_FILES_DIRECTORY_PREFIX}/${locale.lng}`, locale.ns, locale.resources);
    }));

addOverrideTranslationsToi18next(i18n, PLATFORM_CONFIGURATION.defaultLocale);

export default i18n;
