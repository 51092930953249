import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  any,
  getRoleBaseAccessControlRoutes,
  hasPermission,
  PlatformRoute,
  UserPermission,
} from 'mediascouting-core-ui-common';
import GuestGuard from './components/auth/GuestGuard';
import AuthGuard from './components/auth/AuthGuard';
import DashboardLayout from './layouts/PortalLayout';
import { ReduxState } from './redux/reducers';
import LoadingScreen from './components/system/LoadingScreen';
import PublicGuard from './components/auth/PublicGuard';
import TemplateLayout from './layouts/TemplateLayout';

const ROUTES_CONFIG: Array<PlatformRoute> = [
  {
    exact: true,
    path: '/',
    component: (): JSX.Element => <Redirect to="/portal/search" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/defaults/Error')),
  },
  {
    path: '/auth',
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/auth/login',
        component: lazy(() => import('./views/defaults/Login')),
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/shares',
    guard: PublicGuard,
    routes: [
      {
        exact: true,
        path: '/shares/:type/:uuid',
        component: lazy(() => import('./views/defaults/SharedStory')),
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '/portal/templates/:id',
    guard: AuthGuard,
    layout: TemplateLayout,
    when: hasPermission(UserPermission.OWN_TEMPLATES),
    component: lazy(() => import('./views/defaults/TemplateIDE')),
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/portal/search/:stories?/:type?/:id?',
        component: lazy(() => import('./views/defaults/Stories')),
      },
      {
        exact: true,
        path: '/portal/statistics/:tab?',
        component: lazy(() => import('./views/defaults/Statistics')),
      },
      {
        exact: true,
        path: '/portal/alerts',
        when: hasPermission(UserPermission.OWN_ALERTS),
        component: lazy(() => import('./views/defaults/Alerts')),
      },
      {
        exact: true,
        path: '/portal/shares',
        when: any(
          hasPermission(UserPermission.READ_TAG_SHARES),
          hasPermission(UserPermission.READ_QUERY_SHARES),
        ),
        component: lazy(() => import('./views/defaults/Shares')),
      },
      {
        exact: true,
        path: '/portal/exports',
        component: lazy(() => import('./views/defaults/StoryExports')),
      },
      {
        exact: true,
        path: '/portal/montages',
        when: hasPermission(UserPermission.INITIATE_MONTAGE),
        component: lazy(() => import('./views/defaults/Montages')),
      },
      {
        exact: true,
        path: '/portal/montages/exports',
        when: hasPermission(UserPermission.INITIATE_MONTAGE),
        component: lazy(() => import('./views/defaults/MontageExports')),
      },
      {
        exact: true,
        path: '/portal/templates',
        component: lazy(() => import('./views/defaults/TemplateManagement')),
      },
      {
        exact: true,
        path: '/portal/contacts',
        component: lazy(() => import('./views/defaults/Contacts')),
      },
      {
        exact: true,
        path: '/account/:tab?',
        component: lazy(() => import('./views/defaults/Account')),
      },
      {
        exact: true,
        path: '/portal/stories/log',
        when: hasPermission(UserPermission.CREATE_PERCOLATABLES),
        component: lazy(() => import('./views/defaults/StoryLogging')),
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />,
      },
    ],
  },
  {
    component: (): JSX.Element => <Redirect to="/404" />,
  },
];

const renderRoutes = (routes): JSX.Element | null => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.component;

              const key = route.path ? route.path.concat(i) : ''.concat(i);
              return (
                  <Route
                    key={key}
                    path={route.path}
                    exact={route.exact}
                    render={(props): JSX.Element => (
                        <Guard>
                            <Layout>
                                {route.routes
                                  ? renderRoutes(route.routes)
                                  : <Component {...props} />}
                            </Layout>
                        </Guard>
                    )}
                  />
              );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes(): JSX.Element | null {
  const user = useSelector((state: ReduxState) => state.auth.user);
  const roleBaseAccessControlRoutes = getRoleBaseAccessControlRoutes(user, ROUTES_CONFIG);

  return renderRoutes(roleBaseAccessControlRoutes);
}

export default Routes;
