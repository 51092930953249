import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import React, {
  createRef, memo, useEffect, useRef,
} from 'react';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { QueryFilterOptionShallow } from 'mediascouting-core-ui-common';
import { FocusState } from '../../../../../types/layout/topBar/search/suggestion';
import { createFocusState } from '../utils/FocusStateUtils';

type FilteringSuggestionsPropTypes = {
  options: Array<QueryFilterOptionShallow>;
  onSelectedOption: (option: QueryFilterOptionShallow) => void;
  focusIndex: number;
  focusTrigger: boolean;
  filterType: string;
  onChangeFocus: (focusState: FocusState) => void;
};

function FilteringOptions(props: FilteringSuggestionsPropTypes): JSX.Element {
  const {
    options, onSelectedOption, focusIndex, focusTrigger, filterType, onChangeFocus,
  } = props;
  const elRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (elRefs.current.length !== options.length) {
    elRefs.current = options.map((_, i) => elRefs.current[i] || createRef());
  }

  const handleClick = (option) => (): void => {
    onSelectedOption(option);
    onChangeFocus(createFocusState(-1, false, false));
  };

  useEffect(() => {
    const focusWithinOptions = focusIndex >= 0 && focusIndex < options.length;

    if (focusTrigger && focusWithinOptions) {
      const option = options[focusIndex];

      if (option) {
        onChangeFocus(createFocusState(-1, false, false));
        onSelectedOption(option);
      }
    }
  }, [focusTrigger, focusIndex, options, onSelectedOption, onChangeFocus]);

  useEffect(() => {
    const elRef = elRefs.current[focusIndex];

    if (elRef && elRef.current) {
      elRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [focusIndex, options]);

  return (
      <Collapse in={options.length > 0}>
          <Divider />
          <List
            dense
            subheader={(
                <ListSubheader disableSticky component="div" id="advanced-filter-checklist">
                    {filterType}
                </ListSubheader>
            )}
          >
              {
                  options.map((option, index) => (
                      <ListItem
                        key={option.id}
                        ref={elRefs.current[index]}
                        button
                        selected={index === focusIndex}
                        onClick={handleClick(option)}
                      >
                          <ListItemText primary={option.name} />
                          {
                            (index === focusIndex) && (
                                <ListItemSecondaryAction>
                                    <ListItemIcon>
                                        <KeyboardReturnIcon />
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            )
                          }
                      </ListItem>
                  ))
            }
          </List>
      </Collapse>
  );
}

export default memo(FilteringOptions);
