import React from 'react';
import { SelectionState } from 'draft-js';
import {
  EMPTY_STRING, addHoursToDate, getMillisecondsInDay, SPACE,
} from 'mediascouting-core-ui-common';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createSuggestion } from '../SuggestionServiceUtils';
import {
  EntityRange,
  FilterEntityData,
  FilterOptionEntityData,
  SubstituteRequest,
} from '../../../../../../../types/layout/topBar/search/editor';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';
import { mergeSelection } from '../../../../../../../utils/draft-js/SelectionUtils';
import Filter from '../../../QueryEditor/Filter';

const formatHours = (date: Date | string): string => moment(date).format('h:mm:ss a');

const getReference = (
  recognizedType: EntityTypesEnum, usingType: EntityTypesEnum, ref: string,
): string => {
  if (recognizedType === EntityTypesEnum.START_TIME && usingType === EntityTypesEnum.START_TIME) {
    return ref;
  }

  return Date.now().toString();
};

const createDateRangeSuggestion = (
  entityRange: EntityRange,
  existingSuggestions: Array<Suggestion<EntityTypesEnum>>,
  startDate: Date,
  endDate: Date,
  label: React.ReactNode,
): Array<Suggestion<EntityTypesEnum>> => {
  const entityType = entityRange.entity.getType() as EntityTypesEnum;
  const data = entityRange.entity.getData() as FilterEntityData;
  const startingHoursPreview = formatHours(startDate);
  const endingHoursPreview = formatHours(endDate);
  const requestedText = `startingHours: (${startingHoursPreview}) AND endingHours: (${endingHoursPreview})`;
  const selectionState = SelectionState.createEmpty(entityRange.blockKey);
  const selectedRange = mergeSelection(selectionState, entityRange.start, entityRange.start + (data.length || 0));
  const preview = label;
  const startRef = getReference(entityType, EntityTypesEnum.START_TIME, data?.reference || EMPTY_STRING);
  const endRef = getReference(entityType, EntityTypesEnum.END_TIME, data?.reference || EMPTY_STRING);

  const startFilterEntityData: FilterEntityData = {
    length: `startingHours: (${startingHoursPreview})`.length,
    reference: startRef,
  };

  const endFilterEntityData: FilterEntityData = {
    length: `endingHours: (${endingHoursPreview})`.length,
    reference: endRef,
  };

  const startDateFilterOptionEntityData: FilterOptionEntityData = {
    filterType: entityType,
    parentReference: startRef.toString(),
    option: {
      id: getMillisecondsInDay(startDate.toString()) || 0,
      name: startingHoursPreview,
    },
  };

  const endDateFilterOptionEntityData: FilterOptionEntityData = {
    filterType: entityType,
    parentReference: endRef.toString(),
    option: {
      id: getMillisecondsInDay(endDate.toString()) || 0,
      name: endingHoursPreview,
    },
  };

  const request: SubstituteRequest<EntityTypesEnum> = {
    substituteText: requestedText,
    range: selectedRange,
    reference: entityRange,
    cursorPositionWithinText: requestedText.length,
    entityMetadata: [
      {
        type: EntityTypesEnum.START_TIME,
        start: EMPTY_STRING.length,
        end: EntityTypesEnum.START_TIME.length,
        data: startFilterEntityData,
      },
      {
        type: EntityTypesEnum.FILTER_OPTION,
        start: 'startingHours: ('.length,
        end: `startingHours: (${startingHoursPreview}`.length,
        data: startDateFilterOptionEntityData,
      },
      {
        type: EntityTypesEnum.AND_CONDITION_MATCHER,
        start: `startingHours: (${startingHoursPreview})`.length,
        end: `startingHours: (${startingHoursPreview}) AND`.length,
      },
      {
        type: EntityTypesEnum.END_TIME,
        start: `startingHours: (${startingHoursPreview}) AND `.length,
        end: `startingHours: (${startingHoursPreview}) AND endingHours`.length,
        data: endFilterEntityData,
      },
      {
        type: EntityTypesEnum.FILTER_OPTION,
        start: `startingHours: (${startingHoursPreview}) AND endingHours: (`.length,
        end: `startingHours: (${startingHoursPreview}) AND endingHours: (${endingHoursPreview}`.length,
        data: endDateFilterOptionEntityData,
      },
    ],
  };

  return [createSuggestion(preview, request), ...existingSuggestions];
};

const createTimeSectionFilterSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  const currentDate = new Date();
  const dateOfToday = new Date(
    currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0,
  );

  const morningRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    [],
    addHoursToDate(dateOfToday, 6),
    addHoursToDate(dateOfToday, 12),
      <>
          Set
          {SPACE}
          <Filter type={EntityTypesEnum.START_TIME}>Starting Hours</Filter>
          {SPACE}
          and
          {SPACE}
          <Filter type={EntityTypesEnum.END_TIME}>Ending Hours</Filter>
          {SPACE}
          to match morning results (06:00 - 12:00)
      </>,
  );

  const afternoonRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    morningRangeSuggestion,
    addHoursToDate(dateOfToday, 12),
    addHoursToDate(dateOfToday, 18),
      <>
          Set
          {SPACE}
          <Filter type={EntityTypesEnum.START_TIME}>Starting Hours</Filter>
          {SPACE}
          and
          {SPACE}
          <Filter type={EntityTypesEnum.END_TIME}>Ending Hours</Filter>
          {SPACE}
          to match afternoon results (12:00 - 18:00)
      </>,
  );

  const eveningRangeSuggestion = createDateRangeSuggestion(
    entityRange,
    afternoonRangeSuggestion,
    addHoursToDate(dateOfToday, 18),
    addHoursToDate(dateOfToday, 24),
      <Typography>
          Set
          {SPACE}
          <Filter type={EntityTypesEnum.START_TIME}>Starting Hours</Filter>
          {SPACE}
          and
          {SPACE}
          <Filter type={EntityTypesEnum.END_TIME}>Ending Hours</Filter>
          {SPACE}
          to match evening results (18:00 - 00:00)
      </Typography>,
  );

  return eveningRangeSuggestion.reverse();
};

export default createTimeSectionFilterSuggestions;
