import {
  ExportSupportedDisabledFieldEnum,
  LanguageSettingsEnum,
  LogoTypeEnum,
  PowerSearchFilterTypes,
  ReportOptionsEnum,
  StatisticsGraphEnum,
  StatisticsGraphIconEnum,
} from 'mediascouting-core-ui-common';
import {
  AlertSchema,
  CustomPagePaths,
  ExportSchema,
  LoggingSchema,
  NeoNavigationSideBarSchema,
  SearchBarSchema,
  SettingsSchema,
  StatisticsSchema,
  StatisticsTabConfiguration,
  StoriesSchema,
  StoriesSharingSchema,
  StoriesSortingSchema,
  StoriesViewsSchema,
  UserInterfaceConfigurationSchema,
} from '../../schema';
import MetadataTypeEnum, {
  PrintMetadataTypeEnum,
  RadioMetadataTypeEnum,
  TelevisionMetadataTypeEnum,
  WebMetadataTypeEnum,
} from '../../../constants/MetadataTypes';
import { SharingOptionEnum } from '../../../components/features/share/ShareDialog';
import { ViewTypeEnum } from '../../../types/query/results/ViewTypes';
import { SortTypeEnum } from '../../../types/query/results/Sort';
import {
  CommonStoryCardTypesConfiguration,
  RadioStoryCardTypesConfiguration,
  TelevisionStoryCardTypesConfiguration,
} from '../../../components/features/story/StorySnaps/components/GridView/StoryCard';
import { StoryConfiguration } from '../../../components/features/story/StoryReflection';
import { EntityTypesEnum } from '../../../constants/search/editor/EntityTypes';
import { FacetConfiguration, FacetType } from '../../../types/query/results/Facets';
import {
  NeoBarDisplayConfiguration,
  NeoBarSearchConfiguration,
  NeoBarShortcutConfiguration,
} from '../../../components/common/NeoBar/types/NeoBarConfiguration';
import { NeoSearchEntityTypeEnum } from '../../../components/common/NeoBar/types/NeoSearch';
import {
  DEFAULT_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA,
  DEFAULT_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA, DEFAULT_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES,
  DEFAULT_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA, NEO_NAVIGATION_SIDE_BAR_VERSION,
} from '../../defaults';
import { BroadcastStoryTextTabEnum } from '../../../constants/story/BroadcastStoryTabEnum';

const PRESS_DATA_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA: StoriesSharingSchema = {
  disabled: [SharingOptionEnum.TWITTER],
  disableExternalLinks: true,
};

const PRESS_DATA_USER_INTERFACE_STORY_SORTING_CONFIGURATION_SCHEMA: StoriesSortingSchema = {
  disabled: [SortTypeEnum.TEXT_TYPE, SortTypeEnum.RELEVANCE],
};

const PRESS_DATA_PRINT_STORY_CARD_SHOWCASE: CommonStoryCardTypesConfiguration<PrintMetadataTypeEnum> = {
  showcase: {
    date: {
      format: 'MMMM Do YYYY',
    },
    metadata: [
      PrintMetadataTypeEnum.AUDIENCE_ESTIMATE,
      PrintMetadataTypeEnum.PAGES,
      PrintMetadataTypeEnum.WORD_COUNT,
    ],
  },
};

const PRESS_DATA_WEB_STORY_CARD_SHOWCASE: CommonStoryCardTypesConfiguration<WebMetadataTypeEnum> = {
  showcase: {
    metadata: [
      WebMetadataTypeEnum.AUDIENCE_ESTIMATE,
      WebMetadataTypeEnum.WORD_COUNT,
    ],
  },
};

const PRESS_DATA_RADIO_STORY_CARD_SHOWCASE: RadioStoryCardTypesConfiguration<RadioMetadataTypeEnum> = {
  showcase: {
    metadata: [
      RadioMetadataTypeEnum.AUDIENCE_ESTIMATE,
    ],
  },
};

const PRESS_DATA_TELEVISION_STORY_CARD_SHOWCASE: TelevisionStoryCardTypesConfiguration<TelevisionMetadataTypeEnum> = {
  showcase: {
    metadata: [
      TelevisionMetadataTypeEnum.AUDIENCE_ESTIMATE,
    ],
  },
  defaultThumbnailPath: '/images/television2.jpg',
};

const PRESS_DATA_USER_INTERFACE_STORY_VIEWS_CONFIGURATION_SCHEMA: StoriesViewsSchema = {
  brief: {
    card: {
      showDateWithTimeZone: false,
      television: PRESS_DATA_TELEVISION_STORY_CARD_SHOWCASE,
      radio: PRESS_DATA_RADIO_STORY_CARD_SHOWCASE,
      print: PRESS_DATA_PRINT_STORY_CARD_SHOWCASE,
      web: PRESS_DATA_WEB_STORY_CARD_SHOWCASE,
    },
  },
  disabled: [ViewTypeEnum.TIMELINE_VIEW_TYPE],
};

const PRESS_DATA_USER_INTERFACE_STORY_REFLECTION_CONFIGURATION_SCHEMA: StoryConfiguration = {
  broadcast: {
    displayMediaInTimeline: false,
    seeking: {
      limitSeekingToStoryRange: true,
      askForMissingSegment: false,
      maximumSeekingHoursBeforeStory: 6,
      maximumSeekingHoursAfterStory: 6,
    },
    actions: {
      montage: false,
    },
    tabs: {
      availableTabs: [
        {
          id: BroadcastStoryTextTabEnum.TEXT,
          label: 'tabs.broadcast.text',
        },
        {
          id: BroadcastStoryTextTabEnum.ABSTRACT,
          label: 'tabs.broadcast.abstract',
        },
      ],
    },
    television: {
      disableThumbnails: true,
    },
  },
  textualItems: {
    web: {
      presentation: {
        abstractTextOpen: false,
      },
    },
    print: {
      presentation: {
        abstractTextOpen: true,
      },
    },
  },
  metadata: {
    print: {
      date: {
        format: 'MMMM Do YYYY',
      },
    },
    editable: {
      [MetadataTypeEnum.DATE]: false,
      [MetadataTypeEnum.AUDIENCE_ESTIMATE]: false,
      [MetadataTypeEnum.CALC_AD_VALUE]: false,
      [MetadataTypeEnum.PUBLICITY_VALUE]: false,
      [MetadataTypeEnum.PLACEMENT]: false,
      [MetadataTypeEnum.TONE]: false,
      [MetadataTypeEnum.PROMINENCE]: false,
      [MetadataTypeEnum.RUNTIME]: false,
      [MetadataTypeEnum.THIRTY_SEC_AD_VALUE]: false,
      [MetadataTypeEnum.WORD_COUNT]: false,
      [MetadataTypeEnum.UNKNOWN]: false,
      [MetadataTypeEnum.PAGES]: false,
      [MetadataTypeEnum.SURFACE]: false,
      [MetadataTypeEnum.TEXT_TYPE]: false,
      [MetadataTypeEnum.FREQUENCY]: false,
      [MetadataTypeEnum.LANGUAGE]: false,
      [MetadataTypeEnum.AUTHOR]: false,
      [MetadataTypeEnum.MARKET]: false,
      [MetadataTypeEnum.MARKET_RANK]: false,
      [MetadataTypeEnum.SOURCE_TYPE]: false,
      [MetadataTypeEnum.TEXT]: false,
      [MetadataTypeEnum.ABSTRACT_TEXT]: false,
      [MetadataTypeEnum.ORIGIN]: false,
      [MetadataTypeEnum.AVERAGE_STAY]: false,
      [MetadataTypeEnum.UNIQUE_VISITORS]: false,
      [MetadataTypeEnum.RANK]: false,
      [MetadataTypeEnum.CPM]: false,
      [MetadataTypeEnum.PAGE_VIEWS]: false,
      [MetadataTypeEnum.MARKET_TIMEZONE]: false,
    },
    disabled: [
      MetadataTypeEnum.MARKET_RANK,
      MetadataTypeEnum.CALC_AD_VALUE,
      MetadataTypeEnum.PUBLICITY_VALUE,
      MetadataTypeEnum.FREQUENCY,
      MetadataTypeEnum.PLACEMENT,
      MetadataTypeEnum.PROMINENCE,
      MetadataTypeEnum.ORIGIN,
      MetadataTypeEnum.THIRTY_SEC_AD_VALUE,
      MetadataTypeEnum.CPM,
      MetadataTypeEnum.UNIQUE_VISITORS,
      MetadataTypeEnum.AVERAGE_STAY,
      MetadataTypeEnum.PAGE_VIEWS,
      MetadataTypeEnum.RANK,
      MetadataTypeEnum.MARKET_TIMEZONE,
    ],
  },
  common: {
    actions: {
      share: PRESS_DATA_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA,
    },
  },
};

const PRESS_DATA_SETTINGS_LANGUAGE: SettingsSchema = {
  language: {
    options: [LanguageSettingsEnum.ENGLISH, LanguageSettingsEnum.ARABIC, LanguageSettingsEnum.SPANISH],
    enabled: false,
  },
  logo: {
    availableLogos: [LogoTypeEnum.LOGIN, LogoTypeEnum.PORTAL, LogoTypeEnum.DEFAULT],
  },
};

const PRESS_DATA_USER_INTERFACE_STATISTICS_TABS_CONFIGURATION_SCHEMA: Array<StatisticsTabConfiguration> = [
  {
    name: 'monitor',
    title: 'media.title',
    icon: StatisticsGraphIconEnum.THEATERS,
    defaultTabGraphGridSize: [6, 12, 12, 12, 12],
    graphs: [
      {
        id: StatisticsGraphEnum.MENTIONS_DIFFERENCE_IN_TIME,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.SUB_QUERY_MENTIONS,
        graphGridSize: [12, 12, 12, 12, 12],
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SOURCE_TYPE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SOURCE_TYPE_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_MARKET,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_MARKET_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SOURCES,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SOURCES_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_DAY_PUBLISHED,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_DAY_PUBLISHED_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_AUTHORS,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_AUTHORS_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_TAG,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_TAGS,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_MARKETS,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_AUTHORS,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_QUERY_SOURCE_TYPE,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_SOURCE_TYPE_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_DAY_PUBLISHED,
      },
      {
        id: StatisticsGraphEnum.AUDIENCE_DAY_PUBLISHED_PERCENTAGE,
      },

      {
        id: StatisticsGraphEnum.AUDIENCE_QUERIES,
      },
      {
        id: StatisticsGraphEnum.SUM_AUDIENCE_PUBLISHED_QUERY,
      },

    ],
  },
  {
    name: 'sentiment',
    title: 'statistics.tab.sentiment',
    icon: StatisticsGraphIconEnum.PEOPLE,
    defaultTabGraphGridSize: [6, 12, 12, 12, 12],
    graphs: [
      {
        id: StatisticsGraphEnum.MENTIONS_SENTIMENT,
      },
      {
        id: StatisticsGraphEnum.MENTIONS_SENTIMENT_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_SOURCE_TYPE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_SOURCE_TYPE_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_SOURCES,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_SOURCES_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_AUTHORS,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_AUTHORS_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_MARKET,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_MARKET_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_DAY_PUBLISHED,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_DAY_PUBLISHED_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_TAGS,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_TAGS_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_QUERIES,
      },
      {
        id: StatisticsGraphEnum.SENTIMENT_QUERIES_PERCENTAGE,
      },
      {
        id: StatisticsGraphEnum.MENTION_PUBLISHED_SENTIMENT,
      },
    ],
  },
];

const APEX_CHART_COLORS: Array<string> = [
  '#1b10f8', '#f81079', '#edf810', '#10f88f', '#10f8ef',
  '#7b10f8', '#f81019', '#8df810', '#75e256', '#56bbe2',
  '#c356e2', '#e27d56', '#96a106', '#06a15e', '#1106a1',
  '#a10649', '#f100ff', '#ff7100', '#0eff00', '#008eff',
  '#ff00a5', '#ffd900', '#00ffc9', '#0026ff', '#d1ff4f',
  '#4fffd5', '#7d4fff', '#ff4f79', '#ff4fef', '#4fff5f',
];

const PRESS_DATA_USER_INTERFACE_STATISTICS_CONFIGURATION_SCHEMA: StatisticsSchema = {
  tabs: PRESS_DATA_USER_INTERFACE_STATISTICS_TABS_CONFIGURATION_SCHEMA,
  defaultTab: 'monitor',
  defaultColors: APEX_CHART_COLORS,
};

const PRESS_DATA_SEARCH_BAR_CONFIGURATION_SCHEMA: SearchBarSchema = {
  filters: {
    disabled: [
      EntityTypesEnum.ORGANIZATIONS,
      EntityTypesEnum.PEOPLE,
      EntityTypesEnum.GEOLOCATION_TAGS,
    ],
  },
  querySettings: {
    disabled: [
      PowerSearchFilterTypes.ORGANIZATIONS,
      PowerSearchFilterTypes.PEOPLE,
      PowerSearchFilterTypes.GEOLOCATION_TAGS,
    ],
  },
};

const PRESS_DATA_USER_INTERFACE_STORY_CONFIGURATION_SCHEMA: StoriesSchema = {
  views: PRESS_DATA_USER_INTERFACE_STORY_VIEWS_CONFIGURATION_SCHEMA,
  reflection: PRESS_DATA_USER_INTERFACE_STORY_REFLECTION_CONFIGURATION_SCHEMA,
  sorting: PRESS_DATA_USER_INTERFACE_STORY_SORTING_CONFIGURATION_SCHEMA,
  sharing: PRESS_DATA_USER_INTERFACE_STORY_SHARING_CONFIGURATION_SCHEMA,
};

const PRESS_DATA_USER_INTERFACE_FACET_CONFIGURATION_SCHEMA: FacetConfiguration = {
  disabled: [FacetType.DATE],
};

const PRESS_DATA_USER_INTERFACE_CUSTOM_PAGES_CONFIGURATION_SCHEMA: CustomPagePaths = {
  login: 'overrides/pressdata/Login/index',
};

const PRESS_DATA_EXPORTS_CONFIGURATION_SCHEMA: ExportSchema = {
  moreOptions: {
    disabled: [
      ReportOptionsEnum.PRODUCE_EXTERNAL_LINKS,
      ReportOptionsEnum.REMOVE_LIVE_QUERY_GROUP,
    ],
    defaults: {
      [ReportOptionsEnum.REMOVE_LIVE_QUERY_GROUP]: true,
    },
  },
  fields: {
    disabled: [ExportSupportedDisabledFieldEnum.SUMMARY],
  },
};

const PRESS_DATA_ALERT_CONFIGURATION_SCHEMA: AlertSchema = {
  reportOptions: {
    disabled: PRESS_DATA_EXPORTS_CONFIGURATION_SCHEMA.moreOptions.disabled,
    defaults: PRESS_DATA_EXPORTS_CONFIGURATION_SCHEMA.moreOptions.defaults,
  },
};

const PRESS_DATA_LOGGING_CONFIGURATION_SCHEMA: LoggingSchema = {
  activeFeedbackTypes: [
  ],
};

const PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA: NeoBarSearchConfiguration = {
  ...DEFAULT_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA,
};

const PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA: NeoBarDisplayConfiguration = {
  ...DEFAULT_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA,
};

const PRESS_DATA_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA: NeoBarShortcutConfiguration = {
  ...DEFAULT_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA,
};

const PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES: Array<NeoSearchEntityTypeEnum> = [
  ...DEFAULT_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES,
];

const PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_SCHEMA: NeoNavigationSideBarSchema = {
  neo: {
    version: NEO_NAVIGATION_SIDE_BAR_VERSION,
    search: PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_SEARCH_CONFIGURATION_SCHEMA,
    display: PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_DISPLAY_CONFIGURATION_SCHEMA,
    shortcuts: PRESS_DATA_NE0_NAVIGATION_SIDE_BAR_SHORTCUT_SCHEMA,
    types: PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_NEO_TYPES,
  },
  savingOptions: {
    createAllowed: false,
    updateAllowed: false,
  },
  userInterfaceSettings: {
    enabled: false,
    disabled: [],
  },
};

const PRESS_DATA_USER_INTERFACE_CONFIGURATION_SCHEMA: UserInterfaceConfigurationSchema = {
  stories: PRESS_DATA_USER_INTERFACE_STORY_CONFIGURATION_SCHEMA,
  settings: PRESS_DATA_SETTINGS_LANGUAGE,
  statistics: PRESS_DATA_USER_INTERFACE_STATISTICS_CONFIGURATION_SCHEMA,
  search: PRESS_DATA_SEARCH_BAR_CONFIGURATION_SCHEMA,
  facets: PRESS_DATA_USER_INTERFACE_FACET_CONFIGURATION_SCHEMA,
  exports: PRESS_DATA_EXPORTS_CONFIGURATION_SCHEMA,
  alerts: PRESS_DATA_ALERT_CONFIGURATION_SCHEMA,
  customPagePaths: PRESS_DATA_USER_INTERFACE_CUSTOM_PAGES_CONFIGURATION_SCHEMA,
  logging: PRESS_DATA_LOGGING_CONFIGURATION_SCHEMA,
  neoNavigationSideBar: PRESS_DATA_NEO_NAVIGATION_SIDE_BAR_SCHEMA,
};

export default PRESS_DATA_USER_INTERFACE_CONFIGURATION_SCHEMA;
