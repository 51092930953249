import React from 'react';
import { Typography } from '@material-ui/core';
import {
  AdditionRequest, EntityRange,
} from '../../../../../../../types/layout/topBar/search/editor';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createSuggestion, createSuggestionReference } from '../SuggestionServiceUtils';
import ConditionMatcher from '../../../QueryEditor/ConditionMatcher';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';

const createConditionMatcherPreview = (text, matcher): JSX.Element => (
    <span>
        <ConditionMatcher>{matcher}</ConditionMatcher>
        {' '}
        <Typography component="span">{text}</Typography>
    </span>
);

const createGroupSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const entityType = entityRange.entity.getType() as EntityTypesEnum;
  const matcher = (entityType === EntityTypesEnum.AND_CONDITION_MATCHER)
    ? EntityTypesEnum.AND_CONDITION_MATCHER
    : EntityTypesEnum.OR_CONDITION_MATCHER;
  const preview = createConditionMatcherPreview('()', matcher);
  const selectionReference = createSuggestionReference(entityRange);

  const request: AdditionRequest<EntityTypesEnum> = {
    additionText: ' ()',
    reference: selectionReference,
    cursorPositionWithinText: ' ('.length,
  };

  return createSuggestion(preview, request);
};

const createConditionMatcherSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  const groupSuggestion = createGroupSuggestion(entityRange);

  return Array.of(groupSuggestion);
};

export default createConditionMatcherSuggestions;
