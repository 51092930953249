import React, { memo } from 'react';
import ConditionMatcher from '../ConditionMatcher';

type ConditionMatcherDecoratorPropTypes = {
    children?: Array<React.ReactNode>;
};

function ConditionMatcherDecorator(props: ConditionMatcherDecoratorPropTypes): JSX.Element {
  const { children } = props;

  return (
      <ConditionMatcher>
          {children}
      </ConditionMatcher>
  );
}
export default memo(ConditionMatcherDecorator);
