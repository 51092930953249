import React, { useCallback, useEffect, useState } from 'react';
import { NOT_FOUND } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { NavigationSectionItem, NavigationBarSection, NeoBarItemType } from '../../../../types/layout/navBar';
import buildNeoSearchSection from '../../../../layouts/PortalLayout/NavBar/builders/NavBarBuilders';
import useNeoBarHeartbeat from './useNeoBarHeartbeat';
import useNeoBarFetchers from './useNeoBarFetchers';
import TranslationNameSpaceEnum from '../../../../types/translation/TranslationNameSpaceEnum';
import { NeoSearchEntityTypeEnum, NeoSearchRequest } from '../types/NeoSearch';

interface ExposedUseNeoBarNavConfigsProperties {
  getNavBarSections: (searchRequest?: NeoSearchRequest) => Array<NavigationBarSection>;
  handleQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleSharedTagSearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
  handleSharedQuerySearch: (text: string, types: Array<NeoSearchEntityTypeEnum>) => void;
}

enum NAV_SECTION_PRIORITY {
  QUERY = 1,
  TAG = 2,
  SHARED_QUERY = 3,
  SHARED_TAG = 4,
}

const useNeoBarNavSections = (): ExposedUseNeoBarNavConfigsProperties => {
  const { t } = useTranslation(TranslationNameSpaceEnum.NAV_BAR);
  const {
    queriesLoading, sharedQueriesLoading, tagsLoading, tags, queries, sharedQueries,
    handleTagSearch, handleQuerySearch, handleSharedQuerySearch, loadNextQueries, loadNextSharedQueries,
    hasNextTags, hasNextSharedQueries, loadNextTags, hasNextQueries, initTags, initQueries,
    handleSharedTagSearch,
  } = useNeoBarFetchers();

  const [queryNavSection, setQueryNavSection] = useState<NavigationBarSection>(
    buildNeoSearchSection()
      .setSubheader('categorySubHeader.myQueries')
      .setEmptyMessage('categorySubHeader.nothingToSee')
      .setPriority(NAV_SECTION_PRIORITY.QUERY)
      .build(),
  );

  const [sharedQueryNavSection, setSharedQueryNavSection] = useState<NavigationBarSection>(
    buildNeoSearchSection()
      .setSubheader('categorySubHeader.queries')
      .setEmptyMessage('categorySubHeader.nothingToSee')
      .setPriority(NAV_SECTION_PRIORITY.SHARED_QUERY)
      .build(),
  );

  const [tagNavSection, setTagNavSection] = useState<NavigationBarSection>(
    buildNeoSearchSection()
      .setSubheader('categorySubHeader.tags')
      .setEmptyMessage('categorySubHeader.nothingToSee')
      .setPriority(NAV_SECTION_PRIORITY.TAG)
      .build(),
  );

  const [sharedTagNavSection, setSharedTagNavSection] = useState<NavigationBarSection>(
    buildNeoSearchSection()
      .setSubheader('categorySubHeader.sharedTags')
      .setEmptyMessage('categorySubHeader.nothingToSee')
      .setPriority(NAV_SECTION_PRIORITY.SHARED_TAG)
      .build(),
  );

  const handleToggleCollapsed = (
    setFunction: React.Dispatch<React.SetStateAction<NavigationBarSection>>,
  ) => (): void => {
    setFunction((prev) => ({
      ...prev,
      collapsed: !prev.collapsed,
    }));
  };

  const setTagSidebarContent = useCallback((latestTags: Array<NavigationSectionItem>): void => {
    const handleNextTags = hasNextTags ? loadNextTags : NOT_FOUND;
    const createdTagNavSection = buildNeoSearchSection()
      .setSubheader('categorySubHeader.tags')
      .setEmptyMessage(t('categorySubHeader.nothingToSee'))
      .setItems(latestTags)
      .setType(NeoBarItemType.TAG_TYPE)
      .setPriority(NAV_SECTION_PRIORITY.TAG)
      .setOnNext(handleNextTags)
      .setOnSearch(handleTagSearch)
      .setOnToggleCollapsed(handleToggleCollapsed(setTagNavSection))
      .build();

    const createdSharedTagNavSection = buildNeoSearchSection()
      .setSubheader('categorySubHeader.sharedTags')
      .setEmptyMessage(t('categorySubHeader.nothingToSee'))
      .setType(NeoBarItemType.TAG_TYPE)
      .setPriority(NAV_SECTION_PRIORITY.SHARED_TAG)
      .setOnSearch(handleSharedTagSearch)
      .setOnToggleCollapsed(handleToggleCollapsed(setSharedTagNavSection))
      .build();

    setTagNavSection(createdTagNavSection);
    setSharedTagNavSection(createdSharedTagNavSection);
  }, [handleSharedTagSearch, handleTagSearch, hasNextTags, loadNextTags, t]);

  const setSharedQueriesSidebarContent = useCallback((latestQueries: Array<NavigationSectionItem>): void => {
    const handleNext = hasNextSharedQueries ? loadNextSharedQueries : NOT_FOUND;

    setSharedQueryNavSection(
      buildNeoSearchSection()
        .setSubheader('categorySubHeader.queries')
        .setEmptyMessage(t('categorySubHeader.nothingToSee'))
        .setItems(latestQueries)
        .setType(NeoBarItemType.QUERY_TYPE)
        .setPriority(NAV_SECTION_PRIORITY.SHARED_QUERY)
        .setOnNext(handleNext)
        .setOnSearch(handleSharedQuerySearch)
        .setOnToggleCollapsed(handleToggleCollapsed(setSharedQueryNavSection))
        .build(),
    );
  }, [handleSharedQuerySearch, hasNextSharedQueries, loadNextSharedQueries, t]);

  const setQueriesSidebarContent = useCallback((latestQueries: Array<NavigationSectionItem>): void => {
    const handleNext = hasNextQueries ? loadNextQueries : NOT_FOUND;

    setQueryNavSection(
      buildNeoSearchSection()
        .setSubheader('categorySubHeader.myQueries')
        .setEmptyMessage(t('categorySubHeader.nothingToSee'))
        .setItems(latestQueries)
        .setType(NeoBarItemType.QUERY_TYPE)
        .setPriority(NAV_SECTION_PRIORITY.QUERY)
        .setOnNext(handleNext)
        .setOnSearch(handleQuerySearch)
        .setOnToggleCollapsed(handleToggleCollapsed(setQueryNavSection))
        .build(),
    );
  }, [handleQuerySearch, hasNextQueries, loadNextQueries, t]);

  const getNavBarSections = useCallback((
    searchRequest?: NeoSearchRequest,
  ): Array<NavigationBarSection> => {
    const sections: Array<NavigationBarSection> = [];

    if (queryNavSection) {
      sections.push({
        ...queryNavSection,
        hidden: !searchRequest?.queries.fetchQueries || false,
      });
    }

    if (sharedQueryNavSection) {
      sections.push({
        ...sharedQueryNavSection,
        hidden: !searchRequest?.queries.fetchSharedQueries || false,
      });
    }

    if (tagNavSection) {
      sections.push({
        ...tagNavSection,
        hidden: !searchRequest?.tags.fetchTags || false,
      });
    }

    return sections;
  }, [queryNavSection, sharedQueryNavSection, tagNavSection]);

  useEffect(() => {
    setTagNavSection((prev) => ({
      ...prev,
      loading: tagsLoading,
    }));
  }, [tagsLoading]);

  useEffect(() => {
    setSharedQueryNavSection((prev) => ({
      ...prev,
      loading: sharedQueriesLoading,
    }));
  }, [sharedQueriesLoading]);

  useEffect(() => {
    setQueryNavSection((prev) => ({
      ...prev,
      loading: queriesLoading,
    }));
  }, [queriesLoading]);

  useEffect(() => {
    setQueriesSidebarContent(queries);
  }, [queries, setQueriesSidebarContent]);

  useEffect(() => {
    setSharedQueriesSidebarContent(sharedQueries);
  }, [sharedQueries, setSharedQueriesSidebarContent]);

  useEffect(() => {
    setTagSidebarContent(tags);
  }, [tags, setTagSidebarContent]);

  useNeoBarHeartbeat({ initQueries, initTags });

  return {
    getNavBarSections,
    handleQuerySearch,
    handleSharedQuerySearch,
    handleSharedTagSearch,
    handleTagSearch,
  };
};

export default useNeoBarNavSections;
