import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetSourceGroupsResponse,
  getSourceGroupTransformationUtilities,
  onFeedback,
  PagedContent,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  SourceGroup,
  SourceGroupDTO,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

export const getSourceGroups = (
  params?: object, cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<SourceGroup>> => axios.get<GetSourceGroupsResponse>('/source-groups', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_SOURCE_GROUPS]}`)
      .setNotifyUser(false)
      .build()));

    return getSourceGroupTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_SOURCE_GROUPS]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getSourceGroup = (
  id: number,
  cancelToken?: CancelToken,
) => (dispatch): Promise<SourceGroup> => axios.get<SourceGroupDTO>(`/source-groups/${id}`, {
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_SOURCE_GROUP]}`)
      .setNotifyUser(false)
      .build()));

    return getSourceGroupTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_SOURCE_GROUP]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });
