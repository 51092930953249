import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CacheType, NotFound, SupportedCacheDataType } from 'mediascouting-core-ui-common';
import { ReduxState } from '../redux/reducers';
import retrieveFromCache from '../utils/data/CacheUtils';
import { addToCache } from '../constants/actions/creators/CacheActions';
import { AppDispatch } from '../redux/store';

interface ExposedUseLabelsProperties {
    onCache: (type: CacheType, data: SupportedCacheDataType) => void;
    onRetrieveFromCache: (type: CacheType, id: number) => SupportedCacheDataType | NotFound;
}

const useCache = (): ExposedUseLabelsProperties => {
  const dispatch: AppDispatch = useDispatch();
  const cache = useSelector((state: ReduxState) => state.cache);
  const cacheRef = useRef(cache);

  const handleCache = useCallback((type: CacheType, data: SupportedCacheDataType): void => {
    const payload = {
      type,
      data,
    };

    dispatch(addToCache(payload));
  }, [dispatch]);

  const handleRetrieveFromCache = useCallback((
    type: CacheType, id: number,
  ): SupportedCacheDataType | NotFound => retrieveFromCache(type, id, cacheRef.current), []);

  useEffect(() => {
    cacheRef.current = cache;
  });

  return {
    onCache: handleCache,
    onRetrieveFromCache: handleRetrieveFromCache,
  };
};

export default useCache;
