import React, { memo, useCallback, useState } from 'react';
import {
  Box, Drawer, Fade, IconButton, makeStyles, SvgIcon, Theme, Tooltip, Typography,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationPropertiesFlagsEnum, EMPTY_STRING, hasActiveFlag } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../redux/reducers';
import { AppDispatch } from '../../../redux/store';
import getDefaultSnackbarOptions from '../../../utils/common/SnackbarUtils';
import { resetCart } from '../../../constants/actions/creators/CartActions';
import ShoppingCart from '../../../components/features/cart/ShoppingCart';
import Visible from '../../../components/auth/Visible';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1.5),
  },
  drawer: {
    width: 500,
    maxWidth: '100%',
  },
}));

const StyledBadge = withStyles((theme: Theme) => createStyles({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#E91E63',
    color: 'white',
  },
}))(Badge);

function Cart(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const shoppingList = useSelector((state: ReduxState) => state.cart.shoppingList);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleCheckout = useCallback((): void => {
    setOpen(false);
    dispatch(resetCart());
    enqueueSnackbar(t('layout.topBar.search.searchBar.cart.order.create'), getDefaultSnackbarOptions('info'));
  }, [dispatch, enqueueSnackbar, t]);

  return (
      <Visible when={{ flagCondition: hasActiveFlag(ApplicationPropertiesFlagsEnum.CLIP_ORDERING) }}>
          <Fade in={shoppingList.length > 0} timeout={350} unmountOnExit mountOnEnter>
              <Tooltip title={t('layout.topBar.search.searchBar.cart.title') || EMPTY_STRING}>
                  <IconButton
                    color="inherit"
                    onClick={handleOpen}
                    className={classes.icon}
                  >
                      <StyledBadge badgeContent={shoppingList.length}>
                          <ShoppingCartRoundedIcon />
                      </StyledBadge>
                  </IconButton>
              </Tooltip>
          </Fade>
          <Drawer
            anchor="right"
            classes={{ paper: classes.drawer }}
            ModalProps={{ BackdropProps: { invisible: true } }}
            onClose={handleClose}
            open={open}
            variant="temporary"
          >
              <PerfectScrollbar>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                          {t('layout.topBar.search.searchBar.cart.shopping.title')}
                      </Typography>
                      <IconButton onClick={handleClose}>
                          <SvgIcon fontSize="small">
                              <CloseIcon />
                          </SvgIcon>
                      </IconButton>
                  </Box>
                  <Box pl={2} pr={2}>
                      <ShoppingCart shoppingList={shoppingList} onCheckout={handleCheckout} />
                  </Box>
              </PerfectScrollbar>
          </Drawer>
      </Visible>
  );
}

export default memo(Cart);
