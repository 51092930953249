import React, { memo } from 'react';
import ExactTextMatch from '../ExactTextMatch';

type ExactTextMatchDecoratorPropTypes = {
    children?: Array<React.ReactNode>;
};

function ExactTextMatchDecorator(props: ExactTextMatchDecoratorPropTypes): JSX.Element {
  const { children } = props;

  return (
      <ExactTextMatch>
          {children}
      </ExactTextMatch>
  );
}

export default memo(ExactTextMatchDecorator);
