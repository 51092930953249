import {
  buildFeedbackMessage,
  CreateQueryResponse,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetQueriesInfoResponse,
  GetQueriesResponse,
  getQueryInfoTransformationUtilities,
  GetQueryResponse,
  getQueryTransformationUtilities,
  onFeedback,
  PagedContent,
  Query,
  QueryDTO,
  QueryInfo,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  UpdateQueryResponse,
  GetQueryNamesResponse, Shallow,
  getShallowTransformationUtilities,
  GetQueryInfoRemoteParams,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/common/AxiosUtils';

import { beat } from '../constants/actions/creators/HeartbeatActions';
import { HeartbeatDataChange, HeartbeatDataType } from '../redux/reducers/Heartbeat';

export const getQueryInfo = (
  params?: GetQueryInfoRemoteParams, cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<QueryInfo>> => axios.get<GetQueriesInfoResponse>('/queries/info', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_QUERY_INFO]}`)
      .setNotifyUser(false)
      .build()));

    return getQueryInfoTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_QUERY_INFO]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const getQueries = (
  params?: object,
  cancelToken?: CancelToken,
) => (dispatch): Promise<PagedContent<Query>> => axios.get<GetQueriesResponse>('/queries', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_QUERIES]}`)
      .setNotifyUser(false)
      .build()));

    return getQueryTransformationUtilities().transform().all().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_QUERIES]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

interface GetQueryNameParams {
  ids: string;
}

export const getQueryNames = (
  params?: GetQueryNameParams,
  cancelToken?: CancelToken,
) => (dispatch): Promise<Array<Shallow>> => axios.get<GetQueryNamesResponse>('/queries/all', {
  params,
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_QUERY_NAMES]}`)
      .setNotifyUser(false)
      .build()));

    return (data || []).map((dto) => getShallowTransformationUtilities().transform().fromDto(dto));
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_QUERY_NAMES]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export const getQuery = (
  id: number,
  cancelToken?: CancelToken,
) => (dispatch): Promise<Query> => axios.get<GetQueryResponse>(`/queries/${id}`, {
  cancelToken,
})
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_QUERY]}`)
      .setNotifyUser(false)
      .build()));

    return getQueryTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_QUERY]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const deleteQuery = (id: number) => (dispatch): Promise<void> => axios.delete<void>(`/queries/${id}`)
  .then(({ data }) => {
    dispatch(beat({
      cause: HeartbeatDataChange.DELETE,
      type: HeartbeatDataType.QUERY,
      identification: {
        id,
      },
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.DELETE_QUERY]}`)
      .setNotifyUser(true)
      .build()));

    return data;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.DELETE_QUERY]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const updateQuery = (
  id: number,
  dto: Partial<QueryDTO>,
) => (dispatch): Promise<Query> => axios.put<UpdateQueryResponse>(`/queries/${id}`, dto)
  .then(({ data }) => {
    dispatch(beat({
      cause: HeartbeatDataChange.UPDATE,
      type: HeartbeatDataType.QUERY,
      identification: {
        id,
        payload: data,
      },
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.UPDATE_QUERY]}`)
      .setNotifyUser(true)
      .build()));

    return getQueryTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.UPDATE_QUERY]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const createQuery = (
  dto: Partial<QueryDTO>,
) => (dispatch): Promise<Query> => axios.post<CreateQueryResponse>('/queries', dto)
  .then(({ data }) => {
    dispatch(beat({
      cause: HeartbeatDataChange.CREATE,
      type: HeartbeatDataType.QUERY,
      identification: {},
    }));

    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.CREATE_QUERY]}`)
      .setNotifyUser(true)
      .build()));

    return getQueryTransformationUtilities().transform().fromDto(data);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.CREATE_QUERY]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });
