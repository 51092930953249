import React from 'react';
import { BrokenImage } from '@material-ui/icons';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { TFunction } from 'i18next';
import { SHORTCUT_ICON_MAPPER, SHORTCUT_TEXT_TRANSLATION_KEY_MAPPER } from '../../../types/NeoSearch';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';

export const getShortcutIcon = (configuration: NeoBarConfiguration): React.ReactNode => {
  const foundIcon = SHORTCUT_ICON_MAPPER[configuration.shortcuts.selectSuggestedSuggestionKey];

  if (foundIcon) {
    return foundIcon;
  }

  return <BrokenImage />;
};

export const getShortcutText = (
  configuration: NeoBarConfiguration,
  t: TFunction,
): string => {
  const foundKey = SHORTCUT_TEXT_TRANSLATION_KEY_MAPPER[
    configuration.shortcuts.selectSuggestedSuggestionKey
  ];

  if (foundKey) {
    return t(foundKey);
  }

  return EMPTY_STRING;
};
