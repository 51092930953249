import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import ExactTextMatchDecorator
  from '../../../QueryEditor/decorators/ExactTextMatchDecorator';
import ConditionMatcherDecorator
  from '../../../QueryEditor/decorators/ConditionMatcherDecorator';
import FilterDecorator
  from '../../../QueryEditor/decorators/FilterDecorator';
import FilterOptionDecorator
  from '../../../QueryEditor/decorators/FilterOptionDecorator';
import SearchableFilterOptionDecorator
  from '../../../QueryEditor/decorators/SearchableFilterOptionDecorator';
import { EntityTypesEnum, FILTER_ENTITY_TYPES } from '../../../../../../../constants/search/editor/EntityTypes';

const handleEntityStrategyByType = (types: Array<EntityTypesEnum>) => (
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
): void => contentBlock.findEntityRanges((character) => {
  const entityKey = character.getEntity();
  if (entityKey === null) {
    return false;
  }

  return types.includes(contentState.getEntity(entityKey).getType() as EntityTypesEnum);
}, callback);

const createEditorDecorators = (): CompositeDecorator => new CompositeDecorator([
  {
    strategy: handleEntityStrategyByType([EntityTypesEnum.EXACT_TEXT_MATCH]),
    component: ExactTextMatchDecorator,
  },
  {
    strategy: handleEntityStrategyByType([
      EntityTypesEnum.NAND_CONDITION_MATCHER,
      EntityTypesEnum.AND_CONDITION_MATCHER,
      EntityTypesEnum.OR_CONDITION_MATCHER,
      EntityTypesEnum.NOT_CONDITION_MATCHER,
    ]),
    component: ConditionMatcherDecorator,
  },
  {
    strategy: handleEntityStrategyByType(FILTER_ENTITY_TYPES),
    component: FilterDecorator,
  },
  {
    strategy: handleEntityStrategyByType([EntityTypesEnum.FILTER_OPTION]),
    component: FilterOptionDecorator,
  },
  {
    strategy: handleEntityStrategyByType([EntityTypesEnum.SEARCHABLE_FILTER_OPTION]),
    component: SearchableFilterOptionDecorator,
  },
]);

export default createEditorDecorators;
