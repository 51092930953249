import { Theme, withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';

const SmallAvatar = withStyles((theme: Theme) => createStyles({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

export default SmallAvatar;
