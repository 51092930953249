import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import { Fingerprint } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { GeolocationTypeConfigurationFormFields } from './index';
import TranslationNameSpaceEnum from '../../../../../types/translation/TranslationNameSpaceEnum';

export interface MainDetailsPropTypes {
    formik: FormikProps<GeolocationTypeConfigurationFormFields>;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
}));

function MainDetails(props: MainDetailsPropTypes): JSX.Element {
  const classes = useStyles();
  const {
    formik,
  } = props;
  const { t } = useTranslation(TranslationNameSpaceEnum.QUERY_TYPES);

  const getSubheader = (): JSX.Element => (
      <Box className={classes.flexContainer}>
          <div>
              <Typography variant="body2" color="textPrimary">
                  {t('item.geolocation.main.header.title')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                  {t('item.geolocation.main.header.description')}
              </Typography>
          </div>
      </Box>
  );

  return (
      <Card variant="outlined">
          <CardHeader
            avatar={(
                <Avatar className={classes.avatar}>
                    <Fingerprint />
                </Avatar>
            )}
            subheader={getSubheader()}
          />
          <Divider />
          <CardContent>
              <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl className={classes.fullWidth}>
                          <TextField
                            fullWidth
                            label={`${t('item.geolocation.form.name')} *`}
                            placeholder={t('item.geolocation.form.name')}
                            margin="normal"
                            name="name"
                            variant="outlined"
                            value={formik.values.name}
                            helperText={formik.touched.name && formik.errors.name}
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            onChange={formik.handleChange}
                          />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl className={classes.fullWidth}>
                          <TextField
                            fullWidth
                            label={`${t('item.geolocation.form.description')}`}
                            placeholder={t('item.geolocation.form.description')}
                            margin="normal"
                            name="description"
                            variant="outlined"
                            value={formik.values.description}
                            helperText={formik.touched.description && formik.errors.description}
                            error={Boolean(formik.touched.description && formik.errors.description)}
                            onChange={formik.handleChange}
                          />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl className={classes.fullWidth}>
                          <TextField
                            fullWidth
                            label={`${t('item.geolocation.form.summary')}`}
                            placeholder={t('item.geolocation.form.summary')}
                            margin="normal"
                            name="summary"
                            variant="outlined"
                            value={formik.values.summary}
                            helperText={formik.touched.summary && formik.errors.summary}
                            error={Boolean(formik.touched.summary && formik.errors.summary)}
                            onChange={formik.handleChange}
                          />
                      </FormControl>
                  </Grid>
              </Grid>
          </CardContent>
      </Card>
  );
}

export default MainDetails;
