import { NOT_FOUND } from 'mediascouting-core-ui-common';
import {
  ADD_TO_PLAYLIST,
  MOVE_PLAYLIST_ITEM,
  REMOVE_FROM_PLAYLIST,
  RESET_PLAYLIST,
} from '../../constants/actions/types/MontageActionTypes';
import { MontageActions } from '../../constants/actions/creators/MontageActions';
import { PlaylistItem } from '../../types/layout/topBar/Montage';

export type MontageReducer = {
    playlist: Array<PlaylistItem>;
}

const INIT_STATE: MontageReducer = {
  playlist: [],
};

export default (state = INIT_STATE, action: MontageActions): MontageReducer => {
  switch (action.type) {
    case ADD_TO_PLAYLIST: {
      const calculatedOrder = state.playlist[state.playlist.length - 1] !== NOT_FOUND
        ? (state.playlist[state.playlist.length - 1]?.order || 0) + 1
        : 0;

      const payload: PlaylistItem = {
        ...action.payload,
        order: calculatedOrder,
      };

      const updatedPlaylist = [...state.playlist, payload];

      return {
        ...state,
        playlist: updatedPlaylist,
      };
    }

    case REMOVE_FROM_PLAYLIST: {
      const copiedPlaylist = [...state.playlist];

      const found = copiedPlaylist.find((value) => value.story.id === action.payload.story.id
          && value.range.start === action.payload.range.start
          && value.range.end === action.payload.range.end);

      if (found) {
        const index = copiedPlaylist.indexOf(found);
        copiedPlaylist.splice(index, 1);
      }

      const orderedPlaylist = copiedPlaylist.map((playlistItem, index) => ({
        ...playlistItem,
        order: index,
      }));

      return {
        ...state,
        playlist: orderedPlaylist,
      };
    }

    case RESET_PLAYLIST: {
      return {
        ...state,
        playlist: [],
      };
    }

    case MOVE_PLAYLIST_ITEM: {
      const copiedPlaylist = [...state.playlist];
      const [removed] = copiedPlaylist.splice(action.payload.sourceIndex, 1);

      copiedPlaylist.splice(action.payload.destinationIndex, 0, removed);

      const orderedPlaylist = copiedPlaylist.map((playlistItem, index) => ({
        ...playlistItem,
        order: index,
      }));

      return {
        ...state,
        playlist: orderedPlaylist,
      };
    }
    default:
      return state;
  }
};
