/* eslint-disable max-len */
import { EditorState, Modifier, SelectionState } from 'draft-js';
import {
  AdditionRequest,
  EditorRequest,
  EntityMetadata,
  SubstituteRequest,
} from '../../../../../../../types/layout/topBar/search/editor';
import { mergeSelection } from '../../../../../../../utils/draft-js/SelectionUtils';
import EntityMutability from '../../../../../../../constants/draft-js/EntityMutability';

const getMetadataType = <T> (entityMetadata: EntityMetadata<T>): string => {
  if (typeof entityMetadata.type === 'string') {
    return entityMetadata.type;
  }

  return 'Unknown';
};

const getSelectionState = (selection: SelectionState, start?: number, end?: number): SelectionState => {
  if (start && end) {
    return mergeSelection(selection, start, end);
  }

  return selection;
};

const handleAdditionRequest = <T> (
  editorState: EditorState,
  additionRequest: AdditionRequest<T>,
  mutabilityMapper: { [key in string]: EntityMutability },
): EditorState => {
  const {
    additionText, reference, entityMetadata, cursorPositionWithinText,
  } = additionRequest;
  const contentState = editorState.getCurrentContent();

  if (reference) {
    const insertedTextContentState = Modifier.insertText(contentState, reference, additionText);
    const cursorPosition = cursorPositionWithinText
      ? getSelectionState(reference, reference.getEndOffset() + cursorPositionWithinText, reference.getEndOffset() + cursorPositionWithinText)
      : editorState.getSelection();

    if (entityMetadata) {
      let nextContentState = insertedTextContentState;

      entityMetadata.forEach((metadata) => {
        const metadataType = getMetadataType(metadata);
        const contentStateWithEntity = nextContentState.createEntity(metadataType, mutabilityMapper[metadataType], metadata.data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const entitySelection = mergeSelection(reference, reference.getEndOffset() + metadata.start, reference.getEndOffset() + metadata.end);
        nextContentState = Modifier.applyEntity(contentStateWithEntity, entitySelection, entityKey);
      });

      const updatedEditorState = EditorState.push(editorState, nextContentState, 'apply-entity');

      return EditorState.forceSelection(updatedEditorState, cursorPosition);
    }

    const updatedEditorState = EditorState.push(editorState, insertedTextContentState, 'insert-characters');

    return EditorState.forceSelection(updatedEditorState, cursorPosition);
  }

  const userSelection = editorState.getSelection();
  const insertedTextContentState = Modifier.insertText(contentState, userSelection, additionText);
  const cursorPosition = cursorPositionWithinText
    ? getSelectionState(userSelection, userSelection.getEndOffset() + cursorPositionWithinText, userSelection.getEndOffset() + cursorPositionWithinText)
    : userSelection;

  if (entityMetadata) {
    let nextContentState = insertedTextContentState;

    entityMetadata.forEach((metadata) => {
      const metadataType = getMetadataType(metadata);
      const contentStateWithEntity = nextContentState.createEntity(metadataType, mutabilityMapper[metadataType], metadata.data);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const entitySelection = mergeSelection(userSelection, userSelection.getStartOffset() + metadata.start, userSelection.getStartOffset() + metadata.end);
      nextContentState = Modifier.applyEntity(contentStateWithEntity, entitySelection, entityKey);
    });

    const updatedEditorState = EditorState.push(editorState, nextContentState, 'apply-entity');

    return EditorState.forceSelection(updatedEditorState, cursorPosition);
  }

  const updatedEditorState = EditorState.push(editorState, insertedTextContentState, 'insert-characters');

  return EditorState.forceSelection(updatedEditorState, cursorPosition);
};

export const handleSubstituteRequest = <T> (
  editorState: EditorState,
  substituteRequest: SubstituteRequest<T>,
  mutabilityMapper: { [key in string]: EntityMutability },
): EditorState => {
  const {
    substituteText, reference, entityMetadata, range, cursorPositionWithinText,
  } = substituteRequest;
  const contentState = editorState.getCurrentContent();
  const selection = SelectionState.createEmpty(reference.blockKey);
  const referenceRange = mergeSelection(selection, reference.start, reference.end);
  const replacedTextContentState = Modifier.replaceText(contentState, range || referenceRange, substituteText);
  const cursorPosition = cursorPositionWithinText
    ? getSelectionState(selection, reference.start + cursorPositionWithinText, reference.start + cursorPositionWithinText)
    : editorState.getSelection();

  if (entityMetadata) {
    let nextContentState = replacedTextContentState;

    entityMetadata.forEach((metadata) => {
      const metadataType = getMetadataType(metadata);
      const entitySelection = mergeSelection(selection, reference.start + metadata.start, reference.start + metadata.end);
      const entityContentState = nextContentState.createEntity(metadataType, mutabilityMapper[metadataType], metadata.data);
      const lastEntityKey = entityContentState.getLastCreatedEntityKey();
      nextContentState = Modifier.applyEntity(entityContentState, entitySelection, lastEntityKey);
    });

    const updatedEditorState = EditorState.push(editorState, nextContentState, 'apply-entity');

    return EditorState.forceSelection(updatedEditorState, cursorPosition);
  }

  const updatedEditorState = EditorState.push(editorState, replacedTextContentState, 'insert-characters');

  return EditorState.forceSelection(updatedEditorState, cursorPosition);
};

export const isAdditionRequest = <T> (editorRequest: EditorRequest<T>): boolean => !!(editorRequest as AdditionRequest<T>).additionText;

export const isSubstituteRequest = <T> (editorRequest: EditorRequest<T>): boolean => !!(editorRequest as SubstituteRequest<T>).substituteText;

const fulfilEditorRequest = <T> (
  editorState: EditorState,
  editorRequest: EditorRequest<T>,
  mutabilityMapper: { [key in string]: EntityMutability },
): EditorState => {
  try {
    if (isAdditionRequest(editorRequest)) {
      return handleAdditionRequest(editorState, editorRequest as AdditionRequest<T>, mutabilityMapper);
    }

    if (isSubstituteRequest(editorRequest)) {
      return handleSubstituteRequest(editorState, editorRequest as SubstituteRequest<T>, mutabilityMapper);
    }

    return editorState;
  } catch (e) {
    return editorState;
  }
};

export default fulfilEditorRequest;
