import { Badge, Button, Divider } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PowerSearchQueryFilters } from 'mediascouting-core-ui-common';
import SearchTabs from './SearchTabs';

type DesktopActionsPropTypes = {
    onClean: (event) => void;
    onSearch: (event) => void;
    onOpenSettings: (event) => void;
    activeFilters: Array<Partial<PowerSearchQueryFilters>>;
}

function DesktopActions(props: DesktopActionsPropTypes): JSX.Element {
  const {
    onClean, onSearch, onOpenSettings, activeFilters,
  } = props;
  const { t } = useTranslation();

  return (
      <>
          <SearchTabs />
          <Divider orientation="vertical" flexItem />
          <Button
            size="small"
            disableFocusRipple
            startIcon={<ClearIcon />}
            onClick={onClean}
          >
              {t('layout.topBar.search.searchBar.actions.desktop.clear')}
          </Button>
          <Divider orientation="vertical" flexItem />
          <Badge badgeContent={activeFilters.length} color="primary" invisible={activeFilters.length < 1}>
              <Button
                size="small"
                disableFocusRipple
                startIcon={<TuneIcon />}
                onClick={onOpenSettings}
              >
                  {t('layout.topBar.search.searchBar.actions.desktop.query.settings')}
              </Button>
          </Badge>
          <Divider orientation="vertical" flexItem />
          <Button
            size="small"
            disableFocusRipple
            startIcon={<SearchIcon />}
            onClick={onSearch}
          >
              {t('layout.topBar.search.searchBar.actions.desktop.search')}
          </Button>
      </>
  );
}

export default memo(DesktopActions);
