/* eslint-disable no-restricted-syntax,no-await-in-loop,no-plusplus */
import {
  addSecondsToDate,
  COMMA,
  extractMultiValuedFilterOptions,
  extractSingleValuedFilterOption, getDateInUTCByAddingZ,
  PowerSearchSynchronousQueryFilters,
  QueryFilterOptionShallow,
  ReferencedQueryEntities,
  ReferencedQueryEntity, Shallow,
} from 'mediascouting-core-ui-common';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import moment from 'moment';
import {
  FilterOptionEntityData,
  ParsedSegment,
} from '../../../../../../../../types/layout/topBar/search/editor';
import { getContentBlockSelection, mergeSelection } from '../../../../../../../../utils/draft-js/SelectionUtils';
import ENTITY_MUTABILITY_MAPPER from '../../../../../../../../constants/search/editor/EntityMutability';
import {
  ASYNCHRONOUS_MULTIPLE_VALUE_FILTERS, DATE_FILTERS,
  EntityTypesEnum,
  MULTIPLE_VALUE_FILTERS, TIME_OF_DAY_FILTERS,
} from '../../../../../../../../constants/search/editor/EntityTypes';
import { createEntity, updateEntity } from '../../../../../../../../utils/draft-js/EntityUtils';
import PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER from '../../../../../../../../constants/search/editor/Mappers';
import { EntityToPowerSearchFilterTypeMapper } from '../../../../../../../../utils/common/PowerSearchUtils';

const EntityToReferencedQueryEntityMapper = {
  [EntityTypesEnum.TAGS]: 'TAGS',
  [EntityTypesEnum.SUBQUERIES]: 'SUB_QUERIES',
  [EntityTypesEnum.SOURCES]: 'SOURCES',
  [EntityTypesEnum.SOURCE_GROUPS]: 'SOURCE_GROUPS',
  [EntityTypesEnum.SOURCE_TYPES]: 'SOURCE_TYPES',
  [EntityTypesEnum.MARKET_TAGS]: 'MARKETS',
  [EntityTypesEnum.MARKET_GROUPS]: 'MARKET_GROUPS',
  [EntityTypesEnum.OWNER_GROUPS]: 'OWNER_GROUPS',
  [EntityTypesEnum.AUTHORS]: 'AUTHORS',
  [EntityTypesEnum.LANGUAGE]: 'LANGUAGES',
  [EntityTypesEnum.GEOLOCATION_TAGS]: 'GEOLOCATION_TAGS',
  [EntityTypesEnum.PEOPLE]: 'PEOPLE_TAGS',
  [EntityTypesEnum.ORGANIZATIONS]: 'GROUP_TAGS',
  [EntityTypesEnum.PROGRAMS]: 'PROGRAMS',
};

const formatDate = (date: Date | string): string => moment(date).format('MMMM Do YYYY, h:mm:ss a');

const formatDateInTimeOfDay = (date: Date | string): string => moment(date).format('h:mm:ss a');

const applyDate = (
  editorState: EditorState, entitySelection: SelectionState,
  filterType: EntityTypesEnum, date: Shallow,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const blockKey = entitySelection.getAnchorKey();
  const startOffset = entitySelection.getStartOffset();
  const endOffset = entitySelection.getEndOffset();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const entityText = contentBlock.getText().slice(startOffset, endOffset);
  const filterEntityData = { length: endOffset - startOffset };
  const filterMutability = ENTITY_MUTABILITY_MAPPER[filterType];
  const filterRevisedSelection = mergeSelection(entitySelection, startOffset, startOffset + `${filterType}`.length);

  const {
    updatedEditorState: updatedEditorStateWithFilterEntity,
    entityKey: reference,
  } = createEntity(editorState, filterRevisedSelection, filterMutability, filterType, filterEntityData);

  let mutableEditorState = updateEntity(updatedEditorStateWithFilterEntity, reference, {
    reference,
  });

  let index = `${filterType}:`.length;

  index = entityText.indexOf(date.name, index);

  if (index !== -1) {
    const optionStartsAt = startOffset + index;
    const optionEndsAt = startOffset + index + date.name.length;
    const optionSelection = mergeSelection(entitySelection, optionStartsAt, optionEndsAt);
    const filterOptionMutability = ENTITY_MUTABILITY_MAPPER[EntityTypesEnum.FILTER_OPTION];
    const data: FilterOptionEntityData = {
      filterType,
      parentReference: reference,
      option: date,
    };

    const { updatedEditorState } = createEntity(
      mutableEditorState, optionSelection, filterOptionMutability, EntityTypesEnum.FILTER_OPTION, data,
    );

    mutableEditorState = updatedEditorState;
  }

  return mutableEditorState;
};

const applyFilterOptions = (
  editorState: EditorState,
  entitySelection: SelectionState,
  filterType: EntityTypesEnum,
  verifiedOptions: Array<QueryFilterOptionShallow>,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const blockKey = entitySelection.getAnchorKey();
  const startOffset = entitySelection.getStartOffset();
  const endOffset = entitySelection.getEndOffset();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const entityText = contentBlock.getText().slice(startOffset, endOffset);
  const contentBlockText = contentBlock.getText();
  let index = `${filterType}:`.length;

  const filterEntityData = {
    length: endOffset - startOffset,
  };
  const filterMutability = ENTITY_MUTABILITY_MAPPER[filterType];
  const filterRevisedSelection = mergeSelection(entitySelection, startOffset, startOffset + `${filterType}`.length);
  const { updatedEditorState: updatedEditorStateWithFilterEntity, entityKey: reference } = createEntity(
    editorState, filterRevisedSelection, filterMutability, filterType, filterEntityData,
  );

  let mutableEditorState = updateEntity(updatedEditorStateWithFilterEntity, reference, { reference });

  verifiedOptions.forEach((option) => {
    const trimmedEdgesOption = option.name.trimStart().trimEnd();

    index = entityText.indexOf(trimmedEdgesOption, index);

    if (index !== -1) {
      const optionStartsAt = startOffset + index;
      const optionEndsAt = startOffset + index + trimmedEdgesOption.length;
      const foundTextLength = contentBlockText.slice(optionStartsAt, optionEndsAt).length;
      index += foundTextLength;

      const optionSelection = mergeSelection(entitySelection, optionStartsAt, optionEndsAt);
      const filterOptionMutability = ENTITY_MUTABILITY_MAPPER[EntityTypesEnum.FILTER_OPTION];
      const data: FilterOptionEntityData = {
        filterType,
        parentReference: reference,
        option,
      };

      const { updatedEditorState } = createEntity(
        mutableEditorState, optionSelection, filterOptionMutability, EntityTypesEnum.FILTER_OPTION, data,
      );

      mutableEditorState = updatedEditorState;
    }
  });

  return mutableEditorState;
};

const retrieveMultiValuedFilterOptions = (
  filterText: string,
  type: EntityTypesEnum,
  references: ReferencedQueryEntities,
  powerSearchParams: PowerSearchSynchronousQueryFilters,
  onFetchMissingData: (type: EntityTypesEnum, id: number) => Promise<QueryFilterOptionShallow | undefined>,
): Promise<Array<QueryFilterOptionShallow>> => {
  const retrievedData: Array<Promise<QueryFilterOptionShallow | undefined>> = [];
  const extractedOptions = extractMultiValuedFilterOptions(filterText);
  extractedOptions.forEach((option) => {
    const filterOptionIdInStringFormat = option.trimStart().trimEnd();
    const filterOptionId = parseInt(filterOptionIdInStringFormat, 10);

    if (ASYNCHRONOUS_MULTIPLE_VALUE_FILTERS.includes(type)) {
      const reference = EntityToReferencedQueryEntityMapper[type];
      const optionalArray = references[reference] as Array<ReferencedQueryEntity> | undefined;

      if (optionalArray) {
        const found = optionalArray.find((value) => value.id === filterOptionId);

        if (found) {
          retrievedData.push(Promise.resolve({
            id: found.id,
            name: found.name,
          }));
        } else {
          retrievedData.push(onFetchMissingData(type, filterOptionId));
        }
      } else {
        retrievedData.push(onFetchMissingData(type, filterOptionId));
      }
    } else {
      const powerSearchType = EntityToPowerSearchFilterTypeMapper[type];
      const powerSearchFilterOptions = powerSearchParams[powerSearchType] as Array<QueryFilterOptionShallow>
          | undefined;

      if (powerSearchFilterOptions) {
        const found = powerSearchFilterOptions.find(
          (powerSearchFilterOption) => powerSearchFilterOption.id === filterOptionId,
        );

        if (found) {
          retrievedData.push(Promise.resolve(found));
        }
      }
    }
  });

  const retrievedOptions: Array<QueryFilterOptionShallow> = [];

  return Promise.all(retrievedData)
    .then((optionalFilterOptions) => {
      optionalFilterOptions.forEach((option) => {
        if (option) {
          retrievedOptions.push(option);
        }
      });

      return retrievedOptions;
    }).catch(() => []);
};

const formatDates = (
  filterText: string, filterType: EntityTypesEnum, editorState: EditorState, entitySelection: SelectionState,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const unixTimestamp = extractSingleValuedFilterOption(filterText);
  const optionalDate = new Date(parseInt(unixTimestamp, 10));
  const millisecondsSinceTheUnixEpoch = optionalDate.getTime();

  if (!Number.isNaN(millisecondsSinceTheUnixEpoch)) {
    const datePreview = formatDate(optionalDate);
    const formattedDate = `${filterType}: (${datePreview})`;
    const replacedTextContentState = Modifier.replaceText(contentState, entitySelection, formattedDate);
    const replacedTextEditorState = EditorState.push(editorState, replacedTextContentState, 'insert-characters');
    const updatedEntitySelection = mergeSelection(
      entitySelection, entitySelection.getStartOffset(), entitySelection.getStartOffset() + formattedDate.length,
    );

    return applyDate(replacedTextEditorState, updatedEntitySelection, filterType, {
      id: millisecondsSinceTheUnixEpoch,
      name: datePreview,
    });
  }

  const emptyDate = `${filterType}: ()`;
  const replacedTextContentState = Modifier.replaceText(contentState, entitySelection, emptyDate);

  return EditorState.push(editorState, replacedTextContentState, 'insert-characters');
};

const formatTimesOfDay = (
  filterText: string, filterType: EntityTypesEnum, editorState: EditorState, entitySelection: SelectionState,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const milliseconds = extractSingleValuedFilterOption(filterText);
  const currentDate = new Date();
  const initDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const parsedMilliseconds = parseInt(milliseconds, 10);
  const addedSecondsToDate = addSecondsToDate(initDate, parsedMilliseconds / 1000);
  const millisecondsSinceTheUnixEpoch = addedSecondsToDate.getTime();
  const optionalDate = getDateInUTCByAddingZ(addedSecondsToDate.toString());

  if (!Number.isNaN(millisecondsSinceTheUnixEpoch)) {
    const datePreview = formatDateInTimeOfDay(optionalDate);
    const formattedDate = `${filterType}: (${datePreview})`;
    const replacedTextContentState = Modifier.replaceText(contentState, entitySelection, formattedDate);
    const replacedTextEditorState = EditorState.push(editorState, replacedTextContentState, 'insert-characters');
    const updatedEntitySelection = mergeSelection(
      entitySelection, entitySelection.getStartOffset(), entitySelection.getStartOffset() + formattedDate.length,
    );

    return applyDate(replacedTextEditorState, updatedEntitySelection, filterType, {
      id: parsedMilliseconds,
      name: datePreview,
    });
  }

  const emptyDate = `${filterType}: ()`;
  const replacedTextContentState = Modifier.replaceText(contentState, entitySelection, emptyDate);

  return EditorState.push(editorState, replacedTextContentState, 'insert-characters');
};

const formatMultipleFilterOptions = (
  filterText: string, filterType: EntityTypesEnum, editorState: EditorState,
  entitySelection: SelectionState, filterOptions: Array<QueryFilterOptionShallow>,
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const formattedOptions = `${filterType}: (${filterOptions.map((option) => option.name).join(COMMA)})`;
  const replacedTextContentState = Modifier.replaceText(contentState, entitySelection, formattedOptions);
  const updatedEntitySelection = mergeSelection(
    entitySelection, entitySelection.getStartOffset(), entitySelection.getStartOffset() + formattedOptions.length,
  );

  const replacedTextEditorState = EditorState.push(editorState, replacedTextContentState, 'insert-characters');

  return applyFilterOptions(replacedTextEditorState, updatedEntitySelection, filterType, filterOptions);
};

const decodeFilters = async (
  editorState: EditorState,
  parsedSegments: Array<ParsedSegment>,
  references: ReferencedQueryEntities,
  powerSearchParams: PowerSearchSynchronousQueryFilters,
  onFetchMissingData: (type: EntityTypesEnum, id: number) => Promise<QueryFilterOptionShallow | undefined>,
): Promise<EditorState> => {
  try {
    let updatedEditorState = editorState;
    for (let index = parsedSegments.length - 1; index >= 0; index--) {
      const { type, start, end } = parsedSegments[index];

      if (end) {
        const contentState = updatedEditorState.getCurrentContent();
        const filterText = contentState.getPlainText().slice(start, end + 1);
        const entitySelection = getContentBlockSelection(contentState, start, end + 1);
        const mappedType = PARSED_SEGMENT_TYPE_TO_ENTITY_TYPE_MAPPER[type] as EntityTypesEnum;

        if (entitySelection) {
          if (MULTIPLE_VALUE_FILTERS.includes(mappedType)) {
            const filterOptions = await retrieveMultiValuedFilterOptions(
              filterText, mappedType, references, powerSearchParams, onFetchMissingData,
            );

            updatedEditorState = formatMultipleFilterOptions(
              filterText, mappedType, updatedEditorState, entitySelection, filterOptions,
            );
          } else if (DATE_FILTERS.includes(mappedType)) {
            updatedEditorState = formatDates(filterText, mappedType, updatedEditorState, entitySelection);
          } else if (TIME_OF_DAY_FILTERS.includes(mappedType)) {
            updatedEditorState = formatTimesOfDay(filterText, mappedType, updatedEditorState, entitySelection);
          }
        }
      }
    }

    return EditorState.moveFocusToEnd(updatedEditorState);
  } catch (e) {
    return editorState;
  }
};

export default decodeFilters;
