import { MultivaluedPowerSearchQueryFilters, PowerSearchFilterTypes } from 'mediascouting-core-ui-common';
import { CacheActions } from '../../constants/actions/creators/CacheActions';
import { ADD_TO_CACHE, CacheUpdate, SET_CACHE } from '../../constants/actions/types/CacheActionTypes';

export interface CacheReducer extends MultivaluedPowerSearchQueryFilters {

}

const INIT_STATE: CacheReducer = {
  [PowerSearchFilterTypes.DAYS]: [],
  [PowerSearchFilterTypes.TIME_SECTIONS]: [],
  [PowerSearchFilterTypes.SENTIMENTS]: [],
  [PowerSearchFilterTypes.LANGUAGES]: [],
  [PowerSearchFilterTypes.ORIGIN]: [],
  [PowerSearchFilterTypes.SOURCE_TYPES]: [],
  [PowerSearchFilterTypes.TAGS]: [],
  [PowerSearchFilterTypes.ORGANIZATIONS]: [],
  [PowerSearchFilterTypes.GEOLOCATION_TAGS]: [],
  [PowerSearchFilterTypes.PEOPLE]: [],
  [PowerSearchFilterTypes.SUBQUERIES]: [],
  [PowerSearchFilterTypes.SOURCES]: [],
  [PowerSearchFilterTypes.SOURCE_GROUPS]: [],
  [PowerSearchFilterTypes.MARKETS]: [],
  [PowerSearchFilterTypes.MARKET_GROUPS]: [],
  [PowerSearchFilterTypes.STORY_DURATION]: [],
  [PowerSearchFilterTypes.AUTHORS]: [],
};

const getUpdatedCache = (action: CacheActions, updatePayload: CacheUpdate, state: CacheReducer): CacheReducer => {
  try {
    const options = state[updatePayload.type];
    const found = options?.find((searchableOption) => searchableOption?.id === updatePayload?.data?.id);

    if (options && !found) {
      const copiedStateFilterOptions = [...options, updatePayload.data];

      return {
        ...state,
        [updatePayload.type]: copiedStateFilterOptions,
      };
    }
  } catch {
    return {
      ...state,
    };
  }

  return {
    ...state,
  };
};

export default (state = INIT_STATE, action: CacheActions): CacheReducer => {
  switch (action.type) {
    case SET_CACHE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ADD_TO_CACHE: {
      return getUpdatedCache(action, action.payload, state);
    }
    default:
      return state;
  }
};
