import React, { memo } from 'react';
import {
  Box, Divider, makeStyles, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
}));

function Header(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
      <Box>
          <Box p={2}>
              <Typography variant="h5" color="textPrimary">
                  {t('layout.topBar.search.searchBar.notifications')}
              </Typography>
          </Box>
          <Divider light />
      </Box>
  );
}

export default memo(Header);
