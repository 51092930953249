import {
  createInitialPowerSearchQueryFiltersState,
  EMPTY_STRING,
  PowerSearchFilterTypes,
  PowerSearchQueryFilters,
  Query,
} from 'mediascouting-core-ui-common';
import { QuerySearchSpaceActions } from '../../constants/actions/creators/QuerySearchSpaceActions';
import {
  RESET_QUERY_PARAMS,
  SET_DAYS,
  SET_END_TIME,
  SET_GEOLOCATION_TAGS,
  SET_LANGUAGE,
  SET_MARKET,
  SET_ORGANIZATIONS,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_PEOPLE,
  SET_PREVIEW_NEW_ITEMS,
  SET_QUERY_PARAMS,
  SET_QUERY_TEXT,
  SET_SENTIMENT,
  SET_SOURCE_TYPES,
  SET_SOURCES,
  SET_START_TIME,
  SET_STORY_DURATION,
  SET_SUB_QUERIES,
  SET_TAGS,
  SET_TIME_SECTION,
  SET_TITLE,
} from '../../constants/actions/types/QuerySearchSpaceActionTypes';
import { getDefaultSort } from '../../constants/SortOptions';
import { SortOption } from '../../types/query/results/Sort';
import { ReduxFacet } from '../../types/query/results/Facets';
import DEFAULT_SEARCH_PAGE_NUMBER from '../../constants/search/QuerySearchSpace';
import { DateSlugOption } from '../../types/query/results/DateSlug';
import { getDefaultDateSlug } from '../../constants/DateSlugOptions';

export interface QuerySearchSpaceReducer extends PowerSearchQueryFilters {
  query?: Query;
  searchText: string;
  page: number;
  pageSize: number;
  previewNewItems: boolean;
  facets: Array<ReduxFacet>;
  sort: SortOption;
  dateSlug: DateSlugOption;
}

export const INIT_QUERY_SEARCH_SPACE_REDUCER_STATE: QuerySearchSpaceReducer = {
  searchText: EMPTY_STRING,
  facets: [],
  page: 1,
  pageSize: DEFAULT_SEARCH_PAGE_NUMBER,
  sort: getDefaultSort(),
  dateSlug: getDefaultDateSlug(),
  previewNewItems: false,
  ...createInitialPowerSearchQueryFiltersState(),
};

export default (
  state = INIT_QUERY_SEARCH_SPACE_REDUCER_STATE, action: QuerySearchSpaceActions,
): QuerySearchSpaceReducer => {
  switch (action.type) {
    case SET_QUERY_PARAMS: {
      return {
        ...state,
        ...action.queryParams,
      };
    }
    case RESET_QUERY_PARAMS: {
      return {
        ...state,
        ...INIT_QUERY_SEARCH_SPACE_REDUCER_STATE,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }
    case SET_QUERY_TEXT: {
      return {
        ...state,
        searchText: action.queryText,
      };
    }
    case SET_PREVIEW_NEW_ITEMS: {
      return {
        ...state,
        previewNewItems: action.previewNewItems,
      };
    }
    case SET_START_TIME: {
      return {
        ...state,
        [PowerSearchFilterTypes.START]: action.startTime,
      };
    }
    case SET_END_TIME: {
      return {
        ...state,
        [PowerSearchFilterTypes.END]: action.endTime,
      };
    }
    case SET_TITLE: {
      return {
        ...state,
        [PowerSearchFilterTypes.TITLE]: action.title,
      };
    }
    case SET_SUB_QUERIES: {
      return {
        ...state,
        [PowerSearchFilterTypes.SUBQUERIES]: action.subQueries,
      };
    }
    case SET_TAGS: {
      return {
        ...state,
        [PowerSearchFilterTypes.TAGS]: action.tags,
      };
    }
    case SET_SOURCE_TYPES: {
      return {
        ...state,
        [PowerSearchFilterTypes.SOURCE_TYPES]: action.sourceTypes,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        [PowerSearchFilterTypes.LANGUAGES]: action.language,
      };
    }
    case SET_SOURCES: {
      return {
        ...state,
        [PowerSearchFilterTypes.SOURCES]: action.sources,
      };
    }
    case SET_GEOLOCATION_TAGS: {
      return {
        ...state,
        [PowerSearchFilterTypes.GEOLOCATION_TAGS]: action.geolocationTags,
      };
    }
    case SET_MARKET: {
      return {
        ...state,
        [PowerSearchFilterTypes.MARKETS]: action.market,
      };
    }
    case SET_SENTIMENT: {
      return {
        ...state,
        [PowerSearchFilterTypes.SENTIMENTS]: action.sentiment,
      };
    }
    case SET_DAYS: {
      return {
        ...state,
        [PowerSearchFilterTypes.DAYS]: action.days,
      };
    }
    case SET_TIME_SECTION: {
      return {
        ...state,
        [PowerSearchFilterTypes.TIME_SECTIONS]: action.timeSections,
      };
    }
    case SET_PEOPLE: {
      return {
        ...state,
        [PowerSearchFilterTypes.PEOPLE]: action.people,
      };
    }
    case SET_ORGANIZATIONS: {
      return {
        ...state,
        [PowerSearchFilterTypes.ORGANIZATIONS]: action.organizations,
      };
    }
    case SET_STORY_DURATION: {
      return {
        ...state,
        [PowerSearchFilterTypes.STORY_DURATION]: action.storyDuration,
      };
    }
    default:
      return state;
  }
};
