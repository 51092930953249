import React, { memo } from 'react';
import {
  Box, IconButton, SvgIcon, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

type HeaderPropTypes = {
    onClose: () => void;
}

function Header(props: HeaderPropTypes): JSX.Element {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" color="textPrimary">
              {t('layout.topBar.search.searchBar.query.settings.header')}
          </Typography>
          <IconButton onClick={onClose}>
              <SvgIcon fontSize="small">
                  <CloseIcon />
              </SvgIcon>
          </IconButton>
      </Box>
  );
}

export default memo(Header);
