import React, { memo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  any,
  AvatarBadge,
  getInitials,
  hasPermission,
  NOT_FOUND,
  NotFound,
  User,
  UserPermission,
} from 'mediascouting-core-ui-common';
import { deepPurple } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../redux/reducers';
import { AppDispatch } from '../../../redux/store';
import { logout } from '../../../remote/Auth';
import Visible from '../../../components/auth/Visible';
import stopImpersonating from '../../../remote/Impersonate';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  popover: {
    width: 200,
  },
  name: {
    width: 'max-content',
  },
}));

function Account(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef(null);
  const history = useHistory();
  const user = useSelector((state: ReduxState) => state.auth.user);
  const [isOpen, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleAlertsClick = (): void => {
    const urlParams = new URLSearchParams(history.location.search);

    history.push({
      pathname: '/portal/alerts',
      search: urlParams.toString(),
    });

    handleClose();
  };

  const handleMenuNavigation = (path: string) => (): void => {
    const urlParams = new URLSearchParams(history.location.search);

    history.push({
      pathname: path,
      search: urlParams.toString(),
    });

    handleClose();
  };

  const handleStopImpersonation = () => (): void => {
    dispatch(stopImpersonating())
      .finally(() => {
        window.location.reload();
      });
  };

  const getAvatar = (loggedInUser: User): JSX.Element => {
    if (loggedInUser?.impersonatedBy) {
      return (
          <>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={(
                    <Tooltip title={loggedInUser?.impersonatedBy?.fullName}>
                        <AvatarBadge
                          alt={t('layout.topBar.search.searchBar.avatar.original.user')}
                          className={classes.avatar}
                        >
                            <Typography variant="subtitle2">
                                {getInitials(loggedInUser?.impersonatedBy?.fullName)}
                            </Typography>
                        </AvatarBadge>
                    </Tooltip>
                )}
              >
                  <Tooltip title={loggedInUser?.fullName}>
                      <Avatar
                        alt={t('layout.topBar.search.searchBar.avatar.impersonated.user')}
                        className={classes.avatar}
                      >
                          <Typography variant="subtitle2">
                              {getInitials(loggedInUser.fullName)}
                          </Typography>
                      </Avatar>
                  </Tooltip>
              </Badge>
              <Hidden smDown>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.name}
                  >
                      {`${loggedInUser.fullName}`}
                  </Typography>
              </Hidden>
          </>
      );
    }

    return (
        <>
            <Tooltip title={loggedInUser?.fullName}>
                <Avatar alt={t('layout.topBar.search.searchBar.avatar.user')} className={classes.avatar}>
                    <Typography variant="subtitle2">
                        {getInitials(loggedInUser.fullName)}
                    </Typography>
                </Avatar>
            </Tooltip>
            <Hidden smDown>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.name}
                >
                    {`${loggedInUser.fullName}`}
                </Typography>
            </Hidden>
        </>

    );
  };

  const getStopImpersonatingMenuItem = (): JSX.Element | NotFound => {
    if (user?.impersonatedBy) {
      return (
          <MenuItem onClick={handleStopImpersonation()}>
              {t('layout.topBar.search.searchBar.avatar.impersonated.stop')}
          </MenuItem>
      );
    }

    return NOT_FOUND;
  };

  return (
      <>
          {user && (
              <div ref={ref}>
                  <Box
                    display="flex"
                    alignItems="center"
                    component={ButtonBase}
                    onClick={handleOpen}
                  >
                      {getAvatar(user)}
                  </Box>
              </div>
          )}
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
              <MenuItem
                component={RouterLink}
                to="/account/general"
                onClick={handleClose}
              >
                  {t('layout.topBar.search.searchBar.avatar.account')}
              </MenuItem>
              <Visible when={{
                userCondition: any(
                  hasPermission(UserPermission.OWN_ALERTS),
                ),
              }}
              >
                  <MenuItem onClick={handleAlertsClick}>
                      {t('layout.topBar.search.searchBar.avatar.alerts')}
                  </MenuItem>
              </Visible>
              <Visible when={{
                userCondition: any(
                  hasPermission(UserPermission.READ_QUERY_SHARES),
                  hasPermission(UserPermission.READ_TAG_SHARES),
                ),
              }}
              >
                  <MenuItem onClick={handleMenuNavigation('/portal/shares')}>
                      {t('layout.topBar.search.searchBar.avatar.shares')}
                  </MenuItem>
              </Visible>
              <Visible when={{
                userCondition: any(
                  hasPermission(UserPermission.INITIATE_DATA_EXPORT_TASK),
                ),
              }}
              >
                  <MenuItem onClick={handleMenuNavigation('/portal/exports')}>
                      {t('layout.topBar.search.searchBar.avatar.exports')}
                  </MenuItem>
              </Visible>
              <Visible
                when={{ userCondition: hasPermission(UserPermission.CREATE_PERCOLATABLES) }}
              >
                  <MenuItem onClick={handleMenuNavigation('/portal/stories/log')}>
                      {t('layout.topBar.search.searchBar.avatar.logging')}
                  </MenuItem>
              </Visible>
              <Visible
                when={{ userCondition: hasPermission(UserPermission.INITIATE_MONTAGE) }}
              >
                  <MenuItem onClick={handleMenuNavigation('/portal/montages')}>
                      {t('layout.topBar.search.searchBar.avatar.mediaArchive')}
                  </MenuItem>
              </Visible>
              <MenuItem onClick={handleMenuNavigation('/portal/templates')}>
                  {t('layout.topBar.search.searchBar.avatar.templates')}
              </MenuItem>
              {getStopImpersonatingMenuItem()}
              <MenuItem onClick={(): Promise<void> => dispatch(logout())}>
                  {t('layout.topBar.search.searchBar.avatar.logout')}
              </MenuItem>
          </Menu>
      </>
  );
}

export default memo(Account);
