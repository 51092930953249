import React from 'react';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import { EMPTY_STRING, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import { TFunction } from 'i18next';
import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';
import NEO_SEARCH_FILTER_ENTITY_TYPE_ICONS from '../consts/NeoSearchSuggestionIcons';
import { AdditionRequest, EditorRequest, SubstituteRequest } from '../../../../../../types/layout/topBar/search/editor';
import { Suggestion } from '../../../../../../types/layout/topBar/search/suggestion';
import NEO_SEARCH_ENTITY_TYPES_ARRAY from '../consts/NeoSearchEntityTypes';
import NeoSearchTypeSuggestion from '../components/NeoSearchSuggestionService/NeoSearchTypeSuggestion';
import {
  isAdditionRequest,
  isSubstituteRequest,
} from '../../../../../features/search/IntelligentQuerySearchBar/utils/editor/draft-js/EditorRequestUtils';
import NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER from '../../../constants/NeoSearchEntityTypeTranslationMapper';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';

export const createKey = (request: EditorRequest<NeoSearchEntityTypeEnum>): string => {
  if (isAdditionRequest(request)) {
    const additionRequest = request as AdditionRequest<NeoSearchEntityTypeEnum>;

    return additionRequest.additionText;
  }

  if (isSubstituteRequest(request)) {
    const substituteRequest = request as SubstituteRequest<NeoSearchEntityTypeEnum>;

    return substituteRequest.substituteText;
  }

  return EMPTY_STRING;
};

export const findSuggestionRelatedToText = (
  filteringSuggestions: Array<Suggestion<NeoSearchEntityTypeEnum>>,
  text: string,
): Suggestion<NeoSearchEntityTypeEnum> | NotFound => filteringSuggestions.find((suggestion) => {
  if (isAdditionRequest(suggestion.request)) {
    const additionRequest = suggestion.request as AdditionRequest<NeoSearchEntityTypeEnum>;
    const additionText = additionRequest.additionText.toLowerCase();

    return additionText.startsWith(text.toLowerCase()) && !text.toLowerCase().includes(additionText);
  }

  return false;
});

export const getNeoSearchTypeIcon = (
  type?: NeoSearchEntityTypeEnum,
  size?: 'inherit' | 'default' | 'small' | 'large',
  className?: string,
): JSX.Element => {
  if (type) {
    const Icon = NEO_SEARCH_FILTER_ENTITY_TYPE_ICONS[type] || BrokenImageOutlinedIcon;

    return <Icon fontSize={size} className={className} />;
  }

  return <BrokenImageOutlinedIcon />;
};

export const createSuggestion = (
  preview: React.ReactNode,
  request: EditorRequest<NeoSearchEntityTypeEnum>,
): Suggestion<NeoSearchEntityTypeEnum> => ({
  preview,
  request,
});

export const createNeoSearchSuggestions = (
  configuration: NeoBarConfiguration,
  tFunction: TFunction,
): Array<Suggestion<NeoSearchEntityTypeEnum>> => {
  const restrictedEntityTypes = NEO_SEARCH_ENTITY_TYPES_ARRAY.filter(
    (type) => configuration.types.includes(type),
  );

  return restrictedEntityTypes.map((filterableOption) => {
    const i18key = NEO_SEARCH_ENTITY_TYPE_TRANSLATION_MAPPER[filterableOption];
    const translated = tFunction(i18key);

    const preview = (
        <NeoSearchTypeSuggestion
          type={filterableOption}
        />
    );
    const request: AdditionRequest<NeoSearchEntityTypeEnum> = {
      additionText: `${translated} `,
      entityMetadata: [
        {
          type: filterableOption,
          start: 0,
          end: `${translated}`.length,
          data: {
            reference: Date.now() + translated,
            length: `${translated}`.length,
          },
        },
      ],
      reference: NOT_FOUND,
      cursorPositionWithinText: `${translated} `.length,
    };

    return createSuggestion(preview, request);
  });
};

export const rewriteAdditionSuggestionToAlsoReplacePartialText = (
  foundAddition: AdditionRequest<NeoSearchEntityTypeEnum>,
  found: Suggestion<NeoSearchEntityTypeEnum>,
  lastSplit: string,
): Suggestion<NeoSearchEntityTypeEnum> => {
  const remainingText = foundAddition.additionText.toLowerCase()
    .replace(lastSplit.toLowerCase(), EMPTY_STRING);

  const additionRequest: AdditionRequest<NeoSearchEntityTypeEnum> = {
    additionText: `${remainingText} `,
    cursorPositionWithinText: `${remainingText} `.length,
    entityMetadata: [
      {
        type: foundAddition?.entityMetadata
          ? foundAddition?.entityMetadata[0]?.type
          : NeoSearchEntityTypeEnum.GENERAL,
        start: lastSplit.length * -1,
        end: remainingText.length,
        data: {
          reference: Date.now(),
          length: lastSplit.length + remainingText.length,
        },
      },
    ],
  };

  return {
    request: additionRequest,
    preview: found.preview,
  };
};
