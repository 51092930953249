import React, { useCallback } from 'react';
import {
  AppBar, Box, Hidden, makeStyles, Theme, Toolbar,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { NotFound } from 'mediascouting-core-ui-common';
import Logo from '../../../components/common/Logo';
import { QuickSettingsConfiguration } from '../../PortalLayout/TopBar/Settings/ViewSettings';
import PLATFORM_CONFIGURATION from '../../../configuration';
import Notifications from '../../PortalLayout/TopBar/Notifications';
import Settings from '../../PortalLayout/TopBar/Settings';
import Account from '../../PortalLayout/TopBar/Account';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100%',
  },
  logo: {
    minWidth: '185px',
  },
}));

interface TopBarPropTypes {
    logo: string | NotFound;
}

function TopBar(props: TopBarPropTypes): JSX.Element {
  const {
    logo,
  } = props;
  const classes = useStyles();
  const disabledViews = PLATFORM_CONFIGURATION.schema.stories.views.disabled;

  const getConfiguration = useCallback((): QuickSettingsConfiguration => ({
    disabledViews,
  }), [disabledViews]);

  return (
      <AppBar
        position="fixed"
        className={clsx(classes.root)}
      >
          <Toolbar>
              <Hidden mdDown>
                  <RouterLink to="/portal/search" className={classes.logo}>
                      <Logo logo={logo} />
                  </RouterLink>
              </Hidden>
              <Hidden mdDown>
                  <Box ml={5} />
              </Hidden>
              <Hidden mdDown>
                  <Box width="100%" />
              </Hidden>
              <Box ml={2} flexGrow={1} />
              <Notifications />
              <Settings configuration={getConfiguration()} />
              <Box ml={2}>
                  <Account />
              </Box>
          </Toolbar>
      </AppBar>
  );
}

export default TopBar;
