import {
  ADD_TO_PLAYLIST, REMOVE_FROM_PLAYLIST, RESET_PLAYLIST, MOVE_PLAYLIST_ITEM,
} from '../types/MontageActionTypes';
import { PlaylistItem } from '../../../types/layout/topBar/Montage';

export const addToPlaylist = (playlistItem: PlaylistItem) => ({
  type: ADD_TO_PLAYLIST,
  payload: playlistItem,
} as const);

export const removeFromPlaylist = (playlistItem: PlaylistItem) => ({
  type: REMOVE_FROM_PLAYLIST,
  payload: playlistItem,
} as const);

export const resetPlaylist = () => ({
  type: RESET_PLAYLIST,
} as const);

export const movePlaylistItem = (payload: {sourceIndex: number; destinationIndex: number}) => ({
  type: MOVE_PLAYLIST_ITEM,
  payload,
} as const);

export type MontageActions =
    | ReturnType<typeof addToPlaylist>
    | ReturnType<typeof removeFromPlaylist>
    | ReturnType<typeof resetPlaylist>
    | ReturnType<typeof movePlaylistItem>
