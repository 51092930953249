import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { memo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PortalPathMatch } from '../../../../../types/PortalTabs';
import PLATFORM_CONFIGURATION from '../../../../../configuration';

const useTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.type === 'light' ? '#eee' : theme.palette.divider,
    borderRadius: 10,
    minHeight: 37,
  },
  flexContainer: {
    display: 'inline-flex',
    position: 'relative',
    zIndex: 1,
  },
  indicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: 'auto',
    background: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 4,
      right: 4,
      bottom: 0,
      borderRadius: 8,
      backgroundColor: theme.palette.type === 'light' ? '#fff' : theme.palette.action.selected,
      boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
    },
  },
}));

const useTabItemStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      opacity: 1,
    },
    minHeight: 37,
    minWidth: 96,
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
    },
    fontSize: '0.775rem',
  },
  wrapper: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
}));

function SearchTabs(): JSX.Element {
  const tabsStyles = useTabsStyles();
  const tabItemStyles = useTabItemStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch<PortalPathMatch>({ path: '/portal/:tab' });
  const statisticsConfiguration = PLATFORM_CONFIGURATION.schema.statistics;

  const getCurrentTab = (): string => {
    if (match && (match.params.tab === 'search' || match.params.tab === 'statistics')) {
      return match.params.tab;
    }

    return 'search';
  };

  const handleTab = (event, value): void => {
    event.stopPropagation();

    const urlParams = new URLSearchParams(history.location.search);

    history.push({
      pathname: `/portal/${value === 'statistics' ? `statistics/${statisticsConfiguration.defaultTab}` : value}`,
      search: urlParams.toString(),
    });
  };

  return (
      <Tabs
        classes={tabsStyles}
        value={getCurrentTab()}
        onChange={handleTab}
      >
          <Tab classes={tabItemStyles} disableRipple label={t('search.bar.tab.title')} value="search" />
          <Tab classes={tabItemStyles} disableRipple label={t('search.bar.tab.statistics')} value="statistics" />
      </Tabs>
  );
}

export default memo(SearchTabs);
