import React from 'react';
import { AdditionRequest, EntityRange } from '../../../../../../../types/layout/topBar/search/editor';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { createMatcherAdditionRequest, createSuggestion } from '../SuggestionServiceUtils';
import ConditionMatcher from '../../../QueryEditor/ConditionMatcher';
import { EntityTypesEnum } from '../../../../../../../constants/search/editor/EntityTypes';
import Filter from '../../../QueryEditor/Filter';

const MAX_VIEWING_LENGTH = 35;

const createPreview = (type: EntityTypesEnum, text: string, matcher: string): JSX.Element => (
    <span>
        <Filter type={type}>
            {text}
        </Filter>
        {' '}
        <ConditionMatcher>{matcher}</ConditionMatcher>
    </span>
);

const shortenPreviewText = (text: string, type: EntityTypesEnum): string => {
  if (text.length > MAX_VIEWING_LENGTH) {
    return `${type}: (...)`;
  }

  return text;
};

const createORConditionMatcherSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const entityType = entityRange.entity.getType() as EntityTypesEnum;
  const shortText = shortenPreviewText(entityRange.text, entityType);
  const preview = createPreview(entityType, shortText, EntityTypesEnum.OR_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(EntityTypesEnum.OR_CONDITION_MATCHER, entityRange);

  return createSuggestion(preview, request);
};

const createANDConditionMatcherSuggestion = (entityRange: EntityRange): Suggestion<EntityTypesEnum> => {
  const entityType = entityRange.entity.getType() as EntityTypesEnum;
  const shortText = shortenPreviewText(entityRange.text, entityType);
  const preview = createPreview(entityType, shortText, EntityTypesEnum.AND_CONDITION_MATCHER);
  const request: AdditionRequest<EntityTypesEnum> = createMatcherAdditionRequest(EntityTypesEnum.AND_CONDITION_MATCHER, entityRange);

  return createSuggestion(preview, request);
};

const createAdvancedFilterSuggestions = (entityRange: EntityRange): Array<Suggestion<EntityTypesEnum>> => {
  const suggestions: Array<Suggestion<EntityTypesEnum>> = [];
  const ANDSuggestion = createANDConditionMatcherSuggestion(entityRange);
  const ORSuggestion = createORConditionMatcherSuggestion(entityRange);

  suggestions.push(ANDSuggestion, ORSuggestion);

  return [];
};

export default createAdvancedFilterSuggestions;
