import {
  Box, Collapse, ListSubheader, makeStyles, Theme, Typography,
} from '@material-ui/core';
import React, { memo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import { EditorRequest } from '../../../../../../../types/layout/topBar/search/editor';
import { NeoSearchEntityTypeEnum } from '../../../../types/NeoSearch';
import { createKey } from '../../utils/NeoSearchSuggestionServiceUtils';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';
import { NeoBarConfiguration } from '../../../../types/NeoBarConfiguration';
import { getShortcutIcon, getShortcutText } from '../../utils/NeoSearchShortcutUtils';

interface NeoSearchTextSuggestionsPropTypes {
    configuration: NeoBarConfiguration;
    suggestions: Array<Suggestion<NeoSearchEntityTypeEnum>>;
    onEditorRequest: (request: EditorRequest<NeoSearchEntityTypeEnum>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
  previewWrapper: {
    textTransform: 'uppercase',
    cursor: 'hand',
    padding: theme.spacing(1),
  },
}));

function NeoSearchTextSuggestions(props: NeoSearchTextSuggestionsPropTypes): JSX.Element {
  const {
    suggestions, onEditorRequest, configuration,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);

  const handleOnClick = (suggestion) => (): void => {
    onEditorRequest(suggestion.request);
  };

  return (
      <Collapse in={suggestions.length > 0}>
          <ListSubheader disableSticky component="div" id="text-suggestions">
              {t('neoBar.neoSearch.type.suggestions')}
          </ListSubheader>
          <Box className={classes.root}>
              {suggestions.map((suggestion) => (
                  <Box
                    key={createKey(suggestion.request)}
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                  >
                      <Box
                        className={clsx(classes.previewWrapper)}
                        onClick={handleOnClick(suggestion)}
                      >
                          {suggestion.preview}
                      </Box>
                      <Box display="flex" alignItems="center" alignContent="center">
                          {getShortcutIcon(configuration)}
                          <Typography>{getShortcutText(configuration, t)}</Typography>
                      </Box>
                  </Box>

              ))}
          </Box>
      </Collapse>
  );
}

export default memo(NeoSearchTextSuggestions);
