import {
  Box, Collapse, InputBase, List, Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  EMPTY_STRING, EventTypesEnum, NOT_FOUND, NotFound, PowerSearchFilterTypes, useScheduler,
} from 'mediascouting-core-ui-common';
import { Search } from '@material-ui/icons';
import CircleButton from '../../../../CircleButton';
import SectionSubHeader from './SectionSubHeader';
import { NavigationSectionItem, NavigationBarSection } from '../../../../../../types/layout/navBar';
import NeoBarItem from '../../NeoBarItem';
import TranslationNameSpaceEnum from '../../../../../../types/translation/TranslationNameSpaceEnum';
import EmptySection from './EmptySection';
import { NeoBarItemSettings } from '../../../types/NeoSearch';
import { QuerySearchSpaceReducer } from '../../../../../../redux/reducers/QuerySearchSpace';
import { ReduxState } from '../../../../../../redux/reducers';
import { NeoBarConfiguration } from '../../../types/NeoBarConfiguration';

interface SectionPropTypes {
    section: NavigationBarSection;
    configuration: NeoBarConfiguration;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  loadNext: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  lowOpacity: {
    opacity: 0.25,
  },
}));

const useInputStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
  input: {
    fontSize: 14,
  },
  adornedStart: {
    '& > *:first-child': {
      fontSize: 20,
      color: theme.palette.grey[500],
      marginRight: theme.spacing(1),
    },
  },
}));

const getNavigationItemSettings = (
  item: NavigationSectionItem,
  querySearchSpace: QuerySearchSpaceReducer,
): NeoBarItemSettings => {
  const itemSelected = (querySearchSpace.query?.id === item.id)
      || (querySearchSpace[PowerSearchFilterTypes.TAGS][0]?.id === item.id);

  return {
    selected: itemSelected,
    live: item.live,
  };
};

function Section(props: SectionPropTypes): JSX.Element {
  const { section, configuration } = props;
  const classes = useStyles();
  const inputStyles = useInputStyles();
  const { onSchedule } = useScheduler();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const querySearchSpace = useSelector((redux: ReduxState) => redux.querySearchSpace);

  const renderEmptyContent = (config: NavigationBarSection): JSX.Element => {
    if (config.loading) {
      return <EmptySection emptyText={t('categorySubHeader.wait')} />;
    }

    return <EmptySection emptyText={t(`${config.messages.noItemsAvailableI18Key}`)} />;
  };

  const renderNavigationItems = (items: Array<NavigationSectionItem>): JSX.Element => {
    const elements = items.map((item) => (
        <NeoBarItem
          key={item.id}
          type={item.type}
          item={item}
          configuration={configuration}
          settings={getNavigationItemSettings(item, querySearchSpace)}
        />
    ));

    return (
        <>
            {elements}
        </>
    );
  };

  const handleInputChange = (currentSection: NavigationBarSection) => (event): void => {
    if (currentSection.handlers.onSearch && event?.type === EventTypesEnum.CHANGE) {
      const { handlers: { onSearch } } = currentSection;
      const value = event?.target?.value || EMPTY_STRING;

      onSchedule(() => onSearch(value, []), configuration.search.autoSearchInterval);
    }
  };

  const handleLoadMoreClick = (): void => {
    if (section.handlers.onNextPage) {
      section.handlers.onNextPage();
    }
  };

  const getNextPageElement = (): React.ReactElement | NotFound => {
    if (section.handlers.onNextPage) {
      return (
          <Box
            id={`load-next-${section.subheaderTextI18Key}`}
            className={classes.loadNext}
          >
              <CircleButton
                onClick={handleLoadMoreClick}
                disabled={section.loading}
                startIcon={<ExpandMoreIcon />}
                collapsed
                size="small"
                text={t('neoBar.content.section.loadMore')}
              />
          </Box>
      );
    }

    return NOT_FOUND;
  };

  const getClassicSearch = (): React.ReactElement | NotFound => {
    if (!configuration.search.neoSearch.enabled && section.handlers.onSearch) {
      return (
          <InputBase
            id={`input-${section.subheaderTextI18Key}`}
            classes={inputStyles}
            fullWidth
            placeholder={t('categorySubHeader.search')}
            onChange={handleInputChange(section)}
            startAdornment={<Search />}
          />
      );
    }

    return NOT_FOUND;
  };

  const getSectionItems = (): React.ReactElement | NotFound => {
    if (section.items.length > 0) {
      return (
          <>
              {renderNavigationItems(section.items)}
              {getNextPageElement()}
          </>
      );
    }

    return renderEmptyContent(section);
  };

  const getSectionContent = (): JSX.Element => (
      <Box className={clsx({ [classes.lowOpacity]: section.loading })}>
          {getClassicSearch()}
          <Collapse key={`collapse-${section.subheaderTextI18Key}`} in={!section.collapsed}>
              <div>
                  {getSectionItems()}
              </div>
          </Collapse>
      </Box>
  );

  const getSubheader = (): JSX.Element => (
      <Box px={2}>
          <SectionSubHeader section={section} />
      </Box>
  );

  return (
      <List
        key={section.subheaderTextI18Key}
        subheader={getSubheader()}
        dense
      >
          {getSectionContent()}
      </List>
  );
}

export default Section;
