import {
  Box, IconButton, ListSubheader, Tooltip,
} from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING, NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';
import { NavigationBarSection, NeoBarItemType } from '../../../../../../../types/layout/navBar';
import SectionTagControl from './SectionTagControl';

interface SectionSubHeaderPropTypes {
  section: NavigationBarSection;
}

const useStyles = makeStyles(() => createStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function SectionSubHeader(props: SectionSubHeaderPropTypes): JSX.Element {
  const classes = useStyles();
  const { section } = props;
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);

  const getCollapseSectionIcon = (): JSX.Element => {
    if (!section.collapsed) {
      return (
          <Tooltip title={t('neoBar.content.section.collapse') || EMPTY_STRING}>
              <IconButton onClick={section.handlers.onToggleCollapsed}>
                  <ExpandLessIcon />
              </IconButton>
          </Tooltip>
      );
    }

    return (
        <Tooltip title={t('neoBar.content.section.expand') || EMPTY_STRING}>
            <IconButton onClick={section.handlers.onToggleCollapsed}>
                <ExpandMoreIcon />
            </IconButton>
        </Tooltip>
    );
  };

  const getTagControlWhenSectionRefersToTags = (): JSX.Element | NotFound => {
    if (section.type === NeoBarItemType.TAG_TYPE && !section.collapsed) {
      return (
          <SectionTagControl section={section} />
      );
    }

    return NOT_FOUND;
  };

  return (
      <ListSubheader
        disableGutters
        disableSticky
        className={classes.flexContainer}
      >
          <Box className={classes.flexContainer}>
              {getCollapseSectionIcon()}
              {t(section.subheaderTextI18Key)}
          </Box>
          {getTagControlWhenSectionRefersToTags()}
      </ListSubheader>
  );
}

export default SectionSubHeader;
