import {
  APPLY_VIEW_TYPE,
  ENTER_FULL_SCREEN,
  EXIT_FULL_SCREEN, SET_DISABLE_FACETS,
  SET_DISPLAY_ERROR_NOTIFICATIONS, SET_NEO_BAR,
} from '../types/ScreenSettingsActionTypes';
import { ViewTypeEnum } from '../../../types/query/results/ViewTypes';
import { NeoBarConfiguration } from '../../../components/common/NeoBar/types/NeoBarConfiguration';

export const enterFullScreen = () => ({
  type: ENTER_FULL_SCREEN,
} as const);

export const exitFullScreen = () => ({
  type: EXIT_FULL_SCREEN,
} as const);

export const applyViewType = (view: ViewTypeEnum) => ({
  type: APPLY_VIEW_TYPE,
  payload: view,
} as const);

export const setDisplayErrorNotifications = (display: boolean) => ({
  type: SET_DISPLAY_ERROR_NOTIFICATIONS,
  payload: display,
} as const);

export const setDisableFacets = (disable: boolean) => ({
  type: SET_DISABLE_FACETS,
  payload: disable,
} as const);

export const setNeoBar = (neoBarConfig: NeoBarConfiguration) => ({
  type: SET_NEO_BAR,
  payload: neoBarConfig,
} as const);

export type SettingsActions =
    | ReturnType<typeof enterFullScreen>
    | ReturnType<typeof exitFullScreen>
    | ReturnType<typeof applyViewType>
    | ReturnType<typeof setDisplayErrorNotifications>
    | ReturnType<typeof setDisableFacets>
    | ReturnType<typeof setNeoBar>
