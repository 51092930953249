import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotFound } from 'mediascouting-core-ui-common';
import { ReduxState } from '../redux/reducers';
import { HeartbeatDataChange, HeartbeatMonitor } from '../redux/reducers/Heartbeat';

interface HeartbeatMonitorAlert extends HeartbeatMonitor {
  act: () => void;
}

interface UseHeartbeatPropTypes {
  monitors: Array<HeartbeatMonitorAlert>;
}

const useHeartbeat = (props: UseHeartbeatPropTypes): void => {
  const { monitors } = props;
  const action = useSelector((rootState: ReduxState) => rootState.heartbeat.action);

  const getMatchingMonitorConditions = useCallback((monitor: HeartbeatMonitor): Array<boolean> => {
    const conditions: Array<boolean> = [];

    conditions.push(monitor.type === action?.type);
    conditions.push(monitor.cause === HeartbeatDataChange.ANY ? true : monitor.cause === action?.cause);

    return conditions;
  }, [action]);

  const findMatchingMonitor = useCallback(
    (): HeartbeatMonitorAlert | NotFound => monitors
      .find((monitor) => !getMatchingMonitorConditions(monitor).some((boolean) => !boolean)),
    [getMatchingMonitorConditions, monitors],
  );

  const act = useCallback((): void => {
    const found = findMatchingMonitor();

    if (found) {
      found.act();
    }
  }, [findMatchingMonitor]);

  useEffect(() => {
    act();
  }, [act, action]);
};

export default useHeartbeat;
