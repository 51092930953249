import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box, Collapse, Divider, Hidden, Theme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { memo } from 'react';
import { NOT_FOUND, NotFound } from 'mediascouting-core-ui-common';
import { EditorState } from 'draft-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NavigationBarSection } from '../../../../../types/layout/navBar';
import { compare } from '../../../../../utils/common/SortingUtils';
import NeoSearch from '../NeoSearch';
import Logo from '../../../Logo';
import Section from './Section';
import { NeoBarConfiguration } from '../../types/NeoBarConfiguration';

interface NeoBarContentPropTypes {
    logo: string | NotFound;
    sections?: Array<NavigationBarSection>;
    configuration: NeoBarConfiguration;
    onSearch: (editorState: EditorState) => void;
    onSearchEditorStateChanged: (editorState: EditorState) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  logoWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function NeoBarContent(props: NeoBarContentPropTypes): JSX.Element {
  const {
    sections, logo, configuration, onSearch, onSearchEditorStateChanged,
  } = props;
  const classes = useStyles();

  const getLoading = (): boolean => sections?.find((s) => s.loading) !== NOT_FOUND;

  const renderSections = (): JSX.Element => {
    if (sections) {
      return (
          <div>
              {sections.sort(compare).map((section) => (
                  <Collapse key={`hide-${section.subheaderTextI18Key}`} in={!section.hidden}>
                      <Section
                        key={section.subheaderTextI18Key}
                        section={section}
                        configuration={configuration}
                      />
                  </Collapse>
              ))}
          </div>
      );
    }

    return <div />;
  };

  const getNeoSearch = (): React.ReactNode | NotFound => {
    if (configuration.search.neoSearch.enabled) {
      return (
          <NeoSearch
            onSearch={onSearch}
            loading={getLoading()}
            onSearchEditorStateChanged={onSearchEditorStateChanged}
            configuration={configuration}
          />
      );
    }

    return NOT_FOUND;
  };

  return (
      <Box className={classes.container}>
          <Hidden lgUp>
              <Box className={classes.logoWrapper}>
                  <RouterLink to="/">
                      <Logo logo={logo} />
                  </RouterLink>
              </Box>
              <Divider />
          </Hidden>
          {getNeoSearch()}
          <PerfectScrollbar options={{ suppressScrollX: true }}>
              <Box py={2}>
                  {renderSections()}
              </Box>
          </PerfectScrollbar>
      </Box>
  );
}

export default memo(NeoBarContent);
