import React, { useCallback, useMemo } from 'react';
import {
  DEFAULT_FILES_DIRECTORY_PREFIX,
  EMPTY_STRING,
  GetItemsResponse,
  INIT_PAGINATION,
  LanguageSettingsEnum,
  MojitoSelect,
  NotFound,
  Nullable,
  Shallow,
} from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { LOCAL_STORAGE_DEFAULT_LANGUAGE_KEY } from '../../../../constants/LocalStorage';

interface Language extends Shallow{
  value: string;
}

interface LanguageSettingsPropTypes{
  languages: Array<string>;
}
export const getLanguageValueWithoutPrefix = (): string | NotFound => localStorage
  .getItem(LOCAL_STORAGE_DEFAULT_LANGUAGE_KEY)
    ?.replace(`${DEFAULT_FILES_DIRECTORY_PREFIX}/`, EMPTY_STRING);

const UNKNOWN_LANGUAGE = 'UNKNOWN LANGUAGE';
function LanguageSettings(props: LanguageSettingsPropTypes): JSX.Element {
  const { languages } = props;
  const { t } = useTranslation();

  const languageNameMapper: {[key in LanguageSettingsEnum]: string} = useMemo(() => ({
    [LanguageSettingsEnum.ENGLISH]: 'English',
    [LanguageSettingsEnum.ARABIC]: t('layout.topBar.search.searchBar.settings.language.name.arabic'),
    [LanguageSettingsEnum.SPANISH]: t('layout.topBar.search.searchBar.settings.language.name.spanish'),
  }), [t]);

  const constructLanguages = useCallback(() => languages.map((innerLanguage, index) => {
    const displayName = languageNameMapper[innerLanguage];
    const constructedLanguage: Language = { id: index, name: displayName || UNKNOWN_LANGUAGE, value: innerLanguage };
    return constructedLanguage;
  }), [languageNameMapper, languages]);

  const language = constructLanguages()
    .find((innerLanguage) => innerLanguage.value === getLanguageValueWithoutPrefix());

  const fetchLanguages = useCallback((): Promise<GetItemsResponse<Language>> => Promise
    .resolve({
      content: constructLanguages(),
      ...INIT_PAGINATION,
    }), [constructLanguages]);

  const handleLanguageChange = useCallback(<T extends Nullable<Language>> (
    event: Record<string, any>,
    value: T,
  ): void => {
    const lang = value as Language;
    if (lang) {
      localStorage.setItem(LOCAL_STORAGE_DEFAULT_LANGUAGE_KEY, `${DEFAULT_FILES_DIRECTORY_PREFIX}/${lang.value}`);
      window.location.reload(false);
    }
  }, []);

  return (
      <>
          <FormLabel component="legend">
              <Typography variant="overline">
                  {t('layout.topBar.search.searchBar.settings.language')}
              </Typography>
          </FormLabel>
          <MojitoSelect<Language>
            value={language}
            onGetOptionSelected={(option, value): boolean => option.id === value.id}
            onGetOptionLabel={(option): string => option.name}
            onFetchData={fetchLanguages}
            onChange={handleLanguageChange}
          />
      </>
  );
}

export default LanguageSettings;
