import React from 'react';
import { FormikHelpers } from 'formik';
import FormFields, { TopicTypeConfigurationFormFields } from './FormFields';

export interface TopicTypeConfigurationPropTypes {
    onSubmit: (
        values: TopicTypeConfigurationFormFields,
        helpers: FormikHelpers<TopicTypeConfigurationFormFields>,
    ) => Promise<void>;
    onBack: VoidFunction;
    onClose: VoidFunction;
}

function TopicTypeConfiguration(props: TopicTypeConfigurationPropTypes): JSX.Element {
  const { onSubmit, onBack, onClose } = props;

  return (
      <FormFields
        onSubmit={onSubmit}
        onClose={onClose}
        onBack={onBack}
      />
  );
}

export default TopicTypeConfiguration;
