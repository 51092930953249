import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { EditorState } from 'draft-js';
import {
  NOT_FOUND, NotFound, SPACE,
} from 'mediascouting-core-ui-common';
import { Box, Collapse, Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import NeoSearchFilteringSuggestions from './NeoSearchFilteringSuggestions';
import { AdditionRequest, EditorRequest } from '../../../../../../../types/layout/topBar/search/editor';
import { Suggestion } from '../../../../../../../types/layout/topBar/search/suggestion';
import {
  createNeoSearchSuggestions, findSuggestionRelatedToText,
  rewriteAdditionSuggestionToAlsoReplacePartialText,
} from '../../utils/NeoSearchSuggestionServiceUtils';
import { NeoSearchEntityTypeEnum } from '../../../../types/NeoSearch';
import NeoSearchTextSuggestions from './NeoSearchTextSuggestions';
import TranslationNameSpaceEnum from '../../../../../../../types/translation/TranslationNameSpaceEnum';
import { NeoBarConfiguration, TypingSearchSuggestionModeEnum } from '../../../../types/NeoBarConfiguration';
import { getShortcutIcon, getShortcutText } from '../../utils/NeoSearchShortcutUtils';

interface NeoSearchSuggestionServicePropTypes {
    editorState: EditorState;
    expandFilters: boolean;
    configuration: NeoBarConfiguration;
    suggestedSuggestion: Suggestion<NeoSearchEntityTypeEnum> | NotFound;
    onChangeSuggestedSuggestion: React.Dispatch<React.SetStateAction<(Suggestion<NeoSearchEntityTypeEnum>) | NotFound>>;
    onEditorRequest: (request: EditorRequest<NeoSearchEntityTypeEnum>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: '1px solid #dcdcdc',
    borderRadius: '0 0 4px 4px',
    maxHeight: '50vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
  previewWrapper: {
    textTransform: 'uppercase',
    cursor: 'hand',
    padding: theme.spacing(1),
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    minWidth: 30,
    maxWidth: 295,
    width: '100%',
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
}));

function NeoSearchSuggestionService(props: NeoSearchSuggestionServicePropTypes): JSX.Element {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    editorState, expandFilters, suggestedSuggestion, configuration, onEditorRequest,
    onChangeSuggestedSuggestion,
  } = props;
  const [filteringSuggestions, setFilteringSuggestions] = useState<Array<Suggestion<NeoSearchEntityTypeEnum>>>([]);
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);

  const loadFilteringSuggestions = useCallback(() => {
    const filterSuggestions = createNeoSearchSuggestions(configuration, t);

    setFilteringSuggestions(filterSuggestions);
  }, [configuration, t]);

  const getOptionalSuggestionBasedOnLatestWord = useCallback((
    lastSplit: string,
  ): Suggestion<NeoSearchEntityTypeEnum> | NotFound => {
    if (lastSplit.length > 2) {
      const found = findSuggestionRelatedToText(filteringSuggestions, lastSplit);

      if (found && (found.request as AdditionRequest<NeoSearchEntityTypeEnum>)?.additionText) {
        const foundAddition = found.request as AdditionRequest<NeoSearchEntityTypeEnum>;

        return rewriteAdditionSuggestionToAlsoReplacePartialText(foundAddition, found, lastSplit);
      }
    }

    return NOT_FOUND;
  }, [filteringSuggestions]);

  const getTextRecommendation = useCallback((
    currentEditorState: EditorState,
  ): Suggestion<NeoSearchEntityTypeEnum> | NotFound => {
    const text = currentEditorState.getCurrentContent().getPlainText();
    const splits = text.split(SPACE);
    const lastSplit = splits[splits.length - 1];

    return getOptionalSuggestionBasedOnLatestWord(lastSplit);
  }, [getOptionalSuggestionBasedOnLatestWord]);

  const getOptionalSuggestion = useCallback((currentEditorState: EditorState): void => {
    if (configuration.search.neoSearch.suggestions.suggestOptionsWhileUserIsTyping) {
      const suggestion = getTextRecommendation(currentEditorState);

      onChangeSuggestedSuggestion(suggestion);
    }
  }, [
    configuration.search.neoSearch.suggestions.suggestOptionsWhileUserIsTyping,
    getTextRecommendation, onChangeSuggestedSuggestion,
  ]);

  const handleOnClick = useCallback((suggestion) => (): void => {
    onEditorRequest(suggestion.request);
  }, [onEditorRequest]);

  useEffect(() => {
    loadFilteringSuggestions();
  }, [loadFilteringSuggestions]);

  useEffect(() => {
    getOptionalSuggestion(editorState);
  }, [editorState, getOptionalSuggestion]);

  const getSuggestionListImplementationOfSuggesting = useCallback((): JSX.Element => (
      <Collapse in={suggestedSuggestion !== NOT_FOUND} timeout="auto" unmountOnExit>
          <NeoSearchTextSuggestions
            suggestions={suggestedSuggestion ? [suggestedSuggestion] : []}
            onEditorRequest={onEditorRequest}
            configuration={configuration}
          />
      </Collapse>
  ), [configuration, onEditorRequest, suggestedSuggestion]);

  const getPopperImplementationOfSuggesting = useCallback((): JSX.Element => (
      <Popper
        open={suggestedSuggestion !== NOT_FOUND}
        anchorEl={containerRef.current}
        placement="bottom-start"
        className={classes.popper}
      >
          <Box
            className={clsx(classes.previewWrapper)}
            onClick={handleOnClick(suggestedSuggestion)}
          >
              {suggestedSuggestion?.preview}
              <Box display="flex" alignItems="center" alignContent="center">
                  {getShortcutIcon(configuration)}
                  <Typography>{getShortcutText(configuration, t)}</Typography>
              </Box>
          </Box>
      </Popper>
  ), [classes.popper, classes.previewWrapper, configuration,
    handleOnClick, suggestedSuggestion, t]);

  const typingSuggestionRenderModeDecisions = useMemo(
    (): { [key in TypingSearchSuggestionModeEnum]: () => React.ReactNode } => ({
      [TypingSearchSuggestionModeEnum.LIST]: getSuggestionListImplementationOfSuggesting,
      [TypingSearchSuggestionModeEnum.POP_UP]: getPopperImplementationOfSuggesting,
    }), [getPopperImplementationOfSuggesting, getSuggestionListImplementationOfSuggesting],
  );

  const getTypingSuggestions = (): React.ReactNode => {
    if (configuration.search.neoSearch.suggestions.suggestOptionsWhileUserIsTyping) {
      const handler = typingSuggestionRenderModeDecisions[
        configuration.search.neoSearch.suggestions.displayTypingSuggestionMode
      ];

      if (handler) {
        return handler();
      }

      return getSuggestionListImplementationOfSuggesting();
    }

    return NOT_FOUND;
  };

  return (
      <div ref={containerRef} className={classes.paper}>
          {getTypingSuggestions()}
          <Collapse
            in={expandFilters && configuration.search.neoSearch.suggestions.allowUserToExploreOptions}
            timeout="auto"
            unmountOnExit
          >
              <NeoSearchFilteringSuggestions
                suggestions={filteringSuggestions}
                onEditorRequest={onEditorRequest}
              />
          </Collapse>
      </div>
  );
}

export default memo(NeoSearchSuggestionService);
