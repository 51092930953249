import {
  EMPTY_STRING, hasPermission, NOT_FOUND, NotFound, UserPermission,
} from 'mediascouting-core-ui-common';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircleButton from '../../../../../../CircleButton';
import Visible from '../../../../../../../auth/Visible';
import { NeoBarItemType, NavigationBarSection } from '../../../../../../../../types/layout/navBar';
import TranslationNameSpaceEnum from '../../../../../../../../types/translation/TranslationNameSpaceEnum';
import TagEditor from '../../../../../../../features/tag/TagEditor';
import useNeoBarTagManipulation from './hooks/useNeoBarTagManipulation';

interface SectionTagControlPropTypes {
    section: NavigationBarSection;
}

function SectionTagControl(props: SectionTagControlPropTypes): JSX.Element {
  const { section } = props;
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const {
    handleTagCreate, handleFetchTags, handleTagDelete, handleTagUpdate, tagPopperAnchorEl,
    handleTagEditorClose, handleTagEditClick,
  } = useNeoBarTagManipulation();

  const getTagButton = (): JSX.Element => {
    if (section.items.length > 0) {
      return (
          <Tooltip title={t('categorySubHeader.tags.edit') || EMPTY_STRING}>
              <EditIcon />
          </Tooltip>
      );
    }

    return (
        <Tooltip title={t('categorySubHeader.tags.create') || EMPTY_STRING}>
            <AddIcon />
        </Tooltip>
    );
  };

  const getTagElement = (): JSX.Element | NotFound => {
    if (section.type === NeoBarItemType.TAG_TYPE) {
      return (
          <Visible when={{ userCondition: hasPermission(UserPermission.OWN_TAGS) }}>
              <CircleButton
                onClick={handleTagEditClick}
                startIcon={getTagButton()}
                collapsed
              />
          </Visible>
      );
    }

    return NOT_FOUND;
  };

  return (
      <>
          {getTagElement()}
          <Visible when={{ userCondition: hasPermission(UserPermission.OWN_TAGS) }}>
              <TagEditor
                anchorEl={tagPopperAnchorEl}
                onClose={handleTagEditorClose}
                onDelete={handleTagDelete}
                onUpdate={handleTagUpdate}
                onCreate={handleTagCreate}
                onFetchTags={handleFetchTags}
              />
          </Visible>
      </>
  );
}

export default SectionTagControl;
