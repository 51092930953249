import React, {
  memo,
  MutableRefObject,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Drawer, Grid, makeStyles, Paper,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useResizeObserver from 'use-resize-observer';
import {
  AlertDialog,
  BEST_MATCH_STORY_DURATION_ID,
  createDeletePrompt,
  createInitialPowerSearchQueryFiltersState,
  createPowerSearchReducer,
  EMPTY_STRING,
  encodeFilters,
  FIVE_MINUTES_STORY_DURATION_ID,
  getFiltersWithValues,
  GetItemsResponse,
  getMissingData,
  getQuerySettingsFilterConfiguration,
  getSearchResults,
  MojitoSelectFetchDataParams,
  NOT_FOUND,
  NotFound,
  PowerSearchFilter,
  PowerSearchFilterConfiguration,
  PowerSearchFilterTypes,
  PowerSearchQueryFilters,
  QueryDTO,
  QueryFilterOptionShallow,
  QueryInfo,
  RemoteSearchRetrieverParams,
  removeObjectField,
  SET_POWER_SEARCH_QUERY_FILTERS,
  Shallow,
  SupportedRemoteDataRetrieverType,
} from 'mediascouting-core-ui-common';
import { useRouteMatch } from 'react-router-dom';
import { CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../redux/store';
import QuerySettings from './QuerySettings';
import SearchActions from './SearchActions';
import { createQuery, deleteQuery, updateQuery } from '../../../../remote/Query';
import IntelligentQuerySearchBar, {
  IntelligentQuerySearchBarExposedMethods,
} from '../../../../components/features/search/IntelligentQuerySearchBar';
import { ENTITY_TO_POWER_SEARCH_TYPE_MAPPER, EntityTypesEnum } from '../../../../constants/search/editor/EntityTypes';
import { PortalPathMatch } from '../../../../types/PortalTabs';
import { applyQuerySearchParameters } from '../../../../utils/query/QueryUrlUtils';
import { constructQueryDto } from '../../../../utils/query/QuerySearchRequestUtils';
import { createDataRemoteRetrievers, createSearchRemoteRetrievers } from '../../../../utils/data/RemoteRetrieverUtils';
import { SettingFormFields } from './QuerySettings/Footer/SettingsDialog';
import { CacheUpdate } from '../../../../constants/actions/types/CacheActionTypes';
import { addToCache } from '../../../../constants/actions/creators/CacheActions';
import useCache from '../../../../hooks/UseCache';
import {
  createPowerSearchFiltersFromQueryReducer,
  getSearchBarFilterConfiguration,
} from '../../../../utils/common/PowerSearchUtils';
import { IntelligentQuerySearchBarConfiguration } from '../../../../configuration/schema';
import Configuration from '../../../../configuration';
import { setSelection, setFacetGroups } from '../../../../constants/actions/creators/QueryResultsActions';
import { INIT_SELECT_TARGET } from '../../../../redux/reducers/QueryStoryResults';
import { createFacets } from '../../../../utils/query/FacetUtils';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%',
  },
  fullWidth: {
    width: '100%',
  },
}));

function Search(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { onRetrieveFromCache } = useCache();
  const { ref, width = 100 } = useResizeObserver<HTMLDivElement>();
  const match = useRouteMatch<PortalPathMatch>({ path: '/portal/:tab/:tab' });
  const intelligentQuerySearchBarRef = useRef<IntelligentQuerySearchBarExposedMethods | null>(null);
  const [displayQuerySettings, setDisplayQuerySettings] = useState(false);
  const [detachmentModeAnchorEl, setDetachmentModeAnchorEl] = React.useState(null);
  const querySearchSpace = useSelector((state: ReduxState) => state.querySearchSpace);
  const configuration = Configuration.schema.search;
  const cached = useSelector((state: ReduxState) => state.cache);
  const user = useSelector((state: ReduxState) => state.auth.user);
  const [selectedPowerSearchFilters, powerSearchReducerDispatch] = useReducer<Reducer<any, any>>(
    createPowerSearchReducer, createInitialPowerSearchQueryFiltersState(),
  );
  const [alertDialog, setAlertDialogOpen] = useState(() => createDeletePrompt(
    t('layout.topBar.search.query.delete.dialog'),
  ));
  const querySettingsConfiguration: PowerSearchFilterConfiguration = useMemo(
    () => getQuerySettingsFilterConfiguration(user, configuration.querySettings), [configuration.querySettings, user],
  );
  const cachedRef = useRef(cached);

  const getSearchFilterConfiguration = useCallback(
    (): IntelligentQuerySearchBarConfiguration => getSearchBarFilterConfiguration(
      user, configuration.filters,
    ), [configuration.filters, user],
  );

  const onHandleIntelligentQuerySearchBarFilterSearch = useCallback((
    type: EntityTypesEnum, search: string,
  ): Promise<Array<QueryFilterOptionShallow>> => {
    const retrievers = dispatch(createSearchRemoteRetrievers());
    const searchParams: RemoteSearchRetrieverParams = {
      searchText: search,
    };

    return getSearchResults(ENTITY_TO_POWER_SEARCH_TYPE_MAPPER[type], searchParams, cached, retrievers,
      {
        size: 200,
      })
      .then((response) => response.content.map((item) => ({
        id: item?.id !== NOT_FOUND ? item.id : -1,
        name: item?.name || EMPTY_STRING,
      })));
  },
  [cached, dispatch]);

  const handlePowerSearchFilterSearch = useCallback((
    type: PowerSearchFilter,
    params: MojitoSelectFetchDataParams,
    cancelToken: MutableRefObject<CancelTokenSource | NotFound>,
    otherParams?: object,
  ): Promise<GetItemsResponse<SupportedRemoteDataRetrieverType>> => {
    const retrievers = dispatch(createSearchRemoteRetrievers(cancelToken));
    const searchParams: RemoteSearchRetrieverParams = {
      searchText: params.searchText,
      requestedPage: params.requestedPage,
    };

    return getSearchResults(
      type, searchParams, cached, retrievers, otherParams, cancelToken,
    );
  }, [cached, dispatch]);

  const overrideCacheHandling = (): void => NOT_FOUND;

  const remoteDataRetrievers = useMemo(() => dispatch(
    createDataRemoteRetrievers(overrideCacheHandling),
  ), [dispatch]);

  const getMissingDataWrapper = useCallback((
    type: EntityTypesEnum, id: number,
  ): Promise<SupportedRemoteDataRetrieverType | NotFound> => getMissingData(
    ENTITY_TO_POWER_SEARCH_TYPE_MAPPER[type],
    id,
    remoteDataRetrievers,
    onRetrieveFromCache,
  ),
  [onRetrieveFromCache, remoteDataRetrievers]);

  const initializeEditor = useCallback((text: string, queryInfo?: QueryInfo): void => {
    if (intelligentQuerySearchBarRef.current) {
      intelligentQuerySearchBarRef.current.initializeEditor(
        text, cachedRef.current, getMissingDataWrapper, queryInfo,
      );
    }
  }, [getMissingDataWrapper]);

  const getSearchText = useCallback((): string => {
    if (intelligentQuerySearchBarRef.current) {
      try {
        return intelligentQuerySearchBarRef.current?.getSearchableText();
      } catch (e) {
        return EMPTY_STRING;
      }
    }

    return EMPTY_STRING;
  }, []);

  const handleSearch = useCallback((queryId?: number, storyDurationId?: number): void => {
    const params = new URLSearchParams(location.search);
    const updatedParams = applyQuerySearchParameters(
      params, selectedPowerSearchFilters, getSearchText, queryId, storyDurationId,
    );
    const pathname = match ? match.params.tab as string : '/portal/search';

    history.push({
      pathname,
      search: updatedParams.toString(),
    });

    setDisplayQuerySettings(false);
  }, [getSearchText, history, location.search, match, selectedPowerSearchFilters]);

  const onEnterSearch = useCallback(() => {
    setDetachmentModeAnchorEl(null);
    handleSearch();
  }, [handleSearch]);

  const handleQueryTuningOpen = useCallback((event): void => {
    event.stopPropagation();

    setDetachmentModeAnchorEl(null);
    setDisplayQuerySettings(true);
  }, []);

  const handleQueryTuningClose = useCallback((): void => {
    setDisplayQuerySettings(false);
  }, []);

  const handleCleanClick = useCallback((event): void => {
    event.stopPropagation();
    const pathname = match ? match.params.tab as string : '/portal/search';

    history.push({
      pathname,
      search: '?storyDuration=1',
    });
    dispatch(setSelection(INIT_SELECT_TARGET));
    dispatch(setFacetGroups(createFacets({}, Configuration.schema.facets)));
    initializeEditor(EMPTY_STRING);
  }, [dispatch, history, initializeEditor, match]);

  const handleSearchClick = useCallback((event): void => {
    event.stopPropagation();

    setDetachmentModeAnchorEl(null);
    handleSearch();
  }, [handleSearch]);

  const handleDrawerSearch = useCallback((): void => {
    handleSearch();
  }, [handleSearch]);

  const handlePowerSearchChange = useCallback((type: PowerSearchFilter, payload: Array<Shallow> | string | null) => {
    powerSearchReducerDispatch({ type, payload });

    if (payload && payload.length > 0) {
      const lastAdded = payload[payload.length - 1] as Shallow;
      const powerSearchBucketUpdate: CacheUpdate = {
        type,
        data: lastAdded,
      };

      dispatch(addToCache(powerSearchBucketUpdate));
    }
  }, [dispatch]);

  const handleQueryUpdate = useCallback((queryDto: Partial<QueryDTO>): Promise<void> => {
    const queryId = querySearchSpace.query?.id;

    if (queryId) {
      return dispatch(updateQuery(queryId, queryDto))
        .then(() => handleSearch(queryId, queryDto.storyDurationFilterId))
        .catch((e) => Promise.reject(e));
    }

    return Promise.reject();
  }, [dispatch, handleSearch, querySearchSpace.query]);

  const handleQueryCreate = useCallback((queryDto: Partial<QueryDTO>): Promise<void> => dispatch(createQuery(queryDto))
    .then((createdQueryDto) => {
      if (createdQueryDto) {
        const { id, storyDurationFilterId } = createdQueryDto;

        return handleSearch(id, storyDurationFilterId);
      }

      return history.push('/portal/search');
    })
    .catch((e) => Promise.reject(e)), [dispatch, handleSearch, history]);

  const handleSave = useCallback((values: SettingFormFields): Promise<void> => {
    const encodedFilters = encodeFilters(selectedPowerSearchFilters);
    const freeQueryText = getSearchText();
    const {
      name, description, isLiveQuery, shouldAutoPercolate,
    } = values;

    const queryDto = constructQueryDto(
      name, description, freeQueryText, encodedFilters, isLiveQuery, values.storyDurationId, shouldAutoPercolate,
    );

    setDisplayQuerySettings(false);

    if (querySearchSpace.query?.id) {
      return handleQueryUpdate(queryDto);
    }

    return handleQueryCreate(queryDto);
  }, [getSearchText, handleQueryCreate, handleQueryUpdate, querySearchSpace.query, selectedPowerSearchFilters]);

  const handleDelete = useCallback((): void => {
    const queryId = querySearchSpace.query?.id;

    if (queryId) {
      const deleteHandler = (): void => {
        setDisplayQuerySettings(false);

        dispatch(deleteQuery(queryId))
          .finally(() => {
            history.push('/portal/search');
            initializeEditor(EMPTY_STRING);
          });
      };

      setAlertDialogOpen((prevState) => ({ ...prevState, open: true, okHandler: deleteHandler }));
    }
  }, [dispatch, history, initializeEditor, querySearchSpace.query]);

  const handleShare = useCallback((): void => {
    const urlParams = new URLSearchParams(location.search);

    history.push({
      pathname: '/portal/shares',
      search: urlParams.toString(),
    });

    setDisplayQuerySettings(false);
  }, [history, location.search]);

  const handleIntelligentQuerySearchBarDetachment = (event): void => {
    setDetachmentModeAnchorEl(event.currentTarget);
  };

  const handleIntelligentQuerySearchBarUnDetachment = useCallback((): void => {
    setDetachmentModeAnchorEl(null);
  }, []);

  const handleAlertDialogClose = useCallback(() => {
    setAlertDialogOpen((prevState) => ({ ...prevState, open: false }));
  }, []);

  const handleAlertDialogOk = useCallback(() => {
    if (alertDialog.okHandler) {
      alertDialog.okHandler();
    }

    handleAlertDialogClose();
  }, [handleAlertDialogClose, alertDialog]);

  const handleIntelligentQuerySearchBarFailure = useCallback((): null => null, []);

  const handleInit = useCallback(() => {
    if (intelligentQuerySearchBarRef.current) {
      const editorText = intelligentQuerySearchBarRef.current?.getSearchableText();

      if (editorText !== querySearchSpace.searchText) {
        initializeEditor(querySearchSpace.searchText, NOT_FOUND);
      }
    }
  }, [initializeEditor, querySearchSpace.searchText]);

  useEffect(() => {
    handleInit();
  }, [querySearchSpace.query, handleInit]);

  useEffect(() => {
    const updatedPowerSearchFilters = createPowerSearchFiltersFromQueryReducer(querySearchSpace);

    powerSearchReducerDispatch({ type: SET_POWER_SEARCH_QUERY_FILTERS, payload: updatedPowerSearchFilters });
  }, [querySearchSpace]);

  useEffect(() => {
    cachedRef.current = cached;
  }, [cached]);

  const getActiveFilters = useCallback((): Array<Partial<PowerSearchQueryFilters>> => {
    let activeFilters: Partial<PowerSearchQueryFilters> = getFiltersWithValues(selectedPowerSearchFilters);
    if (activeFilters[PowerSearchFilterTypes.STORY_DURATION]?.id === FIVE_MINUTES_STORY_DURATION_ID) {
      activeFilters = removeObjectField(activeFilters, PowerSearchFilterTypes.STORY_DURATION);
    } else if (activeFilters[PowerSearchFilterTypes.STORY_DURATION]?.id === BEST_MATCH_STORY_DURATION_ID) {
      activeFilters = removeObjectField(activeFilters, PowerSearchFilterTypes.STORY_DURATION);
    }

    return Object.entries(activeFilters)
      .map(([key, value]) => ({ [key]: value }));
  }, [selectedPowerSearchFilters]);

  return (
      <>
          <Paper ref={ref} className={classes.fullWidth} onClick={handleIntelligentQuerySearchBarDetachment}>
              <Grid container className={classes.fullWidth} alignItems="center">
                  <Grid item xs={5} sm={7} md={8} lg={5} xl={8}>
                      <IntelligentQuerySearchBar
                        ref={intelligentQuerySearchBarRef}
                        anchorEl={detachmentModeAnchorEl}
                        width={width}
                        onSearch={onEnterSearch}
                        onClickAway={handleIntelligentQuerySearchBarUnDetachment}
                        onDataRequest={onHandleIntelligentQuerySearchBarFilterSearch}
                        onError={handleIntelligentQuerySearchBarFailure}
                        assistantOptions={(
                            <SearchActions
                              onClean={handleCleanClick}
                              onOpenSettings={handleQueryTuningOpen}
                              onSearch={handleSearchClick}
                              activeFilters={getActiveFilters()}
                            />
                        )}
                        configuration={getSearchFilterConfiguration()}
                      />
                  </Grid>
                  <Grid item xs={7} sm={5} md={4} lg={7} xl={4}>
                      <SearchActions
                        onClean={handleCleanClick}
                        onOpenSettings={handleQueryTuningOpen}
                        onSearch={handleSearchClick}
                        activeFilters={getActiveFilters()}
                      />
                  </Grid>
              </Grid>
          </Paper>
          <Drawer
            anchor="right"
            variant="temporary"
            open={displayQuerySettings}
            classes={{ paper: classes.drawer }}
            ModalProps={{ BackdropProps: { invisible: true } }}
            onClose={handleQueryTuningClose}
          >
              <QuerySettings
                query={querySearchSpace.query}
                previewNewItemsIsEnabled={querySearchSpace.previewNewItems}
                powerSearch={selectedPowerSearchFilters}
                synchronousQueryFilterOptions={cached}
                onSave={handleSave}
                onSearch={handleDrawerSearch}
                onDelete={handleDelete}
                onShare={handleShare}
                onClose={handleQueryTuningClose}
                onQueryFilterOptionSearch={handlePowerSearchFilterSearch}
                onChangePowerSearch={handlePowerSearchChange}
                configuration={querySettingsConfiguration}
              />
          </Drawer>
          <AlertDialog
            open={alertDialog.open}
            description={alertDialog.description}
            cancelText={alertDialog.cancelText}
            okText={alertDialog.okText}
            onClose={handleAlertDialogClose}
            onCancel={handleAlertDialogClose}
            onOk={handleAlertDialogOk}
          />
      </>
  );
}

export default memo(Search);
