import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  Box, Paper, TextField, useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  AlertDialog, createDeletePrompt, EMPTY_STRING, Tag, useIsMountedRef,
} from 'mediascouting-core-ui-common';
import { blue, blueGrey } from '@material-ui/core/colors';
import clsx from 'clsx';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import TranslationNameSpaceEnum from '../../../../types/translation/TranslationNameSpaceEnum';

interface FormFields {
  name: string;
  description: string;
  color: string;
}

interface EditorScreenPropTypes {
  tag?: Tag;
  onDelete: (id: number) => Promise<void>;
  onUpdate: (id: number, tag: Partial<Tag>) => Promise<Tag>;
  onCreate: (tag: Partial<Tag>) => Promise<Tag>;
  onCancel: () => void;
}

const useBorderedInputBaseStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 3,
    border: '2px solid',
    borderColor: blueGrey[200],
    '&:hover:not($disabled)': {
      borderColor: blueGrey[500],
    },
    '& > svg': {
      color: blueGrey[300],
    },
  },
  focused: {
    borderColor: blue[700],
    '&:hover:not($disabled)': {
      borderColor: blue[700],
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    '&:hover:not($disabled)': {
      borderColor: theme.palette.error.main,
    },
  },
  input: {
    padding: '0.625rem 0.5rem',
  },
  disabled: {
    borderColor: blueGrey[300],
    backgroundColor: blueGrey[100],
  },
  formControl: {
    'label + &': {
      marginTop: 22,
    },
  },
}));

const useBorderedInputLabelStyles = makeStyles(() => createStyles({
  root: {
    color: blueGrey[400],
    '&$focused:not($error)': {
      color: blue[700],
    },
  },
  error: {},
  focused: {},
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
    fontWeight: 500,
  },
}));

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',
    },
  },
  buttonRootOverride: {
    minWidth: 0,
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  colorOption: {
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
  selectedColor: {
    border: '1px solid gray',
  },
  icon: {
    color: 'white',
    marginTop: '6px',
  },
  colorText: {
    fontWeight: 500,
    color: '#78909c',
    fontSize: '0.9rem',
  },
}));

function EditingScreen(props: EditorScreenPropTypes): JSX.Element {
  const {
    tag, onDelete, onUpdate, onCreate, onCancel,
  } = props;
  const classes = useStyles();
  const borderedInputLabelStyles = useBorderedInputLabelStyles();
  const borderedInputBaseStyles = useBorderedInputBaseStyles();
  const isMounted = useIsMountedRef();
  const { t } = useTranslation([TranslationNameSpaceEnum.NAV_BAR, TranslationNameSpaceEnum.DEFAULT]);
  const theme = useTheme();
  const [alertDialog, setAlertDialogOpen] = useState(() => createDeletePrompt(
    t('categorySubHeader.tags.delete.dialog'),
  ));

  const colors = useMemo(() => [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.grey[300],
  ], [theme]);

  const initialFormValues: FormFields = useMemo(() => ({
    name: tag?.name || EMPTY_STRING,
    description: tag?.description || EMPTY_STRING,
    color: tag?.attributes?.color || theme.palette.grey[300],
  }), [tag, theme.palette.grey]);

  const validationSchema = useMemo(() => Yup.object().shape({
    name: Yup
      .string()
      .required(
        t(`${TranslationNameSpaceEnum.DEFAULT}:form.fields.required`,
          { field: t('categorySubHeader.tags.name') }),
      ),
    color: Yup
      .string()
      .required(
        t(`${TranslationNameSpaceEnum.DEFAULT}:form.fields.required`,
          { field: t('categorySubHeader.tags.color') }),
      ),
  }), [t]);

  const reset = useCallback((e, setStatus, setErrors, setSubmitting) => {
    if (isMounted.current) {
      setStatus({ success: false });
      setErrors({ submit: e.message });
      setSubmitting(false);
    }
  }, [isMounted]);

  const updateTag = (existingTag: Tag, values: FormFields, setErrors, setStatus, setSubmitting): void => {
    const updatedTag: Partial<Tag> = {
      name: values.name,
      description: values.description,
      attributes: {
        color: values.color,
      },
    };

    onUpdate(existingTag.id, updatedTag)
      .catch((e) => {
        reset(e, setStatus, setErrors, setSubmitting);
      });
  };

  const createTag = (values: FormFields, setErrors, setStatus, setSubmitting): void => {
    const constructedTag: Partial<Tag> = {
      name: values.name,
      description: values.description,
      attributes: {
        color: values.color,
      },
    };

    onCreate(constructedTag)
      .catch((e) => {
        reset(e, setStatus, setErrors, setSubmitting);
      });
  };
  const onFormSubmit = (values, {
    setErrors, setStatus, setSubmitting,
  }): void => {
    if (tag) {
      updateTag(tag, values, setErrors, setStatus, setSubmitting);
    } else {
      createTag(values, setErrors, setStatus, setSubmitting);
    }
  };

  const handleDelete = useCallback((setSubmitting: (submitting: boolean) => void): void => {
    const deleteHandler = (): void => {
      setSubmitting(true);

      if (tag) {
        onDelete(tag.id)
          .then(() => {
            if (isMounted.current) {
              setSubmitting(false);
            }
          })
          .catch((e) => e);
      }
    };

    setAlertDialogOpen((prevState) => ({ ...prevState, open: true, okHandler: deleteHandler }));
  }, [isMounted, onDelete, tag]);

  const handleColorChange = (
    updatedColor: string, setFieldValue: (field: string, value: string) => void,
  ): void => {
    setFieldValue('color', updatedColor);
  };

  const handleAlertDialogClose = useCallback(() => {
    setAlertDialogOpen((prevState) => ({ ...prevState, open: false }));
  }, []);

  const handleAlertDialogOk = useCallback(() => {
    if (alertDialog.okHandler) {
      alertDialog.okHandler();
    }

    handleAlertDialogClose();
  }, [handleAlertDialogClose, alertDialog]);

  return (
      <>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                setSubmitting,
              }): JSX.Element => (
                  <form onSubmit={handleSubmit}>
                      <div className={classes.header}>
                          <div>
                              <Typography variant="overline" color="inherit">
                                  {tag ? t('categorySubHeader.tags.update') : t('categorySubHeader.tags.create')}
                              </Typography>
                          </div>
                          <div>
                              <Button
                                disabled={isSubmitting}
                                onClick={onCancel}
                                classes={{ root: classes.buttonRootOverride }}
                                size="small"
                                color="primary"
                              >
                                  {t('categorySubHeader.tags.edit.cancel')}
                              </Button>
                              <Button
                                disabled={isSubmitting}
                                classes={{ root: classes.buttonRootOverride }}
                                type="submit"
                                size="small"
                                color="primary"
                              >
                                  {t('categorySubHeader.tags.edit.confirm')}
                              </Button>
                          </div>
                      </div>
                      <Paper className={classes.root}>
                          <TextField
                            id="name"
                            label={t('categorySubHeader.tags.name')}
                            name="name"
                            margin="dense"
                            fullWidth
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                              classes: borderedInputLabelStyles,
                            }}
                            InputProps={{ classes: borderedInputBaseStyles, disableUnderline: true }}
                          />
                          <TextField
                            id="description"
                            label={t('categorySubHeader.tags.description')}
                            name="description"
                            margin="dense"
                            fullWidth
                            multiline
                            rowsMax={4}
                            rows={1}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            value={values.description}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                              classes: borderedInputLabelStyles,
                            }}
                            InputProps={{ classes: borderedInputBaseStyles, disableUnderline: true }}
                          />
                          <Box pt={2}>
                              <Box pb={1}>
                                  <Typography className={classes.colorText}>
                                      {t('categorySubHeader.tags.color.select')}
                                  </Typography>
                              </Box>
                              <div className={classes.flexContainer}>
                                  {colors.map((color) => (
                                      <Button
                                        key={color}
                                        className={clsx(classes.buttonRootOverride)}
                                        onClick={(): void => handleColorChange(color, setFieldValue)}
                                      >
                                          <div
                                            className={clsx(classes.colorOption)}
                                            style={{
                                              backgroundColor: color,
                                            }}
                                          >
                                              {color === values.color && <CheckOutlinedIcon className={classes.icon} />}
                                          </div>
                                      </Button>
                                  ))}
                              </div>
                          </Box>
                          {tag && (
                              <Box pt={2}>
                                  <Button
                                    disabled={isSubmitting}
                                    onClick={(): void => handleDelete(setSubmitting)}
                                    className={classes.deleteButton}
                                    variant="contained"
                                    fullWidth
                                  >
                                      {t('categorySubHeader.tags.delete')}
                                  </Button>
                              </Box>
                          )}
                      </Paper>
                  </form>
              )}
          </Formik>
          <AlertDialog
            open={alertDialog.open}
            description={alertDialog.description}
            cancelText={alertDialog.cancelText}
            okText={alertDialog.okText}
            onClose={handleAlertDialogClose}
            onCancel={handleAlertDialogClose}
            onOk={handleAlertDialogOk}
          />
      </>
  );
}

export default memo(EditingScreen);
