import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export type CircleButtonStyleProps = {
  collapsed: boolean;
};

export type CircleButtonProps = {
  collapsed: boolean;
  text?: string;
  classes?: Partial<Record<CircleButtonClassKey, string>>;
} & ButtonProps;

export type CircleButtonClassKey = 'root' | 'label' | 'img' | 'startIcon';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: (styleProps: CircleButtonStyleProps) => ({
    minWidth: styleProps.collapsed ? 36 : 34,
    minHeight: styleProps.collapsed ? 36 : 18,
    backgroundColor: theme.palette.common.white,
    padding: `8px ${styleProps.collapsed ? '8px' : '24px'} 8px ${
      styleProps.collapsed ? '8px' : '16px'
    }`,
    borderRadius: 40,
    boxShadow:
        '0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)',
    '&:hover': {
      boxShadow:
          '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
      backgroundColor: '#fafafb',
    },
    '&:active': {
      backgroundColor: '#f1f3f4',
    },
  }),
  label: {
    fontFamily:
        "'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
    color: '#3c4043',
    textTransform: 'none' as const,
    fontWeight: 500,
  },
  startIcon: ({ collapsed }: CircleButtonStyleProps) => ({
    margin: collapsed ? 0 : '',
  }),
}), { name: 'CircleButton' });

const CircleButton = (props: CircleButtonProps): JSX.Element => {
  const { collapsed, text, ...other } = props;
  const styles = useStyles({ collapsed });
  const { ...buttonClasses } = styles;

  return (
      <Button
        disableRipple
        classes={buttonClasses}
        {...other}
      >
          {(!collapsed && text) && text}
      </Button>
  );
};

export default CircleButton;
