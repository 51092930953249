import PersonIcon from '@material-ui/icons/Person';
import ExploreIcon from '@material-ui/icons/Explore';
import BusinessIcon from '@material-ui/icons/Business';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import LabelIcon from '@material-ui/icons/Label';
import ShareIcon from '@material-ui/icons/Share';
import { SvgIcon } from '@material-ui/core';
import { NeoSearchEntityTypeEnum } from '../../../types/NeoSearch';

export const NEO_SEARCH_FILTER_ENTITY_TYPE_ICONS: { [key in NeoSearchEntityTypeEnum]: typeof SvgIcon } = {
  [NeoSearchEntityTypeEnum.PERSON]: PersonIcon,
  [NeoSearchEntityTypeEnum.GEOLOCATION]: ExploreIcon,
  [NeoSearchEntityTypeEnum.ORGANIZATION]: BusinessIcon,
  [NeoSearchEntityTypeEnum.GENERAL]: FindInPageIcon,
  [NeoSearchEntityTypeEnum.TOPIC]: TrendingUpIcon,
  [NeoSearchEntityTypeEnum.QUERY]: TextFieldsIcon,
  [NeoSearchEntityTypeEnum.TAG]: LabelIcon,
  [NeoSearchEntityTypeEnum.SHARED]: ShareIcon,
};

export default NEO_SEARCH_FILTER_ENTITY_TYPE_ICONS;
