/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import {
  AggregationsDTO,
  Author,
  COMMA,
  CurationAggregationDTO,
  DateAggregationDTO,
  DefaultAggregationDTO,
  Describable,
  EMPTY_STRING,
  getDateInUTCByAddingZ,
  GetItemsResponse,
  Identifiable,
  Market,
  NotFound,
  PowerSearchFilterTypes,
  Query,
  Source,
  Tag,
} from 'mediascouting-core-ui-common';
import { MutableRefObject } from 'react';
import { CancelTokenSource } from 'axios';
import {
  Facet, FacetConfiguration, FacetGroup, FacetType, ReduxFacet,
} from '../../types/query/results/Facets';
import FACET_TO_FILTER_MAPPER from '../../constants/search/facets/TransformationMappers';
import { DateSlugOption } from '../../types/query/results/DateSlug';
import getSlugDateRepresentation from './DateSlugUtils';
import { getTags } from '../../remote/Tag';
import { getAuthors } from '../../remote/Author';
import getMarkets from '../../remote/Market';
import { getQueries } from '../../remote/Query';
import { getSourcesWrapper } from '../data/RemoteRetrieverUtils';

enum QueryMatchReceiverCreatorType {
  SYSTEM = 0,
  CURATOR = 1,
  CLIENT = 2,
}

const createFacetDisplay = (value, count, name): Facet => ({
  value,
  count,
  name,
});

const createFacetGroup = (type, facets, count, selectionLimit, name): FacetGroup => ({
  type,
  facets,
  count,
  selectionLimit,
  name,
});

const createUserTagFacetGroup = (
  userTagAggregation: DefaultAggregationDTO | NotFound,
): FacetGroup => {
  const tagFacets: Array<Facet> = [];

  if (userTagAggregation) {
    Object.keys(userTagAggregation).forEach((id) => {
      const aggregationDetails = userTagAggregation[id];
      const tagName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, tagName);

      tagFacets.push(facet);
    });
  }

  return createFacetGroup(FacetType.TAGS, tagFacets, 0, 2, 'facets.name.tags');
};

const createCurationFacetGroup = (
  curationAggregation: CurationAggregationDTO | NotFound,
): FacetGroup => {
  const curationFacets: Array<Facet> = [];

  if (curationAggregation) {
    Object.keys(curationAggregation).forEach((id) => {
      const aggregationDetails = curationAggregation[id];

      Object.keys(aggregationDetails.nested).forEach((nestedId) => {
        const nestedDetails = aggregationDetails.nested[nestedId];
        const curationName = `${aggregationDetails.self.name} ${nestedDetails?.name}`;
        const typeId = QueryMatchReceiverCreatorType[nestedDetails?.name];
        const contextualId = `${aggregationDetails.self.actorId}-${typeId}`;
        const count = `${nestedDetails?.count}`;
        const facet = createFacetDisplay(contextualId, count, curationName);

        curationFacets.push(facet);
      });
    });
  }

  return createFacetGroup(FacetType.CURATION, curationFacets, 0, 2, 'facets.name.curation');
};

const createDateFacetGroup = (dates: Array<DateAggregationDTO> | NotFound): FacetGroup => {
  const dateFacets: Array<Facet> = [];

  if (dates) {
    dates.forEach((dateAggregationContent) => {
      const start = getDateInUTCByAddingZ(dateAggregationContent.start);
      const end = getDateInUTCByAddingZ(dateAggregationContent.end);

      if (start && end) {
        let dateName;

        if (start.getTime() === end.getTime()) {
          dateName = `On ${moment(start).format('MMMM Do YYYY, h:mm:ss a')}`;
        } else {
          dateName = `In between ${moment(start).format('MMMM Do YYYY, h:mm:ss a')} 
          and ${moment(end).format('MMMM Do YYYY, h:mm:ss a')}`;
        }

        const id = `${start.getTime()}-${end.getTime()}`;
        const count = dateAggregationContent.value || 0;
        const facet = createFacetDisplay(id, count, dateName);

        dateFacets.push(facet);
      }
    });
  }

  return createFacetGroup(FacetType.DATE, dateFacets, 0, 2, 'facets.name.dates');
};

const createLanguageFacetGroup = (
  languages: DefaultAggregationDTO | NotFound,
): FacetGroup => {
  const languageFacets: Array<Facet> = [];

  if (languages) {
    Object.keys(languages).forEach((id) => {
      const aggregationDetails = languages[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      languageFacets.push(facet);
    });
  }

  return createFacetGroup(FacetType.LANGUAGE, languageFacets, 0, 2, 'facets.name.languages');
};

const createSubqueryFacetGroup = (subqueries: DefaultAggregationDTO | NotFound): FacetGroup => {
  const sentimentFacets: Array<Facet> = [];

  if (subqueries) {
    Object.keys(subqueries).forEach((id) => {
      const aggregationDetails = subqueries[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      sentimentFacets.push(facet);
    });
  }

  return createFacetGroup(
    FacetType.SUB_QUERIES,
    sentimentFacets,
    subqueries?.docCount || 0,
    2,
    'facets.name.subQueries',
  );
};

const createSentimentFacetGroup = (sentiments: DefaultAggregationDTO | NotFound): FacetGroup => {
  const sentimentFacets: Array<Facet> = [];

  if (sentiments) {
    Object.keys(sentiments).forEach((id) => {
      const aggregationDetails = sentiments[id];
      const sentimentName = aggregationDetails.name;
      const modifiedName = sentimentName.replace('_', ' ');
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, modifiedName);

      sentimentFacets.push(facet);
    });
  }

  return createFacetGroup(FacetType.SENTIMENT, sentimentFacets, 0, 2, 'facets.name.sentiment');
};

const createAuthorsFacetGroup = (authors: DefaultAggregationDTO | NotFound): FacetGroup => {
  const authorsFacets: Array<Facet> = [];

  if (authors) {
    Object.keys(authors).forEach((id) => {
      const aggregationDetails = authors[id];
      const authorsName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, authorsName);

      authorsFacets.push(facet);
    });
  }

  return createFacetGroup(FacetType.AUTHORS, authorsFacets, 0, 2, 'facets.name.author');
};

const createDaysFacetGroup = (days: DefaultAggregationDTO | NotFound): FacetGroup => {
  const dayFacets: Array<Facet> = [];

  if (days) {
    Object.keys(days).forEach((id) => {
      const aggregationDetails = days[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      dayFacets.push(facet);
    });
  }

  return createFacetGroup(FacetType.DAYS, dayFacets, 0, 2, 'facets.name.days');
};

const createTimeSectionFacetGroup = (timeSections: DefaultAggregationDTO | NotFound): FacetGroup => {
  const timeSectionFacets: Array<Facet> = [];

  if (timeSections) {
    Object.keys(timeSections).forEach((id) => {
      const aggregationDetails = timeSections[id];
      const timeSectionName = aggregationDetails?.name || EMPTY_STRING;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, timeSectionName);

      timeSectionFacets.push(facet);
    });
  }

  return createFacetGroup(
    FacetType.TIME_SECTIONS,
    timeSectionFacets,
    0,
    2,
    'facets.name.time.sections',
  );
};

const createSourceTypeFacetGroup = (sourceTypes: DefaultAggregationDTO | NotFound): FacetGroup => {
  const sourceTypeFacets: Array<Facet> = [];

  if (sourceTypes) {
    Object.keys(sourceTypes).forEach((id) => {
      const aggregationDetails = sourceTypes[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      sourceTypeFacets.push(facet);
    });
  }

  return createFacetGroup(
    FacetType.SOURCE_TYPE,
    sourceTypeFacets,
    sourceTypes?.docCount || 0,
    2,
    'facets.name.time.sourceType',
  );
};

const createMediaFacetGroup = (sources: DefaultAggregationDTO | NotFound): FacetGroup => {
  const sourceFacets: Array<Facet> = [];

  if (sources) {
    Object.keys(sources).forEach((id) => {
      const aggregationDetails = sources[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      sourceFacets.push(facet);
    });
  }

  return createFacetGroup(
    FacetType.SOURCES,
    sourceFacets,
      sources?.docCount || 0,
      2,
      'facets.name.time.sources',
  );
};

const createMarketFacetGroup = (markets: DefaultAggregationDTO | NotFound): FacetGroup => {
  const marketFacets: Array<Facet> = [];

  if (markets) {
    Object.keys(markets).forEach((id) => {
      const aggregationDetails = markets[id];
      const marketName = aggregationDetails.name;
      const count = `${aggregationDetails.count}`;
      const facet = createFacetDisplay(id, count, marketName);

      marketFacets.push(facet);
    });
  }

  return createFacetGroup(
    FacetType.MARKET,
    marketFacets,
      markets?.docCount || 0,
      2,
      'facets.name.market',
  );
};

export const sortFacetsByCount = (
  facets: Array<Facet>,
): Array<Facet> => facets.sort(
  (a, b) => b.count - a.count,
);

const clearZeroCountAndSortFacetGroupFacets = (facetGroup: FacetGroup): FacetGroup => {
  const nonZeroCountFacets = facetGroup.facets.filter((facet) => facet.count > 0);

  return {
    ...facetGroup,
    facets: sortFacetsByCount(nonZeroCountFacets),
  };
};

export const createFacets = (
  aggregations: Partial<AggregationsDTO>,
  configuration: FacetConfiguration,
): Array<FacetGroup> => {
  const {
    marketAggregation, mediaAggregation, daysAggregation, sentimentAggregation, languageAggregation, dateAggregation,
    timeSectionAggregation, sourceTypeAggregation, subQueryAggregation,
    curationAggregation, userTagsAggregation, authorsAggregation,
  } = aggregations;
  const facetGroups: Array<FacetGroup> = [];

  const sourceFacetGroup = createSourceTypeFacetGroup(sourceTypeAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(sourceFacetGroup));

  const marketFacetGroup = createMarketFacetGroup(marketAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(marketFacetGroup));

  const mediaFacetGroup = createMediaFacetGroup(mediaAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(mediaFacetGroup));

  const timeSectionFacetGroup = createTimeSectionFacetGroup(timeSectionAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(timeSectionFacetGroup));

  const dayFacetGroup = createDaysFacetGroup(daysAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(dayFacetGroup));

  const sentimentFacetGroup = createSentimentFacetGroup(sentimentAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(sentimentFacetGroup));

  const authorsFacetGroup = createAuthorsFacetGroup(authorsAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(authorsFacetGroup));

  const subqueryFacetGroup = createSubqueryFacetGroup(subQueryAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(subqueryFacetGroup));

  const languageFacetGroup = createLanguageFacetGroup(languageAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(languageFacetGroup));

  const userTagFacetGroup = createUserTagFacetGroup(userTagsAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(userTagFacetGroup));

  const dateFacetGroup = createDateFacetGroup(dateAggregation);
  facetGroups.push(clearZeroCountAndSortFacetGroupFacets(dateFacetGroup));

  return facetGroups.filter((facetGroup) => !configuration.disabled.includes(facetGroup.type));
};

export const removeFacetFromSelected = (
  selectedFacets: Array<ReduxFacet>, facetValueToDelete: string,
): Array<ReduxFacet> => (
  [...selectedFacets].filter((selectedFacet) => selectedFacet.value !== facetValueToDelete)
);

export const addFacetToSelected = (
  selectedFacets: Array<ReduxFacet>,
  facetValueToAdd: string,
  facetGroupTypeToAdd: FacetType,
): Array<ReduxFacet> => {
  const selectedFacet = {
    type: facetGroupTypeToAdd,
    value: facetValueToAdd,
  };

  if (!selectedFacets.some((existingFacet) => existingFacet.type === selectedFacet.type
        && existingFacet.value.toString() === selectedFacet.value.toString())) {
    return [...selectedFacets, selectedFacet];
  }

  return selectedFacets;
};

export const parseFacetsFromUrl = (facetParams): Array<ReduxFacet> => {
  try {
    return facetParams.split('|')
      .filter((value) => value !== '')
      .flatMap((filterGroup) => {
        const splittedFacet = filterGroup.split(':');

        return (
          splittedFacet[1].substring(1, splittedFacet[1].length - 1).split(',').map((facet) => ({
            type: splittedFacet[0],
            value: facet,
          }))
        );
      });
  } catch {
    return [];
  }
};

export const buildFacetParams = (facetGroups: Array<FacetGroup>): string => facetGroups
  .map((facetGroup) => ({
    type: facetGroup.type,
    value: [...facetGroup.facets.map((facet) => facet.value)],
  }))
  .reduce((acc, facetGroup) => `${facetGroup.type}:[${facetGroup.value}]|${acc}`, '');

export const groupByFacetType = (reduxFacets: Array<ReduxFacet>): Array<FacetGroup> => [...reduxFacets]
  .reduce<Array<FacetGroup>>((acc, currValue) => {
    const found = acc.find((filterGroup) => filterGroup.type === currValue.type);

    if (found) {
      const facet: Facet = {
        value: currValue.value,
        name: EMPTY_STRING,
        count: 0,
      };

      found.facets.push(facet);
    } else {
      const facet: Facet = {
        value: currValue.value,
        name: EMPTY_STRING,
        count: 0,
      };

      acc.push({
        type: currValue.type,
        facets: [facet],
        name: EMPTY_STRING,
        selectionLimit: 2,
        count: 0,
      });
    }

    return acc;
  }, []);

export const getFacetsByExcludingFacetType = (
  reduxFacets: Array<ReduxFacet>, excludeType: string,
): Array<ReduxFacet> => reduxFacets.filter((facet) => facet.type !== excludeType);

export const getFacetsByType = (
  reduxFacets: Array<ReduxFacet>, type: string,
): Array<ReduxFacet> => reduxFacets.filter((facet) => facet.type === type);

export const encodeCurationFacets = (
  reduxFacets: Array<ReduxFacet>,
): string => getFacetsByType(reduxFacets, 'curation')
  .map((curationFacet) => curationFacet.value.split('-').join('|')).join(COMMA);

export const getFacetsWithDateSlug = (dateSlug: DateSlugOption, facets: Array<ReduxFacet>): Array<ReduxFacet> => {
  const slugRepresentation = getSlugDateRepresentation(dateSlug);

  if (slugRepresentation) {
    const dateSlugFacet: ReduxFacet = {
      value: slugRepresentation,
      type: FacetType.DATE,
    };

    return [...facets, dateSlugFacet];
  }

  return facets;
};

export const getActiveFacets = (facets: Array<ReduxFacet>): string => {
  let selectedFacetsInStringFormat = EMPTY_STRING;

  try {
    facets.forEach((reduxFacet) => {
      const mappedType = FACET_TO_FILTER_MAPPER[reduxFacet.type];

      if (reduxFacet.type === 'date') {
        const encodedDates = reduxFacet.value;
        const decodedDates = encodedDates.split('-');
        if (decodedDates[0] && decodedDates[1]) {
          const start = decodedDates[0];
          const end = decodedDates[1];
          const antlrFilter = `(start: (${start}) AND end: (${end}))`;
          const matcher = selectedFacetsInStringFormat.length > 0 ? ' AND ' : EMPTY_STRING;

          selectedFacetsInStringFormat = selectedFacetsInStringFormat.concat(`${matcher}${antlrFilter}`);
        }
      } else if (mappedType) {
        const filteredFacets = facets.filter((facet) => facet.type === reduxFacet.type);
        const ids = filteredFacets.map((value) => value.value).join(COMMA);
        const antlrFilter = `${mappedType}: (${ids})`;
        const matcher = selectedFacetsInStringFormat.length > 0 ? ' AND ' : EMPTY_STRING;

        selectedFacetsInStringFormat = selectedFacetsInStringFormat.concat(`${matcher}${antlrFilter}`);
      }
    });

    return selectedFacetsInStringFormat;
  } catch (e) {
    return EMPTY_STRING;
  }
};

export interface RemoteFacetSearchRetrievers {
  [retriever: string]: (
      params?: object, cancelToken?: MutableRefObject<CancelTokenSource | NotFound>
  ) => Promise<GetItemsResponse<Facet>>;
}

export const AVAILABLE_SEARCH_FACET_TYPES: Array<FacetType> = [
  FacetType.SOURCES,
  FacetType.AUTHORS,
  FacetType.MARKET,
  FacetType.TAGS,
  FacetType.SUB_QUERIES,
];
const convertToFacet = <T extends Describable & Identifiable> (
  response: Array<T>,
): Array<Facet> => response.map((tag): Facet => ({
    name: tag.name,
    value: tag.id.toString(),
    count: 0,
  }));

const convertResponseToFacet = <T extends Describable & Identifiable> (
  response: GetItemsResponse<T>): GetItemsResponse<Facet> => ({
    ...response,
    content: convertToFacet(response.content),
  });
export const createFacetSearchRemoteRetrievers = (
  cancelToken?: MutableRefObject<CancelTokenSource | NotFound>,
) => (dispatch): RemoteFacetSearchRetrievers => ({
  [FacetType.TAGS]: (
    params?: object,
  ): Promise<GetItemsResponse<Facet>> => dispatch(getTags(params, cancelToken?.current?.token))
    .then((response: GetItemsResponse<Tag>) => convertResponseToFacet<Tag>(response)),
  [FacetType.SOURCES]: (
    params?: object,
  ): Promise<GetItemsResponse<Facet>> => dispatch(getSourcesWrapper(params || {}, cancelToken))
    .then((response: GetItemsResponse<Source>) => convertResponseToFacet<Source>(response)),
  [FacetType.AUTHORS]: (
    params?: object,
  ): Promise<GetItemsResponse<Facet>> => dispatch(getAuthors(params, cancelToken?.current?.token))
    .then((response: GetItemsResponse<Author>) => convertResponseToFacet<Author>(response)),
  [FacetType.MARKET]: (
    params?: object,
  ): Promise<GetItemsResponse<Facet>> => dispatch(getMarkets(params, cancelToken?.current?.token))
    .then((response: GetItemsResponse<Market>) => convertResponseToFacet<Market>(response)),
  [FacetType.SUB_QUERIES]: (
    params?: object,
  ): Promise<GetItemsResponse<Facet>> => dispatch(getQueries(params, cancelToken?.current?.token))
    .then((response: GetItemsResponse<Query>) => convertResponseToFacet<Query>(response)),
});

export const FIXED_TYPE_TO_CACHE_TYPE_MAPPER = ({
  [FacetType.SOURCES]: PowerSearchFilterTypes.SOURCES,
  [FacetType.AUTHORS]: PowerSearchFilterTypes.AUTHORS,
  [FacetType.MARKET]: PowerSearchFilterTypes.MARKETS,
  [FacetType.TAGS]: PowerSearchFilterTypes.TAGS,
  [FacetType.SUB_QUERIES]: PowerSearchFilterTypes.SUBQUERIES,
});
