import React from 'react';
import { FormikHelpers } from 'formik';
import FormFields, { PersonTypeConfigurationFormFields } from './FormFields';

export interface PersonTypeConfigurationPropTypes {
    onSubmit: (
        values: PersonTypeConfigurationFormFields,
        helpers: FormikHelpers<PersonTypeConfigurationFormFields>,
    ) => Promise<void>;
    onBack: VoidFunction;
    onClose: VoidFunction;
}

function PersonTypeConfiguration(props: PersonTypeConfigurationPropTypes): JSX.Element {
  const { onSubmit, onBack, onClose } = props;

  return (
      <FormFields
        onSubmit={onSubmit}
        onBack={onBack}
        onClose={onClose}
      />
  );
}

export default PersonTypeConfiguration;
