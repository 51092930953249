import React, { useCallback, useMemo } from 'react';
import {
  Box, Button,
  Card,
  CardContent,
  CardHeader, DialogActions, DialogContent, DialogTitle,
  Divider,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import {
  EMPTY_STRING,
  getTranslationKeyWithPrefix,
} from 'mediascouting-core-ui-common';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import MainDetails from './MainDetails';
import TranslationNameSpaceEnum from '../../../../../types/translation/TranslationNameSpaceEnum';

export interface FormFieldsPropTypes {
    onSubmit: (
        values: OrganizationTypeConfigurationFormFields,
        helpers: FormikHelpers<OrganizationTypeConfigurationFormFields>,
    ) => Promise<void>;
    onBack: () => void;
    onClose: () => void;
}

export interface OrganizationTypeConfigurationFormFields {
    name: string;
    description: string;
    summary: string;
}

const useStyles = makeStyles(() => ({
  content: {
    minHeight: '70vh',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function FormFields(props: FormFieldsPropTypes): JSX.Element {
  const {
    onSubmit, onBack, onClose,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation([TranslationNameSpaceEnum.QUERY_TYPES, TranslationNameSpaceEnum.DEFAULT]);

  const initialFormValues: OrganizationTypeConfigurationFormFields = useMemo(() => ({
    name: EMPTY_STRING,
    description: EMPTY_STRING,
    summary: EMPTY_STRING,
  }), []);

  const validationSchema = useMemo(() => Yup.object().shape<Partial<OrganizationTypeConfigurationFormFields>>({
    name: Yup
      .string()
      .required(t(getTranslationKeyWithPrefix(
        'form.fields.required', TranslationNameSpaceEnum.DEFAULT,
      ),
      { field: t('item.organization.form.name') })),
    description: Yup.string(),
    summary: Yup.string(),
  }), [t]);

  const handleSubmit = useCallback((
    values: OrganizationTypeConfigurationFormFields,
    helpers: FormikHelpers<OrganizationTypeConfigurationFormFields>,
  ): Promise<void> => onSubmit(values, helpers), [onSubmit]);

  const getName = (): string => t('item.organization.header.title');

  const getDescription = (): string => t('item.organization.header.description');

  const getSubheader = (): JSX.Element => (
      <Box className={classes.flexContainer}>
          <Box pl={1}>
              <Typography variant="subtitle1" color="textPrimary">
                  {getName()}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                  {getDescription()}
              </Typography>
          </Box>
      </Box>
  );

  return (
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
          {(formikProps): JSX.Element => (
              <form onSubmit={formikProps.handleSubmit}>
                  <DialogTitle>
                      {t('item.organization.dialog.title')}
                  </DialogTitle>
                  <DialogContent dividers>
                      <Card>
                          <CardHeader subheader={getSubheader()} />
                          <Divider />
                          <CardContent className={classes.content}>
                              <FormGroup>
                                  <Grid container spacing={3}>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <MainDetails formik={formikProps} />
                                      </Grid>
                                  </Grid>
                              </FormGroup>
                          </CardContent>
                      </Card>
                  </DialogContent>
                  <DialogActions className={classes.flexContainer}>
                      <Button
                        color="primary"
                        autoFocus
                        onClick={onBack}
                        disabled={formikProps.isSubmitting}
                      >
                          {t('item.organization.dialog.action.back')}
                      </Button>
                      <Box className={classes.flexContainer}>
                          <Button
                            color="primary"
                            autoFocus
                            onClick={onClose}
                            disabled={formikProps.isSubmitting}
                          >
                              {t('item.organization.dialog.action.cancel')}
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={formikProps.isSubmitting}
                          >
                              {t('item.organization.dialog.action.submit')}
                          </Button>
                      </Box>
                  </DialogActions>
              </form>
          )}
      </Formik>
  );
}

export default FormFields;
