type DeliveryOption = {
  id: string;
  name: string;
};

export const MONTAGE_DELIVERY_OPTIONS: Array<DeliveryOption> = [
  {
    id: 'download',
    name: 'Download',
  },
  {
    id: 'stream',
    name: 'Stream',
  },
];

export const getDefaultDeliveryOption = (): DeliveryOption => MONTAGE_DELIVERY_OPTIONS[0];
