import React from 'react';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BusinessIcon from '@material-ui/icons/Business';
import ExploreIcon from '@material-ui/icons/Explore';
import PersonIcon from '@material-ui/icons/Person';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { BrokenImage } from '@material-ui/icons';
import { NeoSearchEntityTypeEnum } from '../types/NeoSearch';

const NEO_SEARCH_ENTITY_AVATAR_MAPPER: { [key in NeoSearchEntityTypeEnum]: React.ReactNode } = {
  [NeoSearchEntityTypeEnum.GENERAL]: <FindInPageIcon fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.ORGANIZATION]: <BusinessIcon fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.GEOLOCATION]: <ExploreIcon fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.PERSON]: <PersonIcon fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.TOPIC]: <TrendingUpIcon fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.TAG]: <BrokenImage fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.QUERY]: <BrokenImage fontSize="inherit" />,
  [NeoSearchEntityTypeEnum.SHARED]: <BrokenImage fontSize="inherit" />,
};

const getNeoSearchNavigationItemAvatarIcon = (type: NeoSearchEntityTypeEnum): React.ReactNode => {
  const icon = NEO_SEARCH_ENTITY_AVATAR_MAPPER[type];

  if (icon) {
    return icon;
  }

  return <BrokenImage fontSize="inherit" />;
};

export default getNeoSearchNavigationItemAvatarIcon;
