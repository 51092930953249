import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type CreativeButtonPropTypes = {
    text: string;
    onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    background:
    'linear-gradient(to right, #FFC371, #FF5F6D)',
    '&:hover': {
      transform: 'scale(1.1)',
    },

  },
  label: {
    color: theme.palette.common.white,
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 700,
  },
  contained: {
    minHeight: 30,
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0],
    },
  },
}));

function CreativeButton(props: CreativeButtonPropTypes): JSX.Element {
  const { text, onClick, ...rest } = props;
  const classes = useStyles();

  return (
      <Button
        classes={{
          root: classes.root,
          label: classes.label,
          contained: classes.contained,
        }}
        onClick={onClick}
        {...rest}
      >
          {text}
      </Button>
  );
}

export default memo(CreativeButton);
