import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import React, {
  createRef, memo, useEffect, useRef,
} from 'react';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { EMPTY_STRING } from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import {
  AdditionRequest,
  EditorRequest,
  SubstituteRequest,
} from '../../../../../types/layout/topBar/search/editor';
import { FocusState, Suggestion } from '../../../../../types/layout/topBar/search/suggestion';
import { createFocusState } from '../utils/FocusStateUtils';
import { isAdditionRequest, isSubstituteRequest } from '../utils/editor/draft-js/EditorRequestUtils';
import { EntityTypesEnum } from '../../../../../constants/search/editor/EntityTypes';

type IntelligentSuggestionsPropTypes = {
  suggestions: Array<Suggestion<EntityTypesEnum>>;
  onEditorRequest: (request: EditorRequest<EntityTypesEnum>) => void;
  focusIndex: number;
  focusTrigger: boolean;
  onChangeFocus: (focusState: FocusState) => void;
};

const createId = (request: EditorRequest<EntityTypesEnum>, index: number): string => {
  if (isAdditionRequest(request)) {
    const additionRequest = request as AdditionRequest<EntityTypesEnum>;

    return additionRequest.additionText + index;
  }

  if (isSubstituteRequest(request)) {
    const substituteRequest = request as SubstituteRequest<EntityTypesEnum>;

    return substituteRequest.substituteText + index;
  }

  return EMPTY_STRING + index;
};

function IntelligentSuggestions(props: IntelligentSuggestionsPropTypes): JSX.Element {
  const {
    suggestions, onEditorRequest, focusIndex, focusTrigger, onChangeFocus,
  } = props;
  const { t } = useTranslation();
  const elRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (elRefs.current.length !== suggestions.length) {
    elRefs.current = suggestions.map((_, i) => elRefs.current[i] || createRef());
  }

  const handleOnClick = (suggestion) => (): void => {
    onEditorRequest(suggestion.request);
    onChangeFocus(createFocusState(-1, false, false));
  };

  useEffect(() => {
    const focusWithinSuggestions = focusIndex >= 0 && focusIndex < suggestions.length;

    if (focusTrigger && focusWithinSuggestions) {
      const foundSuggestion = suggestions[focusIndex];

      if (foundSuggestion) {
        onChangeFocus(createFocusState(-1, false, false));
        onEditorRequest(foundSuggestion.request);
      }
    }
  }, [focusTrigger, focusIndex, onEditorRequest, suggestions, onChangeFocus]);

  useEffect(() => {
    const elRef = elRefs.current[focusIndex];

    if (elRef && elRef.current) {
      elRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [focusIndex, suggestions]);

  return (
      <Collapse in={suggestions.length > 0}>
          <Divider />
          <List subheader={(
              <ListSubheader disableSticky component="div" id="advanced-filter-checklist">
                  {t('layout.topBar.search.searchBar.suggestion.intelligent')}
              </ListSubheader>
          )}
          >
              {
                  suggestions.map((suggestion, index) => (
                      <ListItem
                        ref={elRefs.current[index]}
                        key={createId(suggestion.request, index)}
                        selected={index === focusIndex}
                        onClick={handleOnClick(suggestion)}
                        button
                      >
                          <ListItemText primary={suggestion.preview} />
                          {
                          (index === focusIndex) && (
                              <ListItemSecondaryAction>
                                  <ListItemIcon>
                                      <KeyboardReturnIcon />
                                  </ListItemIcon>
                              </ListItemSecondaryAction>
                          )
                        }
                      </ListItem>
                  ))
            }
          </List>
      </Collapse>
  );
}

export default memo(IntelligentSuggestions);
