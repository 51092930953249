import { ADD_TO_CART, REMOVE_FROM_CART, RESET_CART } from '../types/CartActionTypes';
import { ShoppingItem } from '../../../types/layout/topBar/Cart';

export const addToCart = (shoppingItem: ShoppingItem) => ({
  type: ADD_TO_CART,
  payload: shoppingItem,
} as const);

export const removeFromCart = (shoppingItem: ShoppingItem) => ({
  type: REMOVE_FROM_CART,
  payload: shoppingItem,
} as const);

export const resetCart = () => ({
  type: RESET_CART,
} as const);

export type CartActions =
    | ReturnType<typeof addToCart>
    | ReturnType<typeof removeFromCart>
    | ReturnType<typeof resetCart>;
