import React, { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ShoppingList from './ShoppingList';
import CreativeButton from './CreativeButton';
import { ShoppingItem } from '../../../../types/layout/topBar/Cart';

type CartPropTypes = {
    shoppingList: Array<ShoppingItem>;
    onCheckout: () => void;
};

const useStyles = makeStyles(() => ({
  root: {},
}));

function ShoppingCart(props: CartPropTypes): JSX.Element {
  const { shoppingList, onCheckout } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <Box className={classes.root}>
          <ShoppingList shoppingList={shoppingList} />
          <CreativeButton text={t('layout.topBar.search.searchBar.cart.checkout')} onClick={onCheckout} />
      </Box>

  );
}

export default memo(ShoppingCart);
